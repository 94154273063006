import React from 'react'
import { IconHomeChietKhau } from '../../constants/icon-const'
import { checkMinMax, convertCommission } from "../../functions/common-func"
import DisplayPrice from '../common/DisplayPrice'
import ShowComm from '../product-price/ShowComm'

const RenderPrice = (data, isPublisher) => {



    return (<>
        {!data.data.rangePrice && <span className="price">
            <DisplayPrice price={data.data.price} />
        </span>
        }
        {data.data.rangePrice && <span className="price">{checkMinMax(data.data.rangePrice.minPrice, data.data.rangePrice.maxPrice)}</span>}
        {isPublisher === 1 && (
            <p className="chiet-khau">
                <span>Hoa hồng</span>
                <IconHomeChietKhau />
            </p>
        )}
        {isPublisher === 1 && !data.data.rangePrice && (
            <span className="price__discount">
                <DisplayPrice
                    price={convertCommission(
                        data.data.price,
                        data.data.commissionValue,
                        data.data.commissionType
                    )}
                />
            </span>
        )}
        {isPublisher === 1 && data.data.rangePrice && (
            <ShowComm
                min={data.data.rangePrice.minPrice}
                max={data.data.rangePrice.maxPrice}
                commValue={data.data.commissionValue}
                commType={data.data.commissionType}
                className="in_search"
            />
        )}
    </>
    )
}
export default RenderPrice