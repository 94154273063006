import React, { useState } from 'react'
import { Form, DatePicker, Button } from 'antd'
import moment from 'moment'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { toggleOpenExportProduct} from '../../redux/actions/list-order-action'
import { IconClose } from '../../constants/icon-const'
import { toggleClassNoScroll } from '../../functions/common-func'

const { RangePicker } = DatePicker

function BoxExportOrder({ dispatch, openExportOrder }) {
    const [isOpenDp, setIsOpenDp] = useState(false)

    const handleCloseDp = () => {
        setIsOpenDp(false)
        toggleClassNoScroll()
    }

    const handleOpenDp = (open) => {
        setIsOpenDp(open)
        toggleClassNoScroll()
    }

    const renderExtraFooter = () => (
        <div className="headerPopup">
            <p>Chọn ngày</p>
            <button type="button" className="headerPopup__close" onClick={handleCloseDp}>
                <IconClose />
            </button>
        </div>
    )

    return (
        <div className={`box__filter ${openExportOrder ? 'open' : ''}`}>
            <div className="box__filter__content">
                <div className="box__filter__main">
                    <div className="filter__item">
                        <div className="box__title">
                            <h3>Xuất báo cáo</h3>
                            <div onClick={() => dispatch(toggleOpenExportProduct(false))}>
                                <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                                        fill="#BFBFBF"
                                    />
                                </svg>
                            </div>
                        </div>
                        <Form>
                            <Form.Item>
                                <label>Chọn theo khoảng thời gian</label>
                                <div className="vntDatepicker">
                                    <RangePicker
                                        className="w-100"
                                        open={isOpenDp}
                                        onOpenChange={handleOpenDp}
                                        allowClear={false}
                                        size={'large'}
                                        format={'DD-MM-YYYY'}
                                        inputReadOnly={true}
                                        renderExtraFooter={renderExtraFooter}
                                        value={[moment(), moment().add(1, 'days')]}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <label>Chọn theo trạng thái đơn hàng</label>
                                <div className="list__button">
                                    <Button size={'large'}>Thành công</Button>
                                    <Button size={'large'}>Chờ xử lý</Button>
                                    <Button size={'large'}>Thất bại</Button>
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" size="large" className="w-100">
                                    {' '}
                                    Tải file excel
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
            <div className="box__backdrop" onClick={() => dispatch(toggleOpenExportProduct(false))}></div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        openExportOrder: state.listOrderReducer.openExportOrder,
    }
}
export default withRouter(connect(mapStateToProps)(BoxExportOrder))
