import React from 'react'
import { ArrowLeftOutlined, SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { togglePopupBoxSearch, toggleYourCart } from '../../redux/actions/home-actions'
import { Link } from 'react-router-dom'
import { getSession } from '../../functions/common-func'
import { Input } from 'antd'

function Header({ isTop, dispatch, isPublisher, isLoggedIn }) {
    let user = getSession('userInfo')?.userInfo;
    return (
        <header
            className={`header`}
            style={{
                background:
                    isPublisher === 1
                        ? `url("https://dimuadi.vn/_static/img/common/headerPublisher.png") center bottom / cover no-repeat rgb(255, 255, 255)`
                        : `url("https://dimuadi.vn/_static/img/common/headerBuyer.png") center bottom / cover no-repeat rgb(255, 255, 255)`,
            }}
        >
            <div className="container">
                <div className="header__content">
                    <div className="header__info">
                        <div className={`header__name ${isLoggedIn ? '' : 'not__login'}`}>
                            <p>Chào {(user && user.username) || 'Bạn'}</p>
                        </div>
                        {isPublisher === 1 && (
                            <div className="header__bell">
                                <Link to={'/notification'}>
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.58003 4.08L6.15003 2.65C3.75003 4.48 2.17003 7.3 2.03003 10.5H4.03003C4.18003 7.85 5.54003 5.53 7.58003 4.08ZM19.97 10.5H21.97C21.82 7.3 20.24 4.48 17.85 2.65L16.43 4.08C18.45 5.53 19.82 7.85 19.97 10.5ZM18 11C18 7.93 16.36 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.63003 5.36 6.00003 7.92 6.00003 11V16L4.00003 18V19H20V18L18 16V11ZM12 22C12.14 22 12.27 21.99 12.4 21.96C13.05 21.82 13.58 21.38 13.84 20.78C13.94 20.54 13.99 20.28 13.99 20H9.99003C10 21.1 10.89 22 12 22Z"
                                            fill="white"
                                        />
                                    </svg>
                                    {/*<div className="header__bell__count">+9</div>*/}
                                </Link>
                            </div>
                        )}
                        {isPublisher !== 1 && isLoggedIn && (
                            <div className="header__bell" onClick={() => dispatch(toggleYourCart(true))}>
                                <ShoppingCartOutlined style={{ width: 25, height: 25, color: '#fff' }} />
                                {/*<div className="header__bell__count">+9</div>*/}
                            </div>
                        )}
                    </div>
                    <div className="header__input">
                        <div className="box__search">
                            <div
                                className={`box__search__content ${
                                    isTop ? (isPublisher === 1 ? 'fix__top' : 'fix__top__buyer') : ''
                                } `}
                                id="box__search__home"
                            >
                                <div className="back">
                                    <Link to={'/'}>
                                        <ArrowLeftOutlined className style={{ color: '#ffffff' }} />
                                    </Link>
                                </div>
                                {isPublisher === 1 ? (
                                    <div
                                        className="input__home__search w-100"
                                        onClick={() => dispatch(togglePopupBoxSearch(true))}
                                    >
                                        <Input
                                            size="large"
                                            suffix={<SearchOutlined />}
                                            placeholder="Bạn bán gì hôm nay..."
                                            readOnly
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className="input__home__search w-100"
                                        onClick={() => dispatch(togglePopupBoxSearch(true))}
                                    >
                                        <Input
                                            size="large"
                                            suffix={<SearchOutlined />}
                                            placeholder="Bạn mua gì hôm nay..."
                                            readOnly
                                            onClick={() => dispatch(togglePopupBoxSearch(true))}
                                        />
                                    </div>
                                )}
                                <div>
                                    {isPublisher === 1 && (
                                        <div className="header__bell">
                                            <Link to={'/notification'}>
                                                <svg
                                                    width={24}
                                                    height={24}
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M7.58003 4.08L6.15003 2.65C3.75003 4.48 2.17003 7.3 2.03003 10.5H4.03003C4.18003 7.85 5.54003 5.53 7.58003 4.08ZM19.97 10.5H21.97C21.82 7.3 20.24 4.48 17.85 2.65L16.43 4.08C18.45 5.53 19.82 7.85 19.97 10.5ZM18 11C18 7.93 16.36 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.63003 5.36 6.00003 7.92 6.00003 11V16L4.00003 18V19H20V18L18 16V11ZM12 22C12.14 22 12.27 21.99 12.4 21.96C13.05 21.82 13.58 21.38 13.84 20.78C13.94 20.54 13.99 20.28 13.99 20H9.99003C10 21.1 10.89 22 12 22Z"
                                                        fill="white"
                                                    />
                                                </svg>
                                                {/*<div className="header__bell__count">+9</div>*/}
                                            </Link>
                                        </div>
                                    )}
                                    {isPublisher !== 1 && isLoggedIn && (
                                        <div className="header__bell" onClick={() => dispatch(toggleYourCart(true))}>
                                            <ShoppingCartOutlined style={{ width: 25, height: 25, color: '#fff' }} />
                                            {/*<div className="header__bell__count">+9</div>*/}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

const mapStateToProps = function (state) {
    return {
        openMenuMobile: state.homeReducer.openMenuMobile,
        isPublisher: state.homeReducer.isPublisher,
        isLoggedIn: state.homeReducer.isLoggedIn,
        isTop: state.homeReducer.isTopInput,
    }
}
export default withRouter(connect(mapStateToProps)(Header))
