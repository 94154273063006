import React, { useState, useEffect } from 'react'
import { Button, Checkbox } from 'antd'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import * as queryString from 'query-string'
import { toggleFilterProduct } from '../../redux/actions/search-action'
import { FILTER_PRICE } from '../../constants/search-const'
import { fetchListBrand } from '../../api-services/product-api'
import { IconCheck } from '../../constants/icon-const'

function BoxFilterProduct({ listCategory, openFilterProduct, dispatch, location, history }) {
    const [queryCategory, setQueryCategory] = useState([])
    const [queryBrand, setQueryBrand] = useState([])
    const [queryPrice, setQueryPrice] = useState('')
    // const [listFilterCategory, setListFilterCategory] = useState([])
    const [listFilterPrice, setListFilterPrice] = useState(FILTER_PRICE)
    const [listBrand, setListBrand] = useState([])
    const [listFilterBrand, setListFilterBrand] = useState([])

    useEffect(() => {
        getListBand()
    }, [])

    const getListBand = async () => {
        try {
            const data = await fetchListBrand()
            if (data && data.status === 200) {
                setListBrand(data.data)
                setListFilterBrand(data.data)
            }
        } catch (e) {
            throw e
        }
    }

    useEffect(() => {
        const query = queryString.parse(location.search)
        const category_id = query.category_id
        const brand = query.brand
        // const min_price = query.min_price
        // const max_price = query.max_price
        // const array_query = category_id ? category_id.split(',') : []
        const array_query_brand = brand ? brand.split(',') : []

        // setListFilterCategory(
        //     listCategory.map((res) => {
        //         const checked = array_query.includes(`${res.id}`)
        //         return { ...res, checked }
        //     })
        // )
        setListFilterBrand(
            listBrand.map((res) => {
                const checked = array_query_brand.includes(`${res.name}`)
                return { ...res, checked }
            })
        )
        if (category_id) {
            setQueryCategory(category_id.split(','))
        }
        if (brand) {
            setQueryBrand(brand.split(','))
        }
    }, [location, listCategory])

    // const changeFilterCategory = (value, checked, index) => {
    //     const _queryCategory = [...queryCategory]
    //     const _ListFilterCategory = [...listFilterCategory]
    //     _ListFilterCategory[index].checked = checked
    //     setListFilterCategory(_ListFilterCategory)
    //     if (checked) {
    //         _queryCategory.push(value)
    //     } else {
    //         let _index = _queryCategory.indexOf(value)
    //         if (_index !== -1) _queryCategory.splice(_index, 1)
    //     }
    //     setQueryCategory(_queryCategory)
    // }

    const changeFilterBrand = (value, checked, index) => {
        const _queryBrand = [...queryBrand]
        const _listBrand = [...listFilterBrand]
        _listBrand[index].checked = checked
        setListFilterBrand(_listBrand)
        if (checked) {
            _queryBrand.push(value)
        } else {
            let _index = _queryBrand.indexOf(value)
            if (_index !== -1) _queryBrand.splice(_index, 1)
        }
        setQueryBrand(_queryBrand)
    }

    const changeFilterPrice = (value, checked, index) => {
        let _queryPrice = queryPrice
        const _listFilterPrice = [...listFilterPrice]
        _listFilterPrice.map((res) => {
            res.checked = false
            return res
        })
        if (checked) {
            _listFilterPrice[index].checked = checked
            _queryPrice = value
        } else {
            _queryPrice = ''
        }
        setListFilterPrice(_listFilterPrice)

        setQueryPrice(_queryPrice)
    }

    const convertQueryPrice = (type) => {
        let price = {}
        if (type === 'below_100') {
            price.max_price = 100000
        }
        if (type === 'from_100_to_300') {
            price.min_price = 100000
            price.max_price = 300000
        }
        if (type === 'from_300_to_800') {
            price.min_price = 300000
            price.max_price = 800000
        }
        if (type === 'more_than_800') {
            price.min_price = 800000
        }
        return price
    }

    const handleFilter = () => {
        let category_id = ''
        let brand = ''
        let min_price = null
        let max_price = null
        if (queryCategory && queryCategory.length > 0) {
            category_id = queryCategory.join(',')
        }
        if (queryBrand && queryBrand.length > 0) {
            brand = queryBrand.join(',')
        }
        if (queryPrice) {
            const result = convertQueryPrice(queryPrice)
            min_price = result.min_price
            max_price = result.max_price
        }
        const query = queryString.parse(location.search)
        let new_query = { ...query }
        category_id ? (new_query.category_id = category_id) : delete new_query.category_id
        brand ? (new_query.brand = brand) : delete new_query.brand
        min_price ? (new_query.min_price = min_price) : delete new_query.min_price
        max_price ? (new_query.max_price = max_price) : delete new_query.max_price
        const stringify = queryString.stringify(new_query)
        history.push(`${location.pathname}?${stringify}`)
        dispatch(toggleFilterProduct(false))
    }

    return (
        <div className={`box__filter__product ${openFilterProduct ? 'open' : ''}`}>
            <div className="box__filter__product__content">
                <div className="box__filter__product__main">
                    <div className="box__header">
                        <h4>Lọc sản phẩm</h4>
                        <span onClick={() => dispatch(toggleFilterProduct(false))}>Bỏ chọn</span>
                    </div>
                    <div className="container">
                        {/*<div className="filter__item">*/}
                        {/*    <p className="title">Danh mục</p>*/}
                        {/*    <div className="list__filter">*/}
                        {/*        {Array.isArray(listFilterCategory) &&*/}
                        {/*            listFilterCategory.length > 0 &&*/}
                        {/*            listFilterCategory.map((res, index) => {*/}
                        {/*                return (*/}
                        {/*                    <div key={res.id} className={`item__filter ${res.checked ? 'active' : ''}`}>*/}
                        {/*                        <Button size="large">*/}
                        {/*                            <label htmlFor={`category_${res.id}`}>*/}
                        {/*                                {res.name}*/}
                        {/*                                <IconCheck />*/}
                        {/*                            </label>*/}
                        {/*                        </Button>*/}
                        {/*                        <Checkbox*/}
                        {/*                            style={{ display: 'none' }}*/}
                        {/*                            name="category"*/}
                        {/*                            onChange={(e) =>*/}
                        {/*                                changeFilterCategory(res.id, e.target.checked, index)*/}
                        {/*                            }*/}
                        {/*                            id={`category_${res.id}`}*/}
                        {/*                        />*/}
                        {/*                    </div>*/}
                        {/*                )*/}
                        {/*            })}*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="filter__item">
                            <p className="title">Giá tiền</p>
                            <div className="list__filter">
                                {listFilterPrice.length > 0 &&
                                    listFilterPrice.map((res, index) => {
                                        return (
                                            <div
                                                key={res.value}
                                                className={`item__filter ${res.checked ? 'active' : ''}`}
                                            >
                                                <Button size="large">
                                                    <label htmlFor={`category_${res.value}`}>
                                                        {res.label}
                                                        <IconCheck />
                                                    </label>
                                                </Button>
                                                <Checkbox
                                                    style={{ display: 'none' }}
                                                    name="price"
                                                    checked={res.checked}
                                                    onChange={(e) =>
                                                        changeFilterPrice(res.value, e.target.checked, index)
                                                    }
                                                    id={`category_${res.value}`}
                                                />
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                        <div className="filter__item">
                            <p className="title">Thương hiệu</p>
                            <div className="list__filter">
                                {Array.isArray(listFilterBrand) &&
                                    listFilterBrand.length > 0 &&
                                    listFilterBrand.map((res, index) => {
                                        return (
                                            <div key={res.id} className={`item__filter ${res.checked ? 'active' : ''}`}>
                                                <Button size="large">
                                                    <label htmlFor={`brand_${res.id}`}>
                                                        {res.name}
                                                        <IconCheck />
                                                    </label>
                                                </Button>
                                                <Checkbox
                                                    style={{ display: 'none' }}
                                                    name="brand"
                                                    onChange={(e) =>
                                                        changeFilterBrand(res.name, e.target.checked, index)
                                                    }
                                                    id={`brand_${res.id}`}
                                                />
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                        <div className="form__btn">
                            <Button onClick={handleFilter} type="primary" className="w-100" size="large">
                                Lọc
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="box__backdrop" onClick={() => dispatch(toggleFilterProduct(false))}></div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        openFilterProduct: state.searchReducer.openFilterProduct,
    }
}
export default withRouter(connect(mapStateToProps)(BoxFilterProduct))
