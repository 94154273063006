import React from 'react'
import { Link } from 'react-router-dom'
import { Input, Button, Form } from 'antd'

function ForgotPassword({
    validateRePassword,
    validatePassword,
    validateUserName,
    userName,
    password,
    rePassword,
    handleChangeUserName,
    handleChangePassword,
    handleChangeRePassword,
    handleContinue,
}) {
    return (
        <div className="box__forgot__password">
            <div className="box__header__title">
                <div className="container">
                    <div className="box__header__title__content">
                        <Link to="/login-account">
                            <svg
                                width={8}
                                height={13}
                                viewBox="0 0 8 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M7.41 11.09L2.83 6.5L7.41 1.91L6 0.5L0 6.5L6 12.5L7.41 11.09Z" fill="white" />
                            </svg>
                        </Link>
                        <h2>Quên mật khẩu</h2>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="box__content">
                    <div className="box__title">
                        <h2>Quên mật khẩu</h2>
                    </div>
                    <div className="form-group">
                        <label>Tên tài khoản / email</label>
                        <Form.Item
                            name="username"
                            className="el-input__inner"
                            validateStatus={validateUserName.status}
                            help={validateUserName.text}
                        >
                            <Input
                                placeholder="Nhập tên đăng nhập"
                                value={userName}
                                size="large"
                                onChange={handleChangeUserName}
                            />
                        </Form.Item>
                    </div>
                    <div className="form-group">
                        <label>Mật khẩu</label>
                        <Form.Item
                            name="password"
                            className="el-input__inner"
                            validateStatus={validatePassword.status}
                            help={validatePassword.text}
                        >
                            <Input.Password
                                placeholder="Nhập mật khẩu mới "
                                value={password}
                                size="large"
                                onChange={handleChangePassword}
                            />
                        </Form.Item>
                    </div>
                    <div className="form-group">
                        <label>Nhập lại mật khẩu</label>
                        <Form.Item
                            name="rePassword"
                            className="el-input__inner"
                            validateStatus={validateRePassword.status}
                            help={validateRePassword.text}
                        >
                            <Input.Password
                                placeholder="Nhập lại mật khẩu mới"
                                value={rePassword}
                                size="large"
                                onChange={handleChangeRePassword}
                            />
                        </Form.Item>
                    </div>
                </div>
                <div className="box__footer">
                    <Button
                        onClick={handleContinue}
                        style={{ margin: '20px 0' }}
                        size="large"
                        className="w-100"
                        type="primary"
                    >
                        Xác nhận
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
