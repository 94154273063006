import React from 'react'
import { HomeFilled, DiffFilled, AppstoreFilled, DownOutlined, InfoCircleFilled, PhoneFilled } from '@ant-design/icons'
import logo from '../../assets/images/logoDimuadi.svg'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { toggleShowMenuSidebar } from '../../redux/actions/home-actions'
import { HOT_LINE } from '../../constants/common-contants'

function MenuSideBar() {
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const { isShowMenuSidebar, isPublisher } = useSelector((state) => {
        return {
            isShowMenuSidebar: state.homeReducer.isShowMenuSidebar,
            isPublisher: state.homeReducer.isPublisher,
        }
    })
    const handleClickParentMenu = (event) => {
        // nếu menu đang mở mới thêm open
        if (event.currentTarget.classList.contains('open')) {
            event.currentTarget.classList.remove('open')
        } else {
            event.currentTarget.classList.add('open')
        }
    }
    return (
        <div className={`menu__sideBar ${isShowMenuSidebar ? '' : 'collapsed'}`}>
            <div className="sideBar__header">
                <Link to="/" className="logo">
                    <img src={logo} alt="" />
                </Link>
                <div className="icon__btn" onClick={() => dispatch(toggleShowMenuSidebar(!isShowMenuSidebar))}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="11.5" fill="white" stroke="#1890FF" />
                        <path
                            d="M6.42187 12.3781L12.325 17.5016C12.3703 17.5406 12.4281 17.5625 12.4891 17.5625L13.8719 17.5625C13.9875 17.5625 14.0406 17.4188 13.9531 17.3438L8.48125 12.5938L17.625 12.5938C17.6937 12.5938 17.75 12.5375 17.75 12.4688L17.75 11.5312C17.75 11.4625 17.6937 11.4063 17.625 11.4063L8.48281 11.4063L13.9547 6.65625C14.0422 6.57969 13.9891 6.4375 13.8734 6.4375L12.4437 6.4375C12.4141 6.4375 12.3844 6.44844 12.3625 6.46875L6.42187 11.6219C6.36778 11.6689 6.32441 11.727 6.29469 11.7922C6.26497 11.8575 6.24959 11.9283 6.24959 12C6.24959 12.0717 6.26497 12.1425 6.29469 12.2078C6.32441 12.273 6.36778 12.3311 6.42187 12.3781Z"
                            fill="#1890FF"
                        />
                    </svg>
                </div>
            </div>
            <div className="sidebar__body">
                <div className="sidebar__nav">
                    <div className="sidebar__nav__list">
                        <li className={`sidebar__nav__item ${pathname === '/' ? 'active' : ''}`}>
                            <Link to="/" className="sidebar__nav__link">
                                <HomeFilled />
                                <span className="text">Trang chủ</span>
                            </Link>
                            <div className="sidebar__nav__tooltip">
                                <p>Trang chủ</p>
                            </div>
                        </li>
                        {isPublisher === 1 && (
                            <li className={`sidebar__nav__item ${pathname === '/dashboard' ? 'active' : ''}`}>
                                <Link to="/dashboard" className="sidebar__nav__link">
                                    <AppstoreFilled />
                                    <span className="text">Tổng quan</span>
                                </Link>
                                <div className="sidebar__nav__tooltip">
                                    <p>Tổng quan</p>
                                </div>
                            </li>
                        )}
                        {isPublisher !== 1 && (
                            <li className={`sidebar__nav__item ${pathname === '/list-category' ? 'active' : ''}`}>
                                <Link to="/list-category" className="sidebar__nav__link">
                                    <AppstoreFilled />
                                    <span className="text">Danh mục</span>
                                </Link>
                                <div className="sidebar__nav__tooltip">
                                    <p>Danh mục</p>
                                </div>
                            </li>
                        )}
                        {/*{isPublisher === 1 && (*/}
                        {/*    <li className={`sidebar__nav__item ${pathname === '/choose-product' ? 'active' : ''}`}>*/}
                        {/*        <Link to="/choose-product" className="sidebar__nav__link">*/}
                        {/*            <ShoppingFilled />*/}
                        {/*            <span className="text">Chọn sản phẩm</span>*/}
                        {/*        </Link>*/}
                        {/*        <div className="sidebar__nav__tooltip">*/}
                        {/*            <p>Chọn sản phẩm</p>*/}
                        {/*        </div>*/}
                        {/*    </li>*/}
                        {/*)}*/}
                        {isPublisher === 1 && (
                            <li
                                className={`sidebar__nav__item nav-sub ${
                                    ['/list-order', '/choose-product'].includes(pathname) ? 'active' : ''
                                }`}
                                onClick={(event) => handleClickParentMenu(event)}
                            >
                                <a className="sidebar__nav__link">
                                    <DiffFilled />
                                    <span className="text">Đơn hàng</span>
                                    <span className="down">
                                        <DownOutlined />
                                    </span>
                                </a>
                                <ul className="sidebar__nav__child">
                                    <li
                                        className={`sidebar__nav__item ${
                                            pathname === '/choose-product' ? 'active' : ''
                                        }`}
                                    >
                                        <Link className="sidebar__nav__link" to="/choose-product">
                                            <span>Tạo đơn</span>
                                        </Link>
                                    </li>
                                    <li className={`sidebar__nav__item ${pathname === '/list-order' ? 'active' : ''}`}>
                                        <Link className="sidebar__nav__link" to="/list-order">
                                            <span>Danh sách đơn</span>
                                        </Link>
                                    </li>
                                </ul>
                                <div className="sidebar__nav__tooltip">
                                    <p>Đơn hàng</p>
                                </div>
                            </li>
                        )}
                    </div>
                </div>
                <div className="sidebar__contact">
                    <a href="https://www.dimuadi.vn/" className="d-flex">
                        <div className="icon">
                            <InfoCircleFilled />
                        </div>
                        <p>Về chúng tôi Dimuadi</p>
                    </a>
                    <a href={`tel:${HOT_LINE}`} className="d-flex">
                        <div className="icon">
                            <PhoneFilled />
                        </div>
                        <p>{HOT_LINE}</p>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default MenuSideBar
