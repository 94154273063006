import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import * as queryString from 'query-string'
import { connect } from 'react-redux'

function Pagination({ pages, prevLink, nextLink, location, totalPage }) {
    const query = queryString.parse(location.search)
    const _page = Number(query.page) || 1
    return (
        <ul className="pagination">
            {prevLink && (
                <li className={`pagination__item ${prevLink.is_disable ? 'disabled' : ''}`}>
                    <Link to={prevLink.link} className="pagination__link">
                        <div className="icon">
                            <svg
                                width={18}
                                height={18}
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="svg-left"
                            >
                                <path
                                    d="M9.00195 16.1422L1.93089 9.07108L9.00195 2.00002"
                                    stroke="#36353E"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                    </Link>
                </li>
            )}
            {pages && totalPage > 3 && _page > 3 && (
                <li className={`pagination__item `}>
                    <button className="pagination__link">...</button>
                </li>
            )}
            {pages &&
                pages.map((item) => {
                    return (
                        <li className={`pagination__item ${item.active ? 'active' : ''}`} key={`page-${item.page}`}>
                            <Link to={item.link} className="pagination__link">
                                {item.page}
                            </Link>
                        </li>
                    )
                })}
            {pages && totalPage > 3 && _page < totalPage && (
                <li className={`pagination__item`}>
                    <button className="pagination__link">...</button>
                </li>
            )}

            {nextLink && (
                <li className={`pagination__item ${nextLink.is_disable ? 'disabled' : ''}`}>
                    <Link to={nextLink.link} className="pagination__link">
                        <div className="icon">
                            <svg
                                width={18}
                                height={18}
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="svg-right"
                            >
                                <path
                                    d="M9 1.92896L16.0711 9.00002L9 16.0711"
                                    stroke="#36353E"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>
                    </Link>
                </li>
            )}
        </ul>
    )
}

export default withRouter(connect()(Pagination))
