import React from 'react'

function BreadcrumbNavigation({ option }) {
    return (
        <div className="navigation">
            {Array.isArray(option) &&
                option.length > 0 &&
                option.map((res, index) => {
                    return (
                        <div key={index} className={`navigation__item ${res.active ? 'active' : ''}`}>
                            <span>{res.label}</span>
                        </div>
                    )
                })}
        </div>
    )
}

export default BreadcrumbNavigation
