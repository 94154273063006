import React, { useState } from 'react'
import DisplayPrice from '../../common/DisplayPrice'
import { Button, Form, Input, Modal } from 'antd'
import BoxHeaderTitle from '../../common/BoxHeaderTitle'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// import noOrder from '../../../assets/images/no_order.png'
import ErrorProduct from '../../common/ErrorProduct'
import { DeleteOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
// import { isMaxTableScreen, isMobileScreen } from '../../../functions/common-func'
import Select from 'react-select'
import BreadcrumbNavigation from '../../common/BreadcrumbNavigation'
import { optionNavigationCheckout1 } from '../../../constants/common-contants'
import TextArea from 'antd/es/input/TextArea'
import ShowNote from '../../create-order/ShowNote'

function CheckOutStep1({
    onBack,
    // couponCode,
    // validateCoupon,
    // handleCouponCode,
    // handleCouponApply,
    history,
    isAddress,
    totalPrice,
    listProductFromCart,
    handleCreateOrder,
    idProduct,
    onChangeQuantity,
    phone,
    address,
    province,
    commune,
    name,
    note,
    // isDefault,
    // isCompanyAddress,
    district,
    isDisableDistrict,
    isDisableCommune,
    listProvince,
    setListProvince,
    listDistrict,
    listCommune,
    validateAddress,
    validateProvince,
    validateDistrict,
    validateCommune,
    validateName,
    validatePhone,
    // onChangeIsDefault,
    // onChangeIsCompanyAddress,
    handleChangeName,
    handleChangePhone,
    handleChangeNote,
    handleChangeAddress,
    handleChangeProvince,
    handleChangeDistrict,
    handleChangeCommune,
}) {
    const [isMaxQuantity, setIsMaxQuantity] = useState(false)

    return (
        <div className="check__out">
            <BoxHeaderTitle text="Thông tin đơn hàng" link={'/'} />
            <div className="container">
                <BreadcrumbNavigation option={optionNavigationCheckout1} />
                <div className="check__out__content">
                    {/* {isMobileScreen() && isMaxTableScreen() && (
                       <>
                           {isAddress ? (
                               <div className="check__out__address check__out__item">
                                   <div className="title">
                                       <h4>Địa chỉ nhận hàng</h4>
                                       <span
                                           className="change__address"
                                           onClick={() => history.push(`/address/shipping?addressId=${isAddress.id}`)}
                                       >
                                           Thay đổi
                                       </span>
                                   </div>
                                   <div className="info__address">
                                       <div className="name" style={{ marginBottom: 5 }}>
                                           {isAddress.username} - {isAddress.phoneNumber}
                                       </div>
                                       <div className="address">{isAddress.address}</div>
                                   </div>
                               </div>
                           ) : (
                               <div className="check__out__address check__out__item">
                                   <div className="title">
                                       {' '}
                                       <h4>Địa chỉ nhận hàng</h4>
                                   </div>
                                   <Button
                                       onClick={() => {
                                           history.push('/address/create')
                                       }}
                                       className="w-100 btn__orange"
                                       size="large"
                                   >
                                       + Thêm địa chỉ nhận hàng
                                   </Button>
                               </div>
                           )}
                       </>
                    )} */}
                    <div className="check__out__content__desktop">
                        <div className="check__out__right">
                            <div className="check__out__list check__out__item">
                                <div className="title">
                                    <h4>Danh sách đơn hàng</h4>
                                </div>
                                {Array.isArray(listProductFromCart) &&
                                    listProductFromCart.length > 0 &&
                                    listProductFromCart.map((res, index) => {

                                        return (
                                            <div key={index} className="item__product">
                                                <div className="item__image">
                                                    <img src={res.product && res.product.thumbImage} alt="" />
                                                </div>
                                                <div className="item__text">
                                                    <p className="name__product">{res.product && res.product.name}</p>
                                                    {res.variantData && <ShowNote note={res.product.note} />}
                                                    <div className="price__product">
                                                        <div className="quantity">
                                                            {idProduct ? (
                                                                <div className="product__quantity">
                                                                    <div className="quantity">
                                                                        <Button
                                                                            disabled={res.quantity === 1}
                                                                            onClick={() => onChangeQuantity(index, 'minus')}
                                                                            icon={<MinusOutlined style={{ fontSize: 12 }} />}
                                                                        />
                                                                        <Button> {res.quantity} </Button>
                                                                        <Button
                                                                            disabled={res.quantity === 10}
                                                                            onClick={() => {
                                                                                onChangeQuantity(index, 'plus')
                                                                                if (res.quantity >= 10) setIsMaxQuantity(true)
                                                                            }}
                                                                            className="btn-orange"
                                                                            ref={(node) => {
                                                                                if (node) {
                                                                                    node.style.setProperty('background', 'orange', 'important');
                                                                                }
                                                                            }}
                                                                            icon={<PlusOutlined style={{ fontSize: 12 }} />}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                `Số lượng: ${res.quantity}`
                                                            )}
                                                            {/*Số lượng: {res.quantity}*/}
                                                        </div>

                                                        <p className="price">
                                                            <DisplayPrice price={res.product.price} />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                {listProductFromCart && listProductFromCart.length === 0 && <ErrorProduct />}
                                {listProductFromCart && listProductFromCart.length < 10 && !idProduct && (
                                    <Link to="/list-product">
                                        <Button className="w-100 btn__orange" size="large" type="warning">
                                            + Thêm sản phẩm
                                        </Button>
                                    </Link>
                                )}
                            </div>
                        </div>

                        <div className="check__out__left">
                            <div className="item__order">
                                <div className="container__mobile">
                                    <h2 className="title">Thông tin người mua</h2>
                                    <div className="profile__booker__form">
                                        <div className="form-group">
                                            <label>Người mua</label>
                                            <Form.Item
                                                className="el-input__inner"
                                                validateStatus={validateName.status}
                                                help={validateName.text}
                                            >
                                                <Input
                                                    value={name}
                                                    onChange={handleChangeName}
                                                    placeholder="Nhập tên người mua"
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <label>Số điện thoại</label>
                                            <Form.Item
                                                className="el-input__inner"
                                                validateStatus={validatePhone.status}
                                                help={validatePhone.text}
                                            >
                                                <Input
                                                    value={phone}
                                                    onChange={handleChangePhone}
                                                    placeholder="Nhập số điện thoại"
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <label>Địa chỉ chi tiết</label>
                                            <Form.Item
                                                validateStatus={validateAddress.status}
                                                help={validateAddress.text}
                                                className="el-input__inner"
                                            >
                                                <Input
                                                    value={address}
                                                    onChange={handleChangeAddress}
                                                    placeholder="Nhập số nhà và đường"
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <label>Tỉnh/Thành phố</label>
                                            <Form.Item
                                                validateStatus={validateProvince.status}
                                                help={validateProvince.text}
                                            >
                                                <Select
                                                    value={province}
                                                    onChange={handleChangeProvince}
                                                    options={listProvince}
                                                    isClearable
                                                    placeholder="Tỉnh/Thành phố"
                                                    className="department_sender"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <label>Quận/huyện</label>
                                            <Form.Item
                                                validateStatus={validateDistrict.status}
                                                help={validateDistrict.text}
                                            >
                                                <Select
                                                    value={district}
                                                    onChange={handleChangeDistrict}
                                                    options={listDistrict}
                                                    isClearable
                                                    isDisabled={isDisableDistrict}
                                                    placeholder="Quận/huyện"
                                                    className="department_sender"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <label>Phường/Xã</label>
                                            <Form.Item
                                                validateStatus={validateCommune.status}
                                                help={validateCommune.text}
                                            >
                                                <Select
                                                    value={commune}
                                                    onChange={handleChangeCommune}
                                                    options={listCommune}
                                                    isClearable
                                                    isDisabled={isDisableCommune}
                                                    placeholder="Phường/Xã"
                                                    className="department_sender"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <label>Ghi chú</label>
                                            <Form.Item className="el-input__inner">
                                                <TextArea
                                                    value={note}
                                                    onChange={handleChangeNote}
                                                    placeholder="Ghi chú ..."
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form__btn">
                                <div className="net__price1">
                                    <span style={{ fontSize: '18px' }}> Thành tiền: &nbsp;</span>
                                    <span className="price" style={{ fontSize: '18px', fontWeight: '600', color: '#F5222D' }}>
                                        <DisplayPrice price={totalPrice} />
                                    </span>
                                </div>
                                <Button onClick={handleCreateOrder} type="primary" className="w-100" size="large">
                                    Đặt hàng
                                </Button>
                            </div>
                        </div>
                    </div>

                    <Modal
                        dialogClassName="modal__max__quantity"
                        className="modal__max__quantity"
                        title="Thông báo"
                        footer={null}
                        visible={isMaxQuantity}
                        onCancel={() => setIsMaxQuantity(false)}
                    >
                        <div className="container__mobile">
                            Số lượng mua tối đa: <b>10 Sản phẩm</b> <br />
                            Vui lòng liên hệ để mua số lượng lớn hơn với giá tốt nhất.
                        </div>
                    </Modal>

                    {/*<div className="check__out__item check__out__coupon">*/}
                    {/*    <div className="title">*/}
                    {/*        <h4>Voucher</h4>*/}
                    {/*    </div>*/}
                    {/*    <div className="form__input">*/}
                    {/*        <Form.Item validateStatus={validateCoupon.status} help={validateCoupon.text}>*/}
                    {/*            <Input*/}
                    {/*                value={couponCode}*/}
                    {/*                style={{ textTransform: 'uppercase' }}*/}
                    {/*                size="large"*/}
                    {/*                placeholder="Nhập mã giảm giá"*/}
                    {/*                onChange={handleCouponCode}*/}
                    {/*            />*/}
                    {/*        </Form.Item>*/}
                    {/*        <div className="input-group-btn">*/}
                    {/*            <Button onClick={handleCouponApply} type="primary" size="large">*/}
                    {/*                Áp dụng*/}
                    {/*            </Button>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        toggleCart: state.homeReducer.toggleCart,
    }
}
export default withRouter(connect(mapStateToProps)(CheckOutStep1))
