import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { checkMinMax, convertCommission, formatCurrency, isMobileScreen } from '../../../functions/common-func'
import { PATH_PRODUCT } from '../../../constants/common-contants'
import { fetchTop5 } from '../../../api-services/home-api'
import right from '../../../assets/images/right.svg'
import * as _ from 'lodash'
import { toggleIsPolicyWholeSale } from '../../../redux/actions/home-actions'
import { useDispatch, useSelector } from 'react-redux'
import topIcon from '../../../assets/images/top.svg'
import commIcon from '../../../assets/images/comm_icon.svg'
import DisplayPrice from '../DisplayPrice'
import MinMax from '../../product-price/MinMax'
import ShowComm from '../../product-price/ShowComm'
function TopProduct({ className }) {
    const [listTopProduct, setListTopProduct] = useState({})
    const isPolicyWholesale = useSelector(state => state.homeReducer.isPolicyWholeSale)
    const dispatch = useDispatch()
    useEffect(() => {
        fetchTop5().then((res) => {
            setListTopProduct(res.data)
        })
    }, [isPolicyWholesale])
    const handleSelect = () => {
        dispatch(toggleIsPolicyWholeSale(false))
    }
    const handleSelectWholesale = () => {
        dispatch(toggleIsPolicyWholeSale(true))
    }
    const filterArray = _.partition(listTopProduct, { isPolicyWholesale: true })
    const top5Wholesale = _.partition(filterArray[0], { positon: 1 })
    const top5NotWholesale = _.partition(filterArray[1], { positon: 1 })
    const resultListWholesale = [...top5Wholesale[0], ...top5Wholesale[1]]
    const resultListNotWholesale = [...top5NotWholesale[0], ...top5NotWholesale[1]]
    const listTop = isPolicyWholesale ? resultListWholesale : resultListNotWholesale

    return (
        <div className={`hot__product ${className ? className : ''}`}>
            <div className="title-hot-product">
                <h2 className="title">Xu hướng mua sắm</h2>
                <Link to="/trending-product">
                    <img src={right} />
                </Link>
            </div>
            <div className="filter_label">
                <p className={isPolicyWholesale ? 'label active' : 'label'} onClick={() => handleSelectWholesale()}>
                    Sản phẩm bán sỉ
                </p>
                <p className={!isPolicyWholesale ? 'label active' : 'label'} onClick={() => handleSelect()}>
                    Sản phẩm bán lẻ
                </p>
            </div>
            <ul className="list-top5">
                {listTop &&
                    listTop.length > 0 &&
                    listTop.map((res, index) => {
                        if (index < 5) {
                            return (
                                <a href={`${PATH_PRODUCT}/${res.id}`} key={res.id} className="hot__product__item">
                                    <li className="itemCard">
                                        <div className={index < 5 && res.position === 1 ? 'images top5' : 'images'}>
                                            <img src={res.thumbImage} alt="" />
                                        </div>
                                        <div className="text-group">
                                            <p className="title">{res.name}</p>
                                            <p className="comm"> {isMobileScreen() ? <img src={commIcon} /> : 'Hoa hồng '}
                                                {!res.rangePrice && <DisplayPrice
                                                    price={convertCommission(
                                                        res.price,
                                                        res.commissionValue,
                                                        res.commissionType
                                                    )}
                                                />}
                                                {res.rangePrice &&
                                                    <ShowComm
                                                        min={res.rangePrice.minPrice}
                                                        max={res.rangePrice.maxPrice}
                                                        commType={res.commissionType}
                                                        commValue={res.commissionValue}
                                                    />
                                                }
                                            </p>

                                            {res.priceBeforeDiscount > 0 && !res.rangePrice && (
                                                <>
                                                    <p className="salePrice">{formatCurrency(res.price)}₫</p>
                                                    <div className="discount">
                                                        <p className="originPrice">
                                                            {formatCurrency(res.priceBeforeDiscount)}₫
                                                        </p>
                                                    </div>
                                                </>
                                            )}
                                            {!res.priceBeforeDiscount && !res.rangePrice && (
                                                <>
                                                    <p className="salePrice">{formatCurrency(res.price)}₫</p>
                                                    <br />
                                                </>
                                            )}
                                            {res.rangePrice && (
                                                <>
                                                    <p className="salePrice">
                                                        {checkMinMax(res.rangePrice.minPrice, res.rangePrice.maxPrice)}₫
                                                    </p>
                                                    <br />
                                                </>
                                            )}

                                            {res.priceBeforeDiscount > 0 && (
                                                <div className="percent">
                                                    {!res.rangePrice && <> {res.commissionType === "percent" && <span>
                                                        -{res.commissionValue}%
                                                    </span>}
                                                        {res.commissionType === "amount" && <span>
                                                            -
                                                            {(
                                                                ((res.priceBeforeDiscount - res.price) * 100) /
                                                                res.priceBeforeDiscount
                                                            ).toFixed()}
                                                            %
                                                        </span>}
                                                    </>}
                                                    {index < 5 && <img src={topIcon} alt="" />}
                                                </div>
                                            )}
                                            {!res.priceBeforeDiscount && (

                                                <div className="percent">
                                                    {index < 5 && <img src={topIcon} alt="" />}
                                                </div>
                                            )}
                                        </div>
                                    </li>
                                </a>
                            )
                        }
                    })}
            </ul>
        </div>
    )
}

export default TopProduct
