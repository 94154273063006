import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Input, Form, Button } from 'antd'
import { ArrowLeftOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import BoxFilterProduct from './BoxFilterProduct'
import imageDefault from '../../assets/images/image_faildoad.png'
// import bannerList from '../../assets/images/bannerListProduct.png'
import { FilterFilled, CaretDownOutlined, SearchOutlined } from '@ant-design/icons'
import DisplayPrice from '../common/DisplayPrice'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import { IconHomeChietKhau } from '../../constants/icon-const'
import { toggleFilterProduct, toggleSortProduct } from '../../redux/actions/search-action'
import BoxSortProduct from './BoxSortProduct'
import { togglePopupBoxSearch, toggleYourCart } from '../../redux/actions/home-actions'
import BoxSearch from '../common/BoxSearch'
import { checkMinMax, checkURLImage, convertCommission, isMaxTableScreen, isMobileScreen } from '../../functions/common-func'
import PaginationContainer from '../../container/common/PaginationContainer'
import LoadingComponent from '../common/LoadingComponent'
import ErrorProduct from '../common/ErrorProduct'
import {PATH_PRODUCT} from "../../constants/common-contants";
import ShowComm from '../product-price/ShowComm'
function ListProduct({
    handleClick,
    listProduct,
    listCategory,
    listBanner,
    // listHotWeek,
    name,
    reLoadingSuggestion,
    isSort,
    handleChangeSort,
    validateName,
    handleKeyPressName,
    handleChangeName,
    dispatch,
    isPublisher,
    isLoggedIn,
    isSearch,
    paging,
    setReLoadingSuggestion,
}) {
    const [isTop, setIsTop] = useState(false)

    useEffect(() => {
        let searchInput = document.getElementById('search__input')
        let status = false
        if (searchInput) {
            window.addEventListener('scroll', function () {
                if (window.pageYOffset >= searchInput.offsetTop) {
                    if (status === false) {
                        setIsTop(true)
                        status = true
                    }
                } else {
                    if (status === true) {
                        setIsTop(false)
                        status = false
                    }
                }
            })
        }
    }, [])

    useEffect(() => {
        let endSuggestion = document.getElementById('end__suggestion')
        let statusSuggestion = false
        if (endSuggestion) {
            window.addEventListener('scroll', function () {
                if (window.pageYOffset >= endSuggestion.offsetTop - 420) {
                    if (statusSuggestion === false) {
                        setReLoadingSuggestion(true)
                        statusSuggestion = true
                    }
                } else {
                    if (statusSuggestion === true) {
                        setReLoadingSuggestion(false)
                        statusSuggestion = false
                    }
                }
            })
        }
    }, [listProduct])

    return (
        <section className="search-product">
            <BoxHeaderTitle text="Danh sách sản phẩm" link={'/'} isBell={true} />
            <div className="search__product__content">
                <div className={`search__input`} id="search__input">
                    {(isMobileScreen() || isMaxTableScreen()) && (
                        <div className={`input__search ${isTop ? 'fix__top' : ''}`}>
                            <div className="back">
                                <Link to={'/'}>
                                    <ArrowLeftOutlined className style={{ color: '#ffffff' }} />
                                </Link>
                            </div>
                            <Form.Item validateStatus={validateName.status} help={validateName.text}>
                                <Input
                                    onClick={() => dispatch(togglePopupBoxSearch(true))}
                                    size="large"
                                    style={{ border: '1px solid #BFBFBF', borderRadius: 4 }}
                                    placeholder="Nhập tên sản phẩm"
                                    suffix={<SearchOutlined onClick={handleClick} />}
                                    value={name}
                                    onChange={handleChangeName}
                                    onKeyPress={handleKeyPressName}
                                    readOnly
                                />
                                <BoxSearch />
                            </Form.Item>
                            <div>
                                {isPublisher === 1 && (
                                    <div className="header__bell">
                                        <Link to={'/notification'}>
                                            <svg
                                                width={24}
                                                height={24}
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M7.58003 4.08L6.15003 2.65C3.75003 4.48 2.17003 7.3 2.03003 10.5H4.03003C4.18003 7.85 5.54003 5.53 7.58003 4.08ZM19.97 10.5H21.97C21.82 7.3 20.24 4.48 17.85 2.65L16.43 4.08C18.45 5.53 19.82 7.85 19.97 10.5ZM18 11C18 7.93 16.36 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.63003 5.36 6.00003 7.92 6.00003 11V16L4.00003 18V19H20V18L18 16V11ZM12 22C12.14 22 12.27 21.99 12.4 21.96C13.05 21.82 13.58 21.38 13.84 20.78C13.94 20.54 13.99 20.28 13.99 20H9.99003C10 21.1 10.89 22 12 22Z"
                                                    fill="white"
                                                />
                                            </svg>
                                            {/*<div className="header__bell__count">+9</div>*/}
                                        </Link>
                                    </div>
                                )}
                                {isPublisher !== 1 && isLoggedIn && (
                                    <div className="header__bell" onClick={() => dispatch(toggleYourCart(true))}>
                                        <ShoppingCartOutlined style={{ width: 25, height: 25, color: '#fff' }} />
                                        {/*<div className="header__bell__count">+9</div>*/}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="input__search__desktop">
                        <Form.Item validateStatus={validateName.status} help={validateName.text}>
                            <Input
                                size="large"
                                style={{ border: '1px solid #BFBFBF', borderRadius: 4 }}
                                placeholder="Nhập tên sản phẩm"
                                suffix={<SearchOutlined onClick={handleClick} />}
                                value={name}
                                onChange={handleChangeName}
                                onKeyPress={handleKeyPressName}
                            />
                        </Form.Item>
                        <Button onClick={handleClick} type="primary" size="large">
                            Tìm kiếm
                        </Button>
                    </div>
                </div>
                {isMobileScreen() && (
                    <div className=" banner ">
                        <div className="container__mobile">
                            {listBanner && listBanner.publisherCategory && (
                                <div className="banner__product">
                                    <a href={listBanner.publisherCategory[0].url}>
                                        <img src={listBanner.publisherCategory[0].image} alt="banner product" />
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {/*{!isSearch && <HotDirectory listCategory={listCategory} />}*/}
                <div className=" search__product__item filter__product">
                    <div className="container__mobile">
                        <div className="filter__directory">
                            <div className="sort" onClick={() => dispatch(toggleSortProduct(true))}>
                                <span>Sắp xếp:</span>
                                <div className="sort__item">
                                    <p>{isSort && isSort.label}</p>
                                    <CaretDownOutlined />
                                </div>
                            </div>
                            <div className="filter" onClick={() => dispatch(toggleFilterProduct(true))}>
                                <FilterFilled style={{ color: '#8C8C8C' }} />
                                Lọc
                            </div>
                        </div>
                    </div>
                </div>
                {/*{!isSearch && (*/}
                {/*    <div className="home__product__item hot__week">*/}
                {/*        <div className="container">*/}
                {/*            <h2 className="title">Sản phẩm hot trong tuần</h2>*/}
                {/*            <Slider {...settings}>*/}
                {/*                {Array.isArray(listHotWeek) &&*/}
                {/*                    listHotWeek.map((res) => {*/}
                {/*                        return (*/}
                {/*                            <div key={res.id} className="hot__week__item">*/}
                {/*                                <img src={res.thumbImage} alt="" />*/}
                {/*                                <p className="chiet-khau">*/}
                {/*                                    <IconHomeChietKhau />*/}
                {/*                                    <span>{res.price}</span>*/}
                {/*                                </p>*/}
                {/*                                <div className="discount">*/}
                {/*                                    <span>75%</span>*/}
                {/*                                </div>*/}
                {/*                            </div>*/}
                {/*                        )*/}
                {/*                    })}*/}
                {/*            </Slider>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}
                <div className="home__product__content">
                    <div className="header__title">
                        <div className="container__mobile">
                            {isSearch ? <h2>Danh sách sản phẩm vừa tìm kiếm</h2> : <h2>Danh sách sản phẩm mới nhất</h2>}
                        </div>
                    </div>
                    <div className="home__product">
                        {Array.isArray(listProduct) &&
                            listProduct.map((res) => {
                                return (
                                    <div key={res.id} className="item__product">
                                        <a style={{ color: '#262626' }} href={`${PATH_PRODUCT}/${res.id}`}>
                                            <div className="item__image">
                                                {res.status === 3 && (
                                                    <div className="bg_out_of_stock"><span>Tạm hết hàng</span></div>
                                                )}
                                                <img src={checkURLImage(res.thumbImage) || imageDefault} alt="" />
                                            </div>
                                            <div className="product__text">
                                                <p className="title">{res.name}</p>
                                                {!res.rangePrice && <span className="price">
                                                    <DisplayPrice price={res.price} />
                                                </span>}
                                                {res.rangePrice && <span className="price">{checkMinMax(res.rangePrice.minPrice, res.rangePrice.maxPrice)}</span>}
                                                {isPublisher === 1 && <>
                                                    <p className="chiet-khau">
                                                        <span>Hoa Hồng</span>
                                                        <IconHomeChietKhau />
                                                    </p>
                                                    <span className="price__discount">
                                                        {!res.rangePrice && <DisplayPrice
                                                            price={convertCommission(
                                                                res.price,
                                                                res.commissionValue,
                                                                res.commissionType
                                                            )}
                                                        />}
                                                        {res.rangePrice && <ShowComm
                                                            min={res.rangePrice.minPrice}
                                                            max={res.rangePrice.maxPrice}
                                                            commType={res.commissionType}
                                                            commValue={res.commissionValue}
                                                        />}
                                                    </span>
                                                </>}
                                                
                                            </div>
                                        </a>
                                    </div>
                                )
                            })}
                        {listProduct?.length === 0 && <ErrorProduct />}
                        {(isMobileScreen() || isMaxTableScreen()) && <div id="end__suggestion"></div>}
                    </div>
                    {listProduct?.length > 0 && !(isMobileScreen() || isMaxTableScreen()) && (
                        <PaginationContainer {...paging} />
                    )}
                    {reLoadingSuggestion && paging.page < paging.totalPage && <LoadingComponent />}
                </div>
                <BoxFilterProduct listCategory={listCategory} />
                <BoxSortProduct handleChangeSort={handleChangeSort} isSort={isSort} />
            </div>
        </section>
    )
}

const mapStateToProps = function (state) {
    return {
        openDirectory: state.searchReducer.openDirectory,
        isPublisher: state.homeReducer.isPublisher,
        isLoggedIn: state.homeReducer.isLoggedIn,
    }
}
export default withRouter(connect(mapStateToProps)(ListProduct))
