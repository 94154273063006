import React, { useState } from 'react';
import { formatTimeToText } from '../../functions/common-func';
import DisplayPrice from '../common/DisplayPrice';
import timeCircle from '../../assets/images/time-circle1.svg';
import * as moment from 'moment';
import { PATH_COUPONS } from '../../constants/common-contants';
import { Link } from 'react-router-dom';
// import ModalCouponDetails from './ModalCouponDetail';

function CouponItem({coupon,can_buy,buyProductWithCoupon }) {
    // const [visible, setVisible] = useState(false);
    // const handleCancel = () => {
    //     setVisible(false);
    // }
    // const showModal = () => {
    //     setVisible(true);
    // }
    function renderBtnBuyNow() {
        if (coupon) {
            if (coupon.quantity === coupon.quantity_used) {
                return <button className="btn-disable-txt btn-secondary" disabled>Hết lượt</button>
            }
            if(!can_buy) {
                return <div className="cursor buy-now">Mua ngay</div>
            }
            return <a className="cursor buy-now" onClick={() => buyProductWithCoupon(coupon)}>Mua ngay </a>
        }
    }
    function renderItemCoupon(can_buy) {
        if(!can_buy) {
            return (
                <Link to={`${PATH_COUPONS}?coupon_id=${coupon.id}`}>
                <div key={coupon?.id} className="coupon__item">
                    <div className="coupon__item__title">
                        <p>{coupon?.code}</p>
                        <span>HSD: {moment(coupon?.end_at).format('DD/MM')}</span> 
                    </div>
                    <div className="coupon__item__detail">
                        <p className='sale'>
                            Giảm {(coupon?.coupon_type == 1 || coupon?.couponType == 1) ? <DisplayPrice price={coupon.amount} />:coupon.amount+'%'}
                        </p>
                        <span className='description'>{coupon?.name}</span>
                        <div className="exp-coupon">
                            <div className="exp-time">
                                <img src={timeCircle} alt={coupon?.name} />
                                <span>Còn {formatTimeToText(coupon?.end_at)}</span>
                            </div>
                            {renderBtnBuyNow()}
                        </div>
                    </div>
                </div>
            </Link>
            )
        }
        return (
            <div key={coupon?.id} className="coupon__item">
                <div className="coupon__item__title">
                    <p>{coupon?.code}</p>
                    <span>HSD: {moment(coupon?.endAt).format('DD/MM')}</span> 
                </div>
                <div className="coupon__item__detail">
                    <p className='sale'>
                        Giảm {(coupon?.coupon_type == 1 || coupon?.couponType == 1) ? <DisplayPrice price={coupon.amount} />:coupon.amount+'%'}
                    </p>
                    <span className='description'>{coupon?.name}</span>
                    <div className="exp-coupon">
                        <div className="exp-time">
                            <img src={timeCircle} alt={coupon?.name} />
                            <span>Còn {formatTimeToText(coupon?.endAt)}</span>
                        </div>
                        {renderBtnBuyNow()}
                    </div>
                </div>
            </div>
        )
    }
    return (
        renderItemCoupon(can_buy)
    )
}
export default CouponItem;