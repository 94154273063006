import React from 'react'
import { Modal } from 'antd'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { toggleShowConfirmDelete } from '../../redux/actions/checkout-action'

function PopupConfirmDelete({ handleOk, isShowDelete, dispatch, dataConfirmDelete,text }) {
    return (
        <Modal
            footer={null}
            className="popup__delete"
            title={null}
            visible={isShowDelete}
            onOk={handleOk}
            onCancel={() => dispatch(toggleShowConfirmDelete(false))}
        >
            <p className="text"> {text}</p>
            <div className="action__confirm">
                <span className="no" onClick={() => dispatch(toggleShowConfirmDelete(false))}>
                    Không
                </span>
                <span onClick={() => handleOk(dataConfirmDelete.index, dataConfirmDelete.itemCode)} className="yes">
                    Có
                </span>
            </div>
        </Modal>
    )
}

const mapStateToProps = function (state) {
    return {
        isShowDelete: state.checkoutReducer.isShowDelete,
    }
}
export default withRouter(connect(mapStateToProps)(PopupConfirmDelete))
