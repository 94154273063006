import React from 'react'
import { Space, Spin } from 'antd'

function LoadingComponent() {
    return (
        <div className="d2c__loading__component">
            <div className="d2c__loading__component__content">
                <Space size="middle">
                    <Spin style={{ color: '#ee4d2d' }} size="large" />
                </Space>
            </div>
        </div>
    )
}

export default LoadingComponent
