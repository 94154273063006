import React from 'react'
import { useHistory, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import BoxHeaderTitle from '../../common/BoxHeaderTitle'
import DisplayPrice from '../../common/DisplayPrice'
import { IconHomeChietKhau } from '../../../constants/icon-const'
import { Button } from 'antd'
import { DeleteOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { toggleYourCart } from '../../../redux/actions/home-actions'
import { checkMinMax, convertCommission, getNoteData, renderDiscount } from '../../../functions/common-func'
import PopupConfirmDelete from '../../common/PopupConfirmDelete'
import CouponsSelector from '../../coupon/CouponsSelector'

function YourCart({
    toggleCart,
    dispatch,
    listProduct,
    onChangeQuantity,
    totalPrice,
    setListProductFromCart,
    handleDeleteProduct,
    handleConfirmDelete,
    dataConfirmDelete,
    setIsRefresh
}) {
    const history = useHistory()
    const onBack = () => {
        history.goBack()
    }
    return (
        <div className={`your__cart ${toggleCart ? 'open' : 'open'}`}>
            <BoxHeaderTitle text="Giỏ hàng của bạn" onClose={onBack} />
            {listProduct.length > 0 ? (
                <div className="your__cart__content">
                    <div className="container">
                        <div className="container">
                            <h2 className="title"> Sản phẩm đang chọn </h2>
                        </div>
                    </div>
                    {listProduct.map((res, index) => {
                        let cpDiscount = renderDiscount(res);
                                                
                        let priceAfterChoose = cpDiscount ? res.productPrice*res.quantity - cpDiscount : res.productPrice*res.quantity;
                        return (
                            <div key={res.id} className="cart__item">
                                <div className="container">
                                    <div className="product__content">
                                        <div className="product__image">
                                            <img src={res.product && res.product.thumbImage} alt="" />
                                        </div>
                                        <div className="product__detail">
                                            <p className="name">{res.product && res.product.name}</p>
                                            <p className="price__product">
                                                <span>Giá thành:</span>
                                                <DisplayPrice price={res.product && res.productPrice} />
                                            </p>
                                            <p className="price__product commission">
                                                <span>Hoa hồng:</span>
                                                <span className="price">
                                                    <IconHomeChietKhau />
                                                    <DisplayPrice
                                                        price={
                                                            res.product &&
                                                            convertCommission(
                                                                res.productPrice,
                                                                res.product.commissionValue,
                                                                res.product.commissionType
                                                            )
                                                        }
                                                    />
                                                </span>
                                            </p>
                                            <div className="product__voucher">
                                                <div className='title'>
                                                    <span>Coupon sản phẩm:</span>
                                                </div>
                                                <CouponsSelector
                                                    setIsRefresh = {setIsRefresh}
                                                    product={res}
                                                    cpDiscount={cpDiscount}
                                                />
                                            </div>
                                            <div className="product__quantity">
                                                <div className="quantity">
                                                    <Button
                                                        icon={<MinusOutlined style={{ fontSize: 12 }} />}
                                                        onClick={() => onChangeQuantity(index, 'minus')}
                                                        disabled={res.quantity === 1}
                                                    />
                                                    <Button> {res.quantity} </Button>
                                                    <Button
                                                        onClick={() => onChangeQuantity(index, 'plus')}
                                                        icon={<PlusOutlined style={{ fontSize: 12 }} />}
                                                        disabled={res.quantity === 10}
                                                    />
                                                </div>
                                                <div
                                                    className="delete__product"
                                                    onClick={() => handleConfirmDelete(index, res.itemCode)}
                                                >
                                                    <DeleteOutlined style={{ fontSize: 12 }} />
                                                    Xóa
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className="form__btn">
                        <div className="net__price">
                            <span> Thành tiền</span>
                            <p className="price">
                                <DisplayPrice price={totalPrice} />
                            </p>
                        </div>
                        <Button
                            type="primary"
                            className="w-100"
                            size="large"
                            onClick={() => dispatch(toggleYourCart(false))}
                        >
                            <Link to="/thanh-toan">Tiếp tục</Link>
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="your__cart__content">

                </div>
            )}
            <PopupConfirmDelete text="Bạn muốn bỏ sản phẩm này khỏi giỏ hàng?" handleOk={handleDeleteProduct} dataConfirmDelete={dataConfirmDelete} />
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        toggleCart: state.homeReducer.toggleCart,
    }
}
export default withRouter(connect(mapStateToProps)(YourCart))
