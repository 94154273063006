import { HOME_TYPE_REDUCER } from '../../constants/home-contants'
import { getPublisherSession, getSession } from '../../functions/common-func'

let user = getSession('userInfo')?.userInfo
const _role = getPublisherSession()

const homeReducer = (state = {}, action) => {
    switch (action.type) {
        case HOME_TYPE_REDUCER.SET_IS_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: action.isLoggedIn,
            }
        case HOME_TYPE_REDUCER.SET_ROLE:
            return {
                ...state,
                currentRole: action.currentRole,
            }
        case HOME_TYPE_REDUCER.OPEN_LOADING:
            return {
                ...state,
                showLoading: action.showLoading,
            }
        case HOME_TYPE_REDUCER.MENU_MOBILE:
            return {
                ...state,
                openMenuMobile: action.openMenuMobile,
            }
        case HOME_TYPE_REDUCER.TOGGLE_POPUP_SHARE:
            return {
                ...state,
                toggleShare: action.toggleShare,
            }
        case HOME_TYPE_REDUCER.TOGGLE_POPUP_RULE:
            return {
                ...state,
                toggleRule: action.toggleRule,
            }
        case HOME_TYPE_REDUCER.ADD_LINK_BOX_HEADER:
            return {
                ...state,
                linkBoxHeader: action.linkBoxHeader,
            }
        case HOME_TYPE_REDUCER.TOGGLE_YOUR_CART:
            return {
                ...state,
                toggleCart: action.toggleCart,
            }
        case HOME_TYPE_REDUCER.TOGGLE_SEARCH_BOX:
            return {
                ...state,
                toggleBoxSearch: action.toggleBoxSearch,
            }
        case HOME_TYPE_REDUCER.TOGGLE_POPUP_WELCOME:
            return {
                ...state,
                isShowWelcome: action.isShowWelcome,
            }
        case HOME_TYPE_REDUCER.IS_SHOW_MENU_ACCOUNT:
            return {
                ...state,
                isShowMenuAccount: action.isShowMenuAccount,
            }
        case HOME_TYPE_REDUCER.TOGGLE_MENU_SIDEBAR:
            return {
                ...state,
                isShowMenuSidebar: action.isShowMenuSidebar,
            }
        case HOME_TYPE_REDUCER.IS_PUBLISHER:
            return {
                ...state,
                isPublisher: action.isPublisher,
            }
        case HOME_TYPE_REDUCER.IS_TOP_INPUT:
            return {
                ...state,
                isTopInput: action.isTopInput,
            }
        case HOME_TYPE_REDUCER.IS_POLICY_WHOLESALE:
            return {
                ...state,
                isPolicyWholeSale: action.isPolicyWholeSale,
            }
        default:
            return { ...state, isLoggedIn: !!user, isPublisher: _role ? _role.role : 2 }
    }
}

export default homeReducer
