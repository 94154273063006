import React, { useEffect, useState } from 'react';
import { checkAvailable, checkMinMax, convertVariations, formatCurrency, selectedVariationData } from '../../functions/common-func';
import { isEmpty } from 'lodash'
import MinMax from '../product-price/MinMax';
const GroupVariations = ({
    product,
    setParentKey,
    setChildKey,
    setParentValue,
    setChildValue,
    setParentIndex,
    setChildIndex,

    parentIndex,
    childIndex,
    setVariantId,
    variantId,
    setVariPrice,
    variPrice
}) => {
    useEffect(() => {
        setParentIndex(-1);
        setChildIndex(-1);
    }, [product])
    const variationDatas = convertVariations(product);

    const handleSelectParent = (index, variation) => {
        const { attributeName, attributeValue, children, price } = variation;
        parentIndex === index ? setParentIndex(-1) : setParentIndex(index);
        setParentKey(attributeName);
        setParentValue(attributeValue);
        isEmpty(children) ? setVariPrice(price) : setVariPrice(null)
        
        if (children && children.length > 0 && childIndex > -1) {
            if(children[childIndex].display === false || children[childIndex]===0) {
                setChildIndex(-1);
                setChildValue(null)
            }
        }
    }
    const handleSelectChild = (index, variation) => {

        const { attributeName, attributeValue, price, id } = variation;
        childIndex === index ? setChildIndex(-1) : setChildIndex(index);
        setChildKey(attributeName);
        setChildValue(attributeValue);
        setVariPrice(price);
        setVariantId(id)
        // if(!variation.display || variation.quantity === 0) {
            
        //     setChildIndex(-1);
        //     setChildValue(null);
        // }

    }

    useEffect(() => {

        const selectedPrice = selectedVariationData(variationDatas, parentIndex, childIndex, setChildIndex);
        if (selectedPrice) {
            setVariPrice(selectedPrice);
            setVariantId(selectedPrice.id);
        } else {

            setVariPrice(null);
        }


    }, [parentIndex, childIndex])

    
    return (
        <div className="group-variations">

            {product.rangePrice &&
                <MinMax
                    variPrice={variPrice}
                    priceRange={product.rangePrice}
                    min={product.rangePrice.minPrice}
                    max={product.rangePrice.maxPrice}
                    commValue={product.commissionValue}
                    commType={product.commissionType}
                />
            }
            {/* {product.rangePrice && variPrice && <span className="group-variations-price-value"> {formatCurrency(variPrice.price)}₫</span>} */}

            {/* RENDER PARENT  */}
            {variationDatas.length > 0 && variationDatas[0]?.children?.length > 0 && <><div className="group-variations-content">
                <div className="variation-title" >
                    {variationDatas[0].attributeName}
                </div>
                <div className="variation-content" >
                    {variationDatas.map((variation, index) => {
                        return <>
                            <div className={`variation-value ${parentIndex === index ? 'active' : ''} ${checkAvailable(variation) ? '' : 'disabled'}`} key={index}
                                onClick={() => handleSelectParent(index, variation)}>
                                {variation.attributeValue}
                            </div>
                        </>
                    })}
                </div>
            </div> </>}
            {/* RENDER CHILD  */}
            {variationDatas.length > 0 && variationDatas[parentIndex]?.children?.length > 0 &&

                <><div className="group-variations-content">

                    <div className="variation-title" >
                        {variationDatas[parentIndex].children[0]?.attributeName}
                    </div>
                    <div className="variation-content" >
                        {variationDatas[parentIndex].children?.map((variation, index) => {

                            return <>
                                <div className={`variation-value ${(childIndex === index && variation.display && variation.quantity > 0) ? 'active' : ''} ${(variation.display === false || variation.quantity === 0) ? 'disabled' : ''}`} key={index}
                                    onClick={() => handleSelectChild(index, variation)}>

                                    {variation.attributeValue}
                                </div>
                            </>
                        })}
                    </div>
                </div> </>
            }
            {/* RENDER ONE  */}
            {variationDatas.length > 0 && variationDatas[0]?.children?.length === 0 &&

                <><div className="group-variations-content">

                    <div className="variation-title" >
                        {variationDatas.length && variationDatas[0].attributeName}
                    </div>
                    <div className="variation-content" >
                        {variationDatas.length > 0 && variationDatas.map((variation, index) => {
                            return <>
                                <div className={`variation-value ${(parentIndex === index && variation.display) ? 'active' : ''} ${(variation.display === false || variation.quantity < 1) ? 'disabled' : ''}`} key={index}
                                    onClick={() => handleSelectParent(index, variation)}>

                                    {variation.attributeValue}
                                </div>
                            </>
                        })}
                    </div>
                </div> </>
            }

            {/* RENDER FIRST LOAD  */}
            {parentIndex < 0 && variationDatas.length > 0 && variationDatas[0].children?.length > 0 &&

                <><div className="group-variations-content">

                    <div className="variation-title" >
                        {variationDatas[0].children[0]?.attributeName}
                    </div>
                    <div className="variation-content" >
                        {variationDatas[0].children?.map((variation, index) => {
                            return <>
                                <div className={`variation-value ${childIndex === index ? 'active' : ''}`} key={index}
                                    onClick={() => handleSelectChild(index, variation.attributeName, variation.attributeValue)}>
                                    {variation.attributeValue}
                                </div>
                            </>
                        })}
                    </div>
                </div> </>
            }
        </div>
    );
}
export default GroupVariations;