import ApiMicroService from './request-micro-service'

export const createOrder = async (data) => {
    try {
        const results = await ApiMicroService.post(`/d2c-service/order/products`, data)
        return results.data
    } catch (e) {
        throw e
    }
}
export const createOrderCoupon = async (data) => {
    try {
        const results = await ApiMicroService.post(`/d2c-service/order/products-has-coupon`, data)
        return results.data
    } catch (e) {
        throw e
    }
}

export const createOrderBuyOne = async (data) => {
    try {
        const results = await ApiMicroService.post(`/d2c-service/shopping-cart/buy-one`, data)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchListOrder = async (paramSearch) => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/order/purchased-products?${paramSearch}`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchOrderItemDetail = async (id) => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/order/purchased-products/${id}`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchOrderDetail = async (id) => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/order/${id}/detail`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const createWholeSale = async (data) => {
    try {
        const results = await ApiMicroService.post(`/d2c-service/wholesale-pub`, data)
        return results.data
    } catch (e) {
        throw e
    }
}
