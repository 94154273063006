import React, { useState } from 'react'
import DisplayPrice from '../common/DisplayPrice'
import { Progress, DatePicker } from 'antd'
import moment from 'moment'
import { Bar } from 'react-chartjs-2'
import top1 from '../../assets/images/top1.png'
import top2 from '../../assets/images/top2.png'
import top3 from '../../assets/images/top3.png'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import TopProduct from '../common/TopProduct/TopProduct'
import { displayPrice, isMobileScreen } from '../../functions/common-func'
import { IconClose } from '../../constants/icon-const'
const { RangePicker } = DatePicker

function Dashboard({
    dataCurrent,
    listTop,
    dataChart,
    filter,
    listTopPublisher,
    isOpenDp,
    onChange,
    handleOpenDp,
    startDate,
    endDate,
    handleCloseDp,
}) {
    // convert tỉ lệ duyệt
    const convertApproved = (total, price) => {
        if (total !== 0) {
            return Number((price / total) * 100).toFixed(2)
        }
        return 0
    }

    const renderExtraFooter = () => (
        <div className="headerPopup">
            <p>Chọn ngày</p>
            <button type="button" className="headerPopup__close" onClick={handleCloseDp}>
                <IconClose />
            </button>
        </div>
    )

    const [options] = useState({
        maintainAspectRatio: false,
        scales: {
            xAxes: [
                {
                    stacked: true,
                },
            ],
            yAxes: [
                {
                    stacked: true,
                    ticks: {
                        callback: function (value) {
                            return parseInt(value) / 1000000 + 'tr'
                        },
                    },
                    scaleLabel: {
                        display: true,
                        labelString: '1tr = 1000.000đ',
                    },
                },
            ],
        },
        legend: { position: 'bottom' },
        tooltips: {
            callbacks: {
                label: function (tooltipItem) {
                    let price = displayPrice(tooltipItem.yLabel)
                    const datasetIndex = tooltipItem.datasetIndex
                    if (datasetIndex === 0) {
                        return 'Hoa hồng' + ': ' + price
                    } else {
                        return price
                    }
                },
            },
        },
    })

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day')
    }

    const convertStt = (index) => {
        if (index === 0) {
            return <img src={top1} alt="" />
        }
        if (index === 1) {
            return <img src={top2} alt="" />
        }
        if (index === 2) {
            return <img src={top3} alt="" />
        }
        return index + 1
    }

    return (
        <section className="dashboard__main">
            <BoxHeaderTitle text="Trang tổng quan" link="/" isBell={true} />
            <div className="container">
                <div className="dashboard">
                    <div className="dashboard__menu">
                        {/*<div*/}
                        {/*    onClick={() => handleOnchangeFilter('preApproved')}*/}
                        {/*    className={`menu__item ${filter === 'preApproved' ? 'active' : ''}`}*/}
                        {/*>*/}
                        {/*    <span>Tạm duyệt</span>*/}
                        {/*</div>*/}
                        {/*<div*/}
                        {/*    onClick={() => handleOnchangeFilter('approved')}*/}
                        {/*    className={`menu__item ${filter === 'approved' ? 'active' : ''}`}*/}
                        {/*>*/}
                        {/*    <span>Đã duyệt</span>*/}
                        {/*</div>*/}

                        <RangePicker
                            className="w-100"
                            open={isOpenDp}
                            onChange={onChange}
                            onOpenChange={handleOpenDp}
                            allowClear={true}
                            disabledDate={disabledDate}
                            size={'large'}
                            format={'DD-MM-YYYY'}
                            inputReadOnly={true}
                            renderExtraFooter={renderExtraFooter}
                            value={[startDate, endDate]}
                        />
                    </div>
                    <div className="dashboard__content">
                        <div className="dashboard__content__item">
                            <h2 className="title">Hoa hồng tạm duyệt</h2>
                            <p className="price">
                                <DisplayPrice price={dataCurrent && dataCurrent.pubCom && dataCurrent.pubCom[filter]} />
                            </p>
                            <Progress
                                strokeColor="#722ED1"
                                percent={
                                    dataCurrent &&
                                    dataCurrent.pubCom &&
                                    convertApproved(dataCurrent.pubCom.total, dataCurrent.pubCom[filter])
                                }
                                status="exception"
                            />
                            <div className="text__dashboard">
                                <p className="incurred">
                                    Phát sinh:
                                    <span className="price">
                                        <DisplayPrice
                                            price={dataCurrent && dataCurrent.pubCom && dataCurrent.pubCom.total}
                                        />
                                    </span>
                                </p>
                                <p className="browse__ratio">
                                    Tỉ lệ duyệt:{' '}
                                    {dataCurrent &&
                                        dataCurrent.pubCom &&
                                        convertApproved(dataCurrent.pubCom.total, dataCurrent.pubCom[filter])}
                                    %
                                </p>
                            </div>
                        </div>
                        <div className="dashboard__content__item">
                            <h2 className="title">Doanh số bán tạm duyệt</h2>
                            <p className="price">
                                <DisplayPrice
                                    price={dataCurrent && dataCurrent.saleAmount && dataCurrent.saleAmount[filter]}
                                />
                            </p>
                            <Progress
                                strokeColor="#1890FF"
                                percent={
                                    dataCurrent &&
                                    dataCurrent.saleAmount &&
                                    convertApproved(dataCurrent.saleAmount.total, dataCurrent.saleAmount[filter])
                                }
                                status="exception"
                            />
                            <div className="text__dashboard">
                                <p className="incurred">
                                    Phát sinh:
                                    <span className="price">
                                        <DisplayPrice
                                            price={
                                                dataCurrent && dataCurrent.saleAmount && dataCurrent.saleAmount.total
                                            }
                                        />
                                    </span>
                                </p>
                                <p className="browse__ratio">
                                    Tỉ lệ duyệt:{' '}
                                    {dataCurrent &&
                                        dataCurrent.saleAmount &&
                                        convertApproved(dataCurrent.saleAmount.total, dataCurrent.saleAmount[filter])}
                                    %
                                </p>
                            </div>
                        </div>
                        <div className="dashboard__content__item">
                            <h2 className="title">Đơn hàng tạm duyệt</h2>
                            <p className="price">
                                {(dataCurrent && dataCurrent.conversion && dataCurrent.conversion[filter]) || 0}
                            </p>
                            <Progress
                                strokeColor="#52C41A"
                                percent={
                                    dataCurrent &&
                                    dataCurrent.pubCom &&
                                    convertApproved(dataCurrent.pubCom.total, dataCurrent.pubCom[filter])
                                }
                                status="exception"
                            />
                            <div className="text__dashboard">
                                <p className="incurred">
                                    Phát sinh:
                                    <span className="price">
                                        {(dataCurrent && dataCurrent.conversion && dataCurrent.conversion.total) || 0}
                                    </span>
                                </p>
                                <p className="browse__ratio">
                                    Tỉ lệ duyệt:{' '}
                                    {dataCurrent &&
                                        dataCurrent.conversion &&
                                        convertApproved(dataCurrent.conversion.total, dataCurrent.conversion[filter])}
                                    %
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="product__report">
                        {!isMobileScreen() && <TopProduct listTop={listTop} />}
                        <div className="dashboard__report hot__product">
                            <h2 className="title">Hoa hồng </h2>
                            {dataChart && <Bar data={dataChart} width={100} height={362} options={options} />}
                        </div>
                        <div className="dashboard__report top__publisher">
                            <h2 className="title">Top 10 publisher của tuần</h2>
                            <div className="list__publisher">
                                <div className="item__publisher">
                                    <div className="name__publisher">
                                        <div className="title">Tên publisher</div>
                                    </div>
                                    <div className="commission">
                                        <div className="title">Điểm ATSP</div>
                                    </div>
                                </div>

                                {listTopPublisher &&
                                    Array.isArray(listTopPublisher.reportData) &&
                                    listTopPublisher.reportData.length > 0 &&
                                    listTopPublisher.reportData.map((res, index) => {
                                        if (res.lastName && res.lastName !== 'N/A') {
                                            return (
                                                <div key={index} className="item__publisher">
                                                    <div className="name__publisher">
                                                        <div className="stt">{convertStt(index)}</div>
                                                        <div className="name">
                                                            {res.lastName} {res.firstName}
                                                        </div>
                                                    </div>
                                                    <div className="commission">
                                                        <span className="price">
                                                            {Number(Math.round(res.atsp)).toFixed(0)}
                                                        </span>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return null
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Dashboard
