import { PRODUCT_DETAIL_TYPE_REDUCER } from '../../constants/product-detail-const'

const productDetailReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_DETAIL_TYPE_REDUCER.OPEN_WARNING:
            return {
                ...state,
                openTutorialShare: action.openTutorialShare,
            }
        default:
            return { ...state }
    }
}

export default productDetailReducer
