import ApiMicroService from './request-micro-service'

export const getDetailProduct = async (id) => {
    const params = ''
    try {
        const results = await ApiMicroService.get(`/d2c-service/product/${id}?${params}`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchListProduct = async (filter) => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/product/${filter}`)
        return results.data
    } catch (e) {
        throw e
    }
}
export const fetchDeepLink = async (supplierId) => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/product/deeplink/${supplierId}`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchListHotWeek = async () => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/product/hot`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchListBrand = async () => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/brand`)
        return results.data
    } catch (e) {
        throw e
    }
}
export const fetchListCampaign = async (id) => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/product/campaign/${id}`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchProductInDeal = async (dealId, page = 1, pageSize = 16) => {
    try {
        const results = await ApiMicroService.get(
            `d2c-service/product/deal/${dealId}?page_size=${pageSize}&page=${page}`
        )
        return results.data
    } catch (e) {
        throw e
    }
}
export const fetchTop5Products = async (page = 1, pageSize = 5, is_policy_wholesale = 0) => {
    try {
        const results = await ApiMicroService.get(
            `d2c-service/product/product/top-by-position?page_size=${pageSize}&page=${page}&is_policy_wholesale=${is_policy_wholesale}`
        )
        return results.data
    } catch (e) {
        throw e
    }
}
export const fetchProductPreview = async (idCheck, keyName, tokenCheck) => {
    const params = 'with_relations[content]=1&with_relations[video]=1&with_relations[image]=1&with_relations[brand]=1&with_relations[category]=1&with_relations[attribute]=1&with_relations[variation]=1&with_relations[supplier]=1'
    try {
        const results = await ApiMicroService.get(`/d2c-service/product/${idCheck}?${params}`, {
            headers: {
                [keyName]: tokenCheck,
            }
        })
        return results.data
    } catch (e) {
        throw e
    }
}
