import React, { useEffect, useState } from 'react'
import { Input, Button, Form, Modal } from 'antd'
import { toggleShowPopupRegister } from '../../redux/actions/account-action'
import { removeSession } from '../../functions/common-func';

function RegisterPopup ( props )
{
	const [ title, setTitle ] = useState( 'Đăng ký Dimuadi' );
	const typeLogin = localStorage.getItem( 'type_login' );





	useEffect( () =>
	{
		if ( props.isNotRegistered && typeLogin == "2" )
		{
			setTitle( 'Đăng ký Dimuadi qua Google' );
		} else if ( props.isNotRegistered )
		{
			setTitle( 'Đăng ký Dimuadi' );
		}
	} )

	const submit = async ( e ) =>
	{
		if ( typeLogin === "1" )
		{
			let isValidForm = true;
			if ( !props.username || ( props.username && props.username.trim() == '' ) )
			{
				props.setVldUsername( { status: 'error', text: 'Vui lòng nhập tên tài khoản' } );
				isValidForm = false;
			}
			if ( !props.password || ( props.password && props.password.trim() == '' ) )
			{
				props.setVldPassword( { status: 'error', text: 'Vui lòng nhập mật khẩu' } );
				isValidForm = false;
			}
			if ( !props.password_cf || ( props.password_cf && props.password_cf.trim() == '' ) )
			{
				props.setVldPasswordCf( { status: 'error', text: 'Vui lòng xác nhận mật khẩu' } );
				isValidForm = false;
			}
			if ( !isValidForm ) return;

			if ( props.password_cf && props.password && props.password_cf != props.password )
			{
				props.setVldPasswordCf( { status: 'error', text: 'Mật khẩu xác nhận không khớp' } );
				isValidForm = false;
			}
			if ( !isValidForm ) return;
		}

		await props.register();

	}

	return (
		<Modal
			title={ title }
			className='text-center'
			visible={ props.isShowPopupRegister }
			onCancel={ () => {
				props.dispatch( toggleShowPopupRegister( false ) );
				removeSession('userInfo')
				removeSession('role')
				// let redirect_uri = encodeURI(`http://localhost:4201/login`);
				let redirect_uri = encodeURI(`https://` + window.location.hostname + `/login`);
				let url = `${process.env.REACT_APP_URL_GET_AUTHOR_ACCESS_TRADE}/logout?redirect_uri=${redirect_uri}`
				window.location.href = url;
			} }
			footer={ null }
		>
			<Form >
				<div className="box__content">
					{ props.email &&
						<div className="form-group">
							<Form.Item
								name="email"
								className="el-input__inner"
								style={ { color: "#4f546 !important" } }
							>
								<Input
									placeholder="example@gmail.com"
									readOnly
									className='readonly'
									style={ { color: "#4f546 !important", background: "#e9ecef" } }
									defaultValue={ props.email }
									size="large"
								/>
							</Form.Item>
						</div>
					}
					{ typeLogin === "1" &&
						<>
							<div className="form-group">
								<Form.Item
									name="username"
									className="el-input__inner"
									required
									style={ { color: "#4f546 !important" } }
									// rules={ [ { required: true } ] }
									validateStatus={ props.vldUsername.status }
									help={ props.vldUsername.status }
								>
									<Input
										placeholder="Tên đăng nhập"
										defaultValue={ props.username }
										readOnly
										style={ { color: "#4f546 !important", background: "#e9ecef" } }
										className='readonly'
										// onChange={ e =>
										// {
										// 	if ( e )
										// 	{
										// 		props.setUsername( e?.target?.value?.trim() );
										// 		props.setVldUsername( { status: "", text: "" } );
										// 	}
										// } }
										size="large"
									/>
								</Form.Item>
							</div>

							<div className="form-group">
								<Form.Item
									name="Mật khẩu"
									className="el-input__inner text-left"
									validateStatus={ props.vldPassword.status }
									help={ props.vldPassword.text }
								>
									<Input.Password
										placeholder="Mật khẩu"

										onChange={ e =>
										{
											if ( e )
											{
												props.setPassword( e?.target?.value.trim() );
												props.setVldPassword( { status: "", text: "" } );
											}
										} }
										size="large"
									/>
								</Form.Item>
							</div>

							<div className="form-group">
								<Form.Item
									name="Xác nhận mật khẩu"
									className="el-input__inner text-left"
									validateStatus={ props.vldPassword.status }
									help={ props.vldPasswordCf.text }
								>
									<Input.Password
										placeholder="Xác nhận mật khẩu"
										defaultValue={ props.password_cf }
										onChange={ e =>
										{
											if ( e )
											{
												props.setPassword_cf( e?.target?.value.trim() );
												props.setVldPasswordCf( { status: "", text: "" } );
											}
										} }
										size="large"
									/>
								</Form.Item>
							</div>
						</>
					}



					<div className="form-group">
						<Form.Item
							name="phone"
							className="el-input__inner text-left"
							validateStatus={ props.validatePhone.status }
							help={ props.validatePhone.text }
						>
							<Input
								placeholder="Số điện thoại"
								maxLength={ 10 }
								value={ props.phone || '' }
								size="large"
								onChange={ props.handleChangePhone }
							/>
						</Form.Item>
					</div>

					<div className="form-group">
						<Form.Item
							name="mcnCode"
							className="el-input__inner"
							style={ { marginBottom: "0px" } }
						>
							<Input
								placeholder="Mã giới thiệu (Tùy chọn)"
								value={ props.mcnCode }
								onChange={ ( e ) => props.setMcnCode( e.target.value ) }
								size="large"
								className='mb-0'
							/>
						</Form.Item>
						<p className='pb-0 text-left'>Nhập mã giới thiệu để được ưu đãi vô cùng hấp dẫn</p>
					</div>

				</div>

				<div className="box__footer">
					<Button
						onClick={ submit }
						style={ { margin: '20px 0' } }
						size="large"
						className="w-100"
						type="primary"
					>
						Tiếp tục
					</Button>
				</div>
			</Form>
		</Modal>
	)
}
// const mapStateToProps = function (state) {
//     return {
//         isShowPopupRegister: state.accountReducer.isShowPopupRegister,
//     }
// }
export default RegisterPopup
