import ApiMicroService from './request-micro-service'
import * as axios from 'axios'

export const accountLogin = async (data) => {
    return axios({
        method: 'POST',
        url: process.env.REACT_APP_URL_API_SERVICE + `/d2c-service/auth/signin`,
        data: data,
    })
        .then((result) => {
            return result.data
        })
        .catch((err) => {
            throw err
        })
}

export const accountLoginV2 = async (data) => {
    return axios({
        method: 'POST',
        url: process.env.REACT_APP_URL_API_SERVICE + `/d2c-service/auth/signin-v3`,
        data: data,
    })
        .then((result) => {
            return result.data
        })
        .catch((err) => {
            throw err
        })
}

export const isRegister = async (data) => {
    return axios({
        method: 'POST',
        url: process.env.REACT_APP_URL_API_SERVICE + `/d2c-service/auth/check-register`,
        data: data,
    })
        .then((result) => {
            return result.data;
        })
        .catch((err) => {
			
            throw err
        })
}

export const updateProfile = async (data) => {
    try {
        const results = await ApiMicroService.put(`/d2c-service/user/phone`, data)
        return results.data;
    } catch (e) {
        throw e
    }
}

export const getUserInfo = async () => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/user/detail`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const upgradeRoleForUser = async (data) => {
    try {
        const results = await ApiMicroService.post(`/d2c-service/user/upgrade-role`, data)
        return results.data
    } catch (e) {
        throw e
    }
}

export const updatePhoneNumber = async (user, data) => {
    
    try {
        const microService = axios.create({
            baseURL: process.env.REACT_APP_URL_API_SERVICE,
            responseType: 'json',
        });
        
        microService.defaults.headers.common['Authorization'] = user && user.tokenInfo ? 'Bearer ' + user['tokenInfo'].accessToken : '';
        microService.defaults.headers.post['Content-Type'] = 'application/json';
        const results = await microService.put(`/d2c-service/user/phone`, data)
        return results.data;
    } catch (e) {
        throw e
    }
}
