import React, { useState, useEffect } from 'react'
import { useLocation, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet';
import DisplayPrice from '../common/DisplayPrice'
import { IconHomeChietKhau, IconRightOutlined } from '../../constants/icon-const'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import { LinkOutlined, DownOutlined, UpOutlined, RightOutlined } from '@ant-design/icons'
import ImageGallery from 'react-image-gallery'
import ReactImageMagnify from 'react-image-magnify'
import { Button, message, Modal } from 'antd'
import BoxShare from '../common/popup/BoxShare'
import { togglePopupShare } from '../../redux/actions/home-actions'
import TutorialAccountContainer from '../../container/user/TutorialAccountContainer'
import { toggleShowTutorial } from '../../redux/actions/account-action'
import { ZoomInOutlined } from '@ant-design/icons'
import BoxConfirmCart from '../create-order/BoxConfirmCart'
import NotFound from '../not-found/NotFound'

import {
    checkIsOldVariation,
    checkMinMax,
    checkURL, checkURLImage, checkURLVideo, checkURLYoutube,
    convertCommission,
    convertSizeImage,
    isMaxTableScreen,
    isMobileScreen,
    isTableScreen,
    checkIsNewVariation
} from '../../functions/common-func'
import { DEFAULT_IMAGE, PATH_PRODUCT, PATH_PRODUCT_CATEGORY } from '../../constants/common-contants'
import PaginationContainer from '../../container/common/PaginationContainer'
import ShareListContent from '../common/popup/ShareListContent'
import { toggleChooseContent } from '../../redux/actions/search-action'
import BreadcrumbNavigation from '../common/BreadcrumbNavigation'
import BoxConfirmTutorialShare from '../common/popup/BoxConfirmTutorialShare'
import { toggleTutorialShare } from '../../redux/actions/search-action'
import imageDefault from '../../assets/images/image_faildoad.png'
import PriceRange from './PriceRange'
import GroupVariations from './GroupVariations';
import CouponSlider from './CouponSlider';
import CouponItemSelector from '../coupon/CouponItemSelector';
import OldVariations from './OldVariations';
import { toggleShowNotBuy } from '../../redux/actions/create-order-action';

function ProductDetail({
    dispatch,
    toggleShare,
    onClose,
    dataDetail,
    handleShareProduct,
    handleCreateOrder,
    relateProduct,
    isPublisher,
    paging,
    isLoggedIn,
    deepLink,
    contentShare,
    handleContentShare,
    dataCampaign,
    wholeSale,
    history,
    dataCoupons,
    setDataCoupons,
    showMore,
    setShowMore,
    dataCouponModal,
    setDataCouponModal,
    pagingCoupon,
    setPagingCoupon,
    setParentIndex,
    setChildIndex,
    parentIndex, 
    childIndex,
    variantId,
    setVariantId,
    setParentKey,
    setChildKey,
    setParentValue,
    setChildValue,
    setVariPrice,
    variPrice,
    oldVariation,
    setOldVariation,
    isShowNotBuy
}) {
    const [convertImages, setConvertImages] = useState([])
    const [isImageDesktop, setIsImageDesktop] = useState('')
    const [seeMoreDes, setSeeMoreDes] = useState(false)
    const [seeMoreContent, setSeeMoreContent] = useState(false)
    const [isOpenModalImage, setIsOpenModalImage] = useState(false)
    const [startIndex, setStartIndex] = useState(0)
    const [indexImage, setIndexImage] = useState(1);
    const [openModal, setOpenModal] = useState(false);


    useEffect(() => {
        if (dataDetail && dataDetail.images && dataDetail.images.length > 0) {
            const _convertImages = dataDetail.images.map((res) => {
                return {
                    original: res || DEFAULT_IMAGE,
                    thumbnail: convertSizeImage(res, 200, 200),
                }
            })
            setConvertImages(_convertImages)
            if (_convertImages && _convertImages[0]) {
                setIsImageDesktop(_convertImages[0].original)
            }
        } else {
            setConvertImages([
                {
                    original: DEFAULT_IMAGE,
                    thumbnail: convertSizeImage(DEFAULT_IMAGE, 200, 200),
                },
            ])
        }
    }, [dataDetail]);


    const isShare = toggleShare ? toggleShare.isShare : false
    const isAdvanceInfo = dataDetail && dataDetail.advanceInfo && Array.isArray(dataDetail.advanceInfo)
        && dataDetail.advanceInfo.length > 0 && dataDetail.advanceInfo[0] && dataDetail.advanceInfo[0].length > 0
    let htmlZalo = ` <div
                        class="zalo-share-button"
                        data-href=${dataDetail && dataDetail.videos && dataDetail.videos.length > 0
            ? dataDetail.videos[0]
            : dataDetail.thumbImage || window.location.href
        }
                        data-oaid="579745863508352884"
                        data-layout="4"
                        data-color="blue"
                        data-customize="false"
                    ></div>`
    const options = [
        {
            label: 'Sản phẩm',
        },
        {
            label: '>',
        },
        {
            label: dataDetail && dataDetail.name,
            active: true,
        },
    ]
    const is_old_variation = checkIsOldVariation(dataDetail);
    const is_new_variation = checkIsNewVariation(dataDetail);

    const convertPriceRange = (index,position) => {

        var prices = JSON.parse(dataDetail.priceRange);
        var product = prices[index].split('(');
        var productName = product[0];
        var productPrice;
        (product[1]=== undefined)? productPrice = '':productPrice = product[1].replace(')','');
        return position === 0?productPrice:productName
    }
    const buyProductWithCoupon = (coupon) => {
        message.warning('Vui lòng sử dụng tính năng này trên ứng dụng DiMuaDi');
        
        // if (coupon.quantityUsed === coupon.quantity) {
        //     message.error("Voucher đã hết lượt sử dụng!")
        //     return;
        // }
        // handleCreateOrder(dataDetail.id, dataDetail.quantity=1, coupon)
        
    }
    if (dataDetail && dataDetail.status < 2 ) {
        //redirect to 404
        history.push('/404')
    }

    

    const onChangeImage = (image) => {
        setIsImageDesktop(image)
    };

    const onSlide = (e) => {
        setIndexImage(e + 1)
    };
    const convertMessage = (data) => {
        if (data) {
            return data.split('\u000a').join('<br>')
        }
        return ''
    };


    function renderContactToBuy(wholeSalePriceData) {
        if (!isEmpty(wholeSalePriceData)) {
            const fromQtyForContactToBuy =
                last(wholeSalePriceData).toQty !== null
                    ? last(wholeSalePriceData).toQty + 1
                    : last(wholeSalePriceData).fromQty
            const contactToBuyPrice = { fromQty: fromQtyForContactToBuy }
            return (
                <PriceRange
                    isContactToBuy={true}
                    onWholeSale={() => wholeSale(dataDetail.id, dataDetail.quantity, dataDetail.name)}
                    data={contactToBuyPrice}
                />
            )
        }
        return null
    }

    function renderOldPriceRange() {
        return (
            <>
                <div className="column_price_range">
                    <h4 className="text_header"> {convertPriceRange(0, 0)}</h4>
                    <p className="text_header"> {convertPriceRange(0, 1)} </p>
                </div>
                <div className="column_price_range">
                    <h4>{convertPriceRange(1, 0)}</h4>
                    <p>{convertPriceRange(1, 1)}</p>
                </div>
                <div className="column_price_range">
                    <h4
                        className="text_line_throught"
                        onClick={() => wholeSale(dataDetail.id, dataDetail.quantity, dataDetail.name)}
                    >
                        {convertPriceRange(2, 0)}
                    </h4>
                    <p>{convertPriceRange(2, 1)}</p>
                </div>
            </>
        )
    }
    const renderPriceGroup = () => {
        return (
            <>
                <div className="price_range">
                    <p className="text_header"> Giá bán sỉ:</p>
                    <div className="row_price_range">
                        {!isEmpty(dataDetail.wholesalePrice) &&
                            dataDetail.wholesalePrice.map((wholesalePrice) => (
                                <PriceRange data={wholesalePrice} />
                            ))}
                        {!isEmpty(dataDetail.wholesalePrice) &&
                            last(dataDetail.wholesalePrice).price === null
                            ? renderContactToBuy(dataDetail.wholesalePrice)
                            : ''}
                        {!isEmpty(dataDetail.wholesalePrice) &&
                            last(dataDetail.wholesalePrice).toQty !== null
                            ? renderContactToBuy(dataDetail.wholesalePrice)
                            : ''}

                        {isEmpty(dataDetail.wholesalePrice) &&
                            !isEmpty(dataDetail.priceRange) &&
                            renderOldPriceRange()}
                        {isEmpty(dataDetail.wholesalePrice) && isEmpty(dataDetail.priceRange) && (
                            <>
                                <h4
                                    className="text_line_throught"
                                    style={{ 'marginLeft': '15px' }}
                                    onClick={() => wholeSale(dataDetail.id, dataDetail.quantity, dataDetail.name)}
                                >
                                    Liên hệ
                                </h4>
                                <p className="text_header"> &gt;10 sản phẩm  </p>
                            </>
                        )}
                    </div>
                    <div className="text_center">
                        <p>Đặt hàng tối thiểu 10 sản phẩm</p>
                    </div>
                </div>
            </>
        )
    }
    const onCancel = () =>{
        setShowMore(false);
    };
    const openModalCoupon = () => {
        return (
            <Modal
                title='Coupon được áp dụng'
                visible={showMore}
                onCancel={onCancel}
                footer={null}
                width={isMobileScreen() ? '100%' : '40%'}
                className="modal__coupon-details"
            >

                <div className="modal__coupon-details__content" >
                    {dataCouponModal?.length > 0 && dataCouponModal.map((coupon, index) => {
                        return (
                            <CouponItemSelector 
                            key={index} 
                            coupon={coupon}
                            showMore={true} 
                            buyProductWithCoupon={buyProductWithCoupon}
                            dispatch={dispatch}  
                            />
                        )
                    })}
                    {dataCouponModal?.length <= 0 && <p>Sản phẩm không hỗ trợ coupon</p>}
                </div>
            </Modal>
        )
    }
    if (dataDetail && dataDetail.status < 2) {
        history.push('/404')
    }
    
    return (

        <section className="product_detail">
            <div className="container">
                <BoxHeaderTitle text="Chi tiết sản phẩm" onClose={onClose} isBell={true} />
                <BreadcrumbNavigation option={options} />
                {dataDetail && (
                    <div className="product_detail__content">
                        <Helmet>
                            <title>DiMuaDi | {dataDetail.name}</title>
                            {convertImages && convertImages.length > 0 && (
                                <meta property="og:image" content={convertImages[0].original} />
                            )}
                        </Helmet>
                        <div className="product__detail__item detail__image">

                            {/* <div className="image__mobile">
                                <div className="container">
                                    <video controls>
                                        <source src={dataDetail.videos[0]} type="video/webm" width="auto" height= "200"></source>
                                        <source src={dataDetail.videos[0]} type="video/mp4"width="auto" height= "200"></source>

                                    </video>
                                </div>
                            </div> */}

                            <div className="image__mobile">
                                <div className="container">
                                    <ImageGallery onSlide={onSlide} items={convertImages} startIndex={startIndex} />
                                    <div className="index__image">
                                        <span>
                                            {indexImage}/{convertImages.length}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="image__desktop">
                                <div className="group-images">
                                    <div className="images">
                                        {convertImages.length > 0 &&
                                            convertImages.map((res, index) => {
                                                if (index < 4) {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={`images__item ${res.original === isImageDesktop ? 'active' : ''
                                                                }`}
                                                            onClick={() => onChangeImage(res.original)}
                                                        >
                                                            <img src={res.thumbnail} alt="" />
                                                        </div>
                                                    )
                                                }
                                            })}

                                        {convertImages && convertImages.length > 4 && (
                                            <div
                                                onClick={() => {
                                                    setIsOpenModalImage(true)
                                                    setStartIndex(4)
                                                }}
                                                style={{ position: 'relative' }}
                                                className={`images__item `}
                                            >
                                                <img src={convertImages[4].thumbnail} alt="" />
                                                <div className="background">
                                                    Xem thêm {convertImages.length - 5} ảnh
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="thumbnail">
                                        {/*<ReactImageZoom {...props} />*/}
                                        <ReactImageMagnify
                                            {...{
                                                smallImage: {
                                                    width: 450,
                                                    height: 395,
                                                    src: isImageDesktop || imageDefault,
                                                },
                                                largeImage: {
                                                    src: isImageDesktop || imageDefault,
                                                    width: 900,
                                                    height: 800,
                                                },
                                                enlargedImageContainerDimensions: {
                                                    width: 400,
                                                    height: 600,
                                                },
                                            }}
                                        />
                                        <p className="zoom" style={{ textAlign: 'center' }}>
                                            {' '}
                                            <ZoomInOutlined /> Rê chuột để phóng to
                                        </p>
                                    </div>
                                    {convertImages && convertImages.length > 4 && (
                                        <Modal
                                            className="modal__image__product__detail"
                                            title="Danh sách ảnh"
                                            visible={isOpenModalImage}
                                            footer={null}
                                            onCancel={() => setIsOpenModalImage(false)}
                                        >
                                            <div className="desktop__image__product">
                                                <ImageGallery
                                                    onSlide={onSlide}
                                                    items={convertImages}
                                                    startIndex={startIndex}
                                                    lazyLoad={true}
                                                />
                                            </div>
                                        </Modal>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="detail__info">
                            {/*<BoxWarning*/}
                            {/*    onOpen={() => dispatch(toggleOpenWarning(true))}*/}
                            {/*    onClose={() => dispatch(toggleOpenWarning(false))}*/}
                            {/*    openWarning={openWarning}*/}
                            {/*/>*/}
                            <div className="product_detail__item detail__text border__bottom">
                                <div>
                                    <div className="item__title">
                                        <h2>
                                            {dataDetail.status === 3 && (
                                                <span className="label label_out_of_stock">Tạm hết hàng </span>
                                            )}
                                            {dataDetail.name}
                                        </h2>
                                    </div>

                                    {/*{dataDetail.sku && <p>Nhà cung cấp: {dataDetail.sku}</p>}*/}

                                    {isPublisher === 1 && dataDetail.isPolicyWholesale && renderPriceGroup()}

                                    {!dataDetail.rangePrice && (<p>
                                        Giá bán:{' '}
                                        {isPublisher === 1 && (
                                            <strong>
                                                <DisplayPrice price={dataDetail.price} />
                                            </strong>
                                        )}
                                    </p>)}
                                    {isPublisher !== 1 && !dataDetail.rangePrice && (
                                        <p className="price__buyer">
                                            {Number(dataDetail.discountAmount) > 0 && (
                                                <span className="discount"> -{dataDetail.discountAmount}%</span>
                                            )}
                                            <span className="price">
                                                {' '}
                                                <DisplayPrice price={dataDetail.price} />
                                            </span>
                                            {dataDetail.priceBeforeDiscount > dataDetail.price && (
                                                <span className="price__before__discount">
                                                    {' '}
                                                    <DisplayPrice price={dataDetail.priceBeforeDiscount} />
                                                </span>
                                            )}
                                        </p>
                                        
                                    )}
                                    
                                    {dataDetail.quantity !== -1 && (
                                        <p>
                                            Số lượng: {dataDetail.quantity === 0 ? 'Đã hết hàng' : dataDetail.quantity}
                                        </p>
                                    )}
                                    {isPublisher === 1 && !dataDetail.rangePrice && (
                                        <p>
                                            Hoa hồng:{' '}
                                            <strong className="chiet__khau">
                                                <IconHomeChietKhau />
                                                <span>
                                                    <DisplayPrice
                                                        price={convertCommission(
                                                            dataDetail.price,
                                                            dataDetail.commissionValue,
                                                            dataDetail.commissionType
                                                        )}
                                                    />
                                                </span>
                                            </strong>
                                        </p>
                                    )}
                                    {is_new_variation && <GroupVariations 
                                    product={dataDetail} 
                                    setParentKey={setParentKey}
                                    setChildKey={setChildKey}
                                    setParentValue={setParentValue}
                                    setChildValue={setChildValue}
                                    setParentIndex={setParentIndex}
                                    setChildIndex={setChildIndex}
                                    parentIndex={parentIndex}
                                    childIndex={childIndex}
                                    setVariantId={setVariantId}
                                    variantId={variantId}
                                    setVariPrice={setVariPrice}
                                    variPrice={variPrice}
                                    /> }
                                    { (!is_new_variation && is_old_variation) && 
                                    <OldVariations key={'1'}
                                    product={dataDetail}
                                    oldVariation={oldVariation}
                                    setOldVariation={setOldVariation}
                                    /> }
                                    
                                    {isPublisher === 1 && (
                                        <div
                                            className="role_publisher"
                                            onClick={() => dispatch(toggleShowTutorial(true))}
                                        >
                                            <div className="role_text">
                                                <p>Các bước & quy định bán hàng</p>
                                                <span>Đọc kỹ để kiếm nhiều hoa hồng hơn</span>
                                            </div>
                                            <div className="role_icon">
                                                <IconRightOutlined />
                                            </div>
                                        </div>
                                    )}
                                    <TutorialAccountContainer dataCampaign={dataCampaign} />
                                    { dataCoupons?.data?.length > 0 && 
                                        <>
                                            <div className="coupon-more">
                                                <p>Mã giảm giá:</p>
                                                <p className='more' onClick={() => setShowMore(true)}>
                                                    Xem thêm ({dataCoupons?.paging?.total})
                                                    <RightOutlined/>
                                                </p>
                                            </div>
                                            <div className="product_coupons_slider coupon-list">
                                                <CouponSlider 
                                                data={dataCoupons?.data}
                                                buyProductWithCoupon={buyProductWithCoupon}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            {(isMobileScreen() || isMaxTableScreen()) &&
                                isPublisher === 1 &&
                                dataDetail.contents &&
                                dataDetail.contents.length > 0 && (
                                    <div
                                        className={`share__content product_detail__item ${seeMoreContent ? 'open' : ''
                                            }`}
                                    >
                                        <div className="container">
                                            <div className="item__title">
                                                <h2 className="text__transform" style={{ paddingTop: 10 }}>
                                                    Nội dung chia sẻ để bán hàng
                                                </h2>
                                            </div>
                                            <div className="list__content">
                                                {Array.isArray(dataDetail.contents) &&
                                                    dataDetail.contents.length > 0 &&
                                                    dataDetail.contents.map((res, index) => {
                                                        return (
                                                            <div
                                                                key={res.id}
                                                                onClick={() => handleContentShare(res)}
                                                                className={`item__content ${contentShare && contentShare.id === res.id
                                                                    ? 'active'
                                                                    : ''
                                                                    }`}
                                                            >
                                                                <span>Mẫu {index + 1}</span>
                                                            </div>
                                                        )
                                                    })}
                                            </div>
                                            <div
                                                className="content"
                                                dangerouslySetInnerHTML={{
                                                    __html: convertMessage(contentShare ? contentShare.content : ''),
                                                }}
                                            ></div>
                                            <div className={`see__more`}>
                                                {!seeMoreContent ? (
                                                    <div
                                                        className="see__more__content"
                                                        onClick={() => setSeeMoreContent(true)}
                                                    >
                                                        Xem thêm
                                                        <DownOutlined />
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="see__more__content"
                                                        onClick={() => setSeeMoreContent(false)}
                                                    >
                                                        Thu nhỏ
                                                        <UpOutlined />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                            )}
                            <div className={`product__tranform product_detail__item ${seeMoreDes ? 'open' : ''}`}>
                                <div className="containerr">
                                    <div className="item__title">
                                        <h2 className="text__transform">VẬN CHUYỂN</h2>
                                    </div>
                                    <div
                                        className="content"
                                        dangerouslySetInnerHTML={{ __html: convertMessage(dataDetail.shipPrice) }}
                                    ></div>

                                </div>
                            </div>

                            <div className={`product__tranform product_detail__item ${seeMoreDes ? 'open' : ''}`}>
                                <div className="containerr">
                                    <div className="item__title">
                                        <h2 className="text__transform">THÔNG TIN NHÀ CUNG CẤP</h2>
                                    </div>
                                    <div
                                        className="content"
                                        dangerouslySetInnerHTML={{ __html: convertMessage(dataDetail.supplierInfo) }}
                                    ></div>


                                </div>
                            </div>



                            {isPublisher === 1 && (
                                <div className={`product_detail__item share__product border__bottom ${isShare ? 'open' : ''
                                        } share__desktop`}
                                >
                                    <div className="container">
                                        <div className="item__title">
                                            <h2 className="text__transform">Share thông tin sản phẩm</h2>
                                        </div>
                                        <div className="list__payment__share">
                                            <Button
                                                onClick={() => handleShareProduct(true, 'link', 'Share link bán')}
                                                className="item__share"

                                            >
                                                <LinkOutlined style={{ fontSize: '12px', color: '#40A9FF' }} />
                                                <span>Chia sẻ sản phẩm</span>
                                            </Button>
                                            <Button
                                                // onClick={() => handleCreateOrder(dataDetail.id, dataDetail.quantity)}
                                                onClick={() => buyProductWithCoupon('')}
                                                style={{ margin: '20px 0', borderRadius: 4 }}
                                                size="large"
                                                className="w-100 btn__order__desktop item__share"
                                                type="primary"

                                            >
                                                Tạo đơn hàng
                                            </Button>

                                            &nbsp;&nbsp;

                                            {dataDetail.isPolicyWholesale && (
                                                <Button
                                                    onClick={() => wholeSale(dataDetail.id, dataDetail.quantity, dataDetail.name)}
                                                    style={{ margin: '20px 0', borderRadius: 4 }}
                                                    size="large"
                                                    className="w-100 btn__order__desktop item__share"
                                                    type="primary"


                                                >
                                                    Liên hệ
                                                </Button>
                                            )}

                                        </div>
                                    </div>
                                </div>
                            )}
                            {isPublisher !== 1 && (
                                <div
                                    className={`product_detail__item share__product border__bottom ${isShare ? 'open' : ''
                                        } share__desktop`}
                                >
                                    <div className="container">
                                        <div className="list__payment__share">
                                            <Button
                                                // onClick={() => handleCreateOrder(dataDetail.id, dataDetail.quantity)}
                                                onClick={() => buyProductWithCoupon('')}
                                                style={{ margin: '20px 0', borderRadius: 4 }}
                                                size="large"
                                                className="w-100 btn__order__desktop item__share"
                                                type="primary"

                                            >
                                                Mua ngay
                                            </Button>
                                            &nbsp;&nbsp;
                                            {dataDetail.isPolicyWholesale && (
                                                <Button
                                                    onClick={() => wholeSale(dataDetail.id, dataDetail.quantity, dataDetail.name)}
                                                    style={{ margin: '20px 0', borderRadius: 4 }}
                                                    size="large"
                                                    className="w-100 btn__order__desktop item__share"
                                                    type="primary"


                                                >
                                                    Liên hệ
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="box__footer">
                                <div className="container">
                                    {isPublisher === 1 && (
                                        <div className="btn__group">
                                            <Button
                                                // onClick={() => dispatch(toggleChooseContent(true))}
                                                onClick={() => handleShareProduct(true, 'link', 'Chia sẻ sản phẩm')}
                                                style={{ margin: '20px 0', borderRadius: 4 }}
                                                size="large"
                                                type="primary"
                                                ghost
                                                className="btn_choose_blue"
                                                icon={<LinkOutlined />}

                                            >
                                                Chia sẻ sản phẩm
                                            </Button>
                                            <Button
                                                // onClick={() => handleCreateOrder(dataDetail.id, dataDetail.quantity)}
                                                onClick={() => buyProductWithCoupon('')}
                                                style={{ margin: '20px 0', borderRadius: 4 }}
                                                size="large"
                                                type="primary"

                                            >
                                                Tạo đơn hàng
                                            </Button>
                                        </div>
                                    )}
                                    {isPublisher !== 1 && (
                                        <div className="btn__group">
                                            {isLoggedIn && (
                                                <Button
                                                    onClick={() =>
                                                        handleCreateOrder(dataDetail.id, dataDetail.quantity)
                                                    }
                                                    style={{ margin: '20px 0', borderRadius: 4 }}
                                                    size="large"
                                                    type="primary"

                                                >
                                                    Thêm vào giỏ
                                                </Button>
                                            )}
                                            <Button
                                                // onClick={() => handleCreateOrder(dataDetail.id, dataDetail.quantity)}
                                                onClick={() => buyProductWithCoupon('')}
                                                style={{ margin: '20px 0', borderRadius: 4 }}
                                                size="large"
                                                className={`btn_orange ${!isLoggedIn ? 'w-100' : ''}`}
                                                type="primary"

                                            >
                                                Mua ngay
                                            </Button>
                                            &nbsp;&nbsp;
                                            {dataDetail.isPolicyWholesale && (
                                                <Button
                                                    onClick={() => wholeSale(dataDetail.id, dataDetail.quantity, dataDetail.name)}
                                                    style={{ margin: '20px 0', borderRadius: 4 }}
                                                    size="large"
                                                    className="w-100 btn__order__desktop item__share"
                                                    type="primary"


                                                >
                                                    Liên hệ
                                                </Button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <BoxConfirmCart product={dataDetail} />
                    </div>
                )}

                <div className="product__same_left">
                    <div className="product__same" style={{ width: '100%' }}>
                        <div className="product_detail__content">
                            <div className={`product__description product_detail__item ${seeMoreDes ? 'open' : ''}`} style={{ padding: '0' }}>
                                <div className="containerr">
                                    <div className="item__title">
                                        <h2 className="text__transform">MÔ TẢ SẢN PHẨM</h2>
                                    </div>
                                    <div
                                        className="content"
                                        dangerouslySetInnerHTML={{ __html: convertMessage(dataDetail.description) }}
                                    ></div>
                                    {(isMobileScreen() || isTableScreen()) && (
                                        <div className={`see__more ${seeMoreDes ? 'open' : ''}`}>
                                            {!seeMoreDes ? (
                                                <div className="see__more__content" onClick={() => setSeeMoreDes(true)}>
                                                    Xem thêm
                                                    <DownOutlined />
                                                </div>
                                            ) : (
                                                <div
                                                    className="see__more__content"
                                                    onClick={() => setSeeMoreDes(false)}
                                                >
                                                    Thu nhỏ
                                                    <UpOutlined />
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {isAdvanceInfo && (
                            <div className={`product__advance product_detail__item`}>
                                <div className="containerr">
                                    <div className="item__title">
                                        <h2 className="text__transform">CHI TIẾT SẢN PHẨM</h2>
                                    </div>
                                    <div className="content">
                                        {dataDetail.advanceInfo.map((item) => {
                                            return (
                                                <div style={{ display: 'block', width: '100%' }}>
                                                    {checkURL(item) && checkURLImage(item) && (
                                                        <img width="100%" alt="product_info" src={item} />
                                                    )}
                                                    {checkURL(item) && checkURLVideo(item) && (
                                                        <video width="100%" controls="true">
                                                            <source src={item} type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    )}
                                                    {checkURL(item) && checkURLYoutube(item) && (
                                                        <div className="product__video-container">
                                                            <iframe width="100%" src={item}>
                                                            </iframe>
                                                        </div>
                                                    )}
                                                    {!checkURL(item) && (
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: convertMessage(item) }}
                                                        ></div>
                                                    )}
                                                    <br /><br />
                                                </div>
                                            )
                                        }
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {isAdvanceInfo && isPublisher != 1 && !isMobileScreen() && (
                            <div className="container">
                                <div align="center" className="list__payment__share">
                                    <Button
                                        // onClick={() => handleCreateOrder(dataDetail.id, dataDetail.quantity)}
                                        onClick={() => buyProductWithCoupon('')}
                                        style={{ margin: '20px 0', borderRadius: 4 }}
                                        size="large"
                                        className="w-100 btn__order__desktop item__share"
                                        type="primary"
                                    >
                                        Mua ngay
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className={`product__same product__same_right`}>
                    <div className="title">
                        <h4> SẢN PHẨM TƯƠNG TỰ </h4>
                    </div>
                    <div className="home__product">
                        {Array.isArray(relateProduct) &&
                            relateProduct.length > 0 &&
                            relateProduct.map((item, ind) => {
                                if (ind >= 12) return '';
                                return (
                                    <div key={item.id} className="item__product">
                                        <a style={{ color: '#262626' }} href={`${PATH_PRODUCT}/${item.id}`}>
                                            <div className="box_out_of_stock">
                                                {item.status === 3 && (
                                                    <div className="bg_out_of_stock"><span>Tạm hết hàng</span></div>
                                                )}
                                                <img src={item.thumbImage || DEFAULT_IMAGE} alt="" />
                                            </div>
                                            <p className="title">{item.name}</p>
                                            {!item.rangePrice && <span className="price">
                                                    <DisplayPrice price={item.price} />
                                                </span>}
                                                {item.rangePrice && <span className="price">{checkMinMax(item.rangePrice.minPrice, item.rangePrice.maxPrice)}</span>}
                                            {isPublisher === 1 && !item.rangePrice && (
                                                <>
                                                    <p className="chiet-khau">
                                                        <span>Hoa Hồng</span>
                                                        <IconHomeChietKhau />
                                                    </p>
                                                    <span className="price__discount">
                                                        <DisplayPrice
                                                            price={convertCommission(
                                                                item.price,
                                                                item.commissionValue,
                                                                item.commissionType
                                                            )}
                                                        />
                                                    </span>
                                                </>
                                            )}
                                        </a>
                                    </div>
                                )
                            })}
                    </div>
                    {Array.isArray(relateProduct) && relateProduct.length > 12 && (
                        <div className="btn__group" style={{ textAlign: 'center', marginTop: '10px' }}>
                            <Button
                                onClick={() => history.push(`${PATH_PRODUCT_CATEGORY}?category_id=${dataDetail.categoryId}`)}
                                size="large"
                                className="btn_choose_blue"
                            >
                                Xem thêm
                            </Button>
                        </div>
                    )}
                    {/* <PaginationContainer {...paging} /> */}
                </div>

                <ShareListContent
                    title="Chọn nội dung share"
                    listContent={dataDetail && dataDetail.contents}
                    contentShare={contentShare}
                    deepLink={deepLink}
                    dataDetail={dataDetail}
                    onClose={() => dispatch(toggleChooseContent(false))}
                    handleContentShare={handleContentShare}
                />

                <BoxConfirmTutorialShare
                    onClose={() => dispatch(toggleTutorialShare(false))}
                    deepLink={deepLink}
                    contentShare={contentShare}
                    dataDetail={dataDetail}
                />

                <BoxShare
                    dataDetail={dataDetail}
                    deepLink={deepLink}
                    contentShare={contentShare}
                    htmlZalo={htmlZalo}
                    title="Share link bán"
                    onClose={() => dispatch(togglePopupShare(false, ''))}
                />
                {showMore && openModalCoupon()}

                {/* modal chặn add to cart and Mua ngay */}
                {/* <Modal
                    centered
                    visible={isShowNotBuy}
                    footer={null}
                    onOk={() => dispatch(toggleShowNotBuy(false))}
                    onCancel={() => dispatch(toggleShowNotBuy(false))}
                >
                    <p style={{fontSize:"20px", padding:"15px"}} className="text-center">Vui lòng sử dụng tính năng này trên ứng dụng DiMuaDi</p>
                </Modal> */}
            </div>
        </section>
    )
}


const mapStateToProps = function (state) {
    return {
        openWarning: state.productDetailReducer.openWarning,
        toggleShare: state.homeReducer.toggleShare,
        isPublisher: state.homeReducer.isPublisher,
        isLoggedIn: state.homeReducer.isLoggedIn,
        isShowNotBuy: state.createOrderReducer.isShowNotBuy
    }
}
export default withRouter(connect(mapStateToProps)(ProductDetail))
