import React from 'react'
import noOrder from '../../assets/images/no_order.png'

function ErrorProduct({ text }) {
    return (
        <div style={{ textAlign: 'center', width: '100%' }} className="error__product">
            <img src={noOrder} alt=" no order" />
            <p>{text ? text : 'Không có sản phẩm nào'}</p>
        </div>
    )
}

export default ErrorProduct
