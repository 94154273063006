import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import moment from 'moment'

import Login from '../../component/login/Login'
import { setSession } from '../../functions/common-func'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { accountLogin, accountLoginV2, isRegister, updatePhoneNumber, updateProfile } from '../../api-services/login-api'
import { setIsLoggedIn, toggleIsPublisher, toggleShowLoading } from '../../redux/actions/home-actions'
import * as queryString from 'query-string'
import { toggleShowPopupRegister } from '../../redux/actions/account-action'
const md5 = require('md5')

function LoginContainer({ location, dispatch, history, isShowPopupRegister }) {
    const [role, setRole] = useState(false);
    const [email, setEmail] = useState('');
    const [mcnCode, setMcnCode] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [password_cf, setPassword_cf] = useState('');
    const [userInfo, setUserInfo] = useState({});
	const [username, setUsername] = useState(null);
    const [validatePhone, setValidatePhone] = useState({ status: '', text: '' });
	const [ vldUsername, setVldUsername ] = useState( { status: '', text: '' } );
	const [ vldPassword, setVldPassword ] = useState( { status: '', text: '' } );
	const [ vldPasswordCf, setVldPasswordCf ] = useState( { status: '', text: '' } );

	let phoneFilter = /((09|03|07|08|05|01|02|04|\+84|84)+([0-9]{8,12})\b)/g;
    useEffect(() => {
        const query = queryString.parse(location.search)
        const _role = query.role
        if (_role) {
            setRole(parseInt(_role))
        }
        dispatch(toggleIsPublisher(false))
        dispatch(setIsLoggedIn(false))
    }, [])

    useEffect(() => {
        const query = queryString.parse(location.search);
        const code = query.code;
        if (code) {
			checkIsRegister(code);
        }
    }, [location]);

    const checkIsRegister = async (code) => {
        try {
            const data = {
                accesstrade_code: code,
                role: 1
            }
            dispatch(toggleShowLoading(true))
            const result = await isRegister(data)
            dispatch(toggleShowLoading(false))
            if (result && result.status === 200) {
				setUserInfo(result?.data);
				let dataPhone = result?.data?.tokenData?.phone || result?.data?._json?.phone;
				let check = phoneFilter.test(dataPhone);
				let isRegister = result.data.isNotRegistered 
									|| result.data.isNotRegitered 
									|| result?.data?._json?.userType === "TEMPORARY";

				let obj = {
					tokenData: result?.data?.tokenData,
					isNotRegistered: isRegister,
					is_tempo: result?.data?._json?.userType === "TEMPORARY" 
				}
				

				if(localStorage.getItem("type_login") !== "2") {
					setUsername(result?.data?._json?.username)
				}

				if(result?.data && (isRegister || !check)) {
                    dispatch(toggleShowPopupRegister(true));
					if(result?.data?.tokenData) {
						setEmail(result.data.tokenData.email);
						setPhone(result.data.tokenData.phone);
						setUsername(result.data.tokenData.user_name)
					}
					setUserInfo({...obj, isNotRegistered: true});

                } 
				else {
					setUserInfo(obj);
                    await loginAccessTradeV2({...obj, 
						phone_number: result?.data?._json?.phone,
						email: result?.data?._json?.email
					});
                }
            } else {
                message.error(result.message)
                dispatch(toggleShowLoading(false));
                history.push('/login')
            }
        } catch (e) {
            dispatch(toggleShowLoading(false));
            if (e && e.response && e.response.data) {
                
				message.error(e.response?.data?.message);
				
				if(e?.response?.status === 401) {
					// let redirect_uri = encodeURI(`http://localhost:4200/login`);
					let redirect_uri = encodeURI(`https://` + window.location.hostname + `/login`);
					let url = `${process.env.REACT_APP_URL_GET_AUTHOR_ACCESS_TRADE}/logout?redirect_uri=${redirect_uri}`
					window.location.href = url;
				}
            }
            throw e
        }
    }  

    const handleLoginFacebook = () => {
        window.FB.getLoginStatus(async function (response) {
            if (response['status'] === 'connected') {
                if (response && response.authResponse) {
                    await loginFaceBook(response.authResponse.accessToken)
                } else {
                    message.error('Login thất bại')
                }
            } else {
                window.FB.login(
                    function (response) {
                        if (response && response.authResponse) {
                            return new Promise(() => loginFaceBook(response.authResponse.accessToken))
                        }
                    },
                    { scope: 'public_profile, email' }
                )
            }
        })
    }

    const loginAccessTradeV2 = async (code) => {
       

        try {
            const data = code;
			data.user_name = username;
			console.log("data-------> ", data);
            dispatch(toggleShowLoading(true));
            const result = await accountLoginV2(data);
            dispatch(toggleShowLoading(false));

            if (result && (result.status === 'success' || result?.status === 200) && result.data) {
				dispatch(toggleShowPopupRegister(false));
					let response = {
						tokenData: result.data?.response,
						_json: result?.data?.userInfo,
						userInfo: result?.data?.userInfo,
						...result.data
					}
                    setSession('userInfo', response, 7);
                    localStorage.setItem(
                        'role',
                        JSON.stringify({
                            role: (result.data && result.data.userRole && result.data.userRole[0] && result.data.userRole[0].roleId) || 1,
                        })
                    );
                    clearValue();
                    window.location.href = '/';
            } else {
                message.error(result.message);
            }
        } catch (e) {
            dispatch(toggleShowLoading(false));
			let dataError = e?.response?.data?.data;
			if(dataError && Object.entries(dataError)?.length > 0) {
				let error = Object.entries(dataError)[0];
				message.error(error ? error[1] : e.response.data.message);
			} else if (e && e.response && e.response.data) {
                message.error(e.response.data.message);
                
            }
			history.push('/login');
            throw e;
        }
    }

    const loginFaceBook = async (facebook_token) => {
        try {
            const data = {
                facebook_token: facebook_token,
            }
            if (role) {
                data.role = role
            }
            dispatch(toggleShowLoading(true))
            const result = await accountLogin(data)
            if (result && result.status === 201) {
                setSession('userInfo', result.data, 7)
                dispatch(toggleShowLoading(false))
                localStorage.setItem(
                    'role',
                    JSON.stringify({
                        role: (result.data && result.data.userRole[0] && result.data.userRole[0].roleId) || 1,
                    })
                )
                window.location.href = '/'
            } else {
                message.error(result.message)
                dispatch(toggleShowLoading(false))
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            if (e && e.response && e.response.data && e.response.data.code === 'E00022') {
                message.error(
                    'Email của bạn đã được sử dụng để đăng ký trên Accesstrade, vui lòng chọn "Đăng nhập qua ACCESSTRADE'
                )
                // history.push('/login')
                return
            }
            if (e && e.response && e.response.data && e.response.data.code === 'E00000') {
                message.error('Đăng nhập thất bại, tài khoản cần email để đăng nhập vào hệ thống')
                window.FB.getLoginStatus(function (response) {
                    // console.log(response)
                    if (response && response.status === 'connected') {
                        window.FB.logout(function (response) {
                            // document.location.reload()
                        })
                    }
                })
                return
            }
            throw e
        }
    }

    const handleLoginGoogle = async (response) => {
        try {
            if (response) {
                const data = {
                    google_token: response.accessToken,
                }
                if (role) {
                    data.role = role
                }
                dispatch(toggleShowLoading(true))
                const result = await accountLogin(data)
                dispatch(toggleShowLoading(false))
                if (result && result.status === 201) {
                    setSession('userInfo', result.data, 7)
                    dispatch(toggleShowLoading(false))
                    localStorage.setItem(
                        'role',
                        JSON.stringify({
                            role: (result.data && result.data.userRole[0] && result.data.userRole[0].roleId) || 1,
                        })
                    )
                    setRole(role)
                    window.location.href = '/'
                } else {
                    message.error(result.message)
                    dispatch(toggleShowLoading(false))
                }
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            if (e && e.response && e.response.data && e.response.data.code === 'E00022') {
                message.error(
                    'Email của bạn đã được sử dụng để đăng ký trên Accesstrade, vui lòng chọn "Đăng nhập qua ACCESSTRADE'
                )
                history.push('/login')
                return
            }
            throw e
        }
    }

    const handleLoginAccessTrade = () => {
        try {
            localStorage.setItem("type_login", "1");
            // let redirectUri = encodeURI(`${process.env.REACT_APP_URL_WEBSITE}/login`);
            // let redirectUri = encodeURI(`http://localhost:4200/login`);
            let redirectUri = encodeURI(`https://` + window.location.hostname + `/login`);
            let url = `${process.env.REACT_APP_URL_GET_AUTHOR_ACCESS_TRADE}/oauth/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&scope=user_info&response_type=code&redirect_uri=${redirectUri}&state=`
            let state = md5('loginaccesstrade' + moment().format('DDMMYYYHHMMSS'))
            url += state;
			console.log(url);
            window.location.href = url;
        } catch (e) {
            throw e
        }
    }

    const handleLoginGoogle2 = () => {
        try {
            localStorage.setItem("type_login", "2");
            // let redirectUri = encodeURI(`http://localhost:4201/login`);
            let redirectUri = encodeURI(`https://` + window.location.hostname + `/login`);
            let url = `${process.env.REACT_APP_URL_GET_AUTHOR_ACCESS_TRADE}/oauth/authorize?provider=google&client_id=${process.env.REACT_APP_CLIENT_ID}&scope=user_info&response_type=code&redirect_uri=${redirectUri}&state=`
			let state = md5('loginaccesstrade' + moment().format('DDMMYYYHHMMSS'))
            url += state
			console.log(url);
            window.location.href = url;
			        } catch (e) {
            throw e
        }
    }

    const handleChangePhone = (e) => {
        if(e && e.target.value) {
            setPhone(e.target.value);
            setValidatePhone({ status: '', text: '' });
        }
    };

    const clearValue = () => {
        setUserInfo({});
        setPhone('');
        setEmail('');
        setMcnCode('');
        setValidatePhone({ status: '', text: '' });
        setVldPassword({ status: '', text: '' });
        setVldUsername({ status: '', text: '' });
        setVldPasswordCf({ status: '', text: '' });
        localStorage.removeItem('type_login');
    };

    const register = async () => {
        let phoneFilter = /((09|03|07|08|05|01|02|04|\+84|84)+([0-9]{8,12})\b)/g;
        let isValidForm = true;
        if (!phone || (phone && phone.trim() == '')) {
            setValidatePhone({ status: 'error', text: 'Vui lòng nhập số điện thoại' });
            isValidForm = false;
        }
        if (!phoneFilter.test(phone)) {
            setValidatePhone({ status: 'error', text: 'Vui lòng nhập đúng số điện thoại' });
            isValidForm = false;
        }

        if(!isValidForm) {
            return;
        }

        let data = { ...userInfo, 
			email: email || userInfo?.tokenData?.email,
			user_name: localStorage.getItem('type_login') !== "2" ? username : null, 
			phone_number: phone, 
			mcn_code: mcnCode,
			is_local: localStorage.getItem('type_login') === "1",
			password: localStorage.getItem('type_login') !== "2" ? password : null,
			password_cf: localStorage.getItem('type_login') !== "2" ? password_cf : null,
			isNotRegistered: true };
		console.log("data---------> ", data);
		console.log("user info----> ", userInfo);
        await loginAccessTradeV2(data);
    };

    return (
        <Login
            handleLoginFacebook={handleLoginFacebook}
            handleLoginGoogle={handleLoginGoogle}
            handleLoginGoogle2={handleLoginGoogle2}
            handleLoginAccessTrade={handleLoginAccessTrade}
            email={email}
            phone={phone}
			password={password}
			
			setPassword={setPassword}
			password_cf={password_cf}
			setPassword_cf={setPassword_cf}

			username={username}
			setUsername={setUsername}

            dispatch={dispatch}
            isShowPopupRegister={isShowPopupRegister}
            setMcnCode={setMcnCode}
            validatePhone={validatePhone}
            vldUsername={vldUsername}
            vldPassword={vldPassword}
            vldPasswordCf={vldPasswordCf}
            setVldPassword={setVldPassword}
            setVldPasswordCf={setVldPasswordCf}
            setVldUsername={setVldUsername}

            handleChangePhone={handleChangePhone}
            register={register}
        />
    )
}
const mapStateToProps = function (state) {
    return {
        isLoggedIn: state.homeReducer.isLoggedIn,
        isShowPopupRegister: state.accountReducer.isShowPopupRegister
    }
}
export default withRouter(connect(mapStateToProps)(LoginContainer))
