import { HOME_TYPE_REDUCER } from '../../constants/home-contants'

export const toggleShowLoading = (boolean) => {
    return {
        type: HOME_TYPE_REDUCER.OPEN_LOADING,
        showLoading: boolean,
    }
}

export const toggleShowMenuMobile = (boolean) => {
    return {
        type: HOME_TYPE_REDUCER.MENU_MOBILE,
        openMenuMobile: boolean,
    }
}

export const setIsLoggedIn = (boolean) => ({
    type: HOME_TYPE_REDUCER.SET_IS_LOGGED_IN,
    isLoggedIn: boolean,
})

export const togglePopupShare = (boolean, type, title) => ({
    type: HOME_TYPE_REDUCER.TOGGLE_POPUP_SHARE,
    toggleShare: { isShare: boolean, type, title },
})

export const togglePopupRule = (boolean) => ({
    type: HOME_TYPE_REDUCER.TOGGLE_POPUP_RULE,
    toggleRule: boolean,
})

export const togglePopupWelcomeUser = (boolean) => ({
    type: HOME_TYPE_REDUCER.TOGGLE_POPUP_WELCOME,
    isShowWelcome: boolean,
})

export const toggleYourCart = (boolean) => {
    return {
        type: HOME_TYPE_REDUCER.TOGGLE_YOUR_CART,
        toggleCart: boolean,
    }
}

export const togglePopupBoxSearch = (boolean) => {
    return {
        type: HOME_TYPE_REDUCER.TOGGLE_SEARCH_BOX,
        toggleBoxSearch: boolean,
    }
}

export const setCurrentRole = (role) => {
    return {
        type: HOME_TYPE_REDUCER.SET_ROLE,
        currentRole: role,
    }
}

export const toggleShowMenuAccount = (boolean) => {
    return {
        type: HOME_TYPE_REDUCER.IS_SHOW_MENU_ACCOUNT,
        isShowMenuAccount: boolean,
    }
}

export const toggleShowMenuSidebar = (boolean) => {
    return {
        type: HOME_TYPE_REDUCER.TOGGLE_MENU_SIDEBAR,
        isShowMenuSidebar: boolean,
    }
}

export const toggleIsPublisher = (boolean) => {
    return {
        type: HOME_TYPE_REDUCER.IS_PUBLISHER,
        isPublisher: boolean,
    }
}

export const toggleIsTopInput = (boolean) => {
    return {
        type: HOME_TYPE_REDUCER.IS_TOP_INPUT,
        isTopInput: boolean,
    }
}
export const toggleIsPolicyWholeSale = (boolean) => {
    return {
        type: HOME_TYPE_REDUCER.IS_POLICY_WHOLESALE,
        isPolicyWholeSale: boolean,
    }
}