import React, { useState } from 'react'
import Register from '../../component/register/Register'

function RegisterContainer() {
    const [validateLastName, setValidateLastName] = useState({ status: 'success', text: '' })
    const [validateFirstName, setValidateFirstName] = useState({ status: 'success', text: '' })
    const [validatePhone, setValidatePhone] = useState({ status: 'success', text: '' })
    const [validateUserName, setValidateUserName] = useState({ status: 'success', text: '' })
    const [validatePassword, setValidatePassword] = useState({ status: 'success', text: '' })
    const [validateRePassword, setValidateRePassword] = useState({ status: 'success', text: '' })
    const [lastName, setLastName] = useState('')
    const [firstName, setFirstName] = useState('')
    const [phone, setPhone] = useState('')
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [rePassword, setRePassword] = useState('')

    const clearValidator = () => {
        setValidateFirstName({ status: 'success', text: '' })
        setValidateLastName({ status: 'success', text: '' })
        setValidatePhone({ status: 'success', text: '' })
        setValidateUserName({ status: 'success', text: '' })
        setValidateRePassword({ status: 'success', text: '' })
        setValidatePassword({ status: 'success', text: '' })
    }

    localStorage.removeItem('cartCoupon');

    const handleChangeLastName = (e) => {
        setLastName(e.target.value)
        clearValidator()
    }

    const handleChangeFirstName = (e) => {
        setFirstName(e.target.value)
        clearValidator()
    }

    const handleChangePhone = (e) => {
        setPhone(e.target.value)
        clearValidator()
    }

    const handleChangeUserName = (e) => {
        setUserName(e.target.value)
        clearValidator()
    }

    const handleChangePassword = (e) => {
        setPassword(e.target.value)
        clearValidator()
    }

    const handleChangeRePassword = (e) => {
        setRePassword(e.target.value)
        clearValidator()
    }

    const handleRegister = () => {
        if (validateRegister()) {
        }
    }

    const validateRegister = () => {
        if (!lastName) {
            setValidateFirstName({ status: 'error', text: 'Vui lòng nhập họ của bạn' })
            return false
        }
        if (!firstName) {
            setValidateLastName({ status: 'error', text: 'Vui lòng nhập tên của bạn' })
            return false
        }
        if (!phone) {
            setValidatePhone({ status: 'error', text: 'Vui lòng nhập số điện thoại' })
            return false
        }
        if (!userName) {
            setValidateUserName({ status: 'error', text: 'Vui lòng nhập tên đăng nhập' })
            return false
        }
        if (!password) {
            setValidatePassword({ status: 'error', text: 'Vui lòng nhập mật khẩu' })
            return false
        }
        if (!firstName) {
            setValidateRePassword({ status: 'error', text: 'Vui lòng nhập lại mật khẩu' })
            return false
        }
        if (password !== rePassword) {
            setValidateRePassword({ status: 'error', text: 'Mật khẩu không khớp' })
            return false
        } else {
            return true
        }
    }

    return (
        <Register
            validateLastName={validateLastName}
            validateFirstName={validateFirstName}
            validatePhone={validatePhone}
            validateUserName={validateUserName}
            validatePassword={validatePassword}
            validateRePassword={validateRePassword}
            firstName={firstName}
            lastName={lastName}
            phone={phone}
            userName={userName}
            password={password}
            rePassword={rePassword}
            handleChangeLastName={handleChangeLastName}
            handleChangeFirstName={handleChangeFirstName}
            handleChangePhone={handleChangePhone}
            handleChangeUserName={handleChangeUserName}
            handleChangePassword={handleChangePassword}
            handleChangeRePassword={handleChangeRePassword}
            handleRegister={handleRegister}
        />
    )
}

export default RegisterContainer
