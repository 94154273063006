import React from 'react'
import { Input, Button } from 'antd'
import { SearchOutlined, FilterFilled } from '@ant-design/icons'
import BoxHeaderTitle from '../../common/BoxHeaderTitle'
import Rectangle13 from '../../../assets/images/Rectangle_13.png'
import DisplayPrice from '../../common/DisplayPrice'
import BoxInfoOrder from '../../list-order/BoxInfoOrder'
import { toggleOpenInfoProduct } from '../../../redux/actions/list-order-action'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

function BookingManagement({ dispatch }) {
    return (
        <div className="booking__management">
            <BoxHeaderTitle text="Quản lý đơn hàng" link="/account" />
            <div className="booking__management__content">
                <div className="booking__management__item border__bottom">
                    <div className="search__product">
                        <Input
                            size="large"
                            placeholder="Tìm kiếm theo mã đơn, sản phẩm"
                            prefix={<SearchOutlined style={{ color: '#8C8C8C' }} />}
                            className="input__search"
                        />
                        <FilterFilled style={{ color: '#8C8C8C' }} />
                    </div>
                </div>
                <div className="list__product">
                    <div className="product__item" onClick={() => dispatch(toggleOpenInfoProduct(true))}>
                        <div className="product__info">
                            <div className="order__code">
                                <span>#100003</span> | 13:55CH - 01/08/2020
                            </div>
                            <div className="order__status success">Giao hàng thành công</div>
                        </div>
                        <div className="product__content">
                            <div className="product__image">
                                <img src={Rectangle13} alt="" />
                            </div>
                            <div className="product__text">
                                <p className="name">Sữa đậu nành óc chó Vinamilk 180ml</p>
                                <p className="net__price">
                                    <span>Giá thành</span>
                                    <span className="price">
                                        <DisplayPrice price={290000} />
                                    </span>
                                </p>
                                <Button style={{ borderRadius: 5 }} className="w-100 outline__primary">
                                    Mua lại
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="product__item" onClick={() => dispatch(toggleOpenInfoProduct(true))}>
                        <div className="product__info">
                            <div className="order__code">
                                <span>#100003</span> | 13:55CH - 01/08/2020
                            </div>
                            <div className="order__status success">Giao hàng thành công</div>
                        </div>
                        <div className="product__content">
                            <div className="product__image">
                                <img src={Rectangle13} alt="" />
                            </div>
                            <div className="product__text">
                                <p className="name">Sữa đậu nành óc chó Vinamilk 180ml</p>
                                <p className="net__price">
                                    <span>Giá thành</span>
                                    <span className="price">
                                        <DisplayPrice price={290000} />
                                    </span>
                                </p>
                                <Button style={{ borderRadius: 5 }} className="w-100 outline__primary">
                                    Mua lại
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="product__item" onClick={() => dispatch(toggleOpenInfoProduct(true))}>
                        <div className="product__info">
                            <div className="order__code">
                                <span>#100003</span> | 13:55CH - 01/08/2020
                            </div>
                            <div className="order__status success">Giao hàng thành công</div>
                        </div>
                        <div className="product__content">
                            <div className="product__image">
                                <img src={Rectangle13} alt="" />
                            </div>
                            <div className="product__text">
                                <p className="name">Sữa đậu nành óc chó Vinamilk 180ml</p>
                                <p className="net__price">
                                    <span>Giá thành</span>
                                    <span className="price">
                                        <DisplayPrice price={290000} />
                                    </span>
                                </p>
                                <Button style={{ borderRadius: 5 }} className="w-100 outline__primary">
                                    Mua lại
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="product__item" onClick={() => dispatch(toggleOpenInfoProduct(true))}>
                        <div className="product__info">
                            <div className="order__code">
                                <span>#100003</span> | 13:55CH - 01/08/2020
                            </div>
                            <div className="order__status success">Giao hàng thành công</div>
                        </div>
                        <div className="product__content">
                            <div className="product__image">
                                <img src={Rectangle13} alt="" />
                            </div>
                            <div className="product__text">
                                <p className="name">Sữa đậu nành óc chó Vinamilk 180ml</p>
                                <p className="net__price">
                                    <span>Giá thành</span>
                                    <span className="price">
                                        <DisplayPrice price={290000} />
                                    </span>
                                </p>
                                <Button style={{ borderRadius: 5 }} className="w-100 outline__primary">
                                    Mua lại
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="product__item" onClick={() => dispatch(toggleOpenInfoProduct(true))}>
                        <div className="product__info">
                            <div className="order__code">
                                <span>#100003</span> | 13:55CH - 01/08/2020
                            </div>
                            <div className="order__status success">Giao hàng thành công</div>
                        </div>
                        <div className="product__content">
                            <div className="product__image">
                                <img src={Rectangle13} alt="" />
                            </div>
                            <div className="product__text">
                                <p className="name">Sữa đậu nành óc chó Vinamilk 180ml</p>
                                <p className="net__price">
                                    <span>Giá thành</span>
                                    <span className="price">
                                        <DisplayPrice price={290000} />
                                    </span>
                                </p>
                                <Button style={{ borderRadius: 5 }} className="w-100 outline__primary">
                                    Mua lại
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <BoxInfoOrder />
            </div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        openInfoProduct: state.listOrderReducer.openInfoProduct,
    }
}
export default withRouter(connect(mapStateToProps)(BookingManagement))
