import { combineReducers } from 'redux'
import homeReducer from './reducers/home-reducer'
import searchReducer from './reducers/search-reducers'
import productDetailReducer from './reducers/product-detail-reducer'
import accountReducer from './reducers/account-reducer'
import createOrderReducer from './reducers/create-order-reducer'
import listOrderReducer from './reducers/list-order-reducer'
import checkoutReducer from './reducers/checkout-reducer'

export default combineReducers({
    homeReducer: homeReducer,
    accountReducer: accountReducer,
    searchReducer: searchReducer,
    productDetailReducer: productDetailReducer,
    listOrderReducer: listOrderReducer,
    createOrderReducer: createOrderReducer,
    checkoutReducer: checkoutReducer,
})
