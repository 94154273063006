import React, { useEffect, useState } from 'react'
import { IconHomeChietKhau } from '../../constants/icon-const';
import { checkMinMax, convertCommission, formatCurrency } from '../../functions/common-func';
const MinMax = (props) => {
    const { min, max, commType, commValue, priceRange, variPrice } = props
    let minComm = convertCommission(min, commValue, commType)
    let maxComm = convertCommission(max, commValue, commType)
    return (
        <>
            <div className="group-variations-price-value">
                <div className="d-flex price-group"><span className="price-title">Giá bán:</span>
                    {priceRange && !variPrice && <span className="price-value">{checkMinMax(min, max)}</span>}
                    {priceRange && variPrice && <span className="price-value">{formatCurrency(variPrice.price)}₫</span>}
                </div>
                <div className="d-flex justify-content-center comm-group">
                <span className="comm-title d-flex justify-content-center">Hoa hồng:</span>
                <IconHomeChietKhau />
                    {priceRange && !variPrice && <span className="comm-value">
                        {checkMinMax(minComm, maxComm)}
                    </span>}
                    {priceRange && variPrice && <span className="comm-value">{formatCurrency(convertCommission(variPrice.price, commValue, commType))}₫</span>}
                </div>
            </div>
        </>
    )
}
export default MinMax;