import React, { useEffect, useState, useRef } from 'react'
import { Button, message } from 'antd'
import { ArrowLeftOutlined, EnterOutlined } from '@ant-design/icons'
import { useLocation, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { togglePopupBoxSearch } from '../../redux/actions/home-actions'
import * as queryString from 'query-string'
import { fetchListProduct } from '../../api-services/product-api'
import DisplayPrice from './DisplayPrice'
import { IconHomeChietKhau } from '../../constants/icon-const'
import ErrorProduct from './ErrorProduct'
import { fetchListTop } from '../../api-services/home-api'
import icLoading from './../../assets/images/ic-loading.gif'
import MinMax from '../product-price/MinMax'
import {
    checkMinMax,
    checkURLImage,
    convertCommission,
    convertSizeImage,
    getRecentSearch,
    removeSession,
    saveRecentSearch,
} from '../../functions/common-func'
import ShowComm from '../product-price/ShowComm'
function BoxSearch({ toggleBoxSearch, dispatch, isPublisher }) {
    const [nameProduct, setNameProduct] = useState('')
    const [listProduct, setListProduct] = useState([])
    const [listKeyHot, setListKeyHot] = useState([])
    const [listRecentSearch, setListRecentSearch] = useState([])
    const [isOpenProduct, setIsOpenProduct] = useState(false)
    const { pathname, search } = useLocation()
    const [page, setPageProduct] = useState(1)
    const [showLoading, setShowLoading] = useState(false)
    const [lockLoadMore, setLockLoadMore] = useState(false)
    const inputRef = useRef(null)
    const searchRef = useRef()
    const labelLoadMore = 'loadMore'

    useEffect(() => {
        const query = queryString.parse(search)
        if (query.name) {
            setNameProduct(query.name)
        }
    }, [search, pathname])

    useEffect(() => {
        getListTop()
    }, [])

    useEffect(() => {
        if (toggleBoxSearch) {
            inputRef.current.focus()
        }
    }, [toggleBoxSearch])

    useEffect(() => {
        onSearch(nameProduct)
    }, [nameProduct])

    const getListProduct = async (filter, type = null) => {
        try {
            const data = await fetchListProduct(filter)
            if (data && data.status === 200) {
                if (type === labelLoadMore) {
                    if (data.data.length > 0) {
                        setListProduct(listProduct.concat(data.data))
                        setLockLoadMore(false)
                    }
                } else {
                    setListProduct(data.data)
                }
            }
        } catch (e) {
            throw e
        }
    }

    useEffect(() => {
        let current_data = getRecentSearch()
        if (current_data) {
            setListRecentSearch(current_data)
        }
    }, [pathname, search])

    const getListTop = async () => {
        try {
            const params = {}
            params.page = 1
            params.page_size = 8
            const data = await fetchListTop(params)
            if (data && data.status === 200) {
                setListKeyHot(data.data)
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    const onChangeSearch = async (e) => {
        const keyword = e.target.value
        if (keyword.charAt(0) === ' ') {
            setNameProduct(keyword.substring(1))
        } else {
            setNameProduct(keyword)
        }
    }

    const onSearch = async (keyword) => {
        if (keyword.trim().length >= 2) {
            try {
                let filter = '?'
                filter += `name=${keyword}`
                await getListProduct(filter)
                setIsOpenProduct(true)
            } catch (e) {
                throw e
            }
        } else {
            if (keyword.length === 0) {
                setIsOpenProduct(false)
                setListProduct([])
            }
        }
    }

    const handleClickItemSearch = (value) => {
        setNameProduct(value)
    }

    const deleteRecentSearch = () => {
        removeSession('recent_search')
        setListRecentSearch([])
    }

    const handleClickProduct = (id, name) => {
        const new_data = {
            name: name,
            id: id,
        }
        let current_data = getRecentSearch()
        if (Array.isArray(current_data)) {
            const is_new_search =
                current_data.length === 0 || !current_data.find((item) => Number(item.id) === Number(new_data.id))
            if (is_new_search) current_data.unshift(new_data)
            if (current_data.length > 6) {
                current_data.pop()
            }
            saveRecentSearch(current_data)
        } else {
            saveRecentSearch([new_data])
        }
        dispatch(togglePopupBoxSearch(false))
        window.location.href = `/product/${id}`
    }

    const searchScroll = (e, pageProduct, lockLoadMore) => {
        if (!lockLoadMore) {
            setLockLoadMore(true)
            setShowLoading(true)

            let tmpPageProduct = pageProduct + 1
            setPageProduct(tmpPageProduct)

            setTimeout(async function () {
                const filter = `?name=${nameProduct}&page=${tmpPageProduct}&page_size=25`
                await getListProduct(filter, labelLoadMore)
                setShowLoading(false)
            }, 2000)
        }
    }

    return (
        <div className={`box_search ${toggleBoxSearch ? 'open' : ''}`}>
            <div className="box_search__content" onScroll={(e) => searchScroll(e, page, lockLoadMore)} ref={searchRef}>
                <div className="box_search__main">
                    <div className="form__input__search border__bottom">
                        <div className="back__page" onClick={() => dispatch(togglePopupBoxSearch(false))}>
                            <ArrowLeftOutlined />
                        </div>
                        <input
                            onChange={onChangeSearch}
                            size="large"
                            id={'myAnchor'}
                            className="ant-input-affix-wrapper ant-input-affix-wrapper-lg"
                            ref={inputRef}
                            placeholder="Sản phẩm, thương hiệu bạn muốn bán"
                            type="text"
                            value={nameProduct}
                        // autoFocus
                        />
                    </div>
                    {!isOpenProduct && (
                        <div className="keyword__hot border__bottom box_search__item">
                            <div className="title">Từ khoá hot</div>
                            <div className="list__key__hot">
                                {listKeyHot.length > 0 &&
                                    listKeyHot.map((res) => {
                                        return (
                                            <div
                                                key={res.id}
                                                className="key__item"
                                                onClick={() => handleClickItemSearch(res.name)}
                                            >
                                                <Button size="middle">{res.name}</Button>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    )}

                    {!isOpenProduct && (
                        <div className="search-near box_search__item">
                            <div className="title">
                                <span>sản phẩm tìm kiếm gần đây</span>
                                <span onClick={deleteRecentSearch} className="delete">
                                    Xóa
                                </span>
                            </div>
                            {listRecentSearch.length > 0 &&
                                listRecentSearch.map((res) => {
                                    return (
                                        <div
                                            key={res.id}
                                            className="search-near__item"
                                            onClick={() => handleClickItemSearch(res.name)}
                                        >
                                            <span>{res.name}</span>
                                            <span className="icon">
                                                <EnterOutlined />
                                            </span>
                                        </div>
                                    )
                                })}
                        </div>
                    )}
                    {isOpenProduct && (
                        <div className="box_search__item list__product">
                            {Array.isArray(listProduct) &&
                                listProduct.length > 0 &&
                                listProduct.map((res) => {

                                    return (
                                        <div
                                            onClick={() => handleClickProduct(res.id, res.name)}
                                            key={res.id}
                                            className="product__content"
                                        >
                                            <div className="product__image">
                                                <img
                                                    src={convertSizeImage(checkURLImage(res.thumbImage), 200, 200)}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="product__detail">
                                                <p className="name">{res.name}</p>
                                                <p className="price__product">
                                                    <span>Giá thành:</span>
                                                    {!res.rangePrice && <span className="price">
                                                        <DisplayPrice price={res.price} />
                                                    </span>}
                                                    {res.rangePrice && <span className="price">
                                                        {checkMinMax(res.rangePrice.minPrice, res.rangePrice.maxPrice)}
                                                    </span>}
                                                </p>
                                                {isPublisher === 1  && !res.rangePrice && (
                                                    <p className="price__product commission">
                                                        <span>Hoa hồng:</span>

                                                        <span className="price">
                                                            <IconHomeChietKhau />
                                                            {!res.rangePrice && <DisplayPrice
                                                                price={convertCommission(
                                                                    res.price,
                                                                    res.commissionValue,
                                                                    res.commissionType
                                                                )}
                                                            /> }
                                                            
                                                        </span>
                                                    </p>
                                                )}
                                                
                                                
                                                
                                            </div>
                                        </div>
                                    )
                                })}

                            {listProduct.length === 0 && <ErrorProduct />}

                            {showLoading && (
                                <div className="box-loading text-center">
                                    <img className="ic-sm" src={icLoading} alt="" />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        toggleBoxSearch: state.homeReducer.toggleBoxSearch,
        isPublisher: state.homeReducer.isPublisher,
    }
}
export default withRouter(connect(mapStateToProps)(BoxSearch))
