// import { ArrowLeftOutlined, SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import * as _ from 'lodash'
import 'moment/min/locales.min'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchListBanner, fetchTop5 } from '../../api-services/home-api'
import topIcon from '../../assets/images/top.svg'
import { PATH_PRODUCT } from '../../constants/common-contants'
import { checkMinMax, convertCommission, convertPaging, formatCurrency, isMaxTableScreen, isMobileScreen } from '../../functions/common-func'
import { toggleIsPolicyWholeSale, toggleIsTopInput } from '../../redux/actions/home-actions'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import BoxSearch from '../common/BoxSearch'
import DisplayPrice from '../common/DisplayPrice'
import TopProduct from '../common/TopProduct/TopProduct'
import HomeBanner from '../home/HomeBanner'
import MenuMobile from '../layouts/MenuMobile'
import PaginationContainer from '../../container/common/PaginationContainer'
import * as queryString from 'query-string'
import { Pagination } from 'antd'
import ShowComm from '../product-price/ShowComm'

function TrendingProduct({ history, isPublisher,isLoggedIn }) {
    const [listBanner, setListBanner] = useState([])
    const [listTopProduct, setListTopProduct] = useState({})
    const [pagingTrending, setPagingTrending] = useState({ page: 1, pageSize: 24, totalPage: 1 })
    const dispatch = useDispatch()
    const isPolicyWholesale = useSelector((state) => state.homeReducer.isPolicyWholeSale)

    // if(!isLoggedIn) {
    //     window.location = `${window.location.origin}/login`;
    // }

    localStorage.removeItem('cartCoupon');

    useEffect(() => {
        fetchTop5().then((res) => {
            setListTopProduct(res.data)
        })
    }, [isPolicyWholesale])
    useEffect(() => {
        fetchListBanner().then((res) => {
            setListBanner(res.data)
        })
    }, [])
    const handleSelect = () => {
        dispatch(toggleIsPolicyWholeSale(false))
    }
    const handleSelectWholesale = () => {
        dispatch(toggleIsPolicyWholeSale(true))
    }

    const filterArray = _.partition(listTopProduct, { isPolicyWholesale: true })
    const top5Wholesale = _.partition(filterArray[0], { positon: 1 })
    const top5NotWholesale = _.partition(filterArray[1], { positon: 1 })
    const resultListWholesale = [...top5Wholesale[0], ...top5Wholesale[1]]
    const resultListNotWholesale = [...top5NotWholesale[0], ...top5NotWholesale[1]]
    let listTop = isPolicyWholesale ? resultListWholesale : resultListNotWholesale
    let listTrend = convertPaging(listTop, pagingTrending.pageSize, pagingTrending.page)

    useEffect(() => {
        const query = queryString.parse(history.location.search)
        if (query.page && listTop.length > 0) {
            let total = Math.ceil(listTop.length / pagingTrending.pageSize)
            setPagingTrending({ ...pagingTrending, page: query.page, totalPage: total })
        }
    }, [history.location])
    useEffect(() => {
        let searchInput = document.getElementById('home__box')
        let status = false
        if (searchInput) {
            window.addEventListener('scroll', function () {
                if (window.pageYOffset > searchInput.offsetTop) {
                    if (status === false) {
                        dispatch(toggleIsTopInput(true))
                        status = true
                    }
                } else {
                    if (status === true) {
                        dispatch(toggleIsTopInput(false))
                        status = false
                    }
                }
            })
        }
    }, [dispatch])
    const onClose = () => {
        if (history && history.length > 1) {
            history.goBack()
        } else {
            window.location.href = '/'
        }
    }

    return (
        <section className={`home ${isPublisher !== 1 ? 'is__buyer' : ''}`}>
            {/* MOBILE SCREEN  */}
            {(isMobileScreen() || isMaxTableScreen()) && (
                <>
                    <BoxHeaderTitle text="Xu hướng mua sắm" onClose={onClose} isBell={true} />

                    <div className="home__list__options border__bottom home__product__item">
                        <div className="filter_label">
                            <p
                                className={isPolicyWholesale ? 'label active' : 'label'}
                                onClick={() => handleSelectWholesale()}
                            >
                                Sản phẩm bán sỉ
                            </p>
                            <p className={!isPolicyWholesale ? 'label active' : 'label'} onClick={() => handleSelect()}>
                                Sản phẩm bán lẻ
                            </p>
                        </div>
                        <div className="list-top5-page">
                            {listTrend &&
                                listTrend.length > 0 &&
                                listTrend.map((res, index) => {
                                    return (
                                        <a
                                            href={`${PATH_PRODUCT}/${res.id}`}
                                            key={res.id}
                                            className="hot__product__item"
                                        >
                                            <li className="itemCard">
                                                <div className="images">
                                                    <img src={res.thumbImage} alt="" />
                                                </div>
                                                <div className="text-group">
                                                    <p className="title">{res.name}</p>
                                                    <p className="comm">
                                                        Hoa hồng{' '}
                                                        {!res.rangePrice && <DisplayPrice
                                                            price={convertCommission(
                                                                res.price,
                                                                res.commissionValue,
                                                                res.commissionType
                                                            )}
                                                        />}
                                                        {res.rangePrice && <ShowComm
                                                            min={res.rangePrice.minPrice}
                                                            max={res.rangePrice.maxPrice}
                                                            commType={res.commissionType}
                                                            commValue={res.commissionValue}
                                                        />
                                                        }
                                                    </p>

                                                    {res.priceBeforeDiscount > 0 && !res.rangePrice && (
                                                        <>
                                                            <p className="salePrice">{formatCurrency(res.price)}₫</p>
                                                            <div className="discount">
                                                                <p className="originPrice">
                                                                    {formatCurrency(res.priceBeforeDiscount)}₫
                                                                </p>
                                                            </div>
                                                        </>
                                                    )}
                                                    {!res.priceBeforeDiscount && !res.rangePrice && (
                                                        <>
                                                            <p className="salePrice">{formatCurrency(res.price)}₫</p>
                                                            <br />
                                                        </>
                                                    )}
                                                    {!res.priceBeforeDiscount && res.rangePrice && (<p className="salePrice">{checkMinMax(res.rangePrice.minPrice, res.rangePrice.maxPrice)}</p>)}

                                                </div>
                                                {res.priceBeforeDiscount > 0 && (
                                                    <div className="percent">
                                                        {!res.rangePrice && <> {res.commissionType === "percent" && <span>
                                                            -{res.commissionValue}%
                                                        </span>}
                                                            {res.commissionType === "amount" && <span>
                                                                -
                                                                {(
                                                                    ((res.priceBeforeDiscount - res.price) * 100) /
                                                                    res.priceBeforeDiscount
                                                                ).toFixed()}
                                                                %
                                                            </span>}
                                                        </>}
                                                        {index < 5 && <img src={topIcon} alt="" />}
                                                    </div>
                                                )}

                                                {!res.priceBeforeDiscount && index < 5 && (
                                                    <div className="percent">
                                                        <img src={topIcon} alt="" />
                                                    </div>
                                                )}
                                            </li>
                                        </a>
                                    )
                                })}
                        </div>
                        <PaginationContainer
                            total={listTop.length}
                            pageSize={pagingTrending.pageSize}
                            page={pagingTrending.page}
                        />
                    </div>

                </>
            )}
            {!(isMobileScreen() || isMaxTableScreen()) && (
                <div className="home__desktop">
                    <div className="container">
                        <div className="home__desktop__content">
                            <div className="home__left">
                                <HomeBanner
                                    attrClass={'home__banner__desktop'}
                                    listBanner={listBanner}
                                    isMobile={false}
                                />

                                <div className="home__suggestion home__desktop__item">
                                    <div className="title">
                                        <h2>Xu hướng mua sắm</h2>
                                    </div>
                                    <div className="filter_label">
                                        <p
                                            className={isPolicyWholesale ? 'label active' : 'label'}
                                            onClick={() => handleSelectWholesale()}
                                        >
                                            Sản phẩm bán sỉ
                                        </p>
                                        <p
                                            className={!isPolicyWholesale ? 'label active' : 'label'}
                                            onClick={() => handleSelect()}
                                        >
                                            Sản phẩm bán lẻ
                                        </p>
                                    </div>
                                    <div className="list-top5-page">
                                        {listTrend &&
                                            listTrend.length > 0 &&
                                            listTrend.map((res, index) => {
                                                return (
                                                    <a
                                                        href={`${PATH_PRODUCT}/${res.id}`}
                                                        key={res.id}
                                                        className="hot__product__item"
                                                    >
                                                        <li className="itemCard">
                                                            <div className="images">
                                                                <img src={res.thumbImage} alt="" />
                                                            </div>
                                                            <div className="text-group">
                                                                <p className="title">{res.name}</p>
                                                                <p className="comm">
                                                                    Hoa hồng{' '}
                                                                    {!res.rangePrice && <DisplayPrice
                                                                        price={convertCommission(
                                                                            res.price,
                                                                            res.commissionValue,
                                                                            res.commissionType
                                                                        )}
                                                                    />}
                                                                    {res.rangePrice && <ShowComm
                                                                        min={res.rangePrice.minPrice}
                                                                        max={res.rangePrice.maxPrice}
                                                                        commType={res.commissionType}
                                                                        commValue={res.commissionValue}
                                                                    />
                                                                    }
                                                                </p>

                                                                {res.priceBeforeDiscount > 0 && !res.rangePrice && (
                                                                    <>
                                                                        <p className="salePrice">
                                                                            {formatCurrency(res.price)}₫
                                                                        </p>
                                                                        <div className="discount">
                                                                            <p className="originPrice">
                                                                                {formatCurrency(
                                                                                    res.priceBeforeDiscount
                                                                                )}
                                                                                ₫
                                                                            </p>
                                                                        </div>
                                                                    </>
                                                                )}
                                                                {!res.priceBeforeDiscount && !res.rangePrice && (
                                                                    <>
                                                                        <p className="salePrice">
                                                                            {formatCurrency(res.price)}₫
                                                                        </p>
                                                                        <br />
                                                                    </>
                                                                )}
                                                                {res.rangePrice && (
                                                                    <>
                                                                        <p className="salePrice">
                                                                            {checkMinMax(res.rangePrice.minPrice, res.rangePrice.maxPrice)}₫
                                                                        </p>
                                                                        <br />
                                                                    </>
                                                                )}
                                                            </div>
                                                            {res.priceBeforeDiscount > 0 && (
                                                                <div className="percent">
                                                                    {!res.rangePrice && <> {res.commissionType === "percent" && <span>
                                                                        -{res.commissionValue}%
                                                                    </span>}
                                                                        {res.commissionType === "amount" && <span>
                                                                            -
                                                                            {(
                                                                                ((res.priceBeforeDiscount - res.price) * 100) /
                                                                                res.priceBeforeDiscount
                                                                            ).toFixed()}
                                                                            %
                                                                        </span>}
                                                                    </>}
                                                                    {index < 5 && <img src={topIcon} alt="" />}
                                                                </div>
                                                            )}
                                                            {!res.priceBeforeDiscount && index < 5 && (
                                                                <div className="percent">
                                                                    <img src={topIcon} alt="" />
                                                                </div>
                                                            )}
                                                        </li>
                                                    </a>
                                                )
                                            })}
                                    </div>
                                    <PaginationContainer
                                        total={listTop.length}
                                        pageSize={pagingTrending.pageSize}
                                        page={pagingTrending.page}
                                    />
                                </div>

                            </div>
                            <div className="home__right">
                                <TopProduct listTop={listTop} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <BoxSearch />
            <MenuMobile />
        </section>
    )
}

export default TrendingProduct
