import React from 'react'
import moment from 'moment'
import Rectangle13 from '../../assets/images/Rectangle_13.png'
import 'moment/min/locales.min'
import DisplayPrice from '../common/DisplayPrice'
import { Button } from 'antd'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { toggleOpenInfoProduct } from '../../redux/actions/list-order-action'
function BoxInfoOrder({ openInfoOrder, dispatch, isPublisher }) {
    return (
        <div className={`box__info__order ${openInfoOrder ? 'open' : ''}`}>
            <div className="box__info__order__content">
                <div className="box__info__order__main">
                    <div className="order-item order__info border__bottom">
                        <div className="box__title">
                            <h3>thông tin đơn hàng</h3>
                            <div onClick={() => dispatch(toggleOpenInfoProduct(false))}>
                                <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                                        fill="#BFBFBF"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className="order__info__item">
                            <p>
                                <span>Mã đơn hàng:</span> #100003
                            </p>
                        </div>
                        <div className="order__info__item">
                            <p>
                                <span>Ngày đặt hàng:</span> {moment().format('HH:mm, DD/MM/YYYY')}
                            </p>
                        </div>
                        <div className="order__info__item">
                            <p>
                                <span>Trạng thái đơn hàng:</span> <span className="success">Thành công</span>
                            </p>
                        </div>
                        <div className="order__info__item">
                            <p>
                                <span>Ghi chú:</span> Đơn hàng này là đơn hàng đầu tiên, mong nhà cung cấp hỗ trợ thêm
                                tý tiền ăn trưa cho bọn em
                            </p>
                        </div>
                    </div>
                    <div className=" order-item order__info border__bottom">
                        <h3>Thông tin người nhận</h3>
                        <div className="order__info__item">
                            <p>
                                <span>Họ tên:</span> Nguyễn Thành Đạt
                            </p>
                        </div>
                        <div className="order__info__item">
                            <p>
                                <span>Số điện thoại:</span> 0349657827
                            </p>
                        </div>
                        <div className="order__info__item">
                            <p>
                                <span>Địa chỉ:</span> Số 1 Phạm Văn Bạch, Phường Yêu Hòa, Quận Cầu Giấy, Tp. Hà Nội.
                            </p>
                        </div>
                    </div>
                    <div className=" order-item product__info border__bottom">
                        <h3>Thông tin sản phẩm</h3>
                        <div className="product__info__item">
                            <div className="item__image">
                                <img src={Rectangle13} alt="" />
                            </div>
                            <div className="item__text">
                                <p className="name">Sữa đậu nành óc chó Vinamilk 180ml</p>
                                <div className="item__quantity">
                                    <span>Số lượng</span>
                                    <p className="price">04</p>
                                </div>
                                <div className="item__price">
                                    <span>Giá thành</span>
                                    <p className="price">
                                        <DisplayPrice price={290000} />
                                    </p>
                                </div>
                                <div className="item__price">
                                    <span>Thành tiền</span>
                                    <p className="price">
                                        <DisplayPrice price={320000} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isPublisher === 1 ? (
                        <div className="order-item order__commission">
                            <p className="text">
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M12 1.5C6.202 1.5 1.5 6.202 1.5 12C1.5 17.798 6.202 22.5 12 22.5C17.798 22.5 22.5 17.798 22.5 12C22.5 6.202 17.798 1.5 12 1.5ZM12 20.719C7.186 20.719 3.281 16.814 3.281 12C3.281 7.186 7.186 3.281 12 3.281C16.814 3.281 20.719 7.186 20.719 12C20.719 16.814 16.814 20.719 12 20.719ZM13.118 11.456L12.523 11.318V8.17C13.413 8.292 13.964 8.85 14.058 9.534C14.069 9.628 14.149 9.696 14.243 9.696H15.295C15.405 9.696 15.492 9.6 15.483 9.49C15.34 8.03 14.137 7.092 12.532 6.93V6.164C12.532 6.061 12.448 5.977 12.344 5.977H11.686C11.583 5.977 11.498 6.061 11.498 6.164V6.938C9.839 7.099 8.541 8.016 8.541 9.727C8.541 11.311 9.708 12.075 10.934 12.368L11.513 12.516V15.86C10.477 15.722 9.895 15.169 9.776 14.423C9.762 14.334 9.682 14.269 9.591 14.269H8.508C8.398 14.269 8.311 14.363 8.32 14.473C8.426 15.762 9.403 16.948 11.49 17.1V17.836C11.49 17.939 11.573 18.023 11.677 18.023H12.342C12.445 18.023 12.53 17.939 12.53 17.833L12.525 17.091C14.36 16.929 15.673 15.947 15.673 14.184C15.67 12.558 14.637 11.831 13.118 11.456ZM11.51 11.076C11.379 11.039 11.269 11.004 11.159 10.959C10.366 10.673 9.999 10.212 9.999 9.616C9.999 8.766 10.643 8.28 11.51 8.17V11.077V11.076ZM12.523 15.867V12.734C12.595 12.754 12.661 12.771 12.729 12.785C13.838 13.123 14.21 13.591 14.21 14.311C14.21 15.227 13.521 15.778 12.523 15.867Z"
                                        fill="#1890FF"
                                    />
                                </svg>
                                Hoa hồng hoa hồng
                            </p>
                            <p className="price">
                                <DisplayPrice price={60000} />
                            </p>
                        </div>
                    ) : (
                        <div className="form__btn">
                            <Button className="w-100" type="primary" size="large">
                                Mua lại
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <div className="box__backdrop" onClick={() => dispatch(toggleOpenInfoProduct(false))}></div>
        </div>
    )
}
const mapStateToProps = function (state) {
    return {
        openInfoOrder: state.listOrderReducer.openInfoOrder,
        isPublisher: state.homeReducer.isPublisher,
    }
}
export default withRouter(connect(mapStateToProps)(BoxInfoOrder))
