import React from 'react';
import { getNoteData } from '../../functions/common-func';

const ShowNote = ({note}) => {
        const dataNote = getNoteData(note);

        return (
            <>
               { (dataNote?.length === 1 && dataNote[0]?.name !== "") && <div  className="variant__group">
                <span className="variant"> {dataNote[0].name} : {dataNote[0].value}</span>
            </div> }
            { dataNote && dataNote.length === 2 && <div  className="variant__group">
                <span className="variant">{dataNote[0].name} : {dataNote[0].value} - &nbsp;</span>
                
                <span className="variant">{dataNote[1].name} : {dataNote[1].value}</span>

            </div> }
            </>
            
        )
    
}
export default ShowNote;