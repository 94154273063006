import React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { initializeFirebase } from './firebase'
import * as serviceWorker from './serviceWorker'
import store from './redux/store'
import AppContainer from './container/layouts/AppContainer'
import ScrollToTop from './component/layouts/ScrollToTop'
const createHistory = require('history').createBrowserHistory
export const history = createHistory()

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <ScrollToTop />
            <AppContainer />
        </Router>
    </Provider>,
    document.getElementById('root')
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
initializeFirebase()
serviceWorker.unregister()
serviceWorker.notificationsFirebase()
