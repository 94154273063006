import firebase from 'firebase/app'
import 'firebase/messaging'
import 'firebase/database'
export const initializeFirebase = () => {
    const config =  {
        apiKey: "AIzaSyDzT_lsyJC0MIq6l7AjDY6ax25wVKZzHR4",
        authDomain: "d2c-development-e0869.firebaseapp.com",
        databaseURL: "https://d2c-development-e0869.firebaseio.com",
        projectId: "d2c-development-e0869",
        storageBucket: "d2c-development-e0869.appspot.com",
        messagingSenderId: "380447808214",
        appId: "1:380447808214:web:ba2a009a820dd94da00777",
        measurementId: "G-1PHK4QBKCY"
    }

    firebase.initializeApp(config)
}
export default initializeFirebase
