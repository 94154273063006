import React from 'react'
import HomeContainer from '../container/home/HomeContainer'
import LoginContainer from '../container/login/LoginContainer'
import LoginAccountContainer from '../container/login/LoginAccountContainer'
import RegisterContainer from '../container/register/RegisterContainer'
import ForgotPasswordContainer from '../container/login/ForgotPasswordContainer'
// import UserInfoContainer from '../container/user/UserInfoContainer'
// import ChangePasswordContainer from '../container/user/ChangePasswordContainer'
import DashboardContainer from '../container/dashboard/DashboardContainer'
import ProductDetailContainer from '../container/product-detail/ProductDetailContainer'
import WholeSaleContainer from '../container/product-detail/WholeSaleContainer'
import NotificationContainer from '../container/notification/NotificationContainer'
import ReportContainer from '../container/report/ReportContainer'
import ListProductContainer from '../container/list-product/ListProductContainer'
import AccountContainer from '../container/account/AccountContainer'
import ChooseProductContainer from '../container/choose-product/ChooseProductContainer'
import CreateManyOrderContainer from '../container/create-order/CreateManyOrderContainer'
import CheckOutSuccess from '../component/check-out/CheckOutSuccess'
import ListOrderContainer from '../container/list-order/ListOrderContainer'
import CheckOutStep1Container from '../container/buyer/check-out/CheckOutStep1Container'
import BookingManagementContainer from '../container/buyer/booking-management/BookingManagementContainer'
import NotFound from '../component/not-found/NotFound'
import FormBooker from '../component/create-order/FormBooker'
import BoxAddress from '../component/buyer/check-out/BoxAddress'
import OrderDetailContainer from '../container/order-detail/OrderDetailContainer'
import Policy from '../component/policy/Policy'
import PolicyVietname from '../component/policy/PolicyVietname'
import YourCartContainer from '../container/buyer/your-cart/YourCartContainer'
import CheckoutReview from '../component/review/CheckoutReview'
import ListCategory from '../component/buyer/danh-muc/ListCategory'
import DealHotContainer from '../container/deal-hot/DealHotContainer'
import DealHotListContainer from '../container/deal-hot/DealHotListContainer'
import {
    PATH_NOTIFICATION,
    PATH_NOTIFICATION_DETAIL,
    PATH_PRODUCT_DETAIL,
    PATH_DEAL_HOT,
    PATH_PRODUCT_PREVIEW,
    PATH_COUPONS,
} from '../constants/common-contants'
import NotificationDetailContainer from '../container/notification/NotificationDetailContainer'
import TrendingProduct from '../component/trending-product/TrendingProduct'
import ProductPreviewContainer from '../container/product-preview/ProductPreviewContainer'
import CouponContainer from '../container/coupon/CouponContainer'

const routes = [
    {
        path: '/',
        exact: true,
        name: 'Home',
        component: (history) => <HomeContainer history={history} />,
    },
    {
        path: '/login',
        exact: true,
        name: 'Login',
        component: (history) => <LoginContainer history={history} />,
    },
    {
        path: '/login-account',
        exact: true,
        name: 'Login Account',
        component: (history) => <LoginAccountContainer history={history} />,
    },
    {
        path: '/register',
        exact: true,
        name: 'Register',
        component: (history) => <RegisterContainer history={history} />,
    },
    {
        path: '/forgot-password',
        exact: true,
        name: 'Forgot Password',
        component: (history) => <ForgotPasswordContainer history={history} />,
    },
    // {
    //     path: '/user-info',
    //     exact: true,
    //     name: 'User Info',
    //     component: (history) => <UserInfoContainer history={history} />,
    // },
    {
        path: '/account',
        exact: true,
        name: 'Account',
        component: (history) => <AccountContainer history={history} />,
    },
    {
        path: '/dashboard',
        exact: true,
        name: 'Dashboard',
        component: (history) => <DashboardContainer history={history} />,
    },
    {
        path: PATH_COUPONS,
        exact: true,
        name: 'List Coupon',
        component: (history) => <CouponContainer history={history} />,
    },
    {
        path: '/list-product',
        exact: true,
        name: 'List Product',
        component: (history) => <ListProductContainer history={history} />,
    },

    {
        path: '/trending-product',
        exact: true,
        name: 'Trending Products',
        component: (history) => <TrendingProduct history={history} />,
    },
    {
        path: '/choose-product',
        exact: true,
        name: 'Choose Product',
        component: (history) => <ChooseProductContainer history={history} />,
    },
    {
        path: PATH_PRODUCT_DETAIL,
        exact: true,
        name: 'Product Detail',
        component: (history) => <ProductDetailContainer history={history} />,
    },
    {
        path: PATH_PRODUCT_PREVIEW,
        exact: true,
        name: 'Pending Review',
        component: (history) => <ProductPreviewContainer history={history} />,
    },
    {
        path: PATH_DEAL_HOT,
        exact: true,
        name: 'Product Deal Hot',
        component: (history) => <DealHotContainer history={history} />,
    },
    {
        path: '/deal-list',
        exact: true,
        name: 'List Deal Hot',
        component: (history) => <DealHotListContainer history={history} />,
    },
    //Whole Sale
    {
        path: '/whole-sale/:id',
        exact: true,
        name: 'Whole Sale',
        component: (history) => <WholeSaleContainer history={history} />,
    },
    // {
    //     path: '/change-password',
    //     exact: true,
    //     name: 'Change Password',
    //     component: (history) => <ChangePasswordContainer history={history} />,
    // },

    // thanh toán và review
    {
        path: '/checkout/cart',
        exact: true,
        name: 'Create Many Order',
        component: (history) => <CreateManyOrderContainer history={history} />,
    },
    {
        path: '/checkout/review',
        exact: true,
        name: 'Checkout Review',
        component: (history) => <CheckoutReview history={history} />,
    },
    // {
    //     path: '/thanh-toan',
    //     exact: true,
    //     name: 'Checkout Step1',
    //     component: (history) => <CheckOutStep1Container history={history} />,
    // },
    {
        path: '/thanh-toan/:id',
        exact: true,
        name: 'Checkout Step1',
        component: (history) => <CheckOutStep1Container history={history} />,
    },
    {
        path: '/thanh-toan/review/:id',
        exact: true,
        name: 'Checkout Review Buyer',
        component: (history) => <CheckoutReview history={history} />,
    },

    // thanh toán và review
    {
        path: PATH_NOTIFICATION,
        exact: true,
        name: 'Notification',
        component: (history) => <NotificationContainer history={history} />,
    },
    {
        path: PATH_NOTIFICATION_DETAIL,
        exact: true,
        name: 'Notification detail',
        component: (history) => <NotificationDetailContainer history={history} />,
    },
    {
        path: '/report',
        exact: true,
        name: 'Report',
        component: (history) => <ReportContainer history={history} />,
    },
    {
        path: '/thanh-cong',
        exact: true,
        name: 'Success',
        component: (history) => <CheckOutSuccess history={history} />,
    },
    {
        path: '/that-bai',
        exact: true,
        name: 'Success',
        component: (history) => <CheckOutSuccess history={history} />,
    },
    {
        path: '/list-order',
        exact: true,
        name: 'List Order',
        component: (history) => <ListOrderContainer history={history} />,
    },
    {
        path: '/order/:id',
        exact: true,
        name: 'Order detail',
        component: (history) => <OrderDetailContainer history={history} />,
    },

    {
        path: '/address/create',
        exact: true,
        name: 'Create Address',
        component: (history) => <FormBooker history={history} />,
    },
    {
        path: '/address/shipping',
        exact: true,
        name: 'List Address',
        component: (history) => <BoxAddress history={history} />,
    },
    {
        path: '/address/edit/:id',
        exact: true,
        name: 'Edit Address',
        component: (history) => <FormBooker history={history} />,
    },
    {
        path: '/account/booking-management',
        exact: true,
        name: 'Checkout Step1',
        component: (history) => <BookingManagementContainer history={history} />,
    },
    {
        path: '/privacy-policy',
        exact: true,
        name: 'Privacy policy',
        component: (history) => <Policy history={history} />,
    },
    {
        path: '/dieu-khoan',
        exact: true,
        name: 'Dieu khoan su dung',
        component: (history) => <PolicyVietname history={history} />,
    },
    {
        path: '/chinh-sach',
        exact: true,
        name: 'Chinh Sach Bao Mat',
        component: (history) => <PolicyVietname history={history} />,
    },
    {
        path: '/your-cart',
        exact: true,
        name: 'Your cart',
        component: (history) => <YourCartContainer history={history} />,
    },
    {
        path: '/list-category',
        exact: true,
        name: 'Category',
        component: (history) => <ListCategory history={history} />,
    },
    {
        path: '',
        exact: true,
        name: 'notFound',
        component: (history) => <NotFound history={history} />,
    },
]
export default routes
