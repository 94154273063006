import React, { useState } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import imageDefault from '../../assets/images/image_default_d2c.jpg'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import DisplayPrice from '../common/DisplayPrice'
import { IconHomeChietKhau } from '../../constants/icon-const'
import { Button, message } from 'antd'
import moment from 'moment'
import { setRefreshCart, toggleShowBoxConfirmCart } from '../../redux/actions/search-action'
import { checkMinMax, convertCommission, isMobileScreen } from '../../functions/common-func'
import { Modal } from 'antd'
import { addProductToCart } from '../../api-services/cart-api'

function BoxConfirmCart({ product, isShowConfirmCart, dispatch, title, status, isPublisher, isLoggedIn, history }) {
    const [color, setColor] = useState('')
    const [size, setSize] = useState('')


    const handleCreateOrder = async (id, quantity) => {
        try {
            if (product.colors && product.colors.length > 0 && !color) {
                message.error('Vui lòng chọn màu sản phẩm')
                return
            }
            if (product.sizes && product.sizes.length > 0 && !size) {
                message.error('Vui lòng chọn kích thước sản phẩm')
                return
            }
            
            if (!isLoggedIn) {
                // trường hợp là buyer
                history.push(`/thanh-toan/${id}?color=${color}&size=${size}`)
            } else {
                // trường hợp là publisher
                let note = ''
                if (color) {
                    note += 'Color:' + color
                }
                if (size) {
                    if (color) note += ','
                    note += 'Size:' + size
                }
                const data = {
                    productId: id,
                    quantity: 1,
                    note,
                    productAttributes: [],

                }
                const result = await addProductToCart(data)
                if (result && result.status === 200) {
                    history.push('/checkout/cart')
                    dispatch(toggleShowBoxConfirmCart(false, '', status))
                    dispatch(setRefreshCart(moment()))
                }
            }
        } catch (e) {
            if (e && e.response && e.response.data) {
                if (e.response.data.code === 'E00018' && quantity > 0) {
                    message.error(
                        'Bạn đã chọn hết số lượng còn lại của sản phẩm, không thể chọn thêm được nữa, vui lòng kiểm tra giỏ hàng'
                    )
                    return
                }
                if (e.response.data.code === 'E00018') {
                    message.error('Sản phầm đã hết hàng')
                    return
                } else {
                    message.error(e.response.data.message)
                }
            }
            throw e
        }
    }

    const handleChangeColor = (color) => {
        setColor(color)
    }

    const handleChangeSize = (size) => {
        setSize(size)
    }

    return (
        <div>
            {isMobileScreen() ? (
                <div className={`box__confirm__cart ${isShowConfirmCart ? 'open' : ''}`}>
                    {product && (
                        <div className="box__confirm__cart__content">
                            <div className="box__confirm__cart__main">
                                <div className="title">
                                    {/*{status !== 'success' ? (*/}
                                    {/*    <p className="text red">*/}
                                    {/*        <IconCheckConfirm color="#F5222D" />*/}
                                    {/*        <span>{title ? title : 'Sản phẩm đã hết hàng'}</span>*/}
                                    {/*    </p>*/}
                                    {/*) : (*/}
                                    {/*    <p className="text">*/}
                                    {/*        <IconCheckConfirm color="#0d5302" />*/}
                                    {/*        <span>{title ? title : 'Sản phẩm đã được thêm vào giỏ hàng'}</span>*/}
                                    {/*    </p>*/}
                                    {/*)}*/}
                                    <h2>{title ? title : 'Chọn loại sản phẩm'}</h2>
                                    <div
                                        className="btn__close"
                                        onClick={() => dispatch(toggleShowBoxConfirmCart(false, '', status))}
                                    >
                                        <CloseOutlined />
                                    </div>
                                </div>
                                <div className="product__info">
                                    <div className="product__image">
                                        <img src={product.thumbImage || imageDefault} alt="image product" />
                                    </div>
                                    <div className="product__text">
                                        <div className="product__name">{product.name}</div>
                                        {!product.rangePrice && <div className="price">
                                            Giá: <DisplayPrice price={product.price} />
                                        </div>}
                                        {product.rangePrice &&<div className="price">
                                            Giá: {checkMinMax(product.rangePrice.minPrice, product.rangePrice.maxPrice)}
                                        </div>}
                                        {isPublisher === 1 && !product.rangePrice && (
                                            <div className="price price__commission">
                                                Hoa hồng: {''} <IconHomeChietKhau />
                                                <DisplayPrice
                                                    price={convertCommission(
                                                        product.price,
                                                        product.commissionValue,
                                                        product.commissionType
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {product?.colors?.length > 0 && <p className="title">Màu sắc</p>}
                                <div className="list__color">
                                    {Array.isArray(product.colors) &&
                                        product.colors.length > 0 &&
                                        product.colors.map((res, index) => {
                                            if (index < 10) {
                                                return (
                                                    <div className={`color__item ${res === color ? 'active' : ''}`}>
                                                        <Button
                                                            onClick={() => handleChangeColor(res)}
                                                            style={{ marginRight: 10, marginBottom: 10 }}
                                                        >
                                                            {res}
                                                        </Button>
                                                    </div>
                                                )
                                            }
                                            return null
                                        })}
                                </div>
                                {product?.sizes?.length > 0 && <p className="title">Kích thước</p>}
                                <div className="list__size">
                                    {Array.isArray(product.sizes) &&
                                        product.sizes.length > 0 &&
                                        product.sizes.map((res, index) => {
                                            if (index < 10) {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`size__item ${res === size ? 'active' : ''}`}
                                                    >
                                                        <Button
                                                            onClick={() => handleChangeSize(res)}
                                                            style={{ marginRight: 10, marginBottom: 10 }}
                                                        >
                                                            {res}
                                                        </Button>
                                                    </div>
                                                )
                                            }
                                            return null
                                        })}
                                </div>
                                <Button
                                    onClick={() => handleCreateOrder(product.id, product.quantity)}
                                    type="primary"
                                    size="large"
                                    className="w-100"
                                >
                                    Thêm vào giỏ
                                </Button>
                            </div>
                        </div>
                    )}

                    <div
                        className="box__backdrop"
                        onClick={() => dispatch(toggleShowBoxConfirmCart(false, '', status))}
                    ></div>
                </div>
            ) : (
                <Modal
                    className="box__share__desktop"
                    onCancel={() => dispatch(toggleShowBoxConfirmCart(false, '', status))}
                    footer={null}
                    title={title}
                    visible={isShowConfirmCart}
                >
                    {product && (
                        <div className="box__confirm__cart__main">
                            <div className="title">
                               
                                <h2>{title ? title : 'Chọn loại sản phẩm'}</h2>
                                <div
                                    className="btn__close"
                                    onClick={() => dispatch(toggleShowBoxConfirmCart(false, '', status))}
                                >
                                    <CloseOutlined />
                                </div>
                            </div>
                            <div className="product__info">
                                <div className="product__image">
                                    <img src={product.thumbImage || imageDefault} alt="image product" />
                                </div>
                                <div className="product__text">
                                    <div className="product__name">{product.name}</div>
                                    {!product.rangePrice &&<div className="price">
                                        Giá: <DisplayPrice price={product.price} />
                                    </div> }
                                    {product.rangePrice && <div className="price">
                                        Giá: {checkMinMax(product.rangePrice.minPrice, product.rangePrice.maxPrice)}
                                    </div> }
                                    
                                    {isPublisher === 1 && !product.rangePrice && (
                                        <div className="price price__commission">
                                            Hoa hồng: {''} <IconHomeChietKhau />
                                            <DisplayPrice
                                                price={convertCommission(
                                                    product.price,
                                                    product.commissionValue,
                                                    product.commissionType
                                                )}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {product?.colors?.length > 0 && <p className="title">Màu sắc</p>}
                            <div className="list__color">
                                {Array.isArray(product.colors) &&
                                    product.colors.length > 0 &&
                                    product.colors.map((res, index) => {
                                        if (index < 10) {
                                            return (
                                                <div className={`color__item ${res === color ? 'active' : ''}`}>
                                                    <Button
                                                        onClick={() => handleChangeColor(res)}
                                                        style={{ marginRight: 10, marginBottom: 10 }}
                                                    >
                                                        {res}
                                                    </Button>
                                                </div>
                                            )
                                        }
                                        return null
                                    })}
                            </div>
                            {product?.sizes?.length > 0 && <p className="title">Kích thước</p>}
                            <div className="list__size">
                                {Array.isArray(product.sizes) &&
                                    product.sizes.length > 0 &&
                                    product.sizes.map((res, index) => {
                                        if (index < 10) {
                                            return (
                                                <div className={`size__item ${res === size ? 'active' : ''}`}>
                                                    <Button
                                                        onClick={() => handleChangeSize(res)}
                                                        style={{ marginRight: 10, marginBottom: 10 }}
                                                    >
                                                        {res}
                                                    </Button>
                                                </div>
                                            )
                                        }
                                        return null
                                    })}
                            </div>
                            <Button
                                onClick={() => handleCreateOrder(product.id, product.quantity)}
                                type="primary"
                                size="large"
                                className="w-100"
                            >
                                Thêm vào giỏ
                            </Button>
                        </div>
                    )}
                    
                </Modal>
            )}
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        isShowConfirmCart: state.searchReducer.isShowConfirmCart,
        title: state.searchReducer.title,
        type: state.searchReducer.type,
        status: state.searchReducer.status,
        isPublisher: state.homeReducer.isPublisher,
        isLoggedIn: state.homeReducer.isLoggedIn,
    }
}

export default withRouter(connect(mapStateToProps)(BoxConfirmCart))
