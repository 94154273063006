import ApiMicroService from './request-micro-service'
const endPointCoupon = '/d2c-service/coupon';

export const fetchCoupons = async (params) => {
    const page = params.page || 1;
    const page_size = params.pageSize || 12;
    try {
        const resCoupons = await ApiMicroService.get(`${endPointCoupon}?page_size=${page_size}&page=${page}`);
        return resCoupons.data;
    } catch (e) {}
}
export const getDetailCoupon = async (id) => {
    try {
        const resCoupons = await ApiMicroService.get(`${endPointCoupon}/${id}`);
        return resCoupons.data;
    } catch (e) {}
}
export const getProductCoupon = async (id,params) => {
    const page = params.page || 1;
    const page_size = params.page_size || 12;
    try {
        const resCoupon = await ApiMicroService.get(`/d2c-service/product/list-by-coupon/${id}?page_size=${page_size}&page=${page}`);
        return resCoupon.data;
    } catch (e) {}
}
export const getCouponsByProductID = async (id,statusMore,page,page_size) => {
    try {
        let params = '';
        if(statusMore) {
            params += `?page=${page}&page_size=${page_size}`;
        } else {
            params += `?page=1&page_size=3`;
        }

        const resCoupon = await ApiMicroService.get(`/d2c-service/coupon/list-by-product/${id}${params}`);
        return resCoupon.data;
    } catch (e) {}
}