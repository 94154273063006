import React, { useEffect, useState } from 'react'
import OrderDetail from '../../component/order-detail/OrderDetail'
import { fetchOrderItemDetail } from '../../api-services/order-id'
import { useRouteMatch, useHistory } from 'react-router'
import { useSelector } from 'react-redux'

function OrderDetailContainer() {
    const [orderDetail, setOrderDetail] = useState(false)
    const match = useRouteMatch()
    const history = useHistory()

    const { isLoggedIn } = useSelector((state) => {
        return {
            isLoggedIn: state.homeReducer.isLoggedIn,
        }
    })

    // if(!isLoggedIn) {
    //     window.location = `${window.location.origin}/login`;
    // }
    localStorage.removeItem('cartCoupon');

    useEffect(() => {
        const id = match.params.id
        getDetailOrder(id)
    }, [])

    const onClose = () => {
        history.goBack()
    }

    const getDetailOrder = async (id) => {
        try {
            const data = await fetchOrderItemDetail(id)
            if (data && data.status === 200) {
                setOrderDetail(data.data)
            }
        } catch (e) {
            throw e
        }
    }
    return <OrderDetail onClose={onClose} orderDetail={orderDetail} />
}

export default OrderDetailContainer
