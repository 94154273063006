import React from 'react'
// import { Link } from 'react-router-dom'
import DisplayPrice from '../common/DisplayPrice'
import { IconHomeChietKhau } from '../../constants/icon-const'
import ErrorProduct from '../common/ErrorProduct'
import { checkMinMax, checkURLImage, convertCommission, convertSizeImage } from '../../functions/common-func'
import { PATH_PRODUCT } from '../../constants/common-contants'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import MinMax from '../product-price/MinMax'
import ShowComm from '../product-price/ShowComm'
function SuggestProduct({ listProductSearchDesktop, isOpenProduct, isPublisher }) {
    return (
        <div className={`suggestDefault ${isOpenProduct ? 'open' : ''}`}>
            <div className="suggestDefault__body">
                <div className="listProduct">
                    {Array.isArray(listProductSearchDesktop) &&
                        listProductSearchDesktop.length > 0 &&
                        listProductSearchDesktop.map((res) => {
                            return (
                                <a href={`${PATH_PRODUCT}/${res.id}`}>
                                    <div key={res.id} className="product__content item__product">
                                        <div className="product__image">
                                            <img
                                                src={convertSizeImage(checkURLImage(res.thumbImage), 200, 200)}
                                                alt=""
                                            />
                                        </div>
                                        <div className="product__detail">
                                            <p className="name">{res.name}</p>
                                            <p className="price__product">
                                                <span>Giá thành:</span>
                                                {!res.rangePrice && <span className="price">
                                                    <DisplayPrice price={res.price} />
                                                </span>}
                                                {res.rangePrice && <span className="price">
                                                    {checkMinMax(res.rangePrice.minPrice, res.rangePrice.maxPrice)}
                                                    
                                                </span>}
                                            </p>
                                            {isPublisher === 1 && (
                                                <p className="price__product commission">
                                                    <span>Hoa hồng:</span>
                                                    <span className="price">
                                                        <IconHomeChietKhau />
                                                        { !res.rangePrice && <DisplayPrice
                                                            price={convertCommission(
                                                                res.price,
                                                                res.commissionValue,
                                                                res.commissionType
                                                            )}
                                                        />}
                                                        {res.rangePrice && 
                                                            <ShowComm 
                                                                min={res.rangePrice.minPrice}
                                                                max={res.rangePrice.maxPrice}
                                                                commValue={res.commissionValue}
                                                                commType={res.commissionType}
                                                                className="in_search"
                                                            />
                                                        }
                                                    </span>
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </a>
                            )
                        })}
                    {listProductSearchDesktop && listProductSearchDesktop.length === 0 && <ErrorProduct />}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        isPublisher: state.homeReducer.isPublisher,
    }
}
export default withRouter(connect(mapStateToProps)(SuggestProduct))
