import React from 'react'
import { Input } from 'antd'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import { FilterFilled, DownloadOutlined } from '@ant-design/icons'

function Report() {
    return (
        <section className="report">
            <BoxHeaderTitle text="Báo cáo" link="/" />
            <div className="report__content">
                <div className="report__item border__bottom">
                    <div className="container">
                        <div className="form__input">
                            <Input.Search size="large" placeholder="Tìm kiếm theo mã đơn, sản phẩm" />
                        </div>
                    </div>
                </div>
                <div className="report__item border__bottom report__filter">
                    <div className="container">
                        <div className="report__filter__content">
                            <div className="filter__item">
                                <FilterFilled style={{ color: '#8C8C8C' }} />
                                Lọc đơn hàng
                            </div>
                            <div className="filter__item">
                                <DownloadOutlined style={{ color: '#8C8C8C' }} />
                                Xuất báo cáo
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Report
