import React, { useState, useEffect } from 'react'
import { Form, DatePicker, Button, Checkbox } from 'antd'
import moment from 'moment'
import 'moment/min/locales.min'
import { withRouter, useLocation, useHistory } from 'react-router'
import { connect } from 'react-redux'
import { toggleOpenFilterProduct } from '../../redux/actions/list-order-action'
import { IconClose } from '../../constants/icon-const'
import { toggleClassNoScroll } from '../../functions/common-func'
import * as queryString from 'query-string'

const { RangePicker } = DatePicker

function BoxFilterOrder({ dispatch, openFilterOrder }) {
    const listStatus = [
        // {
        //     id: 1,
        //     name: 'Thành công',
        //     value: 'success',
        //     checked: false,
        // },
        // {
        //     id: 2,
        //     name: 'Thất bại',
        //     value: 'fail',
        //     checked: false,
        // },
        {
            id: 3,
            name: 'Chờ xử lý',
            value: 'pending',
            checked: false,
        },
        {
            id: 4,
            name: 'Đã duyệt',
            value: 'approved',
            checked: false,
        },
        {
            id: 5,
            name: 'Tạm duyệt',
            value: 'pre_approved',
            checked: false,
        },
        {
            id: 6,
            name: 'Đã hủy',
            value: 'rejected',
            checked: false,
        },
    ]
    const [listFilterStatus, setListFilterStatus] = useState(listStatus)
    const [isOpenDp, setIsOpenDp] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [status, setStatus] = useState('')
    const { search, pathname } = useLocation()
    const history = useHistory()

    useEffect(() => {
        const query = queryString.parse(search)
        if (query.startDate) {
            setStartDate(moment(query.startDate, 'DDMMYYYY'))
        }
        if (query.endDate) {
            setEndDate(moment(query.endDate, 'DDMMYYYY'))
        }
        if (query.status) {
            setStatus(query.status)
        }
    }, [search])

    const changeFilterBrand = (value) => {
        setStatus(value)
    }

    const handleCloseDp = () => {
        setIsOpenDp(false)
        toggleClassNoScroll()
    }

    const handleOpenDp = (open) => {
        setIsOpenDp(open)
        toggleClassNoScroll()
    }

    const onChange = (rangeDate) => {
        if (rangeDate) {
            setEndDate(rangeDate[1])
            setStartDate(rangeDate[0])
        }
    }

    const renderExtraFooter = () => (
        <div className="headerPopup">
            <p>Chọn ngày</p>
            <button type="button" className="headerPopup__close" onClick={handleCloseDp}>
                <IconClose />
            </button>
        </div>
    )

    const filterOrder = () => {
        const query = queryString.parse(search)
        let new_query = { ...query }
        status ? (new_query.status = status) : delete new_query.status
        startDate ? (new_query.startDate = moment(startDate).format('DDMMYYYY')) : delete new_query.startDate
        endDate ? (new_query.endDate = moment(endDate).format('DDMMYYYY')) : delete new_query.endDate
        const stringify = queryString.stringify(new_query)
        history.push(`${pathname}?${stringify}`)
        dispatch(toggleOpenFilterProduct(false))
    }

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day')
    }

    const clearFilter = () => {
        setStatus('')
        setStartDate(null)
        setEndDate(null)
    }

    return (
        <div className={`box__filter ${openFilterOrder ? 'open' : ''}`}>
            <div className="box__filter__content">
                <div className="box__filter__main">
                    <div className="filter__item">
                        <div className="box__title">
                            <h3>Bộ lọc</h3>
                            <div onClick={() => dispatch(toggleOpenFilterProduct(false))}>
                                <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                                        fill="#BFBFBF"
                                    />
                                </svg>
                            </div>
                        </div>
                        <Form>
                            <Form.Item>
                                <div className="clear__filter">
                                    <label>Lọc theo khoảng thời gian</label>
                                    <span onClick={clearFilter} style={{ color: '#1890FF' }}>
                                        Xóa bộ lọc
                                    </span>
                                </div>
                                <div className="vntDatepicker">
                                    <RangePicker
                                        className="w-100"
                                        open={isOpenDp}
                                        onChange={onChange}
                                        onOpenChange={handleOpenDp}
                                        allowClear={true}
                                        disabledDate={disabledDate}
                                        size={'large'}
                                        format={'DD-MM-YYYY'}
                                        inputReadOnly={true}
                                        renderExtraFooter={renderExtraFooter}
                                        value={[startDate, endDate]}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <label>Theo trạng thái đơn hàng</label>
                                <div className="list__button">
                                    {listFilterStatus.map((res, index) => {
                                        return (
                                            <div
                                                key={res.id}
                                                className={`item__filter ${res.value === status ? 'active' : ''}`}
                                            >
                                                <Button size="large">
                                                    <label htmlFor={`brand_${res.id}`}>{res.name}</label>
                                                </Button>
                                                <Checkbox
                                                    style={{ display: 'none' }}
                                                    name="brand"
                                                    onChange={() => changeFilterBrand(res.value)}
                                                    id={`brand_${res.id}`}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <Button onClick={filterOrder} type="primary" size="large" className="w-100">
                                    {' '}
                                    Áp dụng bộ lọc
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
            <div className="box__backdrop" onClick={() => dispatch(toggleOpenFilterProduct(false))}></div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        openFilterOrder: state.listOrderReducer.openFilterOrder,
    }
}
export default withRouter(connect(mapStateToProps)(BoxFilterOrder))
