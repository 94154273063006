import React, { useEffect } from 'react'
import { Button, Input, message } from 'antd'
import { CopyOutlined, FacebookFilled } from '@ant-design/icons'
import { IconInstagram, IconMessenger, IconTelegram } from '../../../constants/icon-const'
import {
    TelegramShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    FacebookMessengerShareButton,
} from 'react-share'
import { copyToClipboard } from '../../../functions/common-func'
function ShareLink({ image, url, title, isShare, contentShare, htmlZalo }) {
    const copyContent = (text) => {
        if (text) {
            copyToClipboard(text)
            // message.success('Sao chép thành công')
        }
    }
    useEffect(() => {
        if (url && isShare && contentShare) {
            copyToClipboard(contentShare && contentShare.content + ' ' + url)
            // message.success('Sao chép thành công')
            return
        } else if (url && isShare) {
            copyToClipboard(url)
            // message.success('Sao chép thành công')
        }
    }, [url, isShare, contentShare])
    return (
        <div className="box__share__main">
            <FacebookShareButton
                url={image || window.location.href}
                quote={title}
                className="Demo__some-network__share-button w-100"
            >
                <Button
                    style={{ background: '#1976D2', borderRadius: 4, color: '#ffffff', marginBottom: 25 }}
                    className="w-100"
                >
                    <FacebookFilled style={{ background: '#1976D2' }} />
                    Facebook
                </Button>
            </FacebookShareButton>
            <div className="payment__share">
                <div className="item__share">
                    <TelegramShareButton
                        url={image || window.location.href}
                        title={title}
                        className="Demo__some-network__share-button"
                    >
                        <IconTelegram />
                        <span>Telegram</span>
                    </TelegramShareButton>
                </div>
                <div className="item__share">
                    <FacebookMessengerShareButton
                        url={image || window.location.href}
                        appId="355359492740130"
                        className="Demo__some-network__share-button"
                    >
                        <IconMessenger />
                        <span>Messenger</span>
                    </FacebookMessengerShareButton>
                </div>
                <div className="item__share">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: htmlZalo,
                        }}
                    ></div>
                    {/*<IconTelegram />*/}
                    <span>Zalo</span>
                </div>
                {/*<div className="item__share">*/}
                {/*    <InstapaperShareButton*/}
                {/*        url={url || window.location.href}*/}
                {/*        title={title}*/}
                {/*        className="Demo__some-network__share-button"*/}
                {/*    >*/}
                {/*        <IconInstagram />*/}
                {/*        <span>Instagram</span>{' '}*/}
                {/*    </InstapaperShareButton>*/}
                {/*</div>*/}
            </div>
            <div className="share__with__link">
                <h3>Hoặc sao chép link giới thiệu</h3>
                <div className="input__copy">
                    <Input value={url || ''} />
                    <Button onClick={() => copyContent(url)} className="btn-copy" icon={<CopyOutlined />}>
                        Sao chép
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default ShareLink
