import { SEARCH_TYPE_REDUCER } from '../../constants/search-const'

const searchReducer = (state = {}, action) => {
    switch (action.type) {
        case SEARCH_TYPE_REDUCER.OPEN_HOT_DiRECTORY:
            return {
                ...state,
                openDirectory: action.openDirectory,
            }
        case SEARCH_TYPE_REDUCER.OPEN_FILTER_PRODUCT:
            return {
                ...state,
                openFilterProduct: action.openFilterProduct,
            }
        case SEARCH_TYPE_REDUCER.OPEN_SORT_PRODUCT:
            return {
                ...state,
                openSortProduct: action.openSortProduct,
            }
        case SEARCH_TYPE_REDUCER.OPEN_SHOW_CHOOSE_CONTENT:
            return {
                ...state,
                isShowChooseContent: action.isShowChooseContent,
            }
        case SEARCH_TYPE_REDUCER.TOTAL_PRODUCT_FROM_CART:
            return {
                ...state,
                totalProduct: action.totalProduct,
            }
        case SEARCH_TYPE_REDUCER.REFRESH_CART:
            return {
                ...state,
                refreshCart: action.refreshCart,
            }
        case SEARCH_TYPE_REDUCER.TOTAL_NOTIFICATION_NOT_READ:
            return {
                ...state,
                totalNotification: action.totalNotification,
            }
        case SEARCH_TYPE_REDUCER.REFRESH_NOTIFICATION:
            return {
                ...state,
                refreshNotification: action.refreshNotification,
            }

        case SEARCH_TYPE_REDUCER.OPEN_CONFIRM_TUTORIAL_SHARE:
            return {
                ...state,
                openTutorialShare: action.openTutorialShare,
            }
        case SEARCH_TYPE_REDUCER.OPEN_SHOW_CONFIRM_CART:
            return {
                ...state,
                isShowConfirmCart: action.isShowConfirmCart,
                title: action.title,
                status: action.status,
            }
        default:
            return { ...state }
    }
}

export default searchReducer
