import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import moment from 'moment'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import logo from '../../assets/images/logoDimuadi.svg'
import ErrorProduct from '../common/ErrorProduct'
import { IconNotification, IconNotificationBell } from '../../constants/icon-const'
import { PATH_NOTIFICATION } from '../../constants/common-contants'
import BreadcrumbNavigation from '../common/BreadcrumbNavigation'
import { getTimeMessage, isMaxTableScreen, isMobileScreen } from '../../functions/common-func'

function Notification({
    typeComponent,
    handleTypeComponent,
    listNoti,
    listNotiOrder,
    handleClickItemNotificationOrder,
}) {
    const convertShortDescription = (text) => {
        const shortDescription = text.replace(/<\/?[^>]+(>|$)/g, ' ')
        const arrayText = shortDescription.split(' ')
        return [...new Set(arrayText)].splice(0, 20).join(' ')
    }
    const options = [
        {
            label: 'Thông báo',
            active: true,
        },
    ]
    return (
        <>
            <BreadcrumbNavigation option={options} />

            <section className="notification">
                <BoxHeaderTitle text="Tin nhắn" link="/" />

                {isMobileScreen() && isMaxTableScreen() ? (
                    <div className="nav__menu">
                        <div
                            onClick={() => handleTypeComponent('order')}
                            className={`nav__item ${typeComponent === 'order' ? 'active' : ''}`}
                        >
                            <span>Đơn hàng</span>
                        </div>
                        <div
                            onClick={() => handleTypeComponent('notification')}
                            className={`nav__item ${typeComponent === 'notification' ? 'active' : ''}`}
                        >
                            <span>Thông báo</span>
                        </div>
                    </div>
                ) : (
                    <div className="nav__menu " style={{ padding: 15 }}>
                        <Button
                            className={`${typeComponent === 'order' ? 'active' : ''}`}
                            onClick={() => handleTypeComponent('order')}
                            style={{ marginRight: 15 }}
                            size="large"
                        >
                            Đơn hàng
                        </Button>
                        <Button
                            className={`${typeComponent === 'notification' ? 'active' : ''}`}
                            onClick={() => handleTypeComponent('notification')}
                            size="large"
                        >
                            Thông báo
                        </Button>
                    </div>
                )}
                {typeComponent === 'order' && (
                    <div className="notification__content">
                        <div className="container">
                            {/*<div className="notification__title">*/}
                            {/*    <h3>Mới nhất</h3>*/}
                            {/*    <div className="saw">*/}
                            {/*        <IconCheckSaw />*/}
                            {/*        Đánh dấu đọc hết*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="notification__list">
                                {Array.isArray(listNotiOrder) &&
                                    listNotiOrder?.length > 0 &&
                                    listNotiOrder.map((res) => {
                                        return (
                                            <div
                                                onClick={() => handleClickItemNotificationOrder(res)}
                                                key={res.id}
                                                className="notification__item"
                                            >
                                                <div className="item__logo">
                                                    <img src={logo} alt="logo Dimuadi" />
                                                </div>
                                                <div className="item__content active">
                                                    <div className="title">
                                                        <h4> {res.title}</h4>
                                                        {!res.isRead && <IconNotification />}
                                                    </div>
                                                    <div className="time">
                                                        {' '}
                                                        {/*{moment(res.createdAt).format('DD/MM/YYYY')}*/}
                                                        {getTimeMessage(res.createdAt)}
                                                    </div>
                                                    <p className="content">{res.content}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                {listNotiOrder?.length === 0 && (
                                    <div className="notification__content">
                                        <ErrorProduct text={'Bạn không có thông báo nào'} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {typeComponent === 'notification' && (
                    <div className="notification__content">
                        <div className="container">
                            <div className="notification__list">
                                {Array.isArray(listNoti) &&
                                    listNoti.length > 0 &&
                                    listNoti.map((res) => {
                                        return (
                                            <Link key={res.id} to={`${PATH_NOTIFICATION}/${res.notification_id}`}>
                                                <div className="notification__item">
                                                    <div className="item__logo">
                                                        <IconNotificationBell />
                                                    </div>
                                                    <div className="item__content active">
                                                        <div className="title">
                                                            <h4 className="bold">{res?.notification?.title}</h4>
                                                        </div>
                                                        <p className="content">
                                                            {convertShortDescription(res?.notification?.description)}...
                                                        </p>
                                                        <div className="time">
                                                            {/*{moment(res?.notification?.created_at).format('DD/MM/YYYY')}*/}
                                                            {getTimeMessage(res?.notification?.created_at)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    })}
                                {((listNoti && listNoti.length === 0) || !listNoti) && (
                                    <div className="notification__content">
                                        <ErrorProduct text={'Bạn không có thông báo nào'} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </>
    )
}
//
export default Notification
