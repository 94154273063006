import React, { useState } from 'react'
import LoginAccount from '../../component/login/LoginAccount'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { setSession } from '../../functions/common-func'
import { toggleShowLoading } from '../../redux/actions/home-actions'

function LoginAccountContainer({ history, dispatch }) {
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [isPublisher, setIsPublisher] = useState(true)
    const [validateUserName, setValidateUserName] = useState({ status: 'success', text: '' })
    const [validatePassword, setValidatePassword] = useState({ status: 'success', text: '' })

    localStorage.removeItem('cartCoupon');
    
    const clearValidator = () => {
        setValidateUserName({ status: 'success', text: '' })
        setValidatePassword({ status: 'success', text: '' })
    }

    const handleChangeName = (e) => {
        setUserName(e.target.value)
        clearValidator()
    }

    const handleChangePassword = (e) => {
        setPassword(e.target.value)
        clearValidator()
    }

    const onChangePublisher = (e) => {
        setIsPublisher(!isPublisher)
    }

    const handleLogin = () => {
        if (!userName) {
            setValidateUserName({ status: 'error', text: 'Vui lòng nhập tên tài khoản' })
            return
        }
        if (!password) {
            setValidateUserName({ status: 'error', text: 'Vui lòng nhập mật khẩu' })
            return
        }
        dispatch(toggleShowLoading(true))
        // if (ArrayUser.includes(userName) && ArrayPassword.includes(password)) {
        let data = {
            accessToken: '8OxT0zzZHAEFMDqNSp3hfcxJuJYZMP',
            userInfo: {
                user: {},
            },
        }
        data.userInfo.user.username = userName
        data.userInfo.user.email = 'dattrang1412@gmail.com'
        data.userInfo.user.images =
            'https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=1461332274062505&height=150&width=150&ext=1600243860&hash=AeR3N8q5IliR-u66'
        setSession('userInfo', data, 7)
        localStorage.setItem('role', JSON.stringify({ role: 2 }))
        dispatch(toggleShowLoading(false))
        window.location.href = '/'
        // } else {
        //     setValidateUserName({ status: 'error', text: 'Tài khoản hoặc mật khẩu không chính xác' })
        //     dispatch(toggleShowLoading(false))
        // }
    }

    return (
        <LoginAccount
            userName={userName}
            password={password}
            validateUserName={validateUserName}
            validatePassword={validatePassword}
            isPublisher={isPublisher}
            handleChangeName={handleChangeName}
            handleChangePassword={handleChangePassword}
            handleLogin={handleLogin}
            onChangePublisher={onChangePublisher}
        />
    )
}
const mapStateToProps = function (state) {
    return {
        toggleShare: state.homeReducer.toggleShare,
    }
}
export default withRouter(connect(mapStateToProps)(LoginAccountContainer))
