import React, { useEffect } from 'react'
import UserInfo from '../../component/user/UserInfo'
import { message } from 'antd'
import { removeSession } from '../../functions/common-func'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { setIsLoggedIn } from '../../redux/actions/home-actions'

function UserInfoContainer({ dispatch, history }) {
    const handleLogout = () => {
        removeSession('userInfo')
        dispatch(setIsLoggedIn(false))
        history.push('/login')
    }

    const handleShareWithMobile = async () => {
        if (navigator.share === undefined) {
            message.error('ÁDASDASD')
        } else {
            try {
                const shareData = {
                    title: 'Web Share Demo',
                    text: 'Wanted to share this with you',
                    url: 'https://josephkhan.me',
                }
                await navigator.share(shareData)
                message.success('Successfully sent share')
            } catch (error) {
                message.error('Error sharing: ' + error)
            }
        }
    }

    return <UserInfo handleLogout={handleLogout} handleShareWithMobile={handleShareWithMobile} />
}

const mapStateToProps = function (state) {
    return {}
}
export default withRouter(connect(mapStateToProps)(UserInfoContainer))
