import { LIST_PRODUCT_TYPE_REDUCER } from '../../constants/product-detail-const'

export const toggleOpenInfoProduct = (boolean) => {
    return {
        type: LIST_PRODUCT_TYPE_REDUCER.OPEN_INFO_ORDER,
        openInfoOrder: boolean,
    }
}

export const toggleOpenFilterProduct = (boolean) => {
    return {
        type: LIST_PRODUCT_TYPE_REDUCER.OPEN_FILTER_ORDER,
        openFilterOrder: boolean,
    }
}

export const toggleOpenExportProduct = (boolean) => {
    return {
        type: LIST_PRODUCT_TYPE_REDUCER.OPEN_EXPORT_ORDER,
        openExportOrder: boolean,
    }
}
