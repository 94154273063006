import ApiMicroService from './request-micro-service'

export const fetchListCategory = async () => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/category?page_size=12`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchListHighDiscount = async () => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/product/high-discount`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchListSuggestion = async (paramSearch) => {
    try {
        /*
        const results = await ApiMicroService.get(`/d2c-service/product/suggestion`, {
            params: paramSearch,
        })
         */
        const results = await ApiMicroService.get(`/d2c-service/product`, {
            params: paramSearch,
        })
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchListMostShared = async () => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/product/most-shared?page=1&page_size=8`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchListTop = async (params) => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/product/top`, {
            params: params,
        })
        return results.data
    } catch (e) {
        throw e
    }
}
export const fetchTop5 = async (page = 1, pageSize = 25) => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/product/top-by-position?page=${page}&page_size=${pageSize}`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchListBanner = async () => {
    try {
        const results = await ApiMicroService.get(`d2c-service/banner`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchReportHomePage = async () => {
    try {
        const results = await ApiMicroService.get(`d2c-service/report/homepage`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchDealHot = async (params) => {
    try {
        let strParams = '?position_home=1&page_size=7'
        if (params && params.page_size) {
            if (params.position_home) {
                strParams = `?position_home=${params.position_home}&page_size=${params.page_size}&page=${params.page}`
            } else {
                strParams = `?page_size=${params.page_size}&page=${params.page}`
            }
        }
        const results = await ApiMicroService.get(`d2c-service/deal-hot${strParams}`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchListDealHot = async (params) => {
    try {
        let strParams = '?has_top=false&page_size=30&page=1&sort=DESC'
        if (params) {
            strParams = `?has_top=false&page_size=${params.page_size}&page=${params.page}&sort=${params.sort}`
        }
        const results = await ApiMicroService.get(`d2c-service/deal-hot/lists${strParams}`)
        return results.data
    } catch (e) {
        throw e
    }
}
export const fetchCouponsHome = async () => {
    try {
        const resCoupons = await ApiMicroService.get(`d2c-service/coupon?page_size=3&sort=DESC&usable=on&is_hot=on`);
        return resCoupons.data;
    } catch (e) {}
}
export const fetchNomalCouponsHome = async (page_size) => {
    try {
        const resCoupons = await ApiMicroService.get(`d2c-service/coupon?page_size=${page_size}&sort=DESC&usable=on&is_hot=off`);
        return resCoupons.data;
    } catch (e) {}
}

export const fetchDealHotDetail = async (dealId) => {
    try {
        const results = await ApiMicroService.get(`d2c-service/deal-hot/${dealId}`)
        return results.data
    } catch (e) {
        throw e
    }
}
