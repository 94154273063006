import React, { useEffect } from 'react'
import 'swiper/swiper.scss'
import { connect } from 'react-redux'
import routes from '../../routes/router'
import { Route, Switch, withRouter } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import MessengerCustomerChat from 'react-messenger-customer-chat'

import {
    PAGE_NOT_LOGIN,
    PAGE_MENU_MOBILE,
    PATH_NOT_HEADER,
    PAGE_NOT_MENU_SIDEBAR,
    PATH_NOTIFICATION,
} from '../../constants/common-contants'
import { setIsLoggedIn } from '../../redux/actions/home-actions'
import { getPublisherSession, getSession, isMaxTableScreen, isMobileScreen } from '../../functions/common-func'
import Loading from '../common/Loading'
import Header from './Header'
import MenuMobile from './MenuMobile'
import HeaderDesktop from './HeaderDesktop'
import MenuSideBar from './MenuSideBar'
// import HeaderDesktopBuyer from './HeaderDesktopBuyer'

function App({
    isLoggedIn,
    dispatch,
    toggleShare,
    isEditUserInfo,
    isUserInfo,
    isTutorial,
    isPublisher,
    history,
    isShowMenuSidebar,
}) {
    let user = getSession('userInfo');
    const { pathname } = useLocation()
    const isShare = toggleShare ? toggleShare.isShare : false
    const _publisher = getPublisherSession()

    useEffect(() => {
        if (typeof isLoggedIn === 'undefined') {
            dispatch(setIsLoggedIn(!!(user && user.userInfo)))
        }
    }, [isLoggedIn, pathname])

    useEffect(() => {
        if (!_publisher) {
            localStorage.setItem('role', JSON.stringify({ role: 2 }))
        }
    }, [])
    return (
        <div className="wrapper">
            <Loading />
            {PATH_NOT_HEADER.includes(pathname) && !pathname.startsWith('/product-detail') && <Header />}
            {!PAGE_NOT_MENU_SIDEBAR.includes(pathname) && <MenuSideBar />}
            <div
                className={`page__wrapper ${isShowMenuSidebar ? '' : 'collapsed'} ${
                    PAGE_NOT_MENU_SIDEBAR.includes(pathname) ? 'not__sidebar' : ''
                }`}
            >
                {!PAGE_NOT_MENU_SIDEBAR.includes(pathname) && <HeaderDesktop />}
                {/*{isPublisher !== 1 && !(isMobileScreen() && isMaxTableScreen()) && <HeaderDesktopBuyer />}*/}

                <div className="page__body">
                    <div className="page__content">
                        <Switch>
                            {routes.map(({ component: Component, name, exact, path }) => {
                                return (
                                    <Route
                                        key={name}
                                        path={path}
                                        exact={exact}
                                        render={(routeProps) => {
                                            return <Component {...routeProps} />
                                            if (isLoggedIn) {
                                                return <Component {...routeProps} />
                                            } else {
                                                if (PAGE_NOT_LOGIN.includes(name) || isPublisher !== 1) {
                                                    return <Component {...routeProps} />
                                                } else {
                                                    // return <Redirect to={{ pathname: '/login' }} />
                                                    history.push('/login')
                                                }
                                            }
                                        }}
                                    />
                                )
                            })}
                        </Switch>
                        {/*<YourCartContainer />*/}
                    </div>
                </div>
            </div>

            {PAGE_MENU_MOBILE.includes(pathname) &&
                // !pathname.startsWith('/product') &&
                !pathname.startsWith('/list-product') &&
                !pathname.startsWith('/thanh-toan') &&
                // !pathname.startsWith('/address/edit') &&
                !isEditUserInfo &&
                !isTutorial &&
                !isShare &&
                !isUserInfo && <MenuMobile />}
            {!(isMobileScreen() && isMaxTableScreen()) && (
                <MessengerCustomerChat
                    pageId={process.env.REACT_APP_PUBLIC_FACEBOOK_PAGE_ID}
                    appId={process.env.REACT_APP_PUBLIC_FACEBOOK_ID}
                />
            )}
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        isLoggedIn: state.homeReducer.isLoggedIn,
        isPublisher: state.homeReducer.isPublisher,
        isEditUserInfo: state.accountReducer.isEditUserInfo,
        isTutorial: state.accountReducer.isTutorial,
        toggleShare: state.homeReducer.toggleShare,
        isShowMenuSidebar: state.homeReducer.isShowMenuSidebar,
        isUserInfo: state.accountReducer.isUserInfo,
    }
}
export default withRouter(connect(mapStateToProps)(App))
