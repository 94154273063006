export const PAGE_NOT_LOGIN = [
    'Login',
    'Login Account',
    'Register',
    'Forgot Password',
    'Dieu khoan su dung',
    'Chinh Sach Bao Mat',
    'Privacy policy',
    'Home',
    'List Order',
    'Product Detail',
]
export const PAGE_MENU_MOBILE = [
    '/',
    '/list-order',
    '/your-card',
    '/account',
    '/list-order',
    '/dashboard',
    '/list-category',
    '/notification',
]

export const HOT_LINE = '024 6662 2447'

export const optionNavigationCheckout1 = [
    {
        label: 'Tạo đơn',
        active: true,
    },
    {
        label: '>',
    },
    {
        label: 'Kiểm tra',
    },
    {
        label: '>',
    },
    {
        label: 'Hoàn thành',
    },
]

export const PATH_PRODUCT_CATEGORY = '/list-product'
export const PATH_PRODUCT_PREVIEW = '/product/preview/:id'
export const PATH_PRODUCT_DETAIL = '/product/:id'
export const PATH_DEAL_HOT = '/deal-hot/:id'
export const PATH_DEAL_HOT_LIST = '/deal-list'
export const PATH_DEAL = '/deal-hot'
export const PATH_PRODUCT = '/product'
export const PATH_NOTIFICATION = '/notification'
export const PATH_NOTIFICATION_DETAIL = '/notification/:id'

export const IMAGE_LOGO = 'https://dimuadi.vn/_static/img/common/logo-d2c.png'
export const PATH_COUPONS = '/coupons';

export const PAGE_NOT_MENU_SIDEBAR = ['/login-account', '/login', '/register']
export const EXPRESSTIME = 86400000
export const PATH_NOT_HEADER = ['/']
export const DEFAULT_NUMBER_PAGE_DSIPLAY = 3
export const PAGE_SIZE_HOTEL_LIST = 20

export const CHECK_OUT_TYPE_REDUCER = {
    OPEN_CHANGE_ADDRESS: 'OPEN_CHANGE_ADDRESS',
    OPEN_ADD_ADDRESS: 'OPEN_ADD_ADDRESS',
    OPEN_ACTION__ADDRESS: 'OPEN_ACTION_ADDRESS',
    SET_ID_ADDRESS: 'SET_ID_ADDRESS',
    SET_IS_ADDRESS: 'SET_IS_ADDRESS',
    OPEN_CONFIRM_DELETE: 'OPEN_CONFIRM_DELETE',
}

export const URL_THUMBNAIL = {
    URL_AIRTABLE: 'https://dl.airtable.com',
    URL_DOMAIN_DMD: 'https://dimuadi.vn',
}
export const DEFAULT_IMAGE = 'https://dimuadi.vn/_static/img/common/image_faildoad.png'
