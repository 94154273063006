import React from 'react'
import { Link } from 'react-router-dom'
import { GoogleLogin } from 'react-google-login'
import { Button } from 'antd'
import { IconLoginGg } from '../../constants/icon-const'
import IMAGE_LOGO from '../../assets/images/logoDimuadi.svg'

import BoxHeaderTitle from '../common/BoxHeaderTitle'
import RegisterPopup from '../user/RegisterPopup'
function Login(props) {

    return (
        <section className="home-login">
            <BoxHeaderTitle text={'Đăng nhập'} link="/" />
            <div className="container">
                <div className="home-login__content">
                    <div className="header-logo">
                        <Link to={'/'}>
                            <img src={IMAGE_LOGO} alt="logo d2c" />
                        </Link>
                    </div>
                    <div className="login-title">
                        <h2>Đăng nhập</h2>
                        <h2>TÀI KHOẢN PUBLISHER</h2>
                        <p>Bạn còn chưa bắt đầu với Dimuadi? Chần chờ gì mà không đẩy số ngay hôm nay.</p>
                    </div>
                    <div className="login-form">
                        <div className="form-group">
                            <a onClick={props.handleLoginAccessTrade}>
                                <Button className="w-100 size-48" type="primary">
                                    Đăng nhập qua ACCESSTRADE
                                </Button>
                            </a>
                        </div>
                        <div className="login-line">
                            <span>Hoặc</span>
                        </div>

                        <div className="form-group">
                            <Button onClick={props.handleLoginGoogle2} className="btn_sign_in w-100 size-48">
                                Đăng nhập bằng Google
                            </Button>
                            <div className="input-icon">
                                <IconLoginGg />
                            </div>
                        </div>

                        {/*
                        <div className="form-group">
                            <Button onClick={handleLoginFacebook} className="btn_sign_in w-100 size-48">
                                Đăng nhập bằng Facebook
                            </Button>
                            <div className="input-icon">
                                <svg
                                    width={20}
                                    height={20}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon-btn-sign-in"
                                >
                                    <circle cx={12} cy={12} r={12} fill="#1976D2" />
                                    <path
                                        d="M10.26 24V14.5H7V11H10.26V8.4125C10.26 5.3125 12.153 3.625 14.918 3.625C16.243 3.625 17.381 3.7235 17.713 3.7675V7.0075L15.795 7.008C14.291 7.008 14 7.723 14 8.7715V11H17.5L17 14.5H14V24H10.26Z"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </div>

                        <GoogleLogin
                            render={(renderProps) => (
                                <div className="form-group">
                                    <Button onClick={renderProps.onClick} className="btn_sign_in w-100 size-48">
                                        <span>Đăng nhập bằng Google</span>
                                    </Button>
                                    <div className="input-icon">
                                        <IconLoginGg />
                                    </div>
                                </div>
                            )}
                            // clientId={'168991660506-nb7uijq21rge2qlkh3u3qt6sf4q8qhjm.apps.googleusercontent.com'}
                            clientId={process.env.REACT_APP_NEXT_PUBLIC_GOOGLE_CLIENT_ID}
                            buttonText="Đăng nhập bằng Google"
                            onSuccess={(response) => handleLoginGoogle(response)}
                        />

                        <p>
                            <Link to="/"> Bỏ qua đăng nhập </Link>
                        </p>
                        */}

                        {/*<div className="form-group">*/}
                        {/*    <Button className="btn_sign_in w-100 size-48"> Đăng nhập bằng Apple ID </Button>*/}
                        {/*    <div className="input-icon">*/}
                        {/*        <svg*/}
                        {/*            width={14}*/}
                        {/*            height={18}*/}
                        {/*            viewBox="0 0 14 18"*/}
                        {/*            fill="none"*/}
                        {/*            xmlns="http://www.w3.org/2000/svg"*/}
                        {/*        >*/}
                        {/*            <path*/}
                        {/*                d="M11.5975 9.46333C11.5892 8.13083 12.1933 7.12667 13.4125 6.38667C12.7308 5.41 11.6992 4.87333 10.34 4.77C9.05252 4.66833 7.64418 5.52 7.12918 5.52C6.58418 5.52 5.33752 4.805 4.35752 4.805C2.33335 4.83583 0.18335 6.41833 0.18335 9.63667C0.18335 10.5883 0.357516 11.57 0.705016 12.5817C1.17002 13.9142 2.84585 17.1775 4.59418 17.125C5.50752 17.1033 6.15418 16.4767 7.34418 16.4767C8.49835 16.4767 9.09585 17.125 10.115 17.125C11.8792 17.1 13.3942 14.1333 13.8358 12.7967C11.4708 11.6817 11.5975 9.53083 11.5975 9.46333ZM9.54502 3.50583C10.535 2.33083 10.445 1.26 10.4158 0.875C9.54085 0.925 8.52918 1.47083 7.95335 2.14083C7.31835 2.85917 6.94502 3.74833 7.02502 4.75C7.97085 4.8225 8.83335 4.33583 9.54502 3.50583Z"*/}
                        {/*                fill="black"*/}
                        {/*            />*/}
                        {/*        </svg>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<p className="tip-login">*/}
                        {/*    Bạn đã có tài khoản?*/}
                        {/*    <Link to="/login-account" className="sign-in">*/}
                        {/*        {' '}*/}
                        {/*        Đăng nhập*/}
                        {/*    </Link>*/}
                        {/*</p>*/}
                        <p className="term">
                            Bằng việc đăng ký, bạn đồng ý với
                            <br />
                            <Link to="/dieu-khoan">Điều khoản dịch vụ</Link> và{' '}
                            <Link to="/chinh-sach">Chính sách bảo mật</Link>
                        </p>
                    </div>
                </div>
            </div>
            {
                props.isShowPopupRegister &&
                <RegisterPopup {...props}
                />
            }
            
        </section>
        
    )
}

export default Login
