import React from 'react'

const PriceRange = (props) => {
    const { data, isContactToBuy, onWholeSale } = props
    const { price, fromQty, toQty, note } = data
    if (isContactToBuy) {
        return (
            <div className="column_price_range">
                <h4 className="text_line_throught" onClick={onWholeSale}>
                    Liên hệ
                </h4>
                <p className="text_header"> {`> ${fromQty - 1}`} sản phẩm </p>
            </div>
        )
    }
    return (
        <>
            {price !== null && (
                <div className="column_price_range">
                    <h4 className="text_header">{price.toLocaleString()}₫</h4>
                    {toQty !== null && (
                        <p className="text_header">
                            {fromQty} - {toQty} sản phẩm
                        </p>
                    )}
                    {toQty === null && price !== null && <p className="text_header"> &gt; {fromQty - 1} sản phẩm</p>}
                </div>
            )}
            {price === null && (
                <div className="column_price_range">
                    <p className="text_header"> {note}</p>
                </div>
            )}
        </>
    )
}
export default PriceRange
