import { CREATE_ORDER_TYPE_REDUCER } from '../../constants/home-contants'

export const toggleShowBooker = (boolean) => {
    return {
        type: CREATE_ORDER_TYPE_REDUCER.BOOKER_FORM,
        isBooker: boolean,
    }
}

export const toggleShowNotBuy= (boolean) => {
    return {
        type: CREATE_ORDER_TYPE_REDUCER.SHOW_NOT_BUY,
        isShowNotBuy: boolean,
    }
}
