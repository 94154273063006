import React from 'react'
// import { Link } from 'react-router-dom'
import { checkURLImage } from '../../functions/common-func'
import { DEFAULT_IMAGE, PATH_PRODUCT } from '../../constants/common-contants'
import DisplayPrice from '../common/DisplayPrice'
function HomeSuperDiscountAll({ listHighDiscount }) {
    const itemBottom =
        listHighDiscount &&
        listHighDiscount?.length > 0 &&
        listHighDiscount?.filter((res, index) => {
            return index > 2 && index < 6
        })
    return (
        <div className="home__super_discount__all">
            {listHighDiscount?.length > 0 && (
                <div className="container">
                    <div className="home__superDiscountAll__content">
                        <div className="item__discount__big item__discount">
                            <a href={`${PATH_PRODUCT}/${listHighDiscount[0].id}`}>
                                <img src={checkURLImage(listHighDiscount[0].thumbImage) || DEFAULT_IMAGE} alt="" />
                                <p className="chiet-khau">
                                    <span>
                                        <DisplayPrice price={listHighDiscount[0].price} />
                                    </span>
                                </p>
                                {listHighDiscount[0].discountAmount && (
                                    <div className="discount">
                                        <span>{listHighDiscount[0].discountAmount}%</span>
                                    </div>
                                )}
                            </a>
                        </div>
                        <div className="item__discount__small">
                            <div className="item__discount">
                                <a href={`${PATH_PRODUCT}/${listHighDiscount[1].id}`}>
                                    <img src={checkURLImage(listHighDiscount[1].thumbImage) || DEFAULT_IMAGE} alt="" />
                                    <p className="chiet-khau">
                                        <span>
                                            {' '}
                                            <DisplayPrice price={listHighDiscount[1].price} />
                                        </span>
                                    </p>
                                    {listHighDiscount[1] && listHighDiscount[1].discountAmount && (
                                        <div className="discount">
                                            <span>{listHighDiscount[1].discountAmount}%</span>
                                        </div>
                                    )}
                                </a>
                            </div>
                            <div className="item__discount">
                                <a href={`${PATH_PRODUCT}/${listHighDiscount[2].id}`}>
                                    <img src={checkURLImage(listHighDiscount[2].thumbImage) || DEFAULT_IMAGE} alt="" />
                                    <p className="chiet-khau">
                                        <span>
                                            {' '}
                                            <DisplayPrice price={listHighDiscount[2].price} />
                                        </span>
                                    </p>
                                    {listHighDiscount[2] && listHighDiscount[2].discountAmount && (
                                        <div className="discount">
                                            <span>{listHighDiscount[2].discountAmount}%</span>
                                        </div>
                                    )}
                                </a>
                            </div>
                        </div>
                        {itemBottom.length > 0 &&
                            itemBottom.map((res) => {
                                return (
                                    <div key={res.id} className="item__discount__small item__discount">
                                        <a href={`${PATH_PRODUCT}/${res.id}`}>
                                            <img src={checkURLImage(res.thumbImage) || DEFAULT_IMAGE} alt="" />
                                            <p className="chiet-khau">
                                                <span>
                                                    <DisplayPrice price={res.price} />
                                                </span>
                                            </p>
                                            {res.discountAmount > 0 && (
                                                <div className="discount">
                                                    <span>{res.discountAmount}%</span>
                                                </div>
                                            )}
                                        </a>
                                    </div>
                                )
                            })}
                    </div>
                </div>
            )}
        </div>
    )
}

export default HomeSuperDiscountAll
