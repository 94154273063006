import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, Input, Switch } from 'antd'
import logo from '../../assets/images/logoDimuadi.svg'
import { UserOutlined, KeyOutlined } from '@ant-design/icons'

function LoginAccount({
    userName,
    password,
    handleChangePassword,
    handleChangeName,
    handleLogin,
    validatePassword,
    validateUserName,
    isPublisher,
    onChangePublisher,
}) {
    return (
        <section className="home-login">
            <div className="container">
                <div className="home-login__content">
                    <div className="header-logo">
                        <img src={logo} alt="logo d2c" />
                    </div>
                    <div className="login-title">
                        <h2>Đăng nhập</h2>
                    </div>
                    <div className="login-form">
                        <Form name="basic" initialValues={{ remember: true }}>
                            <Form.Item
                                name="username"
                                className="el-input__inner form-group"
                                validateStatus={validateUserName.status}
                                help={validateUserName.text}
                            >
                                <Input
                                    placeholder="Tên đăng nhập / email"
                                    value={userName}
                                    style={{ textAlign: 'left' }}
                                    prefix={<UserOutlined />}
                                    onChange={handleChangeName}
                                />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                validateStatus={validatePassword.status}
                                help={validatePassword.text}
                                className="el-input__inner form-group"

                            >
                                <Input.Password
                                    placeholder="Mật khẩu"
                                    value={password}
                                    prefix={<KeyOutlined />}
                                    onChange={handleChangePassword}
                                />
                            </Form.Item>
                            <Form.Item valuePropName="checked">
                                <label style={{ marginRight: 10 }}>Publisher</label>
                                <Switch
                                    onChange={onChangePublisher}
                                    checked={isPublisher}
                                    style={{ textAlign: 'left' }}
                                />
                            </Form.Item>
                            <div className="form-password">
                                <a className="link-account">Nhận lại link kích hoạt tài khoản</a>
                                <Link to="/forgot-password" className="forget-pass">
                                    Quên mật khẩu
                                </Link>
                            </div>
                            <Form.Item>
                                <Button onClick={handleLogin} className="btn-sign-in w-100 size-48" type="primary">
                                    Đăng nhập
                                </Button>
                            </Form.Item>
                        </Form>
                        <p className="tip-login">
                            Bạn chưa có tài khoản?{' '}
                            <Link to="/register" className="sign-in">
                                Đăng ký
                            </Link>
                        </p>
                        <p className="login__other">
                            <Link to="/login">Tùy chọn đăng nhập khác</Link>
                        </p>

                        <p className="term">
                            Bằng việc đăng ký, bạn đồng ý với
                            <br />
                            <a>Điều khoản dịch vụ</a> và <a>Chính sách bảo mật</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default LoginAccount
