import React from 'react'
import imageDefault from '../../assets/images/image_default_d2c.jpg'
import { DownOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { toggleOpenDirectory } from '../../redux/actions/search-action'
import { isMobileScreen } from '../../functions/common-func'

function HotDirectory({ openDirectory, dispatch, listCategory }) {
    const indexItem = isMobileScreen() ? 3 : 6
    const arrayList = listCategory && listCategory.filter((res, index) => index < indexItem)
    return (
        <div className={`search__product__item hot__directory ${openDirectory ? 'open' : ''}`}>
            <div className="container">
                <h2 className="title">Danh mục phổ biến</h2>
                <div className="list__directory">
                    {Array.isArray(listCategory) &&
                        listCategory.length > 0 &&
                        arrayList.map((res) => {
                            return (
                                <div key={res.id} className="directory__item">
                                    <Link to={`/list-product?category_id=${res.id}`}>
                                        <div className="icon">
                                            <img src={res.image || imageDefault} alt="" />
                                        </div>
                                        <p>{res.name}</p>
                                    </Link>
                                </div>
                            )
                        })}
                </div>
                <div className="see__more" onClick={() => dispatch(toggleOpenDirectory(true))}>
                    <p>Xem thêm {listCategory.length} danh mục</p>
                    <DownOutlined />
                </div>
                <div className="box__all__directory">
                    <div className="box__all__content">
                        <div className="box__all__main">
                            <h2>Danh mục sản phẩm</h2>
                            <div className="list__directory">
                                {Array.isArray(listCategory) &&
                                    listCategory.length > 0 &&
                                    listCategory.map((res) => {
                                        return (
                                            <div
                                                key={res.id}
                                                className="directory__item"
                                                onClick={() => dispatch(toggleOpenDirectory(false))}
                                            >
                                                <Link to={`/list-product?category_id=${res.id}`}>
                                                    <div className="icon">
                                                        <img src={res.image || imageDefault} alt="" />
                                                    </div>
                                                    <p>{res.name}</p>
                                                </Link>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className="box__backdrop" onClick={() => dispatch(toggleOpenDirectory(false))}></div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        openDirectory: state.searchReducer.openDirectory,
    }
}
export default withRouter(connect(mapStateToProps)(HotDirectory))
