import React from 'react'
import { useSelector } from 'react-redux'
import Report from '../../component/report/Report'

function ReportContainer() {
    const { isLoggedIn } = useSelector((state) => {
        return {
            isLoggedIn: state.homeReducer.isLoggedIn,
        }
    })

    // if(!isLoggedIn) {
    //     window.location = `${window.location.origin}/login`;
    // }
    localStorage.removeItem('cartCoupon');
    return <Report />
}

export default ReportContainer
