import React from 'react'
import Slider from 'react-slick/lib'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Autoplay])

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
        {...props}
        className={'slick-prev slick-arrow show-item' + (currentSlide === 0 ? ' slick-disabled' : '')}
        aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
        type="button"
    >
        <LeftOutlined />
    </button>
)
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
        {...props}
        className={'slick-next slick-arrow show-item' + (currentSlide === slideCount - 1 ? ' slick-disabled' : '')}
        aria-hidden="true"
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
        type="button"
    >
        <RightOutlined />
    </button>
)

const settingsSlide = {
    arrow: true,
    autoplay: true,
    autoplaySpeed: 1500,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
    nextArrow: <SlickArrowLeft />,
    prevArrow: <SlickArrowRight />,
    className: `banner__home__content`,
}

function HomeBanner({ attrClass, listBanner, isMobile }) {
    function getBannerPcRender() {
        return (
            <div className={attrClass}>
                {listBanner && listBanner.publisherHomeMktSlide && listBanner.publisherHomeMktSlide.length > 0 && (
                    <Slider {...settingsSlide}>
                        {listBanner.publisherHomeMktSlide.map((slide) => (
                            <a href={slide.url} key={slide.title}>
                                <img src={slide.image} alt={slide.title} />
                            </a>
                        ))}
                    </Slider>
                )}
            </div>
        )
    }

    function getBannerMobRender() {
        return (
            <Swiper id="box__banner" slidesPerView={1} autoplay loop={true}>
                {listBanner &&
                    listBanner.publisherHomeMktSlide &&
                    listBanner.publisherHomeMktSlide.length > 0 &&
                    listBanner.publisherHomeMktSlide.map((slide, index) => (
                        <SwiperSlide key={`slide-${index}`}>
                            <a href={slide.url} key={slide.title}>
                                <img src={slide.imageMobile || slide.image} alt={slide.title} />
                            </a>
                        </SwiperSlide>
                    ))}
            </Swiper>
        )
    }

    return isMobile ? getBannerMobRender() : getBannerPcRender()
}

export default HomeBanner
