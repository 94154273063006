import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { PATH_PRODUCT } from '../../constants/common-contants'
import { formatCurrency, convertCommission, isMobileScreen, checkMinMax } from '../../functions/common-func'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import { Button } from 'antd'
import { fetchProductInDeal } from '../../api-services/product-api'
import swal from 'sweetalert'
import TopProduct  from '../common/TopProduct/TopProduct'
function getItemText(title, description = '', isNoPadding = true) {
    return (
        <div className="card">
            <div className={'card__item ' + (isNoPadding ? 'no-padding' : '')}>
                <p className="condition_apply">{title}</p>
                {description !== '' && <p>{description}</p>}
            </div>
        </div>
    )
}

function convertDescrition(data) {
    return data.replace('\n', '').replace('<br><br>', '<br>')
}

function DealHot({ dealInfo, products, topProducts, history }) {
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    const [dataProduct, setDataProduct] = useState([])
    let lockLoadMore = false

    useEffect(() => {
        if (products) {
            setDataProduct(products.data)
            setPage(products.paging.page)
            setTotalPage(products.paging.total)
        }
    }, [products])

    const onClose = () => {
        if (history && history.length > 1) {
            history.goBack()
        } else {
            window.location.href = '/'
        }
    }

    const handleSeeMore = (page) => {
        if (!lockLoadMore && page < products.paging.total) {
            if (page < products.paging.total) {
                page = Number(page) + 1
                setPage(page)
                getProduct(page)
            }
        } else {
            swal({
                text: 'Data đang xử lí!',
                icon: 'info',
                showCancelButton: false,
                showConfirmButton: false,
                buttons: false,
                toast: true,
                timer: 1000,
            })
        }
    }

    const getProduct = async (page) => {
        try {
            lockLoadMore = true
            const data = await fetchProductInDeal(dealInfo.id, page)
            if (data && data.data.length > 0) {
                setDataProduct(dataProduct.concat(data.data))
            }
            lockLoadMore = false
        } catch (e) {
            throw e
        }
    }

    return (
        <div className="wrapper__deal__hot">
            <BoxHeaderTitle text="Chi tiết deal hot" onClose={onClose} isBell={true} />
            <div className="content__deal__hot">
                <div className="row">
                    <div className="col-12 col-md-8">
                        {dealInfo && (
                            <div className="deal__hot__info">
                                <div className="card">
                                    <div className="frame-image">
                                        <img src={dealInfo.avatarDetail[0]} alt={dealInfo.title} />
                                    </div>

                                    <div className="card__item">
                                        <p className="label">{dealInfo.title}</p>
                                        <p
                                            dangerouslySetInnerHTML={{
                                                __html: convertDescrition(dealInfo.description || ''),
                                            }}
                                        ></p>
                                    </div>
                                </div>

                                {dataProduct && dataProduct.length > 0 && getItemText('Sản phẩm áp dụng:')}

                                <div className="row">
                                    {products &&
                                        dataProduct &&
                                        dataProduct.length > 0 &&
                                        dataProduct.map((prod) => {
                                            return (
                                                <div
                                                    className="col-xs-6 col-sm-3 col-md-3"
                                                    key={'prod_item_' + prod.id + Math.floor(Math.random() * 100)}
                                                >
                                                    <div className="product__item">
                                                        <Link className="" to={PATH_PRODUCT + '/' + prod.id}>
                                                            <img src={prod.thumb_image} alt={prod.name} />
                                                            {prod.status === 3 && (
                                                                <div className="bg_out_of_stock"><span>Tạm hết hàng</span></div>
                                                            )}
                                                        </Link>
                                                        <p className="eclips-text">{prod.name}</p>
                                                        {!prod.rangePrice && <p>{formatCurrency(prod.price)}₫</p>}
                                                        {prod.rangePrice && <p>{checkMinMax(prod.rangePrice.minPrice, prod.rangePrice.maxPrice)}</p>}

                                                        {!prod.rangePrice && <p>
                                                            <span>Hoa hồng:</span>
                                                            {formatCurrency(
                                                                convertCommission(
                                                                    prod.price,
                                                                    prod.commission_value,
                                                                    prod.commission_type
                                                                )
                                                            )}
                                                            đ
                                                        </p> }
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>

                                {dataProduct && dataProduct.length > 0 && dataProduct.length < totalPage && (
                                    <div className="text-center">
                                        <Button
                                            onClick={() => {
                                                handleSeeMore(page)
                                            }}
                                            size="large"
                                            className="btn_choose_blue btn_load_more_deal"
                                        >
                                            Xem thêm
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="top__product">
                           {!isMobileScreen() && <TopProduct />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DealHot
