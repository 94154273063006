import React, { useState, useEffect } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { Button, Checkbox, message, Modal } from 'antd'
import {
    copyToClipboard,
    getConfirmTutorialShare,
    isMobileScreen,
    saveConfirmTutorialShare,
} from '../../../functions/common-func'
import { togglePopupShare } from '../../../redux/actions/home-actions'
import { useDispatch, useSelector } from 'react-redux'

function BoxConfirmTutorialShare({ onClose, contentShare, dataDetail, deepLink }) {
    const [check, setCheck] = useState(false)
    const dispatch = useDispatch()
    const { openTutorialShare } = useSelector((state) => {
        return {
            openTutorialShare: state.searchReducer.openTutorialShare,
        }
    })
    useEffect(() => {
        let checkTutorial = getConfirmTutorialShare()
        if (checkTutorial) {

            setCheck(checkTutorial.check)
        }
    }, [])
    const onChange = (e) => {
        setCheck(e.target.checked)
    }

    const handleContinue = async (boolean, type, title) => {
        if (check) {
            saveConfirmTutorialShare({ check })
        }
        if (contentShare && contentShare.content) {
            copyToClipboard(contentShare.content + ' ' + deepLink)
            message.success('Nội dung đã được sao chép')
        }
        onClose()

        dispatch(togglePopupShare(boolean, type, title))
        /*
        if (navigator.share === undefined) {
            dispatch(togglePopupShare(boolean, type, title))
        } else {
            try {
                let shareData = {}
                if (type === 'link') {
                    shareData = {
                        title: dataDetail.name,
                        text: dataDetail.name || '',
                        url:
                            dataDetail && dataDetail.videos && dataDetail.videos.length > 0
                                ? dataDetail.videos[0]
                                : dataDetail.thumbImage,
                    }
                }
                await navigator.share(shareData)
                message.success('Chia sẻ thành công')
            } catch (error) {
                throw error
            }
        }
         */
    }

    return (
        <>
            {isMobileScreen() ? (
                <div className={`box__confirm__tutorial ${openTutorialShare ? 'open' : ''}`}>
                    <div className="box__confirm__tutorial__content">
                        <div className="box__title">
                            <h2> Thông tin đã được sao chép </h2>
                            <div className="box__close" onClick={onClose}>
                                <CloseOutlined />
                            </div>
                        </div>
                        <div className="box__confirm__tutorial__main">
                            <p>Hãy dán lên tường và tùy chỉnh trước khi chia sẻ</p>
                            <Checkbox checked={check} onChange={onChange}>
                                Không hiển thị thông báo này nữa
                            </Checkbox>
                            <div className="btn__group">
                                <Button
                                    onClick={() => handleContinue(true, 'link', 'Chia sẻ sản phẩm')}
                                    type="primary"
                                    className="w-100"
                                    size="large"
                                >
                                    Tiếp tục
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="box__backdrop" onClick={onClose}></div>
                </div>
            ) : (
                <Modal
                    className="box__confirm__tutorial"
                    onCancel={onClose}
                    footer={null}
                    title={'Thông tin đã được sao chép'}
                    visible={openTutorialShare}
                >
                    <div className="box__confirm__tutorial__main">
                        <p>Hãy dán lên tường và tùy chỉnh trước khi chia sẻ</p>
                        <Checkbox checked={check} onChange={onChange}>
                            Không hiển thị thông báo này nữa
                        </Checkbox>
                        <div className="btn__group">
                            <Button
                                onClick={() => handleContinue(true, 'link', 'Chia sẻ sản phẩm')}
                                type="primary"
                                className="w-100"
                                size="large"
                            >
                                Tiếp tục
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}

export default BoxConfirmTutorialShare
