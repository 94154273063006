import { LIST_PRODUCT_TYPE_REDUCER } from '../../constants/product-detail-const'

const listOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case LIST_PRODUCT_TYPE_REDUCER.OPEN_INFO_ORDER:
            return {
                ...state,
                openInfoOrder: action.openInfoOrder,
            }
        case LIST_PRODUCT_TYPE_REDUCER.OPEN_FILTER_ORDER:
            return {
                ...state,
                openFilterOrder: action.openFilterOrder,
            }
        case LIST_PRODUCT_TYPE_REDUCER.OPEN_EXPORT_ORDER:
            return {
                ...state,
                openExportOrder: action.openExportOrder,
            }
        default:
            return { ...state }
    }
}

export default listOrderReducer
