import React from 'react'
import { SORT_ITEM } from '../../constants/search-const'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { toggleSortProduct } from '../../redux/actions/search-action'

function BoxSortProduct({ isSort, handleChangeSort, openSortProduct, dispatch }) {
    return (
        <div className={`box__sort__product ${openSortProduct ? 'open' : ''}`}>
            <div className="box__sort__product__content">
                <div className="box__sort__product__main">
                    <div className="list__sort">
                        <div className="list__sort__item">
                            {SORT_ITEM.map((res) => {
                                return (
                                    <div
                                        onClick={() => handleChangeSort(res)}
                                        key={res.id}
                                        className={`sort__item ${isSort && isSort.value === res.value ? 'active' : ''}`}
                                    >
                                        <p>{res.label}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="box__backdrop" onClick={() => dispatch(toggleSortProduct(false))}></div>
        </div>
    )
}


const mapStateToProps = function (state) {
    return {
        openSortProduct: state.searchReducer.openSortProduct,
    }
}
export default withRouter(connect(mapStateToProps)(BoxSortProduct))
