import React from 'react'
import { Link, useLocation, withRouter } from 'react-router-dom'
import {
    PieChartFilled,
    ShopFilled,
    HddFilled,
    UserOutlined,
    ShoppingOutlined,
    NotificationOutlined,
    // ShoppingCartOutlined,
    AppstoreOutlined,
} from '@ant-design/icons'
import { connect } from 'react-redux'

function MenuMobile({ isPublisher, history, isLoggedIn }) {
    const { pathname } = useLocation()
    const handleClick = (link) => {
        if (isLoggedIn) {
            history.push(link)
        } else {
            history.push('/login')
        }
    }
    return (
        <div className="menu__mobile">
            <div className="menu__mobile__content">
                {isPublisher === 1 ? (
                    <div className="list__menu">
                        <div className={`menu__item ${['/'].includes(pathname) ? 'active' : ''}`}>
                            <Link to="/">
                                <div className="d-block" onClick={() => handleClick('/')}>
                                    <ShopFilled style={{ color: '#8C8C8C' }} />
                                </div>
                                <p>Bán hàng</p>
                            </Link>
                        </div>
                        <div className={`menu__item ${['/dashboard'].includes(pathname) ? 'active' : ''}`}>
                            <Link to="/dashboard">
                                <div className="d-block">
                                    <PieChartFilled style={{ color: '#8C8C8C' }} />
                                </div>
                                <p>Tổng quan</p>
                            </Link>
                        </div>
                        <div className="menu__item menu__item__home">
                            <Link to="/choose-product">
                                <div className="d-block">
                                    <ShoppingOutlined style={{ color: '#ffffff', width: 30 }} />
                                </div>
                            </Link>
                        </div>
                        <div className={`menu__item ${['/list-order'].includes(pathname) ? 'active' : ''}`}>
                            <Link to="/list-order">
                                <div className="d-block">
                                    <HddFilled style={{ color: '#8C8C8C' }} />
                                </div>
                                <p>Đơn hàng</p>
                            </Link>
                        </div>
                        <div className={`menu__item ${['/account'].includes(pathname) ? 'active' : ''}`}>
                            <Link to="/account">
                                <div className="d-block">
                                    <UserOutlined style={{ color: '#8C8C8C' }} />
                                </div>
                                <p>Tài khoản</p>
                            </Link>
                        </div>
                    </div>
                ) : (
                    <div className="list__menu">
                        <div className={`menu__item ${['/'].includes(pathname) ? 'active' : ''}`}>
                            <Link to="/">
                                <div className="d-block">
                                    <ShopFilled style={{ color: '#8C8C8C' }} />
                                </div>
                            </Link>

                            <p>Trang chủ</p>
                        </div>
                        <div className={`menu__item ${['/list-category'].includes(pathname) ? 'active' : ''}`}>
                            {/*<Link to="/your-cart">*/}
                            {/*    <div className="d-block">*/}
                            {/*        <ShoppingCartOutlined style={{ color: '#8C8C8C' }} />*/}
                            {/*    </div>*/}
                            {/*    <p>Giỏ hàng</p>*/}
                            {/*</Link>*/}
                            <Link to="/list-category">
                                <div className="d-block">
                                    <AppstoreOutlined style={{ color: '#8C8C8C' }} />
                                </div>
                                <p>Danh mục</p>
                            </Link>
                        </div>
                        <div className={`menu__item ${['/notification'].includes(pathname) ? 'active' : ''}`}>
                            {/*<Link to="/notification">*/}
                            <div className="d-block" onClick={() => handleClick('/notification')}>
                                <NotificationOutlined style={{ color: '#8C8C8C' }} />
                            </div>
                            <p>Thông báo</p>
                            {/*</Link>*/}
                        </div>
                        <div className={`menu__item ${['/account'].includes(pathname) ? 'active' : ''}`}>
                            <div className="d-block" onClick={() => handleClick('/account')}>
                                <UserOutlined style={{ color: '#8C8C8C' }} />
                            </div>
                            <p>Tài khoản</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        isPublisher: state.homeReducer.isPublisher,
        isLoggedIn: state.homeReducer.isLoggedIn,
    }
}
export default withRouter(connect(mapStateToProps)(MenuMobile))
