import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import * as queryString from 'query-string'
import moment from 'moment'
import { message } from 'antd'
import { connect } from 'react-redux'

import { fetchListCampaign, fetchListProduct, fetchProductPreview} from '../../api-services/product-api'
import { togglePopupShare } from '../../redux/actions/home-actions'
import { setRefreshCart, toggleChooseContent, toggleShowBoxConfirmCart } from '../../redux/actions/search-action'
import { copyToClipboard, getCheckData, getSession } from '../../functions/common-func'
import { fetchListTop } from '../../api-services/home-api'
import ProductPreview from '../../component/product-detail/ProductPreview'
function ProductPreviewContainer({ location, dispatch, history, isLoggedIn, isPublisher }) {
    const [dataDetail, setDataDetail] = useState(false)
    const [contentShare, setContentShare] = useState(null)
    const [deepLink, setDeepLink] = useState('')
    const [dataCampaign, setDataCampaign] = useState(false)
    const [relateProduct, setRelateProduct] = useState({})
    const [labelReview, setLabelReview] = useState(null)
    const [isNotFound, setIsNotFound] = useState(false)
    const [errorCheck, setErrorCheck] = useState(false)
    const [isPreview, setIsPreview] = useState(false)
    const [variPrice, setVariPrice] = useState(null)
    const [variantId, setVariantId] = useState(null)
    const [paging, setPaging] = useState({ page: 1, pageSize: 15, total: 1 });

    localStorage.removeItem('cartCoupon');

    useEffect(() => {
        const query = queryString.parse(location.search)
        
        let dataCheck = getCheckData(query, location.pathname)

        if (dataCheck) {
            const { idCheck, keyName, tokenCheck } = dataCheck
            fetchProductPreview(idCheck, keyName, tokenCheck).then(response => {

                if (response.status === 200 && response.data) {
                    setDataDetail(response.data)
                    
                    setLabelReview('Pending Review')
                    setIsPreview(true)
                } else if (response.status === 200 && !response.data) {
                    setIsNotFound(true)
                } else if (response.status === 400) {
                    setErrorCheck(true)
                }
            })
        }
    }, [location])
    useEffect(() => {
        const query = queryString.parse(location.search)
        let user = getSession('userInfo')?.userInfo
        let dataCheck = getCheckData(query, location.pathname)
        const { idCheck } = dataCheck

        if (dataDetail && dataDetail.contents && dataDetail.contents.length > 0) {
            setContentShare(dataDetail.contents[0]) // set nôi dung chia sẻ ban đầu là phần tử đầu tiên của mảng
        }
        if (isLoggedIn && user) {
            let linkShare = process.env.REACT_APP_URL_SHARE_V2 + '/product/' + idCheck + '?pub_id=' + user.id // link chia sẻ cho pub
            setDeepLink(linkShare)
        }
    }, [dataDetail])


    useEffect(() => {
        if (dataDetail && dataDetail.supplierId && isLoggedIn && isPublisher) {
            getDataCampaign(dataDetail.supplierId)
        }
    }, [dataDetail])

    useEffect(() => {
        if (dataDetail) {
            const query = queryString.parse(location.search)
            let page = query.page || 1
            let filter = '?category_id=' + dataDetail.categoryId
            filter += '&page=' + page
            getDataProduct(filter)
        }
    }, [dataDetail, location])

    const getDataProduct = async (filter) => {
        try {
            const _listProduct = await fetchListProduct(filter)
            if (_listProduct && _listProduct.status === 200) {
                if (_listProduct.data && _listProduct.data.length > 0) {
                    setRelateProduct(_listProduct.data)
                    setPaging(_listProduct.paging)
                } else {
                    const params = {}
                    params.page = 1
                    params.page_size = 12
                    const _listTop = await fetchListTop(params)
                    if (_listTop && _listTop.status === 200) {
                        setRelateProduct(_listTop.data)
                        setPaging(_listTop.paging)
                    }
                }
            }
        } catch (e) {
            throw e
        }
    }


    const handleShareProduct = async (boolean, type, title) => {
        if (dataDetail && dataDetail.contents && dataDetail.contents.length > 0) {
            dispatch(toggleChooseContent(true))
        } else {
            if (navigator.share === undefined) {
                dispatch(togglePopupShare(boolean, type, title))
            } else {
                try {
                    let shareData = {}
                    if (type === 'link') {
                        copyToClipboard(deepLink || '')
                        if (deepLink) {
                            message.success('Sao chép đường dẫn thành công')
                        }
                        shareData = {
                            title: dataDetail.name,
                            text: dataDetail.name || '',
                            url:
                                dataDetail && dataDetail.videos && dataDetail.videos.length > 0
                                    ? dataDetail.videos[0]
                                    : dataDetail.thumbImage,
                        }
                    }
                    await navigator.share(shareData)
                    message.success('Chia sẻ thành công')
                } catch (error) {
                    throw error
                }
            }
        }
    }

    const getDataCampaign = async (id) => {
        try {
            const data = await fetchListCampaign(id)
            if (data && data.data && data.status === 200) {
                setDataCampaign(data.data)
            }
        } catch (e) {
            throw e
        }
    }

    const handleContentShare = (content) => {
        setContentShare(content)
    }

    const onClose = () => {
        if (history && history.length > 1) {
            history.goBack()
        } else {
            window.location.href = '/'
        }
    }

    

    const wholeSale = async (id, quantity, name) => {

        history.push(`/whole-sale/${id}`)
    }



    return (
        <>
            {isPreview && <ProductPreview
                dataDetail={dataDetail}
                deepLink={deepLink}
                dataCampaign={dataCampaign}
                contentShare={contentShare}
                relateProduct={relateProduct}
                paging={paging}
                onClose={onClose}
                handleShareProduct={handleShareProduct}
                handleContentShare={handleContentShare}
                wholeSale={wholeSale}
                history={history}
                labelReview={labelReview}
                isPreview={isPreview}
                variPrice={variPrice}
                setVariPrice={setVariPrice}
                setVariantId={setVariantId}
                variantId={variantId}
            />}
            {isNotFound && <h3>Product Not Found!</h3>}
            {errorCheck && <h3>Error Check!</h3>}
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        isLoggedIn: state.homeReducer.isLoggedIn,
        isPublisher: state.homeReducer.isPublisher,
    }
}
export default withRouter(connect(mapStateToProps)(ProductPreviewContainer))
