import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ShoppingCartOutlined } from '@ant-design/icons'
import { toggleYourCart } from '../../redux/actions/home-actions'
import { toggleShowNotBuy } from '../../redux/actions/create-order-action'
import { message, Modal } from 'antd'
function BoxHeaderTitle({
    text,
    link,
    onClose,
    isBell,
    isPublisher,
    dispatch,
    isCart,
    totalProduct,
    refreshCart,
    refreshNotification,
    totalNotification,
    isShowNotBuy
}) {
    const [animation, setAnimation] = useState(false)
    const [animationNoti, setAnimationNoti] = useState(false)

    useEffect(() => {
        if (refreshCart) {
            setAnimation(true)
            setTimeout(() => {
                setAnimation(false)
            }, 3000)
        }
    }, [refreshCart])

    useEffect(() => {
        if (refreshNotification) {
            setAnimationNoti(true)
            setTimeout(() => {
                setAnimationNoti(false)
            }, 3000)
        }
    }, [refreshNotification]);

    const toggleCart = () => {
        dispatch(toggleYourCart(true));
        localStorage.removeItem('cartCoupon');
    }
    return (
        <div className="box__header__title">
            <div className="box__header__title__content">
                {!onClose ? (
                    <Link className={`width-25`} to={link}>
                        <svg width={16} height={16} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.41 11.09L2.83 6.5L7.41 1.91L6 0.5L0 6.5L6 12.5L7.41 11.09Z" fill="white" />
                        </svg>
                    </Link>
                ) : (
                    <div className={`width-25`} onClick={onClose}>
                        <svg width={16} height={16} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.41 11.09L2.83 6.5L7.41 1.91L6 0.5L0 6.5L6 12.5L7.41 11.09Z" fill="white" />
                        </svg>
                    </div>
                )}

                <h2>{text}</h2>
                {isBell && (
                    <div>
                        {isPublisher === 1 && !isCart && (
                            <div className={`header__bell ${animationNoti ? 'open' : ''}`}>
                                <Link to={'/notification'}>
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.58003 4.08L6.15003 2.65C3.75003 4.48 2.17003 7.3 2.03003 10.5H4.03003C4.18003 7.85 5.54003 5.53 7.58003 4.08ZM19.97 10.5H21.97C21.82 7.3 20.24 4.48 17.85 2.65L16.43 4.08C18.45 5.53 19.82 7.85 19.97 10.5ZM18 11C18 7.93 16.36 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.63003 5.36 6.00003 7.92 6.00003 11V16L4.00003 18V19H20V18L18 16V11ZM12 22C12.14 22 12.27 21.99 12.4 21.96C13.05 21.82 13.58 21.38 13.84 20.78C13.94 20.54 13.99 20.28 13.99 20H9.99003C10 21.1 10.89 22 12 22Z"
                                            fill="white"
                                        />
                                    </svg>
                                    {!(totalNotification === 0 || !totalNotification) && (
                                        <div className="header__bell__count">{totalNotification}</div>
                                    )}
                                </Link>
                            </div>
                        )}
                        {isCart && (
                            <>
                                <div
                                    className={`header__bell ${animation ? 'open' : ''}`}
                                    onClick={() => message.warning('Vui lòng sử dụng tính năng này trên ứng dụng DiMuaDi')}
                                >
                                    
                                    <ShoppingCartOutlined style={{ width: 25, height: 25, color: '#fff' }} />
                                    {totalProduct !== 0 && <div className="header__bell__count">{totalProduct}</div>}
                                </div>
                                <Modal
                                    centered
                                    visible={isShowNotBuy}
                                    footer={null}
                                    onOk={() => dispatch(toggleShowNotBuy(false))}
                                    onCancel={() => dispatch(toggleShowNotBuy(false))}
                                >
                                    <p style={{fontSize:"20px", padding:"15px"}} className="text-center">Vui lòng sử dụng tính năng này trên ứng dụng DiMuaDi</p>
                                </Modal>
                            </>  
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
const mapStateToProps = function (state) {
    return {
        isPublisher: state.homeReducer.isPublisher,
        isLoggedIn: state.homeReducer.isLoggedIn,
        totalProduct: state.searchReducer.totalProduct,
        totalNotification: state.searchReducer.totalNotification,
        refreshCart: state.searchReducer.refreshCart,
        refreshNotification: state.searchReducer.refreshNotification,
        isShowNotBuy: state.createOrderReducer.isShowNotBuy
    }
}
export default withRouter(connect(mapStateToProps)(BoxHeaderTitle))
