import React from 'react'
import { Button, message, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { CloseOutlined } from '@ant-design/icons'
import { togglePopupShare } from '../../../redux/actions/home-actions'
import { copyToClipboard, getConfirmTutorialShare, isMobileScreen } from '../../../functions/common-func'
import { toggleTutorialShare } from '../../../redux/actions/search-action'

function ShareListContent({ onClose, title, listContent, contentShare, handleContentShare, deepLink, dataDetail , isPreview}) {
    // const [seeMoreDes, setSeeMoreDes] = useState(false)
    const dispatch = useDispatch()
    const { isShowChooseContent } = useSelector((state) => {
        return {
            isShowChooseContent: state.searchReducer.isShowChooseContent,
            openTutorialShare: state.productDetailReducer.openTutorialShare,
        }
    })
    const convertMessage = (data) => {
        return data.split('\u000a').join('<br>')
    }

    const convertMessageShare = (data) => {
        return data.split('\u000a').join('\n')
    }

    const handleShareProduct = async (boolean, type, title) => {
        let checkTutorial = getConfirmTutorialShare()
        onClose()
        if (checkTutorial && checkTutorial.check) {
            if (contentShare && contentShare.content) {
                copyToClipboard(convertMessageShare(contentShare.content) + '\n' + deepLink)
                message.success('Nội dung đã được sao chép, hãy dán lên tường và tùy chỉnh trước khi chia sẻ')
            }
            dispatch(togglePopupShare(boolean, type, title));
            if (navigator.share === undefined) {
                dispatch(togglePopupShare(boolean, type, title));
            } else {
                try {
                    let shareData = {}
                    // copyToClipboard(deepLink || '')
                    //     if (deepLink) {
                    //         message.success('Sao chép đường dẫn thành công')
                    //     }
                    if (type === 'link') {
                        shareData = {
                            title: dataDetail.name,
                            text: dataDetail.name || '',
                            url:
                                dataDetail && dataDetail.videos && dataDetail.videos.length > 0
                                    ? dataDetail.videos[0]
                                    : dataDetail.thumbImage,
                        }
                    }
                    // await navigator.share(shareData)
                    // message.success('Chia sẻ thành công')
                } catch (error) {
                    throw error
                }
            }
        } else {
            dispatch(toggleTutorialShare(true))
        }
    }

    return (
        <>
            {isMobileScreen() ? (
                <div className={`box__listContent ${isShowChooseContent ? 'open' : ''}`}>
                    <div className="box__listContent__content">
                        <div className="box__title">
                            <h2>{title}</h2>
                            <div className="box__close" onClick={onClose}>
                                <CloseOutlined />
                            </div>
                        </div>
                        {listContent && listContent.length > 0 && (
                            <div className="box__listContent__main">
                                <div className="list__content">
                                    {Array.isArray(listContent) &&
                                        listContent.length > 0 &&
                                        listContent.map((res, index) => {
                                            return (
                                                <div
                                                    key={res.id}
                                                    onClick={() => handleContentShare(res)}
                                                    className={`item__button__content ${
                                                        contentShare && contentShare.id === res.id ? 'active' : ''
                                                    }`}
                                                >
                                                    <Button> Mẫu {index + 1} </Button>
                                                </div>
                                            )
                                        })}
                                </div>
                                <div
                                    className="content"
                                    dangerouslySetInnerHTML={{
                                        __html: convertMessage(contentShare ? contentShare.content : ''),
                                    }}
                                ></div>
                                {/*<div className={`see__more ${seeMoreDes ? 'open' : ''}`}>*/}
                                {/*    {!seeMoreDes ? (*/}
                                {/*        <div className="see__more__content" onClick={() => setSeeMoreDes(true)}>*/}
                                {/*            Xem thêm*/}
                                {/*            <DownOutlined />*/}
                                {/*        </div>*/}
                                {/*    ) : (*/}
                                {/*        <div className="see__more__content" onClick={() => setSeeMoreDes(false)}>*/}
                                {/*            Thu nhỏ*/}
                                {/*            <UpOutlined />*/}
                                {/*        </div>*/}
                                {/*    )}*/}
                                {/*</div>*/}
                                <div className="btn__group">
                                    <Button
                                        onClick={() => handleShareProduct(true, 'link', 'Chia sẻ sản phẩm')}
                                        type="primary"
                                        className= "w-100"
                                        size="large"
                                        disabled={isPreview}
                                    >
                                        Tiếp tục
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="box__backdrop" onClick={onClose}></div>
                </div>
            ) : (
                <Modal
                    className="box__listContent"
                    onCancel={onClose}
                    footer={null}
                    title={title}
                    visible={isShowChooseContent}
                >
                    {listContent && listContent.length > 0 && (
                        <div className="box__listContent__main">
                            <div className="list__content">
                                {Array.isArray(listContent) &&
                                    listContent.length > 0 &&
                                    listContent.map((res, index) => {
                                        return (
                                            <div
                                                key={res.id}
                                                onClick={() => handleContentShare(res)}
                                                className={`item__button__content ${
                                                    contentShare && contentShare.id === res.id ? 'active' : ''
                                                }`}
                                            >
                                                <Button> Mẫu {index + 1} </Button>
                                            </div>
                                        )
                                    })}
                            </div>
                            <div
                                className="content"
                                dangerouslySetInnerHTML={{
                                    __html: convertMessage(contentShare ? contentShare.content : ''),
                                }}
                            ></div>
                            <div className="btn__group">
                                <Button
                                    onClick={() => handleShareProduct(true, 'link', 'Chia sẻ sản phẩm')}
                                    type="primary"
                                    className="button__share__desktop"
                                    size="large"
                                    disabled={isPreview}
                                >
                                    Tiếp tục
                                </Button>
                            </div>
                        </div>
                    )}
                </Modal>
            )}
        </>
    )
}

export default ShareListContent
