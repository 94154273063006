import React, { useState } from 'react';
import { convertTotalCommission, formatTime, formatTimeToText, renderDiscount, renderPriceAfterCP } from '../../functions/common-func';
import DisplayPrice from '../common/DisplayPrice';
import timeCircle from '../../assets/images/time-circle1.svg';
import { useDispatch } from 'react-redux';
import { toggleShowLoading } from '../../redux/actions/home-actions';
import moment from 'moment'
import { message } from 'antd';
import { updateProductCart} from '../../api-services/cart-api';

const CouponItemSelector = (props) => {
    const { coupon, setIsRefresh, setListProductFromCart, product, setShowModalSelectCoupon, buyProductWithCoupon, showMore, listProduct} = props
    const dispatch = useDispatch();

  
    const renderValue = (type, value) => {
        switch (type) {
            case 0:
                return `${value}%`;
            case 1:
                return <DisplayPrice price={value} />;
            default:
                console.log('type error');
        }
    }
    function chooseCoupon(coupon,is_cancel) {
        // try {
            if(is_cancel) {
                const check_used = listProduct.some(item => item.dataCoupon?.id === coupon.id);
                if(check_used) {
                    message.error("Voucher đã được áp dựng trong giỏ hàng!");
                    return;
                }
            }
            let cartCoupon = props.couponLocal ? props.couponLocal: [];
            if(is_cancel) {
                let index = cartCoupon?.findIndex(item => item?.itemCode === product.itemCode);
                if(index > -1) {
                    cartCoupon[index].coupon = coupon;    
                } else {
                    cartCoupon.push({
                        itemCode: product.itemCode,
                        coupon: coupon
                    });
                }
            } else {
                cartCoupon = cartCoupon?.filter(item => item?.itemCode != product.itemCode);
            }
            let dataProdCouponCart = listProduct.map(item => {
                item['dataCoupon'] = cartCoupon?.filter(element => element?.itemCode == item.itemCode)[0]?.coupon
                return item;
            });
            let total_price = 0;
            let total_commission = 0;
            dataProdCouponCart.forEach(res => {
                let cpDiscount = renderDiscount(res);
                let prodDiscount = cpDiscount ? renderPriceAfterCP(res.productPrice*res.quantity, cpDiscount) : res.productPrice*res.quantity
                        total_price += parseInt(Number(
                            prodDiscount).toFixed(0))
                        total_commission += parseInt(Number(convertTotalCommission(prodDiscount, res.quantity, res.product.commissionValue, res.product.commissionType)))
            });
            props.setTotalPrice(total_price);
            props.setTotalCommission(total_commission);
            console.log('list-after----------',listProduct)
            localStorage.setItem('cartCoupon',JSON.stringify(cartCoupon));
            props.setCouponLocal(cartCoupon);
            setShowModalSelectCoupon(false);
            dispatch(toggleShowLoading(false));

        // } catch (e) {
        //     if (e && e.response && e.response.data) {
        //         message.error(e.response.data.message);
        //     }
        //     dispatch(toggleShowLoading(false));
        //     throw e
        // }
    }
    function renderBtn(coupon) {
        if(coupon) {
            if(coupon.quantity === coupon.quantityUsed) {
                return <button className="btn-disable-txt btn-secondary" disabled>Hết lượt</button>
            }
            if(product.dataCoupon?.id === coupon.id) {
                return <button className="cursor cancel" onClick={() => chooseCoupon(coupon,false)}>Hủy</button>
            }
                return <button className="cursor buy-now" onClick={() => chooseCoupon(coupon,true)}>Chọn</button>
        }
    }
    const renderBtnShowMore = () => {
        if(coupon.quantity === coupon.quantityUsed) {
            return <button className="btn-disable-txt btn-secondary" disabled>Hết lượt</button>
        }
            return <button className={`cursor buy-now ${showMore?'show-more':''}`} onClick={() => buyProductWithCoupon(coupon)}>Dùng ngay</button>
    }
    return (
        <div className="coupon__item modal-list-coupon">
            <div className="left coupon-item-time " >
                <p className="cp-code">{coupon.code}</p>
                <span className="cp-time">HSD: {moment(coupon.endAt).format('DD/MM')}</span>
                <p >
                    <span><img src={timeCircle} alt={coupon.description} />
                        còn {formatTimeToText(coupon.endAt)}
                    </span>
                </p>
            </div>
            <div className={`right ${showMore?'show-more-title':''}`}>
                <h3>Giảm {renderValue(coupon.couponType, coupon.amount)}</h3>
                <div className="coupon-item-desc">{coupon.name}</div>
            </div>
            <div className="coupon-item-btn ">
                {showMore ? renderBtnShowMore(): renderBtn(coupon)}
            </div>

        </div>
    )
}
export default CouponItemSelector;