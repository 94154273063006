import React, { useState, useEffect } from 'react'
import { message } from 'antd'
import EditUserInfo from '../../component/user/EditUserInfo'
import { convertFullNameToFirstLastName, getSession, setSession } from '../../functions/common-func'
import { getUserInfo, updateProfile } from '../../api-services/login-api'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { toggleShowLoading } from '../../redux/actions/home-actions'
message.config({
    duration: 3,
})

function EditUserInfoContainer({ dispatch }) {
    const [userName, setUserName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [gender, setGender] = useState(null)
    const [siteLink, setSiteLink] = useState('')
    const [myDescription, setMyDescription] = useState('')
    const [validateUserName, setValidateUserName] = useState({ status: 'success', text: '' })
    const [validateEmail, setValidateEmail] = useState({ status: 'success', text: '' })
    const [validatePhone, setValidatePhone] = useState({ status: 'success', text: '' })
    const [validateGender, setValidateGender] = useState({ status: 'success', text: '' })
    // const [validateSiteLink, setValidateSiteLink] = useState({ status: 'success', text: '' })
    const [validateMyDescription, setValidateMyDescription] = useState({ status: 'success', text: '' })
    let user = getSession('userInfo')?.userInfo
    useEffect(() => {
        if (user) {
            setUserName(user.username)
            setPhone(user.phone)
            setEmail(user.email)
            user.gender && setGender(user.gender)
            setSiteLink(user.siteLink)
            if (user.shortDesc) {
                setMyDescription(user.shortDesc)
            }
        }
    }, [])

    const clearValidator = () => {
        setValidateUserName({ status: 'success', text: '' })
        setValidateEmail({ status: 'success', text: '' })
        setValidatePhone({ status: 'success', text: '' })
        setValidateGender({ status: 'success', text: '' })
        // setValidateSiteLink({ status: 'success', text: '' })
        setValidateMyDescription({ status: 'success', text: '' })
    }

    const handleChangeUsername = (e) => {
        setUserName(e.target.value)
        clearValidator()
    }

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
        clearValidator()
    }

    const handleChangePhone = (e) => {
        setPhone(e.target.value)
        clearValidator()
    }

    const handleChangeGender = (e) => {
        setGender(e)
        clearValidator()
    }

    const handleChangeSiteLink = (e) => {
        setSiteLink(e.target.value)
        clearValidator()
    }

    const handleChangeDescription = (e) => {
        setMyDescription(e.target.value)
        clearValidator()
    }

    const updateUserInfo = async () => {
        let Regex = /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
        let filter = /([0-9]{9})/g
        if (!userName) {
            setValidateUserName({ status: 'error', text: 'Vui lòng nhập họ tên của bạn' })
            return
        }
        if (!email) {
            setValidateEmail({ status: 'error', text: 'Vui lòng nhập email' })
            return
        }
        if (email && !Regex.test(email)) {
            setValidateEmail({ status: 'error', text: 'Vui lòng nhập  đúng định dạng email' })
            return
        }
        if (!phone) {
            setValidatePhone({ status: 'error', text: 'Vui lòng nhập số điện thoại' })
            return
        }
        if (phone && !filter.test(phone)) {
            setValidatePhone({ status: 'error', text: 'Vui lòng nhập  đúng số điện thoại' })
            return
        }
        if (!gender) {
            setValidateGender({ status: 'error', text: 'Vui lòng nhập mô tả về bạn' })
            return
        }

        try {
            const data = {
                email: email,
                firstName: convertFullNameToFirstLastName(userName, 'first_name'),
                lastName: convertFullNameToFirstLastName(userName, 'last_name'),
                username: userName,
                phone_new: phone,
                siteLink: siteLink,
                shortDesc: myDescription,
                description: myDescription,
                gender: gender,
            }
            dispatch(toggleShowLoading(true))
            const result = await updateProfile(data)
            const dataUpdate = await getUserInfo()
            dispatch(toggleShowLoading(false))

            if (result && result.status === 200) {
                let user = getSession('userInfo')?.userInfo
                if (user) {
                    user = dataUpdate.data
                }
                setSession('userInfo', {userInfo: user}, 7)
                message.success('Update thành công')
            } else {
                message.error('Update không thành công')
            }
        } catch (e) {
            message.error('Update không thành công')
            dispatch(toggleShowLoading(false))
            throw e
        }
    }
    return (
        <EditUserInfo
            myDescription={myDescription}
            email={email}
            userName={userName}
            phone={phone}
            gender={gender}
            siteLink={siteLink}
            validateUserName={validateUserName}
            validateEmail={validateEmail}
            validatePhone={validatePhone}
            validateGender={validateGender}
            validateMyDescription={validateMyDescription}
            handleChangeUsername={handleChangeUsername}
            handleChangeEmail={handleChangeEmail}
            handleChangePhone={handleChangePhone}
            handleChangeGender={handleChangeGender}
            handleChangeSiteLink={handleChangeSiteLink}
            handleChangeDescription={handleChangeDescription}
            updateUserInfo={updateUserInfo}
        />
    )
}

const mapStateToProps = function (state) {
    return {}
}
export default withRouter(connect(mapStateToProps)(EditUserInfoContainer))
