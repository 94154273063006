import React from 'react'
import { IconHomeChietKhau } from '../../constants/icon-const'
import DisplayPrice from '../common/DisplayPrice'
import { checkURLImage, convertCommission, convertSizeImage } from '../../functions/common-func'
import { Swiper, SwiperSlide } from 'swiper/react'
import { PATH_PRODUCT } from '../../constants/common-contants'

function HomeSuperDiscount({ deadline, listHighDiscount }) {
    const statusProduct = (quantity) => {
        let result = ''
        if (quantity === 0) {
            result = 'Đã hết'
        }
        if (quantity === 1 || quantity === 2) {
            result = 'Sắp hết'
        }
        if (quantity > 2) {
            result = `Còn ${quantity} sp`
        }
        return result
    }
    return (
        <div className="home__super_discount">
            <div className="container">
                <div className="time__discount">
                    <div className="super__discount">
                        <p>Siêu trợ giá</p>
                        {/*<Countdown*/}
                        {/*    valueStyle={{*/}
                        {/*        fontSize: 16,*/}
                        {/*        background: '#8F9BB3',*/}
                        {/*        color: '#fff',*/}
                        {/*        padding: 5,*/}
                        {/*        fontWeight: 500,*/}
                        {/*        borderRadius: 4,*/}
                        {/*    }}*/}
                        {/*    value={deadline}*/}
                        {/*/>*/}
                    </div>
                    {/*<a>Tất cả</a>*/}
                </div>
                <div className="home__superDiscount__content">
                    <Swiper
                        slidesPerView={2.5}
                    >
                        {Array.isArray(listHighDiscount) &&
                            listHighDiscount.map((res) => {
                                return (
                                    <SwiperSlide key={res.id}>
                                        <div className="superDiscount__item">
                                            <a href={`${PATH_PRODUCT}/${res.id}`}>
                                                <div className="superDiscount__item__content">
                                                    <div className="item__image">
                                                        <img
                                                            src={convertSizeImage(
                                                                checkURLImage(res.thumbImage),
                                                                200,
                                                                200
                                                            )}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <span className="d-block price">
                                                        <DisplayPrice price={res.price} />
                                                    </span>
                                                    <p>{statusProduct(res.quantity)}</p>
                                                    <p className="chiet-khau">
                                                        <IconHomeChietKhau />
                                                        <span style={{ marginLeft: 5, display: 'inline-block' }}>
                                                            <DisplayPrice
                                                                price={convertCommission(
                                                                    res.price,
                                                                    res.commissionValue,
                                                                    res.commissionType
                                                                )}
                                                            />
                                                        </span>
                                                    </p>
                                                    {res.discountAmount && (
                                                        <div className="discount">
                                                            <span>{res.discountAmount}%</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </a>
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default HomeSuperDiscount
