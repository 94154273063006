import React from 'react';
import {convertOldVariations} from '../../functions/common-func';
const OldVariations = ({
    product,
    oldVariation,
    setOldVariation
}) => {
    
    const variationDatas = convertOldVariations(product);
    
    const handleSelectVariation = (variation,attributeValue) => {
        const variation_choose = [...oldVariation];
        let index = variation_choose.findIndex(item => item.id === variation.id);
        if(index < 0) {
            let variate = {
                id:variation.id,
                slug:variation.attribute_name,
                attribute_value:attributeValue.name,
                attribute_value_id:attributeValue.id,
            };
            variation_choose.push(variate);
            
        } else {
            if(variation_choose[index].attribute_value_id === attributeValue.id) {
                variation_choose.splice(index,1);
            } else {
                variation_choose[index].attribute_value = attributeValue.name;
                variation_choose[index].attribute_value_id = attributeValue.id;
            }
        }
        setOldVariation(variation_choose);
    }
    const checkExistVariation = (variation,attribute_item) => {
        if(oldVariation.length <= 0) {
            return false;
        } else {
            const valueCheck = oldVariation.some(item => item.id === variation.id && item.attribute_value_id === attribute_item.id);
            return valueCheck;
        }
        
        
    }

    return (
        <div className="group-variations">
            {variationDatas.length > 0 &&
             <> 
             {variationDatas.map((variation,index) => {
                return (
                    <div className="group-variations-content" key={index}>
                        <div className="variation-title" >
                            {variation.attribute_name}
                        </div>
                        <div className="variation-content" >
                            {variation.attribute_value.map((attribute_item) => {
                                return (
                                <>
                                    <div className={`variation-value ${checkExistVariation(variation,attribute_item) ? 'active' : ''}`}
                                      onClick={() => handleSelectVariation(variation,attribute_item)}  >
                                        {attribute_item.name}
                                    </div>
                                </>
                                ) 
                                
                            })}
                        </div>
                    </div>
                )
             })}
            </>}
        </div>
    );
}
export default OldVariations;