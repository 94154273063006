import React, { useState } from 'react'
import { Input, Button, DatePicker } from 'antd'
import { FilterFilled, SearchOutlined } from '@ant-design/icons'
import moment from 'moment'
import 'moment/min/locales.min'
import { IconHomeChietKhau } from '../../constants/icon-const'
import DisplayPrice from '../common/DisplayPrice'
import BoxInfoOrder from './BoxInfoOrder'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { toggleOpenFilterProduct } from '../../redux/actions/list-order-action'
import BoxFilterOrder from './BoxFilterOrder'
import BoxExportOrder from './BoxExportOrder'
import noOrder from '../../assets/images/no_order.png'
import {
    convertStatus,
    isMaxTableScreen,
    isMobileScreen,
    returnStatus,
    toggleClassNoScroll,
} from '../../functions/common-func'
import PaginationContainer from '../../container/common/PaginationContainer'
import { Link } from 'react-router-dom'
import ErrorProduct from '../common/ErrorProduct'

const { RangePicker } = DatePicker
function ListOrder({
    dispatch,
    listOrder,
    paging,
    handleKeyPress,
    onChangeSearch,
    handleSearch,
    handleOpenDp,
    disabledDate,
    onChange,
    isOpenDp,
    startDate,
    endDate,
    status,
    handleFilterStatus,
}) {
    return (
        <div className="list__order">
            {!(isMobileScreen() && isMaxTableScreen()) && (
                <div className="header__order">
                    <h2>Danh sách đơn hàng</h2>
                    <Button type="primary" size="large">
                        <Link to="/choose-product"> + Tạo đơn hàng</Link>
                    </Button>
                </div>
            )}
            {isMobileScreen() && isMaxTableScreen() ? (
                <div className="list__order__mobile">
                    <BoxHeaderTitle text="Danh sách đơn hàng" link="/" isBell={true} />
                    <div className="list__order__content">
                        <div className="list__order__item border__bottom">
                            <div className="container">
                                <div className="form__input">
                                    <Input
                                        suffix={<SearchOutlined onClick={handleSearch} />}
                                        onKeyPress={handleKeyPress}
                                        onChange={onChangeSearch}
                                        size="large"
                                        placeholder="Tìm kiếm theo mã đơn, sản phẩm"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="list__order__item border__bottom report__filter">
                            <div className="container">
                                <div className="list__order__filter__content">
                                    <div
                                        className="filter__item"
                                        onClick={() => dispatch(toggleOpenFilterProduct(true))}
                                    >
                                        <FilterFilled style={{ color: '#8C8C8C' }} />
                                        Lọc đơn hàng
                                    </div>
                                    {/*<div*/}
                                    {/*    className="filter__item"*/}
                                    {/*    onClick={() => dispatch(toggleOpenExportProduct(true))}*/}
                                    {/*>*/}
                                    {/*    <DownloadOutlined style={{ color: '#8C8C8C' }} />*/}
                                    {/*    Xuất báo cáo*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                        <div className="list__order__item">
                            <div className="list-order">
                                {Array.isArray(listOrder) &&
                                    listOrder.length > 0 &&
                                    listOrder.map((res) => {
                                        return (
                                            <div key={res?.id} className="order__item">
                                                <Link to={`order/${res?.id}`}>
                                                    <div className="order__status">
                                                        <p className="order__code">
                                                            <span>#{res?.code}</span> |{' '}
                                                            {moment(res?.createdAt || res?.createdat).format('HH:MM - DD/MM/YYYY')}
                                                        </p>
                                                        {returnStatus(res?.status)}
                                                    </div>
                                                    <div className="order__commission">
                                                        <p>{(res?.metaData && res?.metaData?.name) || (res?.metadata && res?.metadata?.name)}</p>
                                                        <p className="commission">
                                                            <IconHomeChietKhau />
                                                            <DisplayPrice price={res?.commission} />
                                                        </p>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })}

                                {listOrder && listOrder.length === 0 && (
                                    <div className="order__item">
                                        <div className="image__no__order">
                                            <img src={noOrder} alt=" no order" />
                                            <p>Không tìm thấy đơn hàng</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {listOrder && listOrder.length !== 0 && <PaginationContainer {...paging} />}
                    <BoxInfoOrder />
                    <BoxFilterOrder />
                    <BoxExportOrder />
                </div>
            ) : (
                <div className="list__order__desktop">
                    <div className="filter__order__desktop">
                        <div className="filter__status__desktop">
                            <div
                                onClick={() => handleFilterStatus('all')}
                                className={`status__item ${status === 'all' ? 'active' : ''}`}
                            >
                                Tất cả đơn hàng
                            </div>
                            <div
                                onClick={() => handleFilterStatus('pending')}
                                className={`status__item ${status === 'pending' ? 'active' : ''}`}
                            >
                                Chờ xử lý
                            </div>
                            <div
                                onClick={() => handleFilterStatus('pre_approved')}
                                className={`status__item ${status === 'pre_approved' ? 'active' : ''}`}
                            >
                                Tạm duyệt
                            </div>
                            <div
                                onClick={() => handleFilterStatus('approved')}
                                className={`status__item ${status === 'approved' ? 'active' : ''}`}
                            >
                                Đã duyệt
                            </div>
                            <div
                                onClick={() => handleFilterStatus('rejected')}
                                className={`status__item ${status === 'rejected' ? 'active' : ''}`}
                            >
                                Đã hủy
                            </div>
                        </div>
                        <div className="filter__search__desktop">
                            <div className="vntDatepicker">
                                <RangePicker
                                    className="w-100"
                                    open={isOpenDp}
                                    onChange={onChange}
                                    onOpenChange={handleOpenDp}
                                    allowClear={true}
                                    disabledDate={disabledDate}
                                    size={'large'}
                                    format={'DD-MM-YYYY'}
                                    inputReadOnly={true}
                                    // renderExtraFooter={renderExtraFooter}
                                    value={[startDate, endDate]}
                                />
                            </div>
                            <div className="form__input">
                                <Input
                                    suffix={<SearchOutlined onClick={handleSearch} />}
                                    onKeyPress={handleKeyPress}
                                    onChange={onChangeSearch}
                                    size="large"
                                    placeholder="Tìm kiếm theo mã đơn, sản phẩm"
                                />
                            </div>
                            <Button onClick={handleSearch} type="primary" size="large">
                                Tìm kiếm
                            </Button>
                        </div>
                    </div>
                    <div className="orderAdmin__table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Mã đơn</th>
                                    <th>Ngày đặt hàng</th>
                                    <th>Sản phẩm</th>
                                    <th>Số lượng</th>
                                    <th>Số điện thoại</th>
                                    <th className='text-center'>Tổng tiền</th>
                                    <th className='text-center'>Hoa hồng</th>
                                    <th>Họ tên</th>
                                    <th>Trạng thái</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(listOrder) &&
                                    listOrder?.length > 0 &&
                                    listOrder.map((res) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <Link to={`/order/${res?.id}`}>{res?.code}</Link>
                                                </td>
                                                <td>{moment(res?.createdat || res?.createdAt).format('DD/MM/YYYY')}</td>
                                                <td>{(res?.metaData && res?.metaData?.name) || (res?.metadata && res?.metadata?.name)}</td>
                                                <td>{res?.quantity}</td>
                                                <td>{(res?.shippingAddress?.phoneNumber) || (res?.shippingaddress?.phoneNumber)}</td>
                                                <td className='text-right'>
                                                    <DisplayPrice price={(res?.finalPrice) || (res?.finalprice)} />
                                                </td>
                                                <td className='text-right'>
                                                    <DisplayPrice price={res?.commission} />
                                                </td>
                                                <td>{(res?.shippingAddress?.username) || (res?.shippingaddress?.username)}</td>
                                                <td>
                                                    <button className={`item__status ${res?.status}`}>
                                                        {convertStatus(res?.status)}
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                        {listOrder && listOrder.length === 0 && <ErrorProduct text="Không tìm thấy đơn hàng" />}
                    </div>
                    {listOrder && listOrder.length !== 0 && <PaginationContainer {...paging} />}
                </div>
            )}
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        openInfoProduct: state.listOrderReducer.openInfoProduct,
    }
}
export default withRouter(connect(mapStateToProps)(ListOrder))
