import ApiMicroService from './request-micro-service'
import updateUrl from './updateUrl'
export const fetchListProvince = async (filter, ) => {
    try {
        let apiURL = `/d2c-service/location${filter ? filter : ''}`
        const results = await ApiMicroService.get(updateUrl(apiURL,'page_size','40'))
        return results.data
    } catch (e) {
        throw e
    }

}

export const createAddress = async (data) => {
    try {
        const results = await ApiMicroService.post(`/d2c-service/user/shipping-address`, data)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchAddressUser = async () => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/user/shipping-address?page=1&page_size=10`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchAddressById = async (id) => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/user/shipping-address/${id}`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const deleteAddress = async (id) => {
    try {
        const results = await ApiMicroService.delete(`/d2c-service/user/shipping-address/${id}`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const updateAddressById = async (id, data) => {
    try {
        const results = await ApiMicroService.put(`/d2c-service/user/shipping-address/${id}`, data)
        return results.data
    } catch (e) {
        throw e
    }
}
