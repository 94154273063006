import React, { useState, useEffect } from 'react'
import Pagination from '../../component/common/Pagination'
import { useLocation } from 'react-router'
import * as queryString from 'query-string'
import { DEFAULT_NUMBER_PAGE_DSIPLAY, PAGE_SIZE_HOTEL_LIST } from '../../constants/common-contants'

function PaginationContainer({ page, pageSize, numberPageDisplay, total }) {
    const { pathname, search } = useLocation()
    const [prevLink, setPrevLink] = useState()
    const [nextLink, setNextLink] = useState()
    const [pages, setPages] = useState()
    const [totalPage, setTotalPage] = useState()
    useEffect(() => {
        if (total) {
            const total_page = Math.ceil(total / (pageSize || PAGE_SIZE_HOTEL_LIST))
            const query = queryString.parse(search)
            const current_page = Number(query.page) || Number(page) || 1
            const n =
                total_page < (numberPageDisplay || DEFAULT_NUMBER_PAGE_DSIPLAY)
                    ? total_page
                    : numberPageDisplay || DEFAULT_NUMBER_PAGE_DSIPLAY
            const arr = []
            const range = Math.round(n / 2)
            const start = current_page - range > 1 ? current_page - range : 1
            const end = start + n - 1 < total_page ? start + n - 1 : total_page
            for (let i = start; i <= end; i++) {
                const link = pathname + '?' + queryString.stringify({ ...query, page: i })
                if (i >= 1 && i <= total_page) {
                    arr.push({
                        active: i === current_page,
                        page: i,
                        link,
                    })
                }
            }
            setTotalPage(total_page)
            setPages(arr)
            const queryNext = { ...query, page: current_page + 1 }
            const queryPrev = {
                ...query,
                page: current_page > 1 ? current_page - 1 : '',
            }
            setPrevLink({
                link: pathname + '?' + queryString.stringify(queryPrev),
                is_disable: current_page === 1,
            })
            setNextLink({
                link: pathname + '?' + queryString.stringify(queryNext),
                is_disable: current_page === total_page,
            })
        }
    }, [pageSize, page, total, pathname, search])
    return <Pagination prevLink={prevLink} nextLink={nextLink} pages={pages} totalPage={totalPage} />
}

export default PaginationContainer
