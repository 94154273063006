import React, { useRef, useEffect, useState } from 'react'
import { Link, useLocation, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Avatar, Button, message, Modal } from 'antd'
import { UserOutlined, SettingOutlined, LogoutOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import { getSession, removeSession } from '../../functions/common-func'
import { setIsLoggedIn, toggleShowMenuAccount } from '../../redux/actions/home-actions'
import { toggleShowNotBuy } from '../../redux/actions/create-order-action'
function HeaderDesktop({ isShowMenuAccount, dispatch, history, isPublisher, isShowNotBuy, isLoggedIn, refreshCart, totalProduct }) {
    const [animation, setAnimation] = useState(false)
    const { pathname } = useLocation()

    let user = getSession('userInfo')?.userInfo
    const defaultAirPort = useRef(null)
    useEffect(() => {
        // add when mounted
        document.addEventListener('mousedown', handleClickOutSideAirPort)
        // return function to be called when unmounted
        return () => {
            document.removeEventListener('mousedown', handleClickOutSideAirPort)
        }
    }, [])

    useEffect(() => {
        if (refreshCart) {
            setAnimation(true)
            setTimeout(() => {
                setAnimation(false)
            }, 3000)
        }
    }, [refreshCart])

    const handleLogout = () => {
        removeSession('userInfo')
        removeSession('role')
        dispatch(setIsLoggedIn(false))
        // let redirect_uri = encodeURI(`http://localhost:4200/login`);
        let redirect_uri = encodeURI(`https://` + window.location.hostname + `/login`);
        let url = `${process.env.REACT_APP_URL_GET_AUTHOR_ACCESS_TRADE}/logout?redirect_uri=${redirect_uri}`
        window.location.href = url;
    }

    const handleClickOutSideAirPort = (event) => {
        if (defaultAirPort && defaultAirPort.current && defaultAirPort.current.contains(event.target)) {
            // inside click
            return
        }

        // outside click
        dispatch(toggleShowMenuAccount(false)) // close dropdown
    }
    return (
        <div className="header__desktop">
            <div className="container">
                <div className="header__desktop__content">
                    <div className="header__desktop__right">
                        {isLoggedIn && (
                            <div className="header__bell">
                                <Link to={'/notification'}>
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M7.58003 4.08L6.15003 2.65C3.75003 4.48 2.17003 7.3 2.03003 10.5H4.03003C4.18003 7.85 5.54003 5.53 7.58003 4.08ZM19.97 10.5H21.97C21.82 7.3 20.24 4.48 17.85 2.65L16.43 4.08C18.45 5.53 19.82 7.85 19.97 10.5ZM18 11C18 7.93 16.36 5.36 13.5 4.68V4C13.5 3.17 12.83 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.63003 5.36 6.00003 7.92 6.00003 11V16L4.00003 18V19H20V18L18 16V11ZM12 22C12.14 22 12.27 21.99 12.4 21.96C13.05 21.82 13.58 21.38 13.84 20.78C13.94 20.54 13.99 20.28 13.99 20H9.99003C10 21.1 10.89 22 12 22Z"
                                            fill="#1890FF"
                                        />
                                    </svg>
                                    {/*<div className="header__bell__count">+9</div>*/}
                                </Link>
                            </div>
                        )}
                        {isLoggedIn && !pathname.startsWith('/checkout/cart') && (
                            <>
                            <div className={`header__bell ${animation ? 'open' : ''}`} onClick={() => message.warning('Vui lòng sử dụng tính năng này trên ứng dụng DiMuaDi')}>
                                <ShoppingCartOutlined style={{ width: 25, height: 25, color: '#1890FF' }} />
                                {totalProduct !== 0 && <div className="header__bell__count">{totalProduct}</div>}
                            </div>
                            <Modal
                                centered
                                visible={isShowNotBuy}
                                footer={null}
                                onOk={() => dispatch(toggleShowNotBuy(false))}
                                onCancel={() => dispatch(toggleShowNotBuy(false))}
                            >
                                <p style={{fontSize:"20px", padding:"15px"}} className="text-center">Vui lòng sử dụng tính năng này trên ứng dụng DiMuaDi</p>
                            </Modal>
                            </>
                            
                        )}
                        {isLoggedIn && (
                            <div className="">
                                <div
                                    onClick={() => dispatch(toggleShowMenuAccount(true))}
                                    className="header__desktop__account"
                                >
                                    <Avatar
                                        size={40}
                                        src={
                                            (user && user.avatar) ||
                                            (user &&
                                                user.userAccount &&
                                                user.userAccount.length > 0 &&
                                                user.userAccount[0].avatar) ||
                                            'https://spursbistro.com/wp-content/uploads/2020/06/usericon.png'
                                        }
                                    />
                                    <div className="user_name">
                                        <p className="name">{user && (user.username || user.email)}</p>
                                    </div>
                                </div>
                                {isLoggedIn && (
                                    <div
                                        className={`account__dropdown ${isShowMenuAccount ? 'open' : ''}`}
                                        ref={defaultAirPort}
                                    >
                                        <div
                                            className="item__account"
                                            onClick={() => dispatch(toggleShowMenuAccount(false))}
                                        >
                                            <Link
                                                onClick={() => dispatch(toggleShowMenuAccount(false))}
                                                to={'/account'}
                                            >
                                                <UserOutlined />
                                                <span>Thông tin cá nhân </span>
                                            </Link>
                                        </div>
                                        <div
                                            className="item__account"
                                            onClick={() => dispatch(toggleShowMenuAccount(false))}
                                        >
                                            <Link
                                                onClick={() => dispatch(toggleShowMenuAccount(false))}
                                                to={'/account?type=setting'}
                                            >
                                                <SettingOutlined />
                                                <span>Thiếp lập tài khoản </span>
                                            </Link>
                                        </div>
                                        <div className="item__account" onClick={handleLogout}>
                                            <LogoutOutlined />
                                            <span>Đăng xuất</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {!isLoggedIn && (
                            <div className="header__desktop__account d-none-after">
                                {/*<Link to="/login">
                                    <Button type="primary">Đăng nhập</Button>
                                </Link>*/}
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        isShowMenuAccount: state.homeReducer.isShowMenuAccount,
        isPublisher: state.homeReducer.isPublisher,
        isLoggedIn: state.homeReducer.isLoggedIn,
        totalProduct: state.searchReducer.totalProduct,
        refreshCart: state.searchReducer.refreshCart,
        isShowNotBuy: state.createOrderReducer.isShowNotBuy
    }
}
export default withRouter(connect(mapStateToProps)(HeaderDesktop))
