import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Button, Collapse, Form } from 'antd'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import { toggleShowTutorial } from '../../redux/actions/account-action'
import ErrorProduct from '../common/ErrorProduct'
const { Panel } = Collapse

function TutorialAccount({ isTutorial, dispatch, dataCampaign, type }) {
    const Header = ({ title, text }) => {
        return (
            <div className="header__panel">
                <h4>{title}</h4>
                <p>{text}</p>
            </div>
        )
    }
    return (
        <div className={`tutorial__account ${isTutorial ? 'open' : ''}`}>
            <BoxHeaderTitle text="Hướng dẫn & quy định bán hàng" onClose={() => dispatch(toggleShowTutorial(false))} />
            {type === 'account' ? (
                <div className="tutorial__account__content">
                    <div className="container__mobile">
                        <Collapse>
                            <Panel
                                header={
                                    <Header
                                        text="Đọc kỹ để kiếm nhiều hoa hồng hơn"
                                        title=" 4 bước để bán hàng với Dimuadi"
                                    />
                                }
                                key="1"
                            >
                                <ul className="list__content">
                                    <li className="item__content__rule">
                                        <div className="step">01</div>
                                        <p>
                                            Chọn nội dung mẫu muốn chia sẻ. Hãy viết lại một chút theo ngôn ngữ của bạn
                                        </p>
                                    </li>
                                    <li className="item__content__rule">
                                        <div className="step">02</div>
                                        <p>
                                            Đăng tin cho khách hàng, bạn bè kèm link giới thiệu của bạn. Bạn có thể “đặt
                                            hộ đơn” cho bạn bè
                                        </p>
                                    </li>
                                    <li className="item__content__rule">
                                        <div className="step">03</div>
                                        <p>
                                            Đơn hàng được ghi nhận sau 1-2 phút. Hãy cập nhập thông báo để theo dõi đơn
                                            hàng mỗi ngày
                                        </p>
                                    </li>
                                    <li className="item__content__rule">
                                        <div className="step">04</div>
                                        <p>Nhận hoa hồng vào rút tiền về tài khoản khi đủ hạn mức</p>
                                    </li>
                                </ul>
                            </Panel>
                        </Collapse>
                        <Collapse>
                            <Panel
                                header={<Header text="Đọc kỹ truớc khi bán" title=" Tôi sẽ nhận hoa hồng khi nào" />}
                                key="1"
                            >
                                <ul className="list__content">
                                    <li className="item__content__rule">
                                        <div className="step">01</div>
                                        <p>
                                            Hoa hồng tạm duyệt (Pre-approve) sau khi khách hàng đồng ý mua hàng hoặc
                                            hoàn thành hoạt động mua hàng (tùy chiến dịch)
                                        </p>
                                    </li>
                                    <li className="item__content__rule">
                                        <div className="step">02</div>
                                        <p>
                                            Hoa hồng được duyệt (Approved) là hoa hồng chắc chắn nhận được và sẽ được
                                            thanh toán qua tài khoản từ 18-25 hàng tháng.
                                        </p>
                                    </li>
                                    <li className="item__content__rule">
                                        <div className="step">03</div>
                                        <p>
                                            Hạn mức được nhận hoa hồng: trên 200.000đ/tháng hoặc trên 500.000đ/tuần (Đối
                                            với chiến dịch có thanh toán tuần)
                                        </p>
                                    </li>
                                    <li className="item__content__rule">
                                        <div className="step">04</div>
                                        <p>Thông báo về hoa hồng sẽ được gửi qua thông báo và email.</p>
                                    </li>
                                </ul>
                            </Panel>
                        </Collapse>
                        <Collapse>
                            <Panel header={<Header text="Quy định bắt buộc" title="Đọc kỹ kẻo bi “block”" />} key="1">
                                <ul className="list__content">
                                    <h4>Điều kiện tham gia chiến dịch</h4>
                                    <p className="mb-20">Tỷ lệ đơn rác của Publisher không vượt quá 30% tổng lead.</p>
                                    <p className="mb-20">
                                        <span>Định nghĩa đơn rác</span> = Đơn hàng bị trùng, khách hàng không có nhu cầu
                                        hoặc không quan tâm về sản phẩm, sai họ tên, sai số điện thoại, thuê bao tạm
                                        khóa, gọi liên tục 4 cuộc gọi không thành công.{' '}
                                    </p>
                                    <p>Publisher có thể cập nhập đơn rác ở phần Báo cáo , Lý do hủy </p>
                                    <p>
                                        {' '}
                                        Tỉ lệ duyệt dưới 20% sẽ bị block, với Pub mới có 3 mới phát sinh ko được duyệt
                                        sẽ bị block{' '}
                                    </p>
                                    <p>
                                        {' '}
                                        Test đơn nhiều hoặc nhập sai thông tin, cố tình đưa đơn rác vào hệ thống sẽ bị
                                        block{' '}
                                    </p>
                                </ul>
                            </Panel>
                        </Collapse>
                        {/*<Collapse>*/}
                        {/*    <Panel*/}
                        {/*        header={<Header text="Đọc kỹ hướng dẫn" title="Hoa hồng ghi nhận khi nào" />}*/}
                        {/*        key="1"*/}
                        {/*    >*/}
                        {/*        <ul className="list__content">*/}
                        {/*            <h4>Điều kiện chấp nhận</h4>*/}
                        {/*            <p className="mb-20">*/}
                        {/*                Hoa hồng được chấp nhận thì khánh nhận hàng và thanh toán thành công*/}
                        {/*            </p>*/}
                        {/*            <p className="mb-20">*/}
                        {/*                <span>Định nghĩa đơn rác</span> = Đơn hàng bị trùng, khách hàng không có nhu cầu*/}
                        {/*                hoặc không quan tâm về sản phẩm, sai họ tên, sai số điện thoại, thuê bao tạm*/}
                        {/*                khóa, gọi liên tục 4 cuộc gọi không thành công.{' '}*/}
                        {/*            </p>*/}
                        {/*            <p>Publisher có thể cập nhập đơn rác ở phần Báo cáo , Lý do hủy </p>*/}
                        {/*        </ul>*/}
                        {/*    </Panel>*/}
                        {/*</Collapse>*/}
                    </div>
                    <div className="form__btn">
                        <Button
                            onClick={() => dispatch(toggleShowTutorial(false))}
                            size="large"
                            type="primary"
                            className="w-100"
                        >
                            Đã hiểu! Bán hàng ngay
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="tutorial__account__content">
                    <div className="container__mobile">
                        {dataCampaign && (
                            <div className="content" dangerouslySetInnerHTML={{ __html: dataCampaign }}></div>
                        )}
                        {!dataCampaign && <ErrorProduct text="Không có quy định bán hàng" />}
                    </div>
                </div>
            )}
            {dataCampaign && (
                <div className="form__btn">
                    <Button
                        onClick={() => dispatch(toggleShowTutorial(false))}
                        size="large"
                        type="primary"
                        className="w-100"
                    >
                        Đã hiểu! Bán hàng ngay
                    </Button>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        isTutorial: state.accountReducer.isTutorial,
    }
}
export default withRouter(connect(mapStateToProps)(TutorialAccount))
