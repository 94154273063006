import { ACCOUNT_TYPE_REDUCER } from '../../constants/home-contants'

export const toggleShowChangePassword = (boolean) => {
    return {
        type: ACCOUNT_TYPE_REDUCER.CHANGE_PASSWORD,
        isChangePassword: boolean,
    }
}

export const toggleShowUserInfo = (boolean) => {
    return {
        type: ACCOUNT_TYPE_REDUCER.USER_INFO,
        isUserInfo: boolean,
    }
}

export const toggleShowEditUserInfo = (boolean) => {
    return {
        type: ACCOUNT_TYPE_REDUCER.EDIT_USER_INFO,
        isEditUserInfo: boolean,
    }
}

export const toggleShowTutorial = (boolean) => {
    return {
        type: ACCOUNT_TYPE_REDUCER.TUTORIAL_ACCOUNT,
        isTutorial: boolean,
    }
}

export const toggleShowPopupRegister = (boolean) => {
    return {
        type: ACCOUNT_TYPE_REDUCER.POPUP_REGISTER,
        isShowPopupRegister: boolean,
    }
}
