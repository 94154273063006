import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Avatar, Button } from 'antd'
import moment from 'moment'
import 'moment/min/locales.min'
import { RightOutlined, ShareAltOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons'
import BoxShare from '../common/popup/BoxShare'
import { togglePopupShare } from '../../redux/actions/home-actions'
import { getSession, isMobileScreen } from '../../functions/common-func'
import { connect } from 'react-redux'
// import ChangePasswordContainer from '../../container/user/ChangePasswordContainer'
import { toggleShowTutorial, toggleShowUserInfo } from '../../redux/actions/account-action'
import UserInfoContainer from '../../container/user/UserInfoContainer'
import TutorialAccountContainer from '../../container/user/TutorialAccountContainer'
import UserInfo from '../user/UserInfo'
import EditUserInfoContainer from '../../container/user/EditUserInfoContainer'

function Account({ handleLogout, handleShareWithMobile, dispatch, isPublisher, typeAccount, handleSetType }) {
    let user = getSession('userInfo')?.userInfo
    return (
        <div>
            <div className={`account`}>
                {isMobileScreen() && (
                    <div className="account__content">
                        <div className="account__item ">
                            <div className="container">
                                <div className="user_info">
                                    <div className="user_info_content">
                                        <Avatar
                                            size={64}
                                            src={
                                                (user && user.avatar) ||
                                                (user &&
                                                    user.userAccount &&
                                                    user.userAccount.length > 0 &&
                                                    user.userAccount[0].avatar) ||
                                                'https://spursbistro.com/wp-content/uploads/2020/06/usericon.png'
                                            }
                                        />
                                        <div className="user_name">
                                            <p className="name">{user && (user.username || user.email)}</p>
                                            {user && user.dateJoined && (
                                                <p className="time-join">
                                                    Thành viên từ: {moment(user.dateJoined).format('DD/MM/YYYY')}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="account__item">
                            <div className="container">
                                <div className="title-2" onClick={() => dispatch(toggleShowUserInfo(true))}>
                                    <h2>Thông tin Tài khoản</h2>
                                    <RightOutlined />
                                </div>
                            </div>
                        </div>
                        {isPublisher !== 1 && (
                            <div className="account__item">
                                <div className="container">
                                    <Link style={{ color: '#262626' }} to="/account/booking-management">
                                        <div className="title-2">
                                            <h2>Quản lý đơn hàng</h2>
                                            <RightOutlined />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )}
                        <div className="account__item">
                            <div className="container">
                                <div className="title-2" onClick={() => dispatch(toggleShowTutorial(true))}>
                                    <h2>Hướng dẫn, chính sách bán hàng</h2>
                                    <RightOutlined />
                                </div>
                                <TutorialAccountContainer type={'account'} />
                            </div>
                        </div>
                        <div className={`account__item account_share `}>
                            <div className="container">
                                <div
                                    className="title-2"
                                    // onClick={() => dispatch(togglePopupShare(true, 'link', 'Gửi lời mời sử dụng Dimuadi'))}
                                    onClick={handleShareWithMobile}
                                >
                                    <h2>Mời bạn bè</h2>
                                    <ShareAltOutlined />
                                </div>
                                <BoxShare
                                    dataDetail={{ thumbImage: process.env.REACT_APP_URL_WEBSITE }}
                                    title="Gửi lời mời sử dụng Dimuadi"
                                    onClose={() => dispatch(togglePopupShare(false, 'link', ''))}
                                />
                            </div>
                        </div>
                        {/*<div className="account__item">*/}
                        {/*    <div className="container">*/}
                        {/*        <div className="title-2" onClick={() => dispatch(toggleShowChangePassword(true))}>*/}
                        {/*            <h2>Đổi mật khẩu</h2>*/}
                        {/*            <RightOutlined />*/}
                        {/*        </div>*/}
                        {/*        <ChangePasswordContainer />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="account__item disabled-border">
                            <div className="container">
                                <Button onClick={handleLogout} size="large" className="w-100 btn-logout">
                                    {' '}
                                    Đăng xuất{' '}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
                {!isMobileScreen() && (
                    <div className="account__desktop">
                        <div className="account__desktop__right">
                            <div className="user_info ">
                                <div className="user_info_content">
                                    <Avatar
                                        size={64}
                                        src={
                                            (user && user.avatar) ||
                                            (user &&
                                                user.userAccount &&
                                                user.userAccount.length > 0 &&
                                                user.userAccount[0].avatar) ||
                                            'https://spursbistro.com/wp-content/uploads/2020/06/usericon.png'
                                        }
                                    />
                                    <div className="user_name">
                                        <p className="name">{user && (user.username || user.email)}</p>
                                        {user && user.dateJoined && (
                                            <p className="time-join">
                                                Thành viên từ: {moment(user.dateJoined).format('DD/MM/YYYY')}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="menu__account">
                                <div
                                    className={`item__account ${typeAccount === 'info' ? 'active' : ''}`}
                                    onClick={() => handleSetType('info')}
                                >
                                    <UserOutlined />
                                    <span>Thông tin tài khoản</span>
                                </div>
                                <div
                                    className={`item__account ${typeAccount === 'setting' ? 'active' : ''}`}
                                    onClick={() => handleSetType('setting')}
                                >
                                    <SettingOutlined />
                                    <span>Thiết lập tài khoản</span>
                                </div>
                            </div>
                        </div>
                        <div className="account__desktop__left">
                            {typeAccount === 'info' && <UserInfo />}
                            {typeAccount === 'setting' && <EditUserInfoContainer />}
                        </div>
                    </div>
                )}
            </div>
            <UserInfoContainer />
            <EditUserInfoContainer />
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        toggleShare: state.homeReducer.toggleShare,
        isPublisher: state.homeReducer.isPublisher,
    }
}
export default withRouter(connect(mapStateToProps)(Account))
