import React from 'react'
import { Input, Checkbox, Button, Form } from 'antd'
import logo from '../../assets/images/logoDimuadi.svg'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { togglePopupRule } from '../../redux/actions/home-actions'

function Register({
    validateLastName,
    validateFirstName,
    validateRePassword,
    validatePassword,
    validatePhone,
    validateUserName,
    firstName,
    lastName,
    phone,
    userName,
    password,
    rePassword,
    handleChangeFirstName,
    handleChangeLastName,
    handleChangePhone,
    handleChangeUserName,
    handleChangePassword,
    handleChangeRePassword,
    handleRegister,
    dispatch,
    toggleRule,
}) {
    return (
        <section className="register">
            <div className="container">
                <div className="register__content">
                    <div className="header-logo">
                        <img src={logo} alt="logo d2c" />
                    </div>
                    <div className="login-title">
                        <h2>ĐĂNG KÝ TÀI KHOẢN PUBLISHER</h2>
                    </div>
                    <div className="register-form">
                        <div className="form-group">
                            <label>Họ</label>
                            <Form.Item
                                name="lastName"
                                className="el-input__inner"
                                validateStatus={validateFirstName.status}
                                help={validateFirstName.text}
                            >
                                <Input
                                    placeholder="Nhập họ của bạn"
                                    value={firstName}
                                    size="large"
                                    onChange={handleChangeFirstName}
                                />
                            </Form.Item>
                        </div>
                        <div className="form-group">
                            <label>Tên</label>
                            <Form.Item
                                name="firstName"
                                className="el-input__inner"
                                validateStatus={validateLastName.status}
                                help={validateFirstName.text}
                            >
                                <Input
                                    placeholder="Nhập tên của bạn"
                                    value={lastName}
                                    size="large"
                                    onChange={handleChangeLastName}
                                />
                            </Form.Item>
                        </div>
                        <div className="form-group">
                            <label>Số điện thoại</label>
                            <Form.Item
                                name="phone"
                                className="el-input__inner"
                                validateStatus={validatePhone.status}
                                help={validatePhone.text}
                            >
                                <Input
                                    placeholder="Nhập số điện thọai"
                                    value={phone}
                                    size="large"
                                    onChange={handleChangePhone}
                                />
                            </Form.Item>
                        </div>
                        <div className="form-group">
                            <label>Tên đăng nhập</label>
                            <Form.Item
                                name="username"
                                className="el-input__inner"
                                validateStatus={validateUserName.status}
                                help={validateUserName.text}
                            >
                                <Input
                                    placeholder="Nhập tên đăng nhập"
                                    value={userName}
                                    size="large"
                                    onChange={handleChangeUserName}
                                />
                            </Form.Item>
                        </div>
                        <div className="form-group">
                            <label>Mật khẩu</label>
                            <Form.Item
                                name="password"
                                className="el-input__inner"
                                validateStatus={validatePassword.status}
                                help={validatePassword.text}
                            >
                                <Input.Password
                                    placeholder="Nhập mật khẩu"
                                    value={password}
                                    size="large"
                                    onChange={handleChangePassword}
                                />
                            </Form.Item>
                        </div>
                        <div className="form-group">
                            <label>Nhập lại mật khẩu</label>
                            <Form.Item
                                name="rePassword"
                                className="el-input__inner"
                                validateStatus={validateRePassword.status}
                                help={validateRePassword.text}
                            >
                                <Input.Password
                                    placeholder="Nhập lại mật khẩu"
                                    value={rePassword}
                                    size="large"
                                    onChange={handleChangeRePassword}
                                />
                            </Form.Item>
                        </div>
                        <div className={`form-checkbox ${toggleRule ? 'open' : ''}`}>
                            <Checkbox>
                                Đồng ý với{' '}
                                <a onClick={() => dispatch(togglePopupRule(true))}>các điều khoản cho đối tác</a>
                            </Checkbox>
                        </div>
                        <div className="form-checkbox">
                            <Checkbox>Đồng ý đăng ký nhận thông tin từ Dimuadi</Checkbox>
                        </div>
                        <p style={{ marginTop: 15 }}>
                            Khi đăng ký tài khoản, bạn đồng ý với chính sách quyền riêng tư, các điều khản của
                            Dimuadi
                        </p>
                        <Button
                            onClick={handleRegister}
                            style={{ margin: '20px 0' }}
                            size="large"
                            className="w-100"
                            type="primary"
                        >
                            Đăng ký
                        </Button>
                    </div>
                    <p className="tip-login">
                        Bạn đã có tài khoản?
                        <Link to="/login-account" className="sign-in">
                            {' '}
                            Đăng nhập
                        </Link>
                    </p>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = function (state) {
    return {
        toggleRule: state.homeReducer.toggleRule,
    }
}
export default withRouter(connect(mapStateToProps)(Register))
