import React from 'react'
import { withRouter } from 'react-router-dom'
import { Avatar } from 'antd'
import moment from 'moment'
import 'moment/min/locales.min'
import { EditOutlined, StarFilled, FlagFilled, InfoCircleFilled, MailFilled, PhoneFilled } from '@ant-design/icons'
import { IconGender } from '../../constants/icon-const'
import { connect } from 'react-redux'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import { getSession, isMaxTableScreen, isMobileScreen } from '../../functions/common-func'
import { toggleShowEditUserInfo, toggleShowUserInfo } from '../../redux/actions/account-action'

function UserInfo({ isUserInfo, dispatch }) {
    let user = getSession('userInfo')?.userInfo
    return (
        <div className={`user-info ${isUserInfo ? 'open' : ''}`}>
            <BoxHeaderTitle text="Chi tiết tài khoản" onClose={() => dispatch(toggleShowUserInfo(false))} />
            <div className="user-info__content">
                {isMaxTableScreen() && isMobileScreen() && (
                    <div className="user__info__item ">
                        <div className="container">
                            <div className="user_info">
                                <div className="user_info_content">
                                    <Avatar
                                        size={64}
                                        src={
                                            (user && user.avatar) ||
                                            (user &&
                                                user.userAccount &&
                                                user.userAccount.length > 0 &&
                                                user.userAccount[0].avatar) ||
                                            'https://spursbistro.com/wp-content/uploads/2020/06/usericon.png'
                                        }
                                    />
                                    <div className="user_name">
                                        <p className="name">{user && (user.username || user.email)}</p>
                                        {user && user.dateJoined && (
                                            <p className="time-join">
                                                Thành viên từ: {moment(user.dateJoined).format('DD/MM/YYYY')}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="user_edit" onClick={() => dispatch(toggleShowEditUserInfo(true))}>
                                    <EditOutlined className="mr-5" style={{ color: '#595959', marginRight: 5 }} />
                                    Sửa
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="basic__info user__info__item">
                    <div className="container">
                        <div className="title">
                            <h2>Thông tin Cơ bản</h2>
                        </div>
                        <div className="user__item__content">
                            <div className="info__item">
                                <StarFilled style={{ color: '#8C8C8C' }} className="mr-10" />
                                <span>{user && (user.username || user.email)}</span>
                            </div>
                            {user && user.siteLink && (
                                <div className="info__item">
                                    <FlagFilled style={{ color: '#8C8C8C' }} className="mr-10" />
                                    <span>{user.siteLink}</span>
                                </div>
                            )}

                            {user && user.description && (
                                <div className="info__item">
                                    <InfoCircleFilled style={{ color: '#8C8C8C' }} className="mr-10" />
                                    <span>{user.description}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="basic__info user__info__item basic__info__desktop">
                    <div className="container">
                        <div className="title">
                            <h2>Thông tin không hiển thị</h2>
                        </div>
                        <div className="user__item__content">
                            {user && user.email && (
                                <div className="info__item">
                                    <MailFilled style={{ color: '#8C8C8C' }} className="mr-10" />
                                    <span>{user.email}</span>
                                </div>
                            )}
                            {user && user.phone && (
                                <div className="info__item">
                                    <PhoneFilled style={{ color: '#8C8C8C' }} className="mr-10" />
                                    <span>{user.phone}</span>
                                </div>
                            )}
                            {user && user.gender && (
                                <div className="info__item">
                                    <IconGender className="mr-10" />
                                    <span>{user.gender === 'male' ? 'Nam' : 'Nữ'}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = function (state) {
    return {
        isUserInfo: state.accountReducer.isUserInfo,
    }
}
export default withRouter(connect(mapStateToProps)(UserInfo))
