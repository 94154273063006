import React, { useState, useEffect } from 'react'
import moment from 'moment'
import 'moment/min/locales.min'
import { message } from 'antd'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import Home from '../../component/home/Home'
import {
    fetchListBanner,
    fetchListCategory,
    fetchListHighDiscount,
    fetchListMostShared,
    fetchListSuggestion,
    fetchListTop,
    fetchReportHomePage,
    fetchDealHot,
    fetchCouponsHome,
    fetchNomalCouponsHome
} from '../../api-services/home-api'
import * as queryString from 'query-string'
import { getIsShowTutorial, getSession, setSession } from '../../functions/common-func'
import { togglePopupWelcomeUser, toggleShowLoading } from '../../redux/actions/home-actions'
import { getUserInfo, upgradeRoleForUser } from '../../api-services/login-api'
import { fetchListProduct } from '../../api-services/product-api'

function HomeContainer({ isPublisher, location, history, dispatch, toggleBoxSearch, isLoggedIn }) {
    const [deadline] = useState(moment().add(1, 'days').toDate())
    const [listCategory, setListCategory] = useState([])
    const [listHighDiscount, setListHighDiscount] = useState([])
    const [listSuggestion, setListSuggestion] = useState([])
    const [listMostShared, setListMostShared] = useState([])
    const [listTop, setListTop] = useState([])
    const [dataReport, setDataReport] = useState(false)
    const [listBanner, setListBanner] = useState(false)
    const [pagingSuggestion, setPagingSuggestion] = useState({ page: 1, pageSize: 24, totalPage: 1 })
    const [reLoadingSuggestion, setReLoadingSuggestion] = useState(false)
    const [nameProduct, setNameProduct] = useState('')
    const [listProductSearchDesktop, setListProductSearchDesktop] = useState([])
    const [isOpenProduct, setIsOpenProduct] = useState(false)
    const [listDealHot, setListDealHot] = useState([])
    const [hotCoupon,setHotCoupon] = useState([]);

    if (
        window.location.href === 'https://banhang.dimuadi.vn' ||
        window.location.href === 'https://banhang.dimuadi.vn/'
    ) {
        // if (!isLoggedIn) {
        //     window.location = 'https://banhang.dimuadi.vn/login'
        // }
    }

    useEffect(() => {
        getListCategory()
        getListHighDiscount()
        getListMostShared()
        getListTop()
        getListBanner()
        getListDealHot()
        getListHotCoupon();
        if (isLoggedIn) {
            getReportHomePage()
        }
    }, [isLoggedIn])

    useEffect(() => {
        let isShowTutorial = getIsShowTutorial()
        if (!isShowTutorial && isLoggedIn) {
            dispatch(togglePopupWelcomeUser(true))
        }
    }, [])

    useEffect(() => {
        const paramSearch = {}
        let check = !!(pagingSuggestion.page < pagingSuggestion.totalPage)

        if (pagingSuggestion && reLoadingSuggestion && check) {
            paramSearch.page = pagingSuggestion.page + 1
            paramSearch.page_size = pagingSuggestion.pageSize
            getListSuggestion(paramSearch)
        }
    }, [reLoadingSuggestion])

    useEffect(() => {
        const query = queryString.parse(location.search)
        const paramSearch = {}
        paramSearch.page = query.page || 1
        paramSearch.page_size = query.pageSize || 24
        getListSuggestionDesktop(paramSearch)
        localStorage.removeItem('cartCoupon');
    }, [location])

    useEffect(() => {
        onSearch(nameProduct)
    }, [nameProduct])

    // useEffect(() => {
    //     const paramSearch = {}
    //     let check = !!(pagingSuggestion.page < pagingSuggestion.totalPage)
    //     if (pagingSuggestion && reLoadingSuggestion && check) {
    //         paramSearch.page = pagingSuggestion.page + 1
    //         paramSearch.page_size = pagingSuggestion.pageSize
    //         getListSuggestion(paramSearch)
    //     }
    // }, [reLoadingSuggestion])

    const getListSuggestion = async (paramSearch) => {
        
        try {
            const data = await fetchListSuggestion(paramSearch)
            if (data?.status === 200) {
                if (reLoadingSuggestion) {
                    let _list = [...listSuggestion]
                    _list = _list.concat(data?.data || [])
                    setListSuggestion(_list)
                    setPagingSuggestion(data?.paging)
                } else {
                    setListSuggestion(data?.data )
                    setPagingSuggestion(data?.paging)
                }
            } else {
                message.error(data?.message)
            }
        } catch (e) {
            throw e
        }
    }
    const getListHotCoupon = async () => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await fetchCouponsHome()
            dispatch(toggleShowLoading(false))

            if (data?.status === 200) {
                let _list = data?.data || [];
                if(data?.data?.length < 3 ) {
                    const dataNotHot = await fetchNomalCouponsHome(3 - (data?.data?.length ?? 0)) 
                    if(dataNotHot?.status === 200 || dataNotHot?.data) {
                        _list = _list.concat(dataNotHot?.data || []);
                        setHotCoupon(_list);
                    } else {
                        message.error(dataNotHot?.message);
                    }
                } else {
                    setHotCoupon(data?.data)
                }  
            } else {
                message.error(data?.message);
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            throw e
        }
    }

    const getListSuggestionDesktop = async (paramSearch) => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await fetchListSuggestion(paramSearch)
            dispatch(toggleShowLoading(false))

            if (data?.status === 200) {
                setListSuggestion(data?.data)
                setPagingSuggestion(data?.paging)
            } else {
                message.error(data?.message)
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            throw e
        }
    }

    const getListCategory = async () => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await fetchListCategory()
            dispatch(toggleShowLoading(false))
            if (data?.status === 200) {
                setListCategory(data?.data)
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            throw e
        }
    }

    const getListHighDiscount = async () => {
        try {
            const data = await fetchListHighDiscount()
            if (data?.status === 200) {
                setListHighDiscount(data?.data || []);
            } else {
                message.error(data?.message)
            }
        } catch (e) {
            throw e
        }
    }

    const getListMostShared = async () => {
        try {
            const data = await fetchListMostShared()
            if (data?.status === 200) {
                setListMostShared(data?.data)
            } else {
                message.error(data?.message)
            }
        } catch (e) {
            throw e
        }
    }

    const getListTop = async () => {
        try {
            const params = {}
            params.page = 1
            params.page_size = 5
            const data = await fetchListTop(params)
            if (data?.status === 200) {
                setListTop(data?.data)
            } else {
                message.error(data?.message)
            }
        } catch (e) {
            throw e
        }
    }

    const getListBanner = async () => {
        try {
            const data = await fetchListBanner()
            if (data?.status === 200) {
                setListBanner(data?.data)
            } else {
                message.error(data?.message)
            }
        } catch (e) {
            throw e
        }
    }
    const getReportHomePage = async () => {
        try {
            const data = await fetchReportHomePage()
            if (data?.status === 200) {
                setDataReport(data?.data)
            } else {
                // message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    const onChangeSearchHome = (e) => {
        const value = e.target.value
        const query = queryString.parse(location.search)
        let new_query = { ...query }
        value ? (new_query = { ...query, name: value }) : delete new_query.name
        const stringify = queryString.stringify(new_query)
        history.push(`${location.pathname}?${stringify}`)
    }

    const onKeyPressSearchHome = (e) => {
        if (e.charCode === 13) {
            const value = e.target.value.trim() 
            if (value) {
                const query = queryString.parse(location.search)
                let new_query = { ...query }
                value ? (new_query = { ...query, name: value }) : delete new_query.name
                const stringify = queryString.stringify(new_query)
                history.push(`/list-product?${stringify}`)
            }
        }
    }

    const handleIconSearchClick = () => {
        //setNameProduct(nameProduct.trim())1
        const nameProductTrim = nameProduct.trim()
        if (nameProductTrim) {
            const query = queryString.parse(location.search)
            let new_query = { ...query }
            nameProductTrim ? (new_query = { ...query, name: nameProductTrim }) : delete new_query.name
            const stringify = queryString.stringify(new_query)
            history.push(`/list-product?${stringify}`)
        }
    }

    const handleChangeName = (e) => {
        setNameProduct(e.target.value)
    }

    const onSearch = async (keyword) => {
        if (keyword.trim().length >= 2) {
            keyword = keyword.trim()
            setIsOpenProduct(true)
            try {
                let filter = '?'
                filter += `name=${keyword}`
                await getListProductSearch(filter)
            } catch (e) {
                throw e
            }
        } else {
            if (keyword.length === 0) {
                setIsOpenProduct(false)
                setListProductSearchDesktop([])
            }
        }
    }

    const getListProductSearch = async (filter) => {
        try {
            const data = await fetchListProduct(filter)
            if (data?.status === 200) {
                setListProductSearchDesktop(data?.data)
            } else {
                message.error(data?.message)
                setListProductSearchDesktop([])
            }
        } catch (e) {
            setListProductSearchDesktop([])
            throw e
        }
    }

    const upgradeRole = async () => {
        if (isLoggedIn) {
            const data = {
                roleId: 1,
            }
            let user = getSession('userInfo')?.userInfo
            await upgradeRoleForUser(data)
            const dataUpdate = await getUserInfo()
            if (dataUpdate && dataUpdate.status === 200 && dataUpdate.data) {
                user.userRole = dataUpdate.data.userRole
                setSession('userInfo', dataUpdate.data, 7)
                localStorage.setItem(
                    'role',
                    JSON.stringify({
                        role: 1,
                    })
                )
                // window.location.href = '/'
            }
        } else {
            history.push('/login?role=1')
        }
    }

    const getListDealHot = async () => {
        try {
            const data = await fetchDealHot()
            if (data?.status === 200) {
                setListDealHot(data?.data)
            } else {
                message.error(data?.message)
            }
        } catch (e) {
            throw e
        }
    }

    return (
        <Home
            listHighDiscount={listHighDiscount}
            listCategory={listCategory}
            listSuggestion={listSuggestion}
            listMostShared={listMostShared}
            listTop={listTop}
            listBanner={listBanner}
            listDealHot={listDealHot}
            isPublisher={isPublisher}
            nameProduct={nameProduct}
            dataReport={dataReport}
            deadline={deadline}
            dispatch={dispatch}
            pagingSuggestion={pagingSuggestion}
            toggleBoxSearch={toggleBoxSearch}
            listProductSearchDesktop={listProductSearchDesktop}
            isOpenProduct={isOpenProduct}
            setReLoadingSuggestion={setReLoadingSuggestion}
            reLoadingSuggestion={reLoadingSuggestion}
            onChangeSearchHome={onChangeSearchHome}
            onKeyPressSearchHome={onKeyPressSearchHome}
            handleIconSearchClick={handleIconSearchClick}
            handleChangeName={handleChangeName}
            hotCoupon={hotCoupon}
            setHotCoupon={setHotCoupon}
            upgradeRole={upgradeRole}
            isLoggedIn={isLoggedIn}
        />
    )
}
const mapStateToProps = function (state) {
    return {
        isPublisher: state.homeReducer.isPublisher,
        toggleBoxSearch: state.homeReducer.toggleBoxSearch,
        isLoggedIn: state.homeReducer.isLoggedIn,
    }
}
export default withRouter(connect(mapStateToProps)(HomeContainer))
