import React, { useEffect, useState } from 'react'
import NotificationDetail from '../../component/notification/NotificationDetail'
import { useRouteMatch } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { fetchNotificationById } from '../../api-services/notionfication-api'
import { toggleShowLoading } from '../../redux/actions/home-actions'

function NotificationDetailContainer() {
    const match = useRouteMatch()
    const dispatch = useDispatch()
    const [dataDetail, setDataDetail] = useState(null)

    const { isLoggedIn } = useSelector((state) => {
        return {
            isLoggedIn: state.homeReducer.isLoggedIn,
        }
    })

    // if(!isLoggedIn) {
    //     window.location = `${window.location.origin}/login`;
    // }

    useEffect(() => {
        const id = match.params.id
        getDetailNotification(id)
    }, [])

    const getDetailNotification = async (id) => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await fetchNotificationById(id)
            dispatch(toggleShowLoading(false))
            if (data && data.status === 200) {
                setDataDetail(data.data)
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            throw e
        }
    }
    return <NotificationDetail dataDetail={dataDetail} />
}

export default NotificationDetailContainer
