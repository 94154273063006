import { checkMinMax, convertCommission } from "../../functions/common-func"
import DisplayPrice from "../common/DisplayPrice"
import React from "react"
const ShowComm = (props) => {
    const { min, max, commValue, commType, className = '' } = props
    const minComm = convertCommission(min, commValue, commType)
    const maxComm = convertCommission(max, commValue, commType)
    return (
        <span className={'price__discount ' + className } >
            {minComm === maxComm && <>

                <DisplayPrice
                    price={minComm}
                />
            </>

            }
            {minComm !== maxComm && <>
                <DisplayPrice
                    price={minComm}
                /> -
                <DisplayPrice
                    price={maxComm}
                />

            </>

            }
        </span>)

}
export default ShowComm