import ApiMicroService from './request-micro-service'

export const createNotification = async (data) => {
    try {
        const results = await ApiMicroService.post(`/d2c-service/notifications`, data)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchListNotification = async () => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/notifications`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchListNotificationUnRead = async () => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/notifications/unread`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchListNotificationAccesstrade = async () => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/notifications/accesstrades-notifications`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchNotificationById = async (id) => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/notifications/accesstrades-notifications/${id}`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const notificationUserToken = async (data) => {
    try {
        const results = await ApiMicroService.post(`/d2c-service/notifications/user-token`, data)
        return results.data
    } catch (e) {
        throw e
    }
}

export const readNotification = async (id) => {
    try {
        const results = await ApiMicroService.put(`/d2c-service/notifications/${id}`)
        return results.data
    } catch (e) {
        throw e
    }
}
