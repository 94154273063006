import React from 'react'
import { Button, Popconfirm, message } from 'antd'
import moment from 'moment'
import 'moment/min/locales.min'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { toggleShowActionAddress } from '../../redux/actions/checkout-action'
import { deleteAddress } from '../../api-services/address-api'
import { toggleShowLoading } from '../../redux/actions/home-actions'

function BoxEditDeleteAddress({ isActionAddress, dispatch, idAddress, setRefresh, isDefault }) {
    const confirm = async () => {
        dispatch(toggleShowActionAddress(false))
        try {
            dispatch(toggleShowLoading(true))
            const data = await deleteAddress(idAddress)
            dispatch(toggleShowLoading(false))

            if (data && data.status === 200) {
                message.success('Xóa địa chỉ thành công')
                setRefresh(moment())
                dispatch(toggleShowActionAddress(false))
            } else {
                dispatch(toggleShowActionAddress(false))
                message.error('Xóa địa chỉ thất bại')
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            dispatch(toggleShowActionAddress(false))
            message.error('Xóa địa chỉ thất bại')
            throw e
        }
    }
    return (
        <div className={`box__edit__delete ${isActionAddress ? 'open' : ''}`}>
            <div className="box__edit__delete__content">
                <div className="action__item edit">
                    <Link onClick={() => dispatch(toggleShowActionAddress(false))} to={`/address/edit/${idAddress}`}>
                        <Button className="w-100 height-48" size="large">
                            Chỉnh sửa
                        </Button>
                    </Link>
                    {isDefault.id !== idAddress && (
                        <Popconfirm
                            placement="top"
                            title={'Bạn muốn xoá địa chỉ này ra khỏi sổ địa chỉ?'}
                            onConfirm={confirm}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button className="w-100 height-48" size="large">
                                Xóa
                            </Button>
                        </Popconfirm>
                    )}
                </div>
                <div className="action__item delete" onClick={() => dispatch(toggleShowActionAddress(false))}>
                    <Button className="w-100 height-48">Hủy</Button>
                </div>
            </div>
            <div className="box__backdrop" onClick={() => dispatch(toggleShowActionAddress(false))}></div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        isActionAddress: state.checkoutReducer.isActionAddress,
        idAddress: state.checkoutReducer.idAddress,
    }
}
export default withRouter(connect(mapStateToProps)(BoxEditDeleteAddress))
