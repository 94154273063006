import { ACCOUNT_TYPE_REDUCER } from '../../constants/home-contants'

const accountReducer = (state = {}, action) => {
    switch (action.type) {
        case ACCOUNT_TYPE_REDUCER.CHANGE_PASSWORD:
            return {
                ...state,
                isChangePassword: action.isChangePassword,
            }
        case ACCOUNT_TYPE_REDUCER.USER_INFO:
            return {
                ...state,
                isUserInfo: action.isUserInfo,
            }
        case ACCOUNT_TYPE_REDUCER.EDIT_USER_INFO:
            return {
                ...state,
                isEditUserInfo: action.isEditUserInfo,
            }
        case ACCOUNT_TYPE_REDUCER.TUTORIAL_ACCOUNT:
            return {
                ...state,
                isTutorial: action.isTutorial,
            }
        case ACCOUNT_TYPE_REDUCER.POPUP_REGISTER:
        return {
            ...state,
            isShowPopupRegister: action.isShowPopupRegister,
        }
        default:
            return { ...state }
    }
}

export default accountReducer
