import React  from 'react'
// import { setCurrentRole } from '../../redux/actions/home-actions'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

function NotiUser({ currentRole }) {
    // useEffect(() => {
    //     window.OneSignal = window.OneSignal || []
    //     window.OneSignal.push(function () {
    //         window.OneSignal.sendTag('user_type', currentRole)
    //     })
    // }, [setCurrentRole])
    return null
}

const mapStateToProps = function (state) {
    return {
        currentRole: state.homeReducer.currentRole,
    }
}
export default withRouter(connect(mapStateToProps)(NotiUser))
