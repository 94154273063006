import BoxHeaderTitle from '../common/BoxHeaderTitle'
import { Link } from 'react-router-dom'
import { PATH_DEAL, PATH_PRODUCT } from '../../constants/common-contants'
import React, { useEffect, useState } from 'react'
import { fetchListDealHot } from '../../api-services/home-api'
import { Button } from 'antd'
import swal from 'sweetalert'
import TopProduct from '../common/TopProduct/TopProduct'
import { isMobileScreen } from '../../functions/common-func'

function DealHot({ listDealHot, topProducts, history }) {
    const [dataDealHot, setDataDealHot] = useState([])
    const [totalItem, setTotalItem] = useState(false)
    const [page, setPage] = useState(1)
    let lockLoadMore = false

    useEffect(() => {
        if (listDealHot && listDealHot.data.length > 0) {
            setDataDealHot(listDealHot.data)
            setTotalItem(listDealHot.paging.total)
        }
    }, [listDealHot])

    const onClose = () => {
        if (history && history.length > 1) {
            history.goBack()
        } else {
            window.location.href = '/'
        }
    }

    const getAllDealHot = async (page) => {
        try {
            ++page
            setPage(page)
            const params = {
                page: page,
                page_size: 30,
                sort: 'ASC',
            }
            const data = await fetchListDealHot(params)
            if (data && data.data.length > 0) {
                setDataDealHot(dataDealHot.concat(data.data))
            }
        } catch (e) {
            throw e
        }
    }

    const showNotification = (text) => {
        swal({
            text: text,
            icon: 'info',
            showCancelButton: false,
            showConfirmButton: false,
            buttons: false,
            toast: true,
            timer: 1000,
        })
    }

    const handleSeeMore = async (page) => {
        if (!lockLoadMore) {
            lockLoadMore = true
            await getAllDealHot(page)
            lockLoadMore = false
        } else {
            showNotification('Data đang xử lí!')
        }
    }

    return (
        <div className="wrapper__deal__hot">
            <BoxHeaderTitle text="List deal hot" onClose={onClose} isBell={true} />
            <div className="content__deal__hot">
                <div className="row">
                    <div className="col-12 col-md-8">
                        <div className="deal__hot__info">
                            <div className="card card-mobile">
                                <p className="label-title">CÁC ƯU đãi hot</p>

                                {dataDealHot && dataDealHot.length > 0 && (
                                    <div className="row">
                                        {dataDealHot.map((deal, index) => {
                                            return (
                                                <Link
                                                    to={PATH_DEAL + '/' + deal.id}
                                                    className="col-xs-6 col-sm-3 col-md-3 deal__item__box"
                                                    key={'deal-item-' + deal.id}
                                                >
                                                    <div className="frame__img__item">
                                                        <img src={deal.avatar_home[0]} alt="" />
                                                    </div>
                                                </Link>
                                            )
                                        })}
                                    </div>
                                )}

                                {dataDealHot.length < totalItem && (
                                    <div className="text-center">
                                        <Button
                                            onClick={() => {
                                                handleSeeMore(page)
                                            }}
                                            size="large"
                                            className="btn_choose_blue btn_load_deal"
                                        >
                                            Xem thêm
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {!isMobileScreen() && <div className="col-12 col-md-4">
                        <div className="top__product">
                            <TopProduct />
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default DealHot
