import React, { useEffect, useState } from 'react'
import moment from 'moment'
import DisplayPrice from '../common/DisplayPrice'
import { Button } from 'antd'
import { useSelector } from 'react-redux'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import { checkMinMax, checkURLImage, convertCommission, convertTotalCommission, isMaxTableScreen, isMobileScreen, renderOrderDiscount, renderPriceAfterCP } from '../../functions/common-func'
import { DEFAULT_IMAGE } from '../../constants/common-contants'
import BreadcrumbNavigation from '../common/BreadcrumbNavigation'

function OrderDetail({ orderDetail, onClose }) {
    // const [totalCommission, setTotalCommission] = useState(0)1
    const { isPublisher } = useSelector((state) => {
        return {
            isPublisher: state.homeReducer.isPublisher,
        }
    })
    const options = [
        {
            label: 'Sản phẩm',
        },
        {
            label: '>',
        },
        {
            label: 'Chi tiết đơn hàng',
            active: true,
        },
    ]

    const countDiscount = () => {
        if(orderDetail?.coupon_id > 0) {
            return renderOrderDiscount(orderDetail)
        }
        return false;
    }
    const countPriceAfter = () => {
        let price = orderDetail?.productPrice * orderDetail?.quantity;
        if(orderDetail?.coupon_id > 0) {
            return renderPriceAfterCP(price, countDiscount())
        } 
        return price;
    }


    const returnStatus = (status) => {
        if (status === 'success') {
            return <span className="success"> Thành công</span>
        }
        if (status === 'fail') {
            return <span className="error"> Thất bại</span>
        }
        if (status === 'pending') {
            return <span className="pending"> Chờ xử lý</span>
        }
        if (status === 'pre_approved') {
            return <span className="pending"> Tạm duyệt</span>
        }
        if (status === 'approved') {
            return <span className="success"> Đã duyệt</span>
        }
        if (status === 'rejected') {
            return <span className="error"> Đã hủy</span>
        }
        if (status === 'hold') {
            return <span className="hold"> Tạm giữ</span>
        }
        return <span className="pending">{status}</span>
    }

    //pending = chờ xử lý, approved = Đã duyệt, rejected = đã huỷ, pre_approved = Tạm duyệt

    return (
        <div className="order__detail">
            <BoxHeaderTitle onClose={onClose} text="Chi tiết đơn hàng" />
            {orderDetail && (
                <div className="container">
                    <BreadcrumbNavigation option={options} />

                    <div className="order__detail__content">
                        {(isMobileScreen() || isMaxTableScreen()) && (
                            <div className="order-item order__info border__bottom">
                                <div className="box__title">
                                    <h3>Thông tin đơn hàng</h3>
                                </div>
                                <div className="order__info__item font-weight">
                                    <p>
                                        <span>Mã đơn hàng:</span> {orderDetail.code}
                                    </p>
                                </div>
                                <div className="order__info__item font-weight">
                                    <p>
                                        <span>Ngày đặt hàng:</span>{' '}
                                        {moment(orderDetail.createdAt).format('HH:mm, DD/MM/YYYY')}
                                    </p>
                                </div>
                                <div className="order__info__item font-weight">
                                    <p>
                                        <span>Trạng thái đơn hàng:</span> {returnStatus(orderDetail.status)}
                                    </p>
                                </div>
                                {/*<div className="order__info__item">*/}
                                {/*    <p>*/}
                                {/*        <span>Ghi chú:</span> Đơn hàng này là đơn hàng đầu tiên, mong nhà cung cấp hỗ trợ*/}
                                {/*        thêm tý tiền ăn trưa cho bọn em*/}
                                {/*    </p>*/}
                                {/*</div>*/}
                            </div>
                        )}
                        {(isMobileScreen() || isMaxTableScreen()) && (
                            <div className=" order-item order__info border__bottom">
                                <div className="box__title">
                                    <h3>Thông tin người nhận</h3>
                                </div>
                                <div className="order__info__item">
                                    <p>
                                        <span>Họ tên:</span> {orderDetail?.shippingAddress?.username}
                                    </p>
                                </div>
                                <div className="order__info__item">
                                    <p>
                                        <span>Số điện thoại:</span> {orderDetail?.shippingAddress?.phoneNumber}
                                    </p>
                                </div>
                                <div className="order__info__item">
                                    <p>
                                        <span>Địa chỉ:</span> {orderDetail?.shippingAddress?.address},{' '}
                                        {orderDetail?.shippingAddress?.wardName},{' '}
                                        {orderDetail?.shippingAddress?.districtName},{' '}
                                        {orderDetail?.shippingAddress?.provinceName},
                                    </p>
                                </div>
                            </div>
                        )}
                        <div className=" order-item product__info border__bottom">
                            <div className="box__title">
                                <h3>Thông tin sản phẩm</h3>
                            </div>

                            <div className="product__info__item">
                                <div className="item__image">
                                    <img
                                        src={checkURLImage(orderDetail?.metaData?.thumbImage) || DEFAULT_IMAGE}
                                        alt=""
                                    />
                                </div>
                                <div className="item__text">
                                    <p className="name">{orderDetail?.metaData?.name}</p>
                                    <div className="item__quantity">
                                        <span>Số lượng</span>
                                        <p className="price">{orderDetail?.quantity}</p>
                                    </div>
                                    <div className="item__price">
                                        <span>Giá thành</span>
                                        {!orderDetail.rangePrice && <p className="price">
                                            <DisplayPrice price={orderDetail.productPrice} />
                                        </p>}
                                        {orderDetail.rangePrice && <p className="price">{checkMinMax(orderDetail.rangePrice.minPrice, orderDetail.rangePrice.maxPrice)}</p>}
                                    </div>
                                    <div className="item__price">
                                        <span>Hoa hồng: </span>
                                        <p className="price"> {
                                            <DisplayPrice price={convertCommission(
                                                orderDetail?.productPrice,
                                                orderDetail?.metaData?.commissionValue,
                                                orderDetail?.metaData?.commissionType
                                            )} />
                                        }</p>
                                    </div>

                                    { countDiscount() &&
                                        <div className="item__price">
                                            <span>Discount: </span>
                                            <p className="price">
                                                {
                                                    <DisplayPrice price={countDiscount()}/>
                                                }
                                            </p>
                                            
                                        </div>
                                    }
                                    <div className="item__price">
                                        <span>Thành tiền</span>
                                        <p className="price">
                                            <DisplayPrice price={countPriceAfter()} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isPublisher === 1 && (
                            <div className="order-item order__commission">
                                <p className="text">
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12 1.5C6.202 1.5 1.5 6.202 1.5 12C1.5 17.798 6.202 22.5 12 22.5C17.798 22.5 22.5 17.798 22.5 12C22.5 6.202 17.798 1.5 12 1.5ZM12 20.719C7.186 20.719 3.281 16.814 3.281 12C3.281 7.186 7.186 3.281 12 3.281C16.814 3.281 20.719 7.186 20.719 12C20.719 16.814 16.814 20.719 12 20.719ZM13.118 11.456L12.523 11.318V8.17C13.413 8.292 13.964 8.85 14.058 9.534C14.069 9.628 14.149 9.696 14.243 9.696H15.295C15.405 9.696 15.492 9.6 15.483 9.49C15.34 8.03 14.137 7.092 12.532 6.93V6.164C12.532 6.061 12.448 5.977 12.344 5.977H11.686C11.583 5.977 11.498 6.061 11.498 6.164V6.938C9.839 7.099 8.541 8.016 8.541 9.727C8.541 11.311 9.708 12.075 10.934 12.368L11.513 12.516V15.86C10.477 15.722 9.895 15.169 9.776 14.423C9.762 14.334 9.682 14.269 9.591 14.269H8.508C8.398 14.269 8.311 14.363 8.32 14.473C8.426 15.762 9.403 16.948 11.49 17.1V17.836C11.49 17.939 11.573 18.023 11.677 18.023H12.342C12.445 18.023 12.53 17.939 12.53 17.833L12.525 17.091C14.36 16.929 15.673 15.947 15.673 14.184C15.67 12.558 14.637 11.831 13.118 11.456ZM11.51 11.076C11.379 11.039 11.269 11.004 11.159 10.959C10.366 10.673 9.999 10.212 9.999 9.616C9.999 8.766 10.643 8.28 11.51 8.17V11.077V11.076ZM12.523 15.867V12.734C12.595 12.754 12.661 12.771 12.729 12.785C13.838 13.123 14.21 13.591 14.21 14.311C14.21 15.227 13.521 15.778 12.523 15.867Z"
                                            fill="#1890FF"
                                        />
                                    </svg>
                                    Tổng hoa hồng:
                                </p>
                                <p className="price">
                                    <DisplayPrice price={convertTotalCommission(
                                            countPriceAfter(),
                                            orderDetail?.quantity,
                                            orderDetail?.metaData?.commissionValue,
                                            orderDetail?.metaData?.commissionType
                                        )} />
                                </p>
                            </div>
                        )}
                        {!(isMobileScreen() || isMaxTableScreen()) && (
                            <div className=" order-item order__info border__bottom">
                                <div className="box__title">
                                    <h3>Thông tin đơn hàng</h3>
                                </div>
                                <div className="orderAdmin__table">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Mã sản phẩm</th>
                                                <th>Ngày mua</th>
                                                <th>Trạng thái</th>
                                                <th>Khách hàng</th>
                                                <th>Số điện thoại</th>
                                                <th>Địa chỉ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{orderDetail.code}</td>
                                                <td>{moment(orderDetail.createdAt).format('HH:mm, DD/MM/YYYY')}</td>
                                                <td>
                                                    <div className="status">{returnStatus(orderDetail.status)}</div>
                                                </td>
                                                <td>
                                                    {' '}
                                                    {orderDetail.shippingAddress &&
                                                        orderDetail.shippingAddress.username}
                                                </td>
                                                <td>
                                                    {' '}
                                                    {orderDetail.shippingAddress &&
                                                        orderDetail.shippingAddress.phoneNumber}
                                                </td>
                                                <td>
                                                    {orderDetail?.shippingAddress?.address},{' '}
                                                    {orderDetail?.shippingAddress?.wardName},{' '}
                                                    {orderDetail?.shippingAddress?.districtName},{' '}
                                                    {orderDetail?.shippingAddress?.provinceName},
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default OrderDetail
