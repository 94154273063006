import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { Link, useLocation, withRouter } from 'react-router-dom'
import imgSuccess from '../../assets/images/checkOutSuccess.png'
import { connect, useSelector } from 'react-redux'
import * as queryString from 'query-string'
import { HOT_LINE } from '../../constants/common-contants'
import BreadcrumbNavigation from '../common/BreadcrumbNavigation'
function CheckOutSuccess({ isPublisher, location }) {
    const [orderId, setOrderId] = useState(false)
    const { pathname } = useLocation()

    const { isLoggedIn } = useSelector((state) => {
        return {
            isLoggedIn: state.homeReducer.isLoggedIn,
        }
    })

    // if(!isLoggedIn) {
    //     window.location = `${window.location.origin}/login`;
    // }
    localStorage.removeItem('cartCoupon');
    const options = [
        {
            label: 'Tạo đơn',
        },
        {
            label: '>',
        },
        {
            label: 'Kiểm tra',
        },
        {
            label: '>',
        },
        {
            label: 'Hoàn thành',
            active: true,
        },
    ]
    useEffect(() => {
        const query = queryString.parse(location.search)
        if (query.order_id) {
            setOrderId(query.order_id)
        }
    }, [location])
    return (
        <div className="page__success">
            <div className="container">
                <BreadcrumbNavigation option={options} />
                <div className="page__success__content">
                    <img src={imgSuccess} alt="images success" />
                    <div className="text">
                        {pathname === '/thanh-cong' ? <h2>Tạo đơn hàng thành công</h2> : <h2>Tạo đơn hàng thất bại</h2>}
                        {pathname !== '/thanh-cong' ? (
                            <>
                                <p>
                                    Rất tiếc ! Gặp lỗi hoặc sản phẩm bạn đang định bán đã hết. Vui lòng tạo lại đơn
                                    hàng.
                                </p>
                                <p>
                                    Hoặc liên hệ tổng đài hỗ trợ:{' '}
                                    <a href={`tel:${HOT_LINE}`} style={{ fontWeight: 600 }}>
                                        {' '}
                                        {HOT_LINE}{' '}
                                    </a>
                                </p>
                            </>
                        ) : (
                            <>
                                {isPublisher === 1 ? (
                                    <p>
                                        Chúc mùng bạn đã đặt đơn hàng thành công. Còn rất nhiều sản phẩm đang chờ bạn,
                                        nhanh tay kiếm hoa hồng thôi nào!
                                    </p>
                                ) : (
                                    <p>
                                        Cảm ơn bạn đã mua hàng tại Dimuadi!
                                        Người mua sẽ được gọi điện trong 24h để xác nhận đơn hàng.
                                    </p>
                                )}
                                {isPublisher === 1 && (
                                    <p>
                                        Kiểm tra đơn hàng: <strong>#{orderId}</strong>
                                        <Link to={`/order/${orderId}`} style={{ fontWeight: 600 }}>
                                            {' '}
                                            Tại đây{' '}
                                        </Link>
                                    </p>
                                )}
                            </>
                        )}
                    </div>
                    {isPublisher === 1 ? (
                        <div>
                            <Button size="large" className="btn__orange">
                                {pathname === '/thanh-cong' ? (
                                    <Link to="/list-product">Đặt đơn tiếp </Link>
                                ) : (
                                    <Link to="/list-product">Đặt lại đơn </Link>
                                )}
                            </Button>
                            <p className="or">Hoặc</p>
                            <Button size="large">
                                {' '}
                                <Link style={{ color: '#595959' }} to="/">
                                    Quay về trang chủ{' '}
                                </Link>
                            </Button>
                        </div>
                    ) : (
                        <Button size="large" className="btn__orange">
                            <Link to="/list-product">Đặt đơn tiếp </Link>
                        </Button>
                        // <Button size="large" className="btn__orange">
                        //     <Link to="/login">Trở thành Publisher</Link>
                        // </Button>
                    )}


                </div>
            </div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        isPublisher: state.homeReducer.isPublisher,
    }
}
export default withRouter(connect(mapStateToProps)(CheckOutSuccess))
