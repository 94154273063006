import React from 'react'
import { Form, Input, Select, Button } from 'antd'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { toggleShowEditUserInfo } from '../../redux/actions/account-action'
const { Option } = Select

function EditUserInfo({
    isEditUserInfo,
    dispatch,
    userName,
    email,
    phone,
    gender,
    siteLink,
    myDescription,
    validateUserName,
    validateEmail,
    validateMyDescription,
    handleChangeUsername,
    handleChangeEmail,
    handleChangePhone,
    handleChangeGender,
    handleChangeSiteLink,
    handleChangeDescription,
    updateUserInfo,
    validatePhone,
    validateGender,
}) {
    return (
        <div className={`edit__user__info ${isEditUserInfo ? 'open' : ''}`}>
            <BoxHeaderTitle text="Chỉnh sửa" onClose={() => dispatch(toggleShowEditUserInfo(false))} />
            <div className="edit__user__info__content">
                <div className="container__mobile">
                    <Form>
                        <div className="title">Thông tin cơ bản</div>
                        <Form.Item
                            validateStatus={validateUserName.status}
                            help={validateUserName.text}
                            className="form-group"
                        >
                            <label> Họ và tên</label>
                            <Input
                                value={userName}
                                onChange={handleChangeUsername}
                                size="large"
                                placeholder="Nguyễn Văn A"
                            />
                        </Form.Item>
                        <Form.Item
                            // validateStatus={validateUserName.status}
                            // help={validateUserName.text}
                            className="form-group"
                        >
                            <label> Website của bạn</label>
                            <Input
                                onChange={handleChangeSiteLink}
                                value={siteLink}
                                size="large"
                                placeholder="https://www.abc.vn"
                            />
                        </Form.Item>
                        <Form.Item
                            className="form-group"
                            validateStatus={validateMyDescription.status}
                            help={validateMyDescription.text}
                        >
                            <label> Mô tả cá nhân</label>
                            <Input
                                value={myDescription}
                                onChange={handleChangeDescription}
                                size="large"
                                placeholder="Mô tả cá nhân"
                            />
                        </Form.Item>
                        <div className="title">Thông tin hiển thị</div>
                        <Form.Item
                            className="form-group"
                            validateStatus={validateEmail.status}
                            help={validateEmail.text}
                        >
                            <label> Email</label>
                            <Input
                                onChange={handleChangeEmail}
                                value={email}
                                size="large"
                                placeholder="nguyenvana@gmail.com"
                            />
                        </Form.Item>
                        <Form.Item
                            validateStatus={validatePhone.status}
                            help={validatePhone.text}
                            className="form-group"
                        >
                            <label> Số điện thoại</label>
                            <Input
                                onChange={handleChangePhone}
                                value={phone}
                                size="large"
                                placeholder="Vd: 098765432"
                            />
                        </Form.Item>
                        <Form.Item
                            validateStatus={validateGender.status}
                            help={validateGender.text}
                            className="form-group"
                        >
                            <label>Giới tính</label>
                            <Select
                                onChange={handleChangeGender}
                                placeholder="Giới tính"
                                size="large"
                                value={gender}
                                style={{ width: '100%' }}
                            >
                                <Option value="male">Nam </Option>
                                <Option value="female">Nữ</Option>
                            </Select>
                        </Form.Item>
                        <div className="form__btn">
                            <Button onClick={updateUserInfo} size="large" type="primary" className="w-100">
                                Cập nhập thông tin
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = function (state) {
    return {
        isEditUserInfo: state.accountReducer.isEditUserInfo,
    }
}
export default withRouter(connect(mapStateToProps)(EditUserInfo))
