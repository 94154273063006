import React from 'react'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import ImageError from '../../assets/images/pageError.png'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
function NotFound() {
    localStorage.removeItem('cartCoupon');
    return (
        <div className="not__found__page">
            <BoxHeaderTitle link="/" text="Quay lại" />
            <div className="container">
                <div className="not__found__page__content">
                    <img src={ImageError} alt="page not found" />
                    <p className="text">Trang bạn đang truy cập bị lỗi rồi Trở lại sau nhé . . .</p>
                    <Button size="large" type="primary">
                        <Link to="/"> Đặt đơn tiếp </Link>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default NotFound
