import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form, Input, Button, Modal, message } from 'antd'
import DisplayPrice from '../common/DisplayPrice'
import BoxSearch from '../common/BoxSearch'
import BoxConfirmCart from '../create-order/BoxConfirmCart'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import { ArrowLeftOutlined, SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import { IconHomeChietKhau } from '../../constants/icon-const'
import {
    checkMinMax,
    checkURLImage,
    convertCommission,
    convertSizeImage,
    isMaxTableScreen,
    isMobileScreen,
} from '../../functions/common-func'
import { togglePopupBoxSearch } from '../../redux/actions/home-actions'
import { Swiper, SwiperSlide } from 'swiper/react'
import LoadingComponent from '../common/LoadingComponent'
import ErrorProduct from '../common/ErrorProduct'
import SuggestProduct from './SuggestProduct'
import PaginationContainer from '../../container/common/PaginationContainer'
import { PATH_PRODUCT } from '../../constants/common-contants'
import RenderPrice from '../product-detail/RenderPrice'
import ShowComm from '../product-price/ShowComm'
import { toggleShowNotBuy } from '../../redux/actions/create-order-action'

function ChooseProduct({
    listTop,
    listMostShared,
    listProduct,
    setReLoadingSuggestion,
    reLoadingSuggestion,
    pagingSuggestion,
    isPublisher,
    dispatch,
    validateName,
    chooseProduct,
    nameProduct,
    handleChangeName,
    handleClick,
    // handleKeyPressName,
    listProductSearchDesktop,
    isOpenProduct,
    handleProductToCart,
    totalProduct,
    animation,
    isShowNotBuy
}) {
    const [isTop, setIsTop] = useState(false)

    useEffect(() => {
        let searchInput = document.getElementById('search__input')
        let status = false
        if (searchInput) {
            window.addEventListener('scroll', function () {
                if (window.pageYOffset >= searchInput.offsetTop) {
                    if (status === false) {
                        setIsTop(true)
                        status = true
                    }
                } else {
                    if (status === true) {
                        setIsTop(false)
                        status = false
                    }
                }
            })
        }
    }, [])

    useEffect(() => {
        let endSuggestion = document.getElementById('end__suggestion')
        let statusSuggestion = false
        if (endSuggestion) {
            window.addEventListener('scroll', function () {
                if (window.pageYOffset >= endSuggestion.offsetTop - 420) {
                    if (statusSuggestion === false) {
                        setReLoadingSuggestion(true)
                        statusSuggestion = true
                    }
                } else {
                    if (statusSuggestion === true) {
                        setReLoadingSuggestion(false)
                        statusSuggestion = false
                    }
                }
            })
        }
    }, [listProduct]);
    const messageWarning = () => {
        message.warning('Vui lòng sử dụng tính năng này trên ứng dụng DiMuaDi');
    }
    return (
        <section className="search-product choose__product">
            <BoxHeaderTitle text="Chọn sản phẩm" link="/" isBell={true} isCart={true} />
            <div className="search__product__content">
                <div className="container">
                    <div className={`search__input`} id="search__input">
                        {(isMobileScreen() || isMaxTableScreen()) && (
                            <div className={`input__search ${isTop ? 'fix__top' : ''}`}>
                                <div className="back">
                                    <Link to={'/'}>
                                        <ArrowLeftOutlined className style={{ color: '#ffffff' }} />
                                    </Link>
                                </div>
                                <Form.Item validateStatus={validateName.status} help={validateName.text}>
                                    <Input
                                        onClick={() => dispatch(togglePopupBoxSearch(true))}
                                        size="large"
                                        style={{ border: '1px solid #BFBFBF', borderRadius: 4 }}
                                        placeholder="Nhập tên sản phẩm"
                                        suffix={<SearchOutlined onClick={handleClick} />}
                                        // value={nameProduct}
                                        // onChange={handleChangeName}
                                        // onKeyPress={handleKeyPressName}
                                        readOnly
                                    />
                                    <BoxSearch />
                                </Form.Item>
                                <div>
                                    <div className={`header__bell ${animation ? 'open' : ''}`} onClick={messageWarning}>
                                        {/* <Link to={'/checkout/cart'}>
                                            
                                        </Link> */}
                                        <ShoppingCartOutlined style={{ width: 25, height: 25, color: '#fff' }} />
                                        {totalProduct !== 0 && (
                                            <div className="header__bell__count">{totalProduct}</div>
                                        )}
                                    </div>
                                    <Modal
                                        centered
                                        visible={isShowNotBuy}
                                        footer={null}
                                        onOk={() => dispatch(toggleShowNotBuy(false))}
                                        onCancel={() => dispatch(toggleShowNotBuy(false))}
                                    >
                                        <p style={{fontSize:"20px", padding:"15px"}} className="text-center">Vui lòng sử dụng tính năng này trên ứng dụng DiMuaDi</p>
                                    </Modal>
                                </div>
                            </div>
                        )}
                        <div className="search__product__desktop">
                            <div className="input__search__desktop">
                                <Form.Item validateStatus={validateName.status} help={validateName.text}>
                                    <Input
                                        size="large"
                                        style={{ border: '1px solid #BFBFBF', borderRadius: 4 }}
                                        placeholder="Nhập tên sản phẩm"
                                        suffix={<SearchOutlined onClick={handleClick} />}
                                        value={nameProduct}
                                        onChange={handleChangeName}
                                    // onKeyPress={handleKeyPressName}
                                    />
                                </Form.Item>
                            </div>
                            <SuggestProduct
                                isOpenProduct={isOpenProduct}
                                listProductSearchDesktop={listProductSearchDesktop}
                            />
                        </div>
                    </div>
                    <div className="search__product__item share__near">
                        <div className="container__mobile">
                            <h2 className="title">SẢN PHẨM CHIA SẺ GẦN ĐÂY</h2>
                            <div className="list__share__near">
                                {Array.isArray(listMostShared) &&
                                    listMostShared.length > 0 &&
                                    listMostShared.map((res) => {

                                        return (
                                            <div key={res.id} className="share__near__item product__content">
                                                <div className="product__image">
                                                    <a href={`${PATH_PRODUCT}/${res.id}`}>
                                                        <img
                                                            src={convertSizeImage(
                                                                checkURLImage(res.thumbImage),
                                                                200,
                                                                200
                                                            )}
                                                            alt=""
                                                        />
                                                    </a>
                                                </div>
                                                <div className="product__detail">
                                                    <a href={`${PATH_PRODUCT}/${res.id}`}>
                                                        <p className="name">{res.name}</p>
                                                        <p className="price__product">
                                                            <span>Giá thành:</span>
                                                            <RenderPrice data={res} />
                                                        </p>
                                                        {isPublisher === 1 && (
                                                            <p className="price__product commission">
                                                                <span>Hoa hồng:</span>
                                                                <span className="price">
                                                                    <IconHomeChietKhau />
                                                                    {!res.rangePrice && <DisplayPrice
                                                                        price={convertCommission(
                                                                            res.price,
                                                                            res.commissionValue,
                                                                            res.commissionType
                                                                        )}
                                                                    />}
                                                                    {res.rangePrice && <ShowComm
                                                                        min={res.rangePrice.minPrice}
                                                                        max={res.rangePrice.maxPrice}
                                                                        commType={res.commissionType}
                                                                        commValue={res.commissionValue}
                                                                    />
                                                                    }
                                                                </span>
                                                            </p>
                                                        )}
                                                        {res.quantity !== -1 && (
                                                            <p className="price__product">
                                                                <span>Số lượng:</span>
                                                                {res.quantity === 0 ? (
                                                                    <span style={{ color: '#F5222D', marginLeft: 5 }}>
                                                                        {' '}
                                                                        Đã hết hàng{' '}
                                                                    </span>
                                                                ) : (
                                                                    <span style={{ marginLeft: 5 }}>
                                                                        {res.quantity}
                                                                    </span>
                                                                )}
                                                            </p>
                                                        )}
                                                    </a>
                                                    <Button
                                                        onClick={() => handleProductToCart(res)}
                                                        className="btn_choose_blue"
                                                        type="primary"
                                                        ghost
                                                        disabled={res.quantity === 0}
                                                    >
                                                        Chọn
                                                    </Button>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className="search__product__item hot__week">
                        <div className="container__mobile">
                            <h2 className="title">TOP 5 SẢN PHẨM BÁN CHẠY</h2>
                            <Swiper slidesPerView={3.5}>
                                {Array.isArray(listTop) &&
                                    listTop.length > 0 &&
                                    listTop.map((res) => {
                                        return (
                                            <SwiperSlide key={res.id}>
                                                <div className="hot__week__item">
                                                    <a href={`${PATH_PRODUCT}/${res.id}`}>
                                                        <img
                                                            src={convertSizeImage(
                                                                checkURLImage(res.thumbImage),
                                                                200,
                                                                200
                                                            )}
                                                            alt=""
                                                        />
                                                    </a>
                                                    <p className="chiet-khau">
                                                        <IconHomeChietKhau />
                                                        <span>
                                                            <DisplayPrice
                                                                price={convertCommission(
                                                                    res.price,
                                                                    res.commissionValue,
                                                                    res.commissionType
                                                                )}
                                                            />
                                                        </span>
                                                    </p>
                                                    <div className="discount">
                                                        <span>{res.discountAmount}%</span>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })}
                            </Swiper>
                        </div>
                    </div>

                    <div className="home__product__content">
                        <div className="header__title">
                            <div className="container__mobile">
                                <h2>Danh sách sản phẩm mới</h2>
                            </div>
                        </div>
                        <div className="home__product">
                            {Array.isArray(listProduct) &&
                                listProduct.length > 0 &&
                                listProduct.map((res) => {
                                    return (
                                        <div key={res.id} className="item__product">
                                            <a className="choose-product" href={`${PATH_PRODUCT}/${res.id}`}>
                                                <div className="item__image">
                                                    <img
                                                        src={convertSizeImage(checkURLImage(res.thumbImage), 200, 200)}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="product__text">
                                                    <p className="title">{res.name}</p>
                                                    <RenderPrice data={res} isPublisher={isPublisher} />
                                                    {res.quantity !== -1 && (
                                                        <p className="price__product">
                                                            {res.quantity === 0 ? (
                                                                <span style={{ color: '#F5222D' }}> Đã hết hàng </span>
                                                            ) : (
                                                                <span> Số lượng: {res.quantity}</span>
                                                            )}
                                                        </p>
                                                    )}
                                                    <p className="chiet-khau">
                                                        <span>Hoa Hồng</span>
                                                        <IconHomeChietKhau />
                                                    </p>
                                                    <span className="price__discount">
                                                        {!res.rangePrice && <DisplayPrice
                                                            price={convertCommission(
                                                                res.price,
                                                                res.commissionValue,
                                                                res.commissionType
                                                            )}
                                                        />
                                                        }
                                                        {res.rangePrice && <ShowComm
                                                            min={res.rangePrice.minPrice}
                                                            max={res.rangePrice.maxPrice}
                                                            commType={res.commissionType}
                                                            commValue={res.commissionValue}
                                                        />
                                                        }
                                                    </span>
                                                </div>
                                            </a>
                                            <Button
                                                onClick={() => handleProductToCart(res)}
                                                className="btn_choose_blue w-100 mt-10"
                                                type="primary"
                                                ghost
                                            >
                                                Chọn
                                            </Button>
                                        </div>
                                    )
                                })}
                            {listProduct.length === 0 && <ErrorProduct />}
                            {(isMobileScreen() || isMaxTableScreen()) && <div id="end__suggestion"></div>}
                        </div>
                        {listProduct.length > 0 && !(isMobileScreen() || isMaxTableScreen()) && (
                            <PaginationContainer
                                total={pagingSuggestion.total}
                                page={pagingSuggestion.page}
                                pageSize={pagingSuggestion.pageSize}
                            />
                        )}
                    </div>
                    {reLoadingSuggestion && pagingSuggestion.page < pagingSuggestion.totalPage && <LoadingComponent />}
                </div>
            </div>
            <BoxConfirmCart product={chooseProduct} />
        </section>
    )
}

const mapStateToProps = function (state) {
    return {
        openDirectory: state.searchReducer.openDirectory,
        isPublisher: state.homeReducer.isPublisher,
        isLoggedIn: state.homeReducer.isLoggedIn,
        isShowNotBuy: state.createOrderReducer.isShowNotBuy
    }
}
export default withRouter(connect(mapStateToProps)(ChooseProduct))
