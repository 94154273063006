import React from 'react'
import banner from '../../assets/images/bannerNotification.png'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import { PATH_NOTIFICATION } from '../../constants/common-contants'
import BreadcrumbNavigation from '../common/BreadcrumbNavigation'

function NotificationDetail({ dataDetail }) {
    const options = [
        {
            label: 'Thông báo',
        },
        {
            label: '>',
        },
        {
            label: dataDetail?.notification?.title,
            active: true,
        },
    ]
    return (
        <>
            <BreadcrumbNavigation option={options} />
            <div className="notification__detail">
                <BoxHeaderTitle text="Chi tiết thông báo" link={PATH_NOTIFICATION} />
                <div className="banner">
                    <img src={banner} alt="" />
                </div>
                <div className="container">
                    <div className="notification__detail__content">
                        <h2 className="title">{dataDetail?.notification?.title}</h2>

                        <p>
                            <div dangerouslySetInnerHTML={{ __html: dataDetail?.notification?.description }} />
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotificationDetail
