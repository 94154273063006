import React from 'react'
import { PATH_PRODUCT } from '../../constants/common-contants'
import DisplayPrice from '../common/DisplayPrice'
import { IconHomeChietKhau } from '../../constants/icon-const'
import {
    checkMinMax,
    checkURLImage,
    convertCommission,
    convertSizeImage,
    isMaxTableScreen,
    isMobileScreen
} from '../../functions/common-func'
import ErrorProduct from '../common/ErrorProduct'
import ShowComm from '../product-price/ShowComm'

function HomeSuggestion({ listSuggestion, isPublisher }) {

    return (
        <div className="home__product">
            {Array.isArray(listSuggestion) &&
                listSuggestion.length > 0 &&
                listSuggestion.map((item, index) => {
                    return (
                        <div key={index} className="item__product">
                            <a style={{ color: '#262626' }} href={`${PATH_PRODUCT}/${item.id}`}>
                                <div className="item__image">
                                    {item.status === 3 && (
                                        <div className="bg_out_of_stock"><span>Tạm hết hàng</span></div>
                                    )}
                                    {/*<img src={item.thumbImage} alt="" />*/}
                                    <img src={convertSizeImage(checkURLImage(item.thumbImage), 200, 200)} alt="" />
                                </div>
                                <p className="title">{item.name}</p>
                                {!item.rangePrice && <span className="price">
                                    <DisplayPrice price={item.price} />
                                </span>} 
                                {item.rangePrice && <span className="price">{checkMinMax(item.rangePrice.minPrice, item.rangePrice.maxPrice)}</span>}
                                {isPublisher === 1 &&  (
                                    <p className="chiet-khau">
                                        <span>Hoa hồng</span>
                                        <IconHomeChietKhau />
                                    </p>
                                )}
                                {isPublisher === 1 && !item.rangePrice && (
                                    <span className="price__discount">
                                        <DisplayPrice
                                            price={convertCommission(
                                                item.price,
                                                item.commissionValue,
                                                item.commissionType
                                            )}
                                        />
                                    </span>
                                )}
                                {isPublisher === 1 && item.rangePrice && (
                                    <ShowComm
                                        min={item.rangePrice.minPrice}
                                        max={item.rangePrice.maxPrice}
                                        commValue={item.commissionValue}
                                        commType={item.commissionType}
                                     />
                                )}
                            </a>
                        </div>
                    )
                })}
            {listSuggestion && listSuggestion.length === 0 && <ErrorProduct />}
            {(isMobileScreen() || isMaxTableScreen()) && <div id="end__suggestion"></div>}
        </div>
    )
}

export default HomeSuggestion
