import { RightOutlined } from '@ant-design/icons';
import { message, Modal} from 'antd';
import React, { useEffect, useState } from 'react';
import { getCouponsByProductID } from '../../api-services/coupon-product-api';
import { isMobileScreen } from '../../functions/common-func';
import DisplayPrice from '../common/DisplayPrice';
import CouponItemSelector from './CouponItemSelector';


const CouponsSelector = (props) => {
    const [dataCoupon, setDataCoupon] = useState([]);
    const [showModalSelectCoupon, setShowModalSelectCoupon] = useState(false);
    const [pagingCoupon, setPagingCoupon] = useState({ page: 1, pageSize: 25, total: 0, totalPage:0 }) 
    const [actionChange, setActionChange] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    
    const getProdCP = async (id,page,pageSize) => {
        try {
            const data = await getCouponsByProductID(id,true,page,pageSize)
            if (data?.status === 200) {
                if(data?.paging?.page === 1) {
                    setDataCoupon(data.data);
                } else {
                    let _list = dataCoupon;
                    _list = _list.concat(data?.data);
                    setDataCoupon(_list);
                }
                setPagingCoupon(data.paging);
                setIsLoading(false);
            } else {
                message.error(data.message);
            }
        } catch (e) {
            throw e
        }
    };
    useEffect(() => {
        if(actionChange > 0) {
            setPagingCoupon({ page: 1, pageSize: 25, total: 0, totalPage:0 });
            getProdCP(props.product.productId,pagingCoupon.page,pagingCoupon.pageSize);
        }
    }, [actionChange]);

    useEffect(() => {
        if(isLoading && pagingCoupon?.page < pagingCoupon?.totalPage) { 
            getProdCP(props.product.productId, pagingCoupon.page + 1, pagingCoupon.pageSize);
        } 
        if(isLoading && pagingCoupon?.page === pagingCoupon?.totalPage) { 
            getProdCP(props.product.productId, 1, pagingCoupon.pageSize);
        }
    },[isLoading])

    const handleSelectCP = (id) => {
        setShowModalSelectCoupon(true);
        setActionChange(id);
    }
    const onCancel = () => {
        setShowModalSelectCoupon(false)
    }
    return (
        <>
           {
                <div className='voucher' onClick={() => {handleSelectCP(props.product.productId) }}>
                    <div className="coupon_selector">
                        { props.product.dataCoupon?.id > 0 && props.cpDiscount
                            &&  <DisplayPrice price={props?.cpDiscount*(-1)} />
                        } 
                        {!props.product.dataCoupon  && 'Chọn voucher'}
                    </div>
                    <RightOutlined/> 
                </div>
           } 
            <Modal
                title='Coupon được áp dụng'
                visible={showModalSelectCoupon}
                onCancel={onCancel}
                footer={null}
                width={isMobileScreen() ? '100%' : '40%'}
                className="modal__coupon-details"
            >

                <div className="modal__coupon-details__content" >
                    {dataCoupon && dataCoupon.length > 0 && dataCoupon.map((coupon, index) => {
                        return (
                            <CouponItemSelector 
                            key={index} 
                            coupon={coupon} 
                            product={props.product}
                            showMore={false}  
                            setShowModalSelectCoupon={setShowModalSelectCoupon}
                            setIsRefresh={props.setIsRefresh}
                            listProduct={props.listProduct}
                            setCouponLocal={props.setCouponLocal}
                            couponLocal={props.couponLocal}
                            setListProduct={props.setListProductFromCart}
                            setTotalPrice={props.setTotalPrice}
                            setTotalCommission={props.setTotalCommission}
                            />
                        )
                    })}
                    {dataCoupon?.length <= 0 && <p>Sản phẩm không hỗ trợ coupon</p>}
                    { pagingCoupon?.totalPage >= 2 &&  
                        <button id="coupon__end" onClick={() =>{setIsLoading(true)}}>{ pagingCoupon?.page < pagingCoupon?.totalPage ? 'Xem Thêm' : 'Rút gọn'}</button>
                    }
                </div>
                
            </Modal>

        </>
    )

}
export default CouponsSelector;