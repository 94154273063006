import React, { useState, useEffect } from 'react'
import ForgotPassword from '../../component/login/ForgotPassword'

function ForgotPasswordContainer() {
    const [validateUserName, setValidateUserName] = useState({ status: 'success', text: '' })
    const [validatePassword, setValidatePassword] = useState({ status: 'success', text: '' })
    const [validateRePassword, setValidateRePassword] = useState({ status: 'success', text: '' })
    const [userName, setUserName] = useState('')
    const [password, setPassword] = useState('')
    const [rePassword, setRePassword] = useState('')

    localStorage.removeItem('cartCoupon');

    const clearValidator = () => {
        setValidateUserName({ status: 'success', text: '' })
        setValidateRePassword({ status: 'success', text: '' })
        setValidatePassword({ status: 'success', text: '' })
    }

    const handleChangeUserName = (e) => {
        setUserName(e.target.value)
        clearValidator()
    }

    const handleChangePassword = (e) => {
        setPassword(e.target.value)
        clearValidator()
    }

    const handleChangeRePassword = (e) => {
        setRePassword(e.target.value)
        clearValidator()
    }
    const validateForgotPassword = () => {
        if (!userName) {
            setValidateUserName({ status: 'error', text: 'Vui lòng nhập tên đăng nhập / email' })
            return false
        }
        if (!password) {
            setValidatePassword({ status: 'error', text: 'Vui lòng nhập mật khẩu ' })
            return false
        }
        if (!rePassword) {
            setValidateRePassword({ status: 'error', text: 'Vui lòng nhập lại mật khẩu' })
            return false
        }
        if (rePassword !== password) {
            setValidateRePassword({ status: 'error', text: 'Mật khẩu không khớp' })
            return false
        } else {
            return true
        }
    }

    const handleContinue = () => {
        if (validateForgotPassword()) {
            try {
                console.log('đã qua validate')
            } catch (e) {
                throw e
            }
        }
    }

    return (
        <ForgotPassword
            validateUserName={validateUserName}
            validatePassword={validatePassword}
            validateRePassword={validateRePassword}
            userName={userName}
            password={password}
            rePassword={rePassword}
            handleChangeUserName={handleChangeUserName}
            handleChangePassword={handleChangePassword}
            handleChangeRePassword={handleChangeRePassword}
            handleContinue={handleContinue}
        />
    )
}

export default ForgotPasswordContainer
