import React from 'react'
import Slider from 'react-slick/lib'
function HomeMarketing({ listBanner, isPublisher }) {
    const settings = {
        // autoplay: true,
        // autoplaySpeed: 3000,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        className: `home__marketing__content`,
    }
    return (
        <div className="home__marketing">
            <div className="container">
                <Slider {...settings}>
                    {isPublisher === 1 &&
                        listBanner.publisherHomeSlide &&
                        listBanner.publisherHomeSlide.map((res, index) => {
                            return (
                                <div key={index} className="item__marketing">
                                    <a href={res.url}>
                                        <img src={res.image} alt="" />
                                    </a>
                                </div>
                            )
                        })}
                    {isPublisher !== 1 &&
                        listBanner.buyerHomeSlide &&
                        listBanner.buyerHomeSlide.map((res, index) => {
                            return (
                                <div key={index} className="item__marketing">
                                    <a href={res.url}>
                                        <img src={res.image} alt="" />
                                    </a>
                                </div>
                            )
                        })}
                </Slider>
            </div>
        </div>
    )
}

export default HomeMarketing
