import { DEFAULT_IMAGE, EXPRESSTIME, URL_THUMBNAIL } from '../constants/common-contants'
import moment from 'moment'
import React from 'react'
import { uniqBy, isEmpty } from 'lodash'


export const isMobileScreen = () => {
    return window.innerWidth < 768
}

export const isWideScreen = () => {
    // eslint-disable-next-line no-undef
    return window.innerWidth >= 1200
}

export const isDesktopScreen = () => {
    return window.innerWidth >= 992 && window.innerWidth < 1200
}

export const isTableScreen = () => {
    return window.innerWidth >= 768 && window.innerWidth < 992
}

export const isMaxTableScreen = () => {
    return window.innerWidth < 992
}

export const getSession = function (sessionName) {
    let Value = JSON.parse(window.localStorage.getItem(sessionName))
    if (Value) {
        let expirationDate = new Date(Value.expirationDate)
        if (expirationDate > new Date()) {
            return Value
        } else {
            window.localStorage.removeItem(sessionName)
        }
    }
    return false
}

export const setSession = function (sessionName, sessionValue = {}, expirationInMin) {
    let expirationDate = new Date(new Date().getTime() + EXPRESSTIME * expirationInMin)
    sessionValue.expirationDate = expirationDate.toISOString()
    localStorage.setItem(sessionName, JSON.stringify(sessionValue))
}

export const addSession = (sessionName, sessionValue) => {
    localStorage.setItem(sessionName, JSON.stringify(sessionValue))
}

export const removeSession = (sessionName) => {
    localStorage.removeItem(sessionName)
}

export const getCurrencySession = () => {
    const currency = window.localStorage.getItem('currency')
    return currency ? currency : 'VND'
}

export const getRecentSearch = () => {
    const data = window.localStorage.getItem('recent_search')
    return data ? JSON.parse(data) : []
}

export const saveRecentSearch = (data) => {
    return window.localStorage.setItem('recent_search', JSON.stringify(data))
}

export const saveListAddress = (data) => {
    return window.localStorage.setItem('address_not_login', JSON.stringify(data))
}

export const saveIdPublisher = (data) => {
    setSession('pub_id', data, 30)
    return window.localStorage.setItem('pub_id', JSON.stringify(data))
}

export const saveConfirmTutorialShare = (data) => {
    return window.localStorage.setItem('confirm_tutorial_share', JSON.stringify(data))
}

export const saveAddressReview = (data) => {
    return window.localStorage.setItem('address_review', JSON.stringify(data))
}

export const getSessionListAddress = () => {
    const data = window.localStorage.getItem('address_not_login')
    return data ? JSON.parse(data) : []
}

export const getSessionAddressReview = () => {
    const data = window.localStorage.getItem('address_review')
    return data ? JSON.parse(data) : null
}

export const getConfirmTutorialShare = () => {
    const data = window.localStorage.getItem('confirm_tutorial_share')
    return data ? JSON.parse(data) : null
}

export const getIdPublisher = () => {
    const data = getSession('pub_id')
    return data ? data.pub_id : null
}

export const getIsShowTutorial = () => {
    const isShow = window.localStorage.getItem('is_show_tutorial')
    return isShow ? JSON.parse(isShow) : null
}

export const getExChangeRateSession = () => {
    const changerate = window.localStorage.getItem('exchange_rate')
    return changerate ? JSON.parse(changerate) : null
}

export const getNotificationToken = () => {
    const token = window.localStorage.getItem('notification_token')
    return token ? JSON.parse(token) : null
}

export const getPublisherSession = () => {
    const publisher = window.localStorage.getItem('role')
    return publisher ? JSON.parse(publisher) : null
}

export const toggleClassNoScroll = (type) => {
    if (typeof document !== 'undefined') {
        if (type === 'add') {
            if (!document.body.classList.contains('noScroll')) {
                document.body.classList.add('noScroll')
            }
        } else if (type === 'remove') {
            document.body.classList.remove('noScroll')
        } else {
            if (document.body.classList.contains('noScroll')) {
                document.body.classList.remove('noScroll')
            } else {
                document.body.classList.add('noScroll')
            }
        }
    }
}

export const removeUserSession = () => {
    localStorage.clear()
}

export const convertFullNameToFirstLastName = (fullName, type) => {
    let arr_name = fullName.split(' ')
    if (arr_name.length >= 1) {
        if (type === 'last_name') return arr_name[0]
        else if (type === 'first_name') {
            return arr_name.slice(1).join(' ') ? arr_name.slice(1).join(' ') : arr_name[0]
        }
    }
}
export function formatTimeToText(timeString) {
    const timeEnd =  moment(timeString).utc().format('YYYY-MM-DD').toString() + ' 23:59:59';
    let strTime = moment(timeEnd).utc().fromNow(true).toString();

    return strTime.replace('days', 'ngày')
        .replace('day', 'ngày')
        .replace('years', 'năm')
        .replace('year', 'năm')
        .replace('months','tháng')
        .replace('month','tháng')
        .replace('ago', 'trước')
        .replace('hours', 'giờ')
        .replace('hour', 'giờ')
        .replace('minutes', 'phút')
        .replace('minute', 'phút')
        .replace('seconds', 'giây')
        .replace('second', 'giây')
        .replace('in', '')
        .replace('a few', 'vài')
        .replace('an', '1')
        .replace('a', '1');
}
export function formatTime(timeString, format) {
    return moment(timeString).utc().format(format);
}

export const copyToClipboard = (text) => {
    const $body = document.getElementsByTagName('body')[0]
    const $tempInput = document.createElement('INPUT')
    $body.appendChild($tempInput)
    $tempInput.setAttribute('value', text)
    $tempInput.select()
    document.execCommand('copy')
    $body.removeChild($tempInput)
}

export const convertCommission = (price, value, type) => {
    let priceCommission = 0
    if (type==='amount') {
        priceCommission = value
    }
    if (type === 'percent') {
        priceCommission = Number((price * value) / 100).toFixed(0)
    }
    return Number(priceCommission)
}

export const convertTotalCommission = (price, quantity, value, type) => {
    let priceCommission = 0
    if(type === 'amount') {
        // value > price ? value = price : value = value
        priceCommission = (value * quantity).toFixed(0)
    }
    
    if (type === 'percent') {
        priceCommission = Number((price * value) / 100).toFixed(0)
    }
    return Number(priceCommission)
}

export const returnStatus = (status) => {
    if (status === 'success') {
        return <p className="success"> Thành công</p>
    }
    if (status === 'fail') {
        return <p className="error"> Thất bại</p>
    }
    if (status === 'pending') {
        return <p className="warning">Chờ xử lý</p>
    }
    if (status === 'pre_approved') {
        return <p className="warning">Tạm duyệt</p>
    }
    if (status === 'approved') {
        return <p className="success"> Đã duyệt</p>
    }
    if (status === 'rejected') {
        return <p className="error"> Đã hủy</p>
    }
    if (status === 'hold') {
        return <p className="hold"> Tạm giữ</p>
    }
}

export const convertStatus = (status) => {
    if (status === 'success') {
        return 'Thành công'
    }
    if (status === 'fail') {
        return 'Thất bại'
    }
    if (status === 'pending') {
        return 'Chờ xử lý'
    }
    if (status === 'pre_approved') {
        return 'Tạm duyệt'
    }
    if (status === 'approved') {
        return 'Đã duyệt'
    }
    if (status === 'rejected') {
        return 'Đã hủy'
    }
    if (status === 'hold') {
        return 'Tạm giữ'
    }
}

export const checkURL = (url) => {
    if (url && url.toLowerCase().startsWith('http')) {
        return url
    }
    return false
}

export const checkURLImage = (url) => {
    if (url) {
        let urlCheck = url.replace(/\[\d\]/, '');
        if (urlCheck.match(/(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|jpeg|gif|png|webp|svg)/gi) != null) return url;
    }

    return false
}

export const checkURLVideo = (url) => {
    if (url && url.toLowerCase().match(/\.(mp4|avi|mov|wmv)$/) != null) {
        return url
    }
    return false
}

export const checkURLYoutube = (url) => {
    if (url && url.toLowerCase().match(/^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm) != null) {
        return url
    }
    return false
}

export const displayPrice = (price, currency) => {
    if (price) {
        price = Number(price)
        const currencies = {
            USD: ['$', 2, ',', 'before', 'United States dollar'],
            VND: ['₫', 0, '.', 'after', 'Vietnamese dong'],
        }
        const formatCurr = (xx, curr) => {
            if (!curr) curr = 'VND'
            let sym = currencies[curr][0]
            let dec = currencies[curr][1]

            let yy = Number(Math.round(xx + 'e' + dec) + 'e-' + dec)
            // let y = x.toFixed(dec);
            let zz = yy.toString().replace(/\B(?=(\d{3})+(?!\d))/g, currencies[curr][2])
            return currencies[curr][3] === 'before' ? sym + zz : zz + sym
        }
        const formatPrice = () => {
            let rate = 1
            if (currency === 'USD') {
                let exChangeRate = getExChangeRateSession()
                rate = exChangeRate['transfer']
            }
            return formatCurr(price / rate, currency)
        }
        return formatPrice()
    }
    return 0
}

// export const dateToYMD = (date) => {
//     let day = date.getDate()
//     let month = date.getMonth() + 1 //Month from 0 to 11
//     let year = date.getFullYear()
//     return '' + year + '/' + (month <= 9 ? '0' + month : month) + '/' + (day <= 9 ? '0' + day : day)
// }

export const getAddressByIdSession = (id) => {
    let list = getSessionListAddress()
    let address = null
    if (list.length > 0) {
        let data = list.filter((res) => {
            return res.id === id
        })
        address = data && data[0]
    }
    return address
}

export const convertSizeImage = (url, width, height) => {
    let _width = width || 600
    let _height = height || 600
    if (url && (url.startsWith(URL_THUMBNAIL.URL_AIRTABLE) || url.startsWith(URL_THUMBNAIL.URL_DOMAIN_DMD))) {
        if (url) {
            return process.env.REACT_APP_URL_CROP_IMAGE + '/' + _width + 'x' + _height + '/' + url
        }
        return process.env.REACT_APP_URL_CROP_IMAGE + '/' + _width + 'x' + _height + '/' + DEFAULT_IMAGE
    } else {
        if (url) {
            return url
        } else {
            return process.env.REACT_APP_URL_CROP_IMAGE + '/' + _width + 'x' + _height + '/' + DEFAULT_IMAGE
        }
    }
}

export const getTimeMessage = (create_at) => {
    let result = ''
    const now = moment()
    const expiration = moment(create_at)
    const diff = now.diff(expiration)
    const diffDuration = moment.duration(diff)
    const days = diffDuration.days()
    const hours = diffDuration.hours()
    const minutes = diffDuration.minutes()
    if (days <= 0) {
        if (hours <= 0) {
            result = `${minutes} phút trước`
        } else result = `${hours} giờ trước`
    } else {
        result = moment(create_at).format('DD-MM-YYYY HH:mm')
    }
    return result
}

export const formatCurrency = (number) => {
    if (isNaN(number) && number === 0) return 0

    const str = Number(number).toFixed(0).split('.')
    return str[0]
        .split('')
        .reverse()
        .reduce(function (acc, num, i) {
            return number === '-' ? acc : num + (i && !(i % 3) ? '.' : '') + acc
        }, '')
        .replace(/\.00$/, '')
}
export const convertPaging = (list, pageSize = 25, pageNumber = 1) => {
    return list.slice(pageSize * (pageNumber - 1), pageSize * pageNumber)
}

export const getCheckData = (params, pathname) => {
    const id = pathname.split('/').slice(-1).pop()
    let keyName = Object.keys(params)[0];
    const getKeyName = (keyName) => {
        if (keyName === 'admin_token') {
            keyName = 'admin-token';
        } else if (keyName === 'adv_token') {
            keyName = 'adv-token';
        } else (keyName = 'admin-token');
        return keyName;
    }
    keyName = getKeyName(keyName);
    const options = {
        keyName: keyName,
        tokenCheck: Object.values(params)[0],
        idCheck: Number(id)
    }
    return options;
}

export const convertVariations = (product) => {
    const checkIsVariation = (rangePrice) => {
        if (rangePrice) {
            return true
        } else {
            return false
        }
    }
    const data = checkIsVariation(product.rangePrice) ? product.variations : [];

    let variations = [];
    let childrenVariations = [];
    let groupVariations = [];
    if (data && data.length > 0) {
        
        data.forEach(item => {
            if (item.parentId == 0) { variations.push(item) }
        })
        data.forEach(item => {
            if (item.parentId != 0) { childrenVariations.push(item) }
        })
    }
    if (variations && variations.length > 0) {
        variations.forEach(item => {
            let children = [];
            childrenVariations.forEach(child => {
                if (child.parentId == item.id) {
                    children.push(child)
                }
            })
            groupVariations.push({
                ...item,
                children: uniqBy(children, 'id')
            })
        })
    }
    const uniqueVariations = uniqBy(groupVariations, 'id'); // remove duplicate
    return uniqueVariations

    // return groupVariations;
}

export const convertOldVariations = (product) => {
    let variations = [];
    const checkIsVariation = (old_variation) => {
        if (old_variation && old_variation.length > 0) {
            return true;
        } 
        return false;
    }
    if(product?.productVariations && product?.productVariations?.length > 0) {
        const data = checkIsVariation(product.productVariations) ? product.productVariations : [];
    
        if (data && data.length > 0) {
            variations = data.reduce((vari_item,element) => {
                let obj_variation = {
                    id:element?.attributeId,
                    attribute_name: element?.attribute?.name,
                    attribute_value:[]
                };
                let index = vari_item.findIndex(item => item.id == element.attributeId);
                if(index < 0) {
                    obj_variation.attribute_value.push({
                        id:element?.attributeValue?.id,
                        name:element?.attributeValue?.attributeValue
                    });
                    vari_item.push(obj_variation);
                } else {
                    vari_item[index].attribute_value.push({
                        id:element.attributeValue?.id,
                        name:element.attributeValue?.attributeValue
                    });
                }
                
                return vari_item;
            },[])
        }
        return variations;
    } else if(product?.colors?.length > 0 || product?.sizes?.length > 0) {
        const data_entable_colors = checkIsVariation(product.colors) ? product.colors : [];
        const data_entable_sizes = checkIsVariation(product.sizes) ? product.sizes : [];
        if(data_entable_colors?.length > 0) {
            let arr_attribute_value = data_entable_colors.reduce(function(attribute, element, i) {
                let arr_item = {
                    id: i+1,
                    name:element
                }
                attribute.push(arr_item);
                return attribute;
              }, []);
            variations.push({
                id:'colors',
                attribute_name:'Màu sắc',
                attribute_value:arr_attribute_value
            });
        }
        if(data_entable_sizes?.length > 0) {
            let arr_attribute_value = data_entable_sizes.reduce(function(attribute, element, i) {
                let arr_item = {
                    id: i+1,
                    name:element
                };
                attribute.push(arr_item);
                return attribute;
              }, []);
            variations.push({
                id:'sizes',
                attribute_name:'Kích cỡ',
                attribute_value:arr_attribute_value
            });
        }
        return variations;
    }
}
export const checkIsOldVariation = (product) => {
    if(product?.productVariations?.length > 0 || product.sizes?.length > 0 || product.colors?.length > 0) {
        return true;
    }
    return false;
}
export const checkIsNewVariation = (product) => {
    if(product?.variations?.length > 0) {
        return true;
    }
    return false;
}
export const checkMinMax = (min, max) => {

    let price = ''
    if (min == max) return price = ` ${formatCurrency(min)}₫`
    else return price = ` ${formatCurrency(min)}₫ - ${formatCurrency(max)}₫`
}

// validate selected new variations data
export const selectedVariationData = (variDatas, index1, index2, setChildIndex) => {
    let listPrice = []
    let selectedPrice = null
    for (let i = 0; i < variDatas.length; i++) {
        if (variDatas[i].children.length > 0) {
            for (let j = 0; j < variDatas[i].children.length; j++) {
                if (variDatas[i].children[j].display && variDatas[i].children[j].quantity > 0) {
                    listPrice.push({
                        index: [i, j],
                        price: variDatas[i].children[j].price,
                        id  : variDatas[i].children[j].id
                    }
                    )
                }
            }
        } else {
            listPrice.push({
                index: [i, -1],
                price: variDatas[i].price,
                id  : variDatas[i].id
            })
            setChildIndex(-1)
        }
    }
    selectedPrice = listPrice.find(item => item.index[0] === index1 && item.index[1] === index2)
    return selectedPrice
}
export const checkAvailable = (variation) => {
    const { children } = variation;
    let count = 0
    if (children && children.length > 0) {
        children.forEach(child => {
            if (child.display===true) {
                count++
            }
        }
        )
    }
     
    if (count > 0 || children.length === 0) {
        return true
    } else {
        return false
    }
}
export const renderDiscount = (prod) => {
    let discount = undefined;
    let coupon = prod.dataCoupon;
    if (coupon && coupon.couponType === 0) {
        let discount = Math.floor(prod.productPrice * prod.quantity * (coupon.amount / 100));
        discount > coupon.maxMoney && coupon.maxMoney !== 0 ? discount = coupon.maxMoney : discount = discount;
        if (prod.productPrice * prod.quantity < coupon.minOrder) {
            discount = undefined;
        }
        return discount;
    }
    if (coupon && coupon.couponType === 1) {
        let discount = coupon.amount;
        discount > coupon.maxMoney && coupon.maxMoney !== 0 ? discount = coupon.maxMoney : discount = discount;

        if (prod.productPrice * prod.quantity < coupon.minOrder) {
            discount = undefined;
        }

        
        if (coupon.maxMoney === 0) {
            if ( coupon.amount > prod.productPrice * prod.quantity ) discount = prod.productPrice * prod.quantity
            else discount = coupon.amount;
        }
        return discount;
    }
    return discount;
}
export const renderPriceAfterCP = (totalPrice,couponPrice) => {
    if(totalPrice >= couponPrice) {
        return totalPrice - couponPrice; 
    }
    return 0;
}

export const renderOrderDiscount = (prod) => {
    let discount = undefined;
    if (prod?.coupon_type === 0) {
        let discount = Math.floor(prod?.productPrice * prod?.quantity * (prod?.coupon_amount / 100));
        discount > prod?.coupon_max_money && prod?.coupon_max_money !== 0 ? discount = prod?.coupon_max_money : discount = discount;
        if (prod.productPrice * prod.quantity < prod?.coupon_min_order) {
            discount = undefined;
        }
        return discount;
    }
    if (prod?.coupon_type === 1) {
        let discount = prod?.coupon_amount;
        discount > prod?.coupon_max_money && prod?.coupon_max_money !== 0 ? discount = prod?.coupon_max_money : discount = discount;

        if (prod.productPrice * prod.quantity < prod?.coupon_min_order) {
            discount = undefined;
        }

        
        if (prod?.coupon_max_money === 0) {
            if ( prod?.coupon_amount > prod.productPrice * prod.quantity ) discount = prod.productPrice * prod.quantity
            else discount = prod?.coupon_amount;
        }
        return discount;
    }
    return discount;
}

//check product have child variations
export const checkHaveChild = (variations) => {
    if (variations.length > 0 && isEmpty(variations[0].children)) return false
    if (variations.length > 0 && !isEmpty(variations[0].children)) return true
}
//get data from note variations

export const getNoteData = (note = "") => {
    let attrData = [];
    if (note?.length > 0) {
        let array = note.split(",").slice(0).filter(item => !item.includes("CouponCode"));
        if (array.length) {
            array.forEach((attr) => {
                attr = attr.replace(/^\s+/g, "").split(":");
                attrData.push({
                    name: attr[0],
                    value: attr[1]
                })

            });
        }
    }
    return attrData;
}

