import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import { isMobileScreen } from '../../functions/common-func';
import CouponItem from '../coupon/CouponItem';
// import 'swiper/swiper.min.css'
const CouponSlider = ({ data,can_buy, buyProductWithCoupon }) => {

    return (
        <Swiper id="box_coupons"
            slidesPerView={isMobileScreen() ? 1 : 2}
            // centeredSlides={true}
            pagination={{
                clickable: true,
            }}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            modules={[Pagination]}
            spaceBetween={20}
        // loop={true} 
        >
            {data && data.map((item, index) => {
                return (
                    <SwiperSlide key={`slide-${index}`}>
                        <CouponItem
                            coupon={item}
                            can_buy={true}
                            buyProductWithCoupon={buyProductWithCoupon}
                            
                        />
                    </SwiperSlide>
                )
            })}

        </Swiper>
    )
}
export default CouponSlider;
