export const HOME_TYPE_REDUCER = {
    SET_IS_LOGGED_IN: 'SET_IS_LOGGED_IN',
    MENU_MOBILE: 'MENU_MOBILE',
    ADD_LINK_BOX_HEADER: 'ADD_LINK_BOX_HEADER',
    OPEN_LOADING: 'OPEN_LOADING',
    TOGGLE_POPUP_SHARE: 'TOGGLE_POPUP_SHARE',
    TOGGLE_POPUP_RULE: 'TOGGLE_POPUP_RULE',
    TOGGLE_POPUP_WELCOME: 'TOGGLE_POPUP_WELCOME',
    TOGGLE_YOUR_CART: 'TOGGLE_YOUR_CART',
    TOGGLE_SEARCH_BOX: 'TOGGLE_SEARCH_BOX',
    SET_ROLE: 'SET_ROLE',
    IS_SHOW_MENU_ACCOUNT: 'IS_SHOW_MENU_ACCOUNT',
    TOGGLE_MENU_SIDEBAR: 'TOGGLE_MENU_SIDEBAR',
    IS_PUBLISHER: 'IS_PUBLISHER',
    IS_TOP_INPUT: 'IS_TOP_INPUT',
    IS_POLICY_WHOLESALE: 'IS_POLICY_WHOLESALE',
}

export const ACCOUNT_TYPE_REDUCER = {
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    USER_INFO: 'USER_INFO',
    EDIT_USER_INFO: 'EDIT_USER_INFO',
    TUTORIAL_ACCOUNT: 'TUTORIAL_ACCOUNT',
    POPUP_REGISTER: 'POPUP_REGISTER'
}

export const CREATE_ORDER_TYPE_REDUCER = {
    BOOKER_FORM: 'BOOKER_FORM',
    SET_IS_ADDRESS: 'BOOKER_FORM',
    SHOW_NOT_BUY: 'SHOW_NOT_BUY'
}
