import { SEARCH_TYPE_REDUCER } from '../../constants/search-const'
import { PRODUCT_DETAIL_TYPE_REDUCER } from '../../constants/product-detail-const'

export const toggleOpenDirectory = (boolean) => {
    return {
        type: SEARCH_TYPE_REDUCER.OPEN_HOT_DiRECTORY,
        openDirectory: boolean,
    }
}

export const toggleFilterProduct = (boolean) => {
    return {
        type: SEARCH_TYPE_REDUCER.OPEN_FILTER_PRODUCT,
        openFilterProduct: boolean,
    }
}

export const toggleSortProduct = (boolean) => {
    return {
        type: SEARCH_TYPE_REDUCER.OPEN_SORT_PRODUCT,
        openSortProduct: boolean,
    }
}

export const toggleShowBoxConfirmCart = (boolean, title, status) => {
    return {
        type: SEARCH_TYPE_REDUCER.OPEN_SHOW_CONFIRM_CART,
        isShowConfirmCart: boolean,
        title: title,
        status: status,
    }
}

export const toggleChooseContent = (boolean) => {
    return {
        type: SEARCH_TYPE_REDUCER.OPEN_SHOW_CHOOSE_CONTENT,
        isShowChooseContent: boolean,
    }
}

export const toggleTutorialShare = (boolean) => {
    return {
        type: SEARCH_TYPE_REDUCER.OPEN_CONFIRM_TUTORIAL_SHARE,
        openTutorialShare: boolean,
    }
}

export const setTotalProduct = (total) => {
    return {
        type: SEARCH_TYPE_REDUCER.TOTAL_PRODUCT_FROM_CART,
        totalProduct: total,
    }
}

export const setRefreshCart = (boolean) => {
    return {
        type: SEARCH_TYPE_REDUCER.REFRESH_CART,
        refreshCart: boolean,
    }
}

export const setTotalNotification = (total) => {
    return {
        type: SEARCH_TYPE_REDUCER.TOTAL_NOTIFICATION_NOT_READ,
        totalNotification: total,
    }
}

export const setRefreshNotification = (boolean) => {
    return {
        type: SEARCH_TYPE_REDUCER.REFRESH_NOTIFICATION,
        refreshNotification: boolean,
    }
}
