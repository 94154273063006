import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import 'antd/dist/antd.css'
import '../../assets/css/styles.css'
import '../../assets/css/index.css'
import '../../assets/scss/style.scss'
import '../../assets/css/frontend.min.css'
import '../../assets/css/commom.css'
import App from '../../component/layouts/App'
import * as queryString from 'query-string'
import { getNotificationToken, saveIdPublisher } from '../../functions/common-func'
import { fetchProductFromCart } from '../../api-services/cart-api'
import { setTotalNotification, setTotalProduct } from '../../redux/actions/search-action'
import { fetchListNotificationUnRead, notificationUserToken } from '../../api-services/notionfication-api'

export const useMounted = () => {
    const [mounted, setMounted] = useState(false)
    useEffect(() => setMounted(true), [])
    return mounted
}
function AppContainer() {
    const location = useLocation()
    const dispatch = useDispatch()
    const isMounted = useMounted()

    const { refreshCart, isLoggedIn, refreshNotification } = useSelector((state) => {
        return {
            refreshCart: state.searchReducer.refreshCart,
            refreshNotification: state.searchReducer.refreshNotification,
            isLoggedIn: state.homeReducer.isLoggedIn,
        }
    })

    useEffect(() => {
        if (isLoggedIn) {
            getProductFromCart()
        }
    }, [refreshCart])

    useEffect(() => {
        if (isLoggedIn) {
            getNotificationNotRead()
        }
    }, [refreshNotification])

    useEffect(() => {
        const query = queryString.parse(location.search)
        if (query.pub_id) {
            saveIdPublisher({ pub_id: query.pub_id })
        }
    }, [location])

    useEffect(() => {
        if (isLoggedIn) {
            const registerNotificationUserToken = async (data) => {
                try {
                    await notificationUserToken(data)
                } catch (e) {
                    throw e
                }
            }
            let token = getNotificationToken()
            const data = {
                token,
            }
            registerNotificationUserToken(data)
        }
    }, [])

    useEffect(() => {
        // 1216538601705933 vntrip
        // load script cho acebook

        window.fbAsyncInit = function () {
            window.FB.init({
                appId: process.env.REACT_APP_PUBLIC_FACEBOOK_ID,
                cookie: true,
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v8.0',
            })
        }
        ;(function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) {
                return
            }
            js = d.createElement(s)
            js.id = id
            js.src = 'https://connect.facebook.net/en_US/sdk.js'
            fjs.parentNode.insertBefore(js, fjs)
        })(document, 'script', 'facebook-jssdk')
    }, [isMounted])

    const getProductFromCart = async () => {
        try {
            const data = await fetchProductFromCart()
            if (data && data.status === 200) {
                dispatch(setTotalProduct(data?.data?.shoppingCartItems?.length))
            }
        } catch (e) {
            throw e
        }
    }

    const getNotificationNotRead = async () => {
        try {
            const data = await fetchListNotificationUnRead()
            if (data && data.status === 200) {
                dispatch(setTotalNotification(data.data || 0))
            }
        } catch (e) {
            throw e
        }
    }

    return <App />
}

export default AppContainer
