import React from 'react'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import { useHistory, useLocation } from 'react-router'

function PolicyVietname() {
	const { pathname } = useLocation()

	const history = useHistory()
	const onBack = () => {
		history.goBack()
	}
	return (
		<div className="d2c__policy">
			<BoxHeaderTitle
				text={`${pathname === '/dieu-khoan' ? 'Điều khoản sử dụng' : 'Chính sách bảo mật'}`}
				onClose={onBack}
				isBell={false}
			/>

			<div className="container">
				{pathname === '/dieu-khoan' && (
					<div className="d2c__policy__content">
						<h2>ĐIỀU KHOẢN SỬ DỤNG</h2>
						<h3>THÔNG TIN VỀ CHỦ SỞ HỮU WEBSITE</h3>

						<p className="title">
							<strong>Thông tin liên hệ</strong>
						</p>
						<div className="info">
							<p>Tên tổ chức: CÔNG TY CỔ PHẦN THƯƠNG MẠI VÀ DỊCH VỤ D2C VIỆT NAM</p>

							<p>
								Giấy chứng nhận đăng ký doanh nghiệp số: 0109327194 do Phòng đăng ký kinh doanh - Sở Kế hoạch và Đầu tư thành phố Hà Nội cấp ngày 01/09/2020.
							</p>

							<p>Địa chỉ: Số 1, ngách 1, ngõ 63, Phố Ngô Thì Sỹ, Tổ dân phố số 9, Phường Vạn Phúc, Quận Hà Đông, Thành phố Hà Nội.</p>

							<p>Hotline: 024 3217 1304</p>
							<p>
								Email: <a href="mailto:support@dimuadi.vn">support@dimuadi.vn</a>
							</p>
						</div>
						<h3>1. THÔNG TIN VỀ ĐIỀU KIỆN GIAO DỊCH CHUNG</h3>
						<p className="title">
							<strong>1.1. Một số định nghĩa chung:</strong>
						</p>
						<ul>
							<li>
								DiMuaDi là sản phẩm công nghệ thuộc CÔNG TY CỔ PHẦN THƯƠNG MẠI VÀ DỊCH VỤ D2C VIỆT NAM:
								<ul>
									<li>
										Là đơn vị cung cấp giải pháp phần mềm bán hàng cho đối tượng bán hàng là CTV và Đại lý.
									</li>
									<li>
										Ứng dụng DiMuaDi với 2 chức năng chính:
										<ul>
											<li>Cung cấp các công cụ hỗ trợ bán hàng, giúp Người bán (CTV- Đại lý) bán hàng.</li>
											<li>Cung cấp hệ thống tạo đơn cho Người bán (CTV/Đại lý) để được hưởng hoa hồng bán hàng.</li>
										</ul>
									</li>
									<li>
										DiMuaDi không tạo ra môi trường mà ở đó có sự tương tác, mua bán hàng hoá/dịch vụ trực tiếp giữa Người bán và Nhà cung cấp, các hoạt động đều được thông quan đội ngũ quản trị DiMuaDi.
									</li>
								</ul>
							</li>
							<li>
								“Publisher” là một cá nhân, tổ chức muốn nhận phí quảng cáo thông qua Bên A,
								bằng cách trở thành cộng tác viên hoặc đại lý của Bên A được gọi chung Là Publisher.
								Publisher được chia thành 2 nhóm là Cộng tác viên và Đại lý. Cộng tác viên”
								(CTV) là các cá nhân, tổ chức có kiến thức và kinh nghiệm bán hàng,
								thực hiện hoạt động giới thiệu, tư vấn sản phẩm/dịch vụ đến khách hàng thông
								qua các kênh giao tiếp đa dạng. Đại Lý” là cá nhân, tổ chức được cấp phép đại diện
								cho nhãn hàng thực hiện việc phân phối bán hàng các sản phẩm/dịch vụ của nhãn hàng
								một cách hợp pháp theo quy định riêng của từng nhãn hàng.
							</li>
							<li>
								“Trang mạng Publisher” được hiểu là một trang mạng Internet hoặc ứng dụng
								(app) do Publisher vận hành, quản lý và được Publisher đăng ký sử dụng Các dịch vụ.
							</li>
							<li>
								“Nhà quảng cáo” (Advertiser) hay được gọi là Nhà Cung Cấp được hiểu là một tổ chức,
								công ty có Pháp Nhân, có yêu cầu sử dụng các Dịch vụ theo cách thức Bên A chỉ định,
								nhằm kết nối các khách thăm đến trang mạng bán hàng của DIMUADI thông qua phương thức
								đăng quảng cáo trên Trang mạng Bên B.
							</li>
							<li>
								“Trang mạng bán hàng” được hiểu là một trang mạng do DIMUADI phát hành và quản lý nhằm
								cung cấp các hàng hóa và dịch vụ của nhà quảng cáo.
							</li>
							<li>
								“Khách thăm” hay “khách hàng" được hiểu là các cá nhân sẽ truy cập Trang mạng Bên B và được
								chuyển tiếp tới Trang mạng bán hàng của DIMUADI dành cho người tiêu dùng bằng đường dẫn kích
								hoạt khi nhấp chuột vào quảng cáo do Nhà quảng cáo đăng trên Trang mạng Bên B.
							</li>
							<li>
								“Trang quản lý” được hiểu là một trang mạng chuyên dụng do Bên A cung cấp cho Bên B liên quan đến
								Các dịch vụ mà qua đó, Bên B có thể lựa chọn quảng cáo mà Bên B muốn thiết lập Liên kết quảng cáo
								hay xác nhận các điều khoản và điều kiện về phí quảng cáo, v.v.
							</li>
							<li>
								“Liên kết quảng cáo” sẽ có hiệu lực khi Bên B đăng ký đăng tải một bản quảng cáo với Nhà quảng cáo mà
								Bên B mong muốn và đã lựa chọn từ nhiều chương trình Liên kết quảng cáo có trên Trang quản lý hay từ
								các nguồn khác nằm trong Các dịch vụ và được Nhà quảng cáo chấp thuận yêu cầu đăng quảng cáo.
							</li>
							<li>
								“Kết quả đăng quảng cáo” được hiểu là bất kì kết quả quảng cáo nào bắt nguồn từ việc Khách thăm truy cập
								các quảng cáo của Nhà quảng cáo đăng trên Trang mạng Bên B như mua hàng hóa hay đăng kí thành viên, và
								được đăng ký vào dữ liệu Các dịch vụ. Các kết quả đăng quảng cáo sẽ được tính phí quảng cáo.
							</li>
						</ul>
						<p className="title">
							{' '}
							<strong>1.2. Phương thức & Quy trình cung ứng dịch vụ </strong>
						</p>
						<ul>
							<li>
								Các dịch vụ được hiểu là dịch vụ đăng quảng cáo cho các sản phẩm của Nhà
								quảng cáo thông qua trang mạng của Publisher.
							</li>
							<li>
								DIMUADI cung cấp các liên kết quảng cáo cho Publisher để Publisher tiến
								hành việc quảng bá sản phẩm cho Nhà Quảng cáo.
							</li>
							<li>
								Nếu Khách thăm ghé vào trang mạng của Publisher, thực hiện thao tác mua hàng
								hoặc đăng ký thành viên, v.v, bằng cách nhấp chuột vào liên kết quảng cáo, một
								khoản phí quảng cáo nhất định tỉ lệ với kết quả đăng quảng cáo sẽ được Nhà quảng
								cáo thanh toán cho Publisher thông qua DIMUADI.
							</li>
							<li>
								Publisher có thể xác nhận loại quảng cáo, phí đăng quảng cáo, trạng thái tức thời của
								quảng cáo, v.v, trên Trang quản lý chuyên dùng dành cho Publisher được DIMUADI cung
								cấp trên Internet.
							</li>
						</ul>

						<p className="title">
							{' '}
							<strong>1.3. Thời hạn và phương thức thanh toán dịch vụ</strong>
						</p>
						<ul>
							<li>
								Thanh toán qua Tài khoản ngân hàng/ATM. Thanh toán qua tất cả các tài khoản ngân hàng.
								DIMUADI miễn phí toàn bộ chi phí chuyển tiền cho các thành viên.
							</li>
							<li>
								Để thanh toán, doanh thu của Publisher cần đạt tối thiểu hạn mức 200.000 VNĐ/1 kỳ thanh
								toán. DIMUADI sẽ cộng dồn vào kỳ thanh toán tiếp theo nếu chưa đủ hạn mức thanh toán.
							</li>
							<li>
								DIMUADI sẽ tiến hành thủ tục đối soát và thanh toán doanh thu cho Publisher vào thứ 6 hàng
								tuần nếu đạt điều kiện thanh toán.
							</li>
							<li>
								Doanh thu thực nhận của Publisher bằng 100% doanh thu trong tháng trừ đi các khoản thanh toán
								tạm tính (nếu có), thuế thu nhập cá nhân và khoản giảm trừ khác (nếu có).
							</li>
						</ul>
						<h3>2. NGHĨA VỤ CÁC BÊN</h3>
						<p className="title">
							<strong>2.1. Nghĩa vụ của DIMUADI</strong>
						</p>
						<ul>
							<li>
								Chịu trách nhiệm cung cấp công cụ thống kê trực tuyến,
								số liệu chạy qua nền tảng liên quan đến dịch vụ hợp tác cho Publisher.
							</li>
							<li>
								Đảm bảo chất lượng và duy trì hoạt động ổn định của hệ thống kỹ thuật
								của Nền tảng để đáp ứng các yêu cầu sử dụng Dịch vụ của các bên liên quan
								được thông suốt và kịp thời.
							</li>
							<li>
								DIMUADI cam kết không thể can thiệp vào Dịch vụ trong suốt quá trình hợp
								tác kinh doanh với Publisher.
							</li>
							<li>
								DIMUADI được Publisher ủy quyền chịu trách nhiệm thu hộ phí phân phối và
								quảng bá Dịch vụ, mọi kịch bản thu phí của Dịch vụ được tuân thủ theo đúng
								kịch bản thu phí của các Nhà quảng cáo Dịch vụ.
							</li>
							<li>
								Mọi trách nhiệm liên quan đến việc Dịch vụ bị chỉnh sửa/thay đổi nằm ngoài hệ
								thống Nền tảng của DIMUADI mà do Publisher quản lý thì Publisher phải chịu các
								trách nhiệm liên quan.
							</li>
							<li>
								Chịu trách nhiệm cung cấp công cụ thống kê trực tuyến, số liệu chạy qua Nền
								tảng liên quan đến Dịch vụ hợp tác cho Publisher.
							</li>
							<li>
								Chịu trách nhiệm cung cấp và hỗ trợ về kỹ thuật cho Publisher và các khách hàng
								của Publisher. Cung cấp và hướng dẫn cho Publisher các tài liệu về cách sử dụng Dịch vụ đảm bảo việc cung cấp Dịch vụ cho các khách hàng được đúng đắn, kịp thời và thông suốt.
							</li>
							<li>
								Thông báo kịp thời cho Publisher các nhược điểm hoặc sai sót trong các Dịch vụ mà DIMUADI phát hiện từ bất kỳ nguồn nào.
							</li>
							<li>
								Hỗ trợ với Publisher thực hiện và giải quyết mọi khiếu nại của khách hàng về vấn đề kỹ thuật của Dịch vụ.
							</li>
							<li>
								Chịu trách nhiệm đối soát, xác nhận số liệu và thanh toán cho Publisher phần doanh thu Publisher được hưởng được. Phần doanh thu còn lại DIMUADI có trách nhiệm thanh toán cho các Nhà quảng cáo (nếu có) và khấu trừ phần phí thuê Nền tảng DIMUADI được hưởng.
							</li>
						</ul>
						<p className="title">
							<strong>2.2. Nghĩa vụ của Advertiser</strong>
						</p>
						<ul>
							<li>
								Chịu hoàn toàn trách nhiệm trước pháp luật về Sản Phẩm và các thông tin hình ảnh liên quan do Advertiser cung cấp trên DIMUADI và có nhu cầu dịch vụ tiếp thị liên kết của DIMUA, đảm bảo các Sản Phẩm thông tin này là hợp pháp, không trái với đạo đức, thuần phong mỹ tục của Việt Nam.
							</li>
							<li>
								Advertiser đảm bảo có đầy đủ tư cách cũng như quyền pháp lý để cấp phép sử dụng các Sản Phẩm quảng cáo và thông tin liên quan cho DIMUADI, cam kết Sản phẩm không vi phạm bất cứ quyền của Bên thứ ba nào. Đồng thời tuân thủ đầy đủ quy định hiện hành của pháp luật về các loại giấy phép.
							</li>
							<li>
								Chủ động quảng bá, giới thiệu thông tin Dịch vụ đến khách hàng hoặc các đối tác phân phối một cách rõ ràng và minh bạch (Ví dụ: thông tin Dịch vụ đầy đủ; giá bán được niêm yết rõ ràng,…).
							</li>
						</ul>
						<p className='title'>
							<strong>2.3. Nghĩa vụ của Publisher</strong>
						</p>
						<ul>
							<li>
								Cung cấp cho DIMUADI bản sao chứng thực Chứng minh thư nhân dân của Publisher. Nếu Publisher có thay đổi Chứng minh thư nhân dân thì phải thông báo với DIMUADI đồng thời cung cấp cho DIMUADI bản sao chứng thực Chứng minh thư nhân dân mới của Publisher.
							</li>
							<li>
								Chịu hoàn toàn trách nhiệm trước pháp luật về Sản Phẩm và các thông tin hình ảnh liên quan do Publisher tiếp thị ra bên ngoài hệ thống của DIMUADI. Đảm bảo quá trình và nội dung truyền thông tiếp thị của Publisher đối với các sản phẩm từ hệ thống DIMUADI hợp pháp, không trái với đạo đức, thuần phong mỹ tục của Việt Nam và tuân thủ các quy định hiện hành của Nhà nước trong lĩnh vực Quảng cáo.
							</li>
							<li>
								Publisher sẽ chịu mọi trách nhiệm liên quan bao gồm nhưng không giới hạn trách nhiệm giải quyết các khiếu nại, yêu cầu của bên thứ ba liên quan đến quyền sở hữu trí tuệ, giấy phép và bất kỳ khiếu nại nào khác; bồi thường thiệt hại cho DIMUADI và/hoặc bên thứ ba (nếu có) do các hành vi vi phạm của mình gây ra.
							</li>
							<li>
								Cung cấp các thông tin thanh toán chính xác (tài khoản, ngân hàng …) được sử dụng để DIMUADI thanh toán cho Publisher. Publisher phải chịu hoàn toàn trách nhiệm về những rủi ro xảy ra do việc cung cấp thông tin thanh toán không chính xác gây ra. Trong trường hợp Publisher có thay đổi các thông tin thanh toán phải thông báo qua email hoặc văn bản cho DIMUADI và được DIMUADI kiểm tra, xác nhận. Thời hạn thông báo thay đổi trước kỳ thanh toán ít nhất  7 ngày.							</li>
							<li>
								Tất cả các Dịch vụ sau khi Publisher mang ra khỏi Nền tảng của DIMUADI, thì Publisher phải chịu trách nhiệm quản lý và kiểm soát.
							</li>
							<li>
								Mọi trách nhiệm liên quan đến việc Dịch vụ bị chỉnh sửa/thay đổi nằm ngoài hệ thống Nền tảng của DIMUADI mà do Publisher quản lý thì Publisher phải chịu các trách nhiệm liên quan.
							</li>
							<li>
								Đảm bảo các điều kiện khai thác, sử dụng Dịch vụ cho khách hàng trong phạm vi khả năng và trách nhiệm của Publisher; phát hiện ra các khiếm khuyết, sai sót của Dịch vụ từ bất kỳ nguồn nào và thông báo để DIMUADI phối hợp sửa chữa.
							</li>
							<li>
								Trong trường hợp có các tranh chấp/nghi vấn liên quan tới cách triển khai, hủy đơn hàng, …Publisher có trách nhiệm phải cung cấp các tài liệu, hồ sơ, bằng chứng cho DIMUADI để chứng minh và làm việc với advertiser.
							</li>
							<li>
								Phối hợp cùng DIMUADI thực hiện việc đối soát thanh toán doanh thu theo chu kỳ.
							</li>
							<li>
								<p className="title">
									<strong>Các hành vi bị nghiêm cấm</strong>
								</p>
								<ul>
									<li>
										Nghiêm cấm việc tiến hành quảng bá, kinh doanh, phân phối Dịch vụ bằng hình thức SPAM, gian lận như Cheating, hacking,… Nghiêm cấm quảng bá Dịch vụ trên các kênh vi phạm pháp luật, trái với thuần phong mỹ tục,
										kênh bị tranh chấp và/hoặc các hình thức không được pháp luật cho phép hoặc chưa quy định rõ ràng …. Nếu phát hiện Publisher vi phạm, Publisher cũng phải chịu số tiền phạt theo quy định của Điều khoản sử dụng này. Đồng thời DIMUADI không có trách nhiệm nào về kinh tế và pháp lý đối với các vi phạm ở trên do Publisher gây ra.
									</li>
									<li>
										Nghiêm cấm Publisher sử dụng link affiliate hoặc các thông tin của Nhà quảng cáo để thực hiện việc quảng cáo trên các diễn đàn, group, cộng đồng, Facebook giữa các Nhà quảng cáo đối thủ.
									</li>
									<li>
										Không chạy từ khóa thương hiệu, nghiêm cấm sử dụng quảng cáo tìm kiếm đối với các từ khóa thương hiệu của nhà quảng cáo trong trường hợp nhà quảng cáo có quy định.
									</li>
									<li>
										Không sử dụng từ khóa cố tình viết sai tên thương hiệu để gây nhầm lẫn với khách hàng. Cụ thể: – Không sử dụng các từ khóa thương hiệu (Brand Key) của nhà quảng cáo như: Shopee, Tiki, FPTshop, Yes24,… – Các từ khóa liên quan hoặc cố tình viết sai tên thương hiệu gây hiểu nhầm.
									</li>
									<li>
										Không chạy quảng cáo trực tiếp tới trang nhà quảng cáo: Publisher không được phép chạy quảng cáo trực tiếp tới trang của Nhà quảng cáo, không được để hiển thị đường link website của Nhà quảng cáo trong chiến dịch chạy quảng cáo. Việc chạy trực tiếp này rất dễ gây hiểu nhầm cho người dung cũng như ảnh hưởng tới thương hiệu của Nhà quảng cáo.
									</li>
									<li>
										Không được đại diện – mạo danh thương hiệu của nhà quảng cáo: Nghiêm cấm Publisher tạo dựng các trang web, page mạo danh nhà quảng cáo Nghiêm cấm Publisher liên hệ tư vấn trực tiếp cho khách hàng với tư cách là đại diện Nhà quảng cáo trên trang web của Nhà quảng cáo.
									</li>
									<li>
										Nghiêm cấm hành vi tự đặt hàng rồi hoàn trả, mạo danh Advertiser lừa đảo thông tin người dùng hoặc các hành vi gian lận khác để lôi kéo người tiêu dùng đặt đơn nhưng không nhận hàng.
									</li>
									<li>
										Nghiêm cấm hành vi bán phá giá đến người tiêu dùng, hoặc vi phạm quy định về giá của Nhà Cung Cấp và gây ảnh hưởng đến hệ thống phân phối của nhãn hàng.
									</li>
									<li>
										Nghiêm cấm hành vi liên hệ trực tiếp với nhà cung cấp để hỏi về đơn hàng và chính sách hoa hồng. Mọi thông tin cần giải đáp, vui lòng liên hệ DIMUADI qua Fanpage Dimuadi.vn hoặc nút chat trên ứng dụng.
									</li>
									<li>
										Nghiêm cấm hành vi giả mạo nhãn hàng hay cung cấp các thông tin sai lệch nhằm tiếp cận người dùng.
									</li>

								</ul>
							</li>
							<li>
								<p className='title'>
									<strong>
										Trường hợp Publisher vận hành trang mạng thuộc một trong các trường hợp sau thì DIMUADI
										có khả năng từ chối đơn đăng ký tham gia chương trình tiếp thị liên kết. Thêm vào đó,
										nếu sau khi được chấp thuận mà DIMUADI phát hiện ra trang mạng nêu trên cũng thuộc một
										trong các trường hợp sau thì DIMUADI có thể hủy bỏ việc đăng ký dựa theo các quy định của
										Thỏa thuận:
									</strong>
								</p>
								<ul>
									<li>
										Trang mạng chứa đựng nội dung tục tĩu, khiêu dâm.
									</li>
									<li>
										Trang mạng chứa đựng nội dung vi phạm Bản quyền tác giả, Bản quyền thương mại,
										nội dung từ các tên miền được biết đến rộng rãi hay các quyền sở hữu tài sản
										trí tuệ.
									</li>
									<li>
										Trang mạng chứa đựng nội dung có tính phỉ báng, bôi nhọ, cản trở việc kinh doanh,
										vi phạm danh dự, quyền riêng tư, quyền hình ảnh hay các hình thức tương tự.

									</li>
									<li>
										Trang mạng có liên quan đến các hoạt động đầu tư lừa đảo, bán hàng đa cấp hay
										các hình thức tương tự hoặc tham gia giới thiệu các hoạt động trên.
									</li>
									<li>
										Trang mạng có liên quan đến cờ bạc, cá cược
										(ngoại trừ các trò chơi do Nhà nước
										vận hành, sổ xố hay các trò chơi hợp pháp).
									</li>
									<li>
										Trang mạng chứa đựng nội dung vi phạm pháp luật hiện hành,
										các sắc lệnh, các quy định trong ngành, v.v, hay các nội
										dung trái luật và chống đối xã hội.
									</li>
									<li>
										Trang mạng chứa đựng nội dung công kích lại trật tự và
										đạo đức cộng đồng.
									</li>
									<li>
										Trang mạng chứa đựng nội dung về tôn giáo.
									</li>
									<li>
										Trang mạng chỉ dành cho người thân, Publisher bè, các cá nhân cụ thể sử dụng.
									</li>
									<li>
										Trang mạng công khai cho công chúng không theo cách thức, ví dụ như, yêu cầu
										có tên người dùng và mật mã để truy cập.
									</li>
									<li>
										Trang mạng nghèo nàn về nội dung hay khó hiểu.
									</li>
									<li>
										Trang mạng chứa đựng số lượng lớn các đường dẫn tới các trang mạng khác.
									</li>
									<li>
										Các trường hợp khác mà DIMUADI xét thấy không phù hợp.
									</li>
								</ul>
							</li>
						</ul>
					</div>
				)}

				{pathname === '/chinh-sach' && (
					<div className="d2c__policy__content">
						<h3>CHÍNH SÁCH BẢO VỆ THÔNG TIN CÁ NHÂN</h3>
						<ul>
							<li>
								Chính sách này áp dụng cùng với các thông báo, điều khoản hợp đồng,
								điều khoản chấp thuận khác áp dụng liên quan đến việc DIMUADI thu thập,
								lưu trữ, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân của Publisher và khách hàng.
							</li>
							<li>
								Chính sách này được áp dụng cho cả Publisher và khách hàng đang sử dụng sản phẩm, dịch vụ
								của DIMUADI trừ khi có tuyên bố rõ ràng ngược lại.
							</li>
						</ul>
						<p className='title'>
							<strong>1. Phương thức DIMUADI thu thập dữ liệu cá nhân của Publisher & khách hàng:</strong>
						</p>
						<ul>
							<li style={{ listStyle: 'none' }}>
								Dữ liệu cá nhân cơ bản bao gồm:
								<ul>
									<li>
										Họ, chữ đệm và tên khai sinh, tên gọi khác (nếu có).
									</li>
									<li>
										Ngày, tháng, năm sinh; ngày, tháng, năm chết hoặc mất tích.
									</li>
									<li>
										Giới tính.
									</li>
									<li>
										Nơi sinh, nơi đăng ký khai sinh, nơi thường trú, nơi tạm trú, nơi ở hiện tại,
										quê quán, địa chỉ liên hệ.
									</li>
									<li>Quốc tịch.</li>
									<li>Hình ảnh của cá nhân.
									</li>
									<li>Số điện thoại, số chứng minh nhân dân, số định danh cá nhân, số hộ chiếu,
										số giấy phép lái xe, số biển số xe, số mã số thuế cá nhân, số bảo hiểm xã hội, số thẻ bảo hiểm y tế.
									</li>
									<li>Thông tin về tài khoản số của cá nhân; dữ liệu cá nhân phản ánh hoạt động,
										lịch sử hoạt động trên không gian mạng.

									</li>
								</ul>
							</li>
							<li style={{ listStyle: 'none' }}>
								DIMUADI thu thập dữ liệu cá nhân bằng cách thức:
								<ul>
									<li>Khi Publisher đăng ký và/hoặc sử dụng Nền tảng DIMUADI.</li>
									<li>
										Khi Publisher gửi bất kỳ biểu mẫu nào, bao gồm đơn đăng ký hoặc các mẫu đơn khác liên quan đến bất kỳ sản phẩm và dịch vụ
										nào của DIMUADI, bằng hình thức trực tuyến hay dưới hình thức khác.
									</li>
									<li>
										Khi Publisher ký kết bất kỳ thỏa thuận nào hoặc cung cấp các tài liệu hoặc thông tin khác liên quan đến tương tác giữa Publisher với DIMUADI.
									</li>
									<li>
										Khi Publisher tương tác với DIMUADI, như cuộc gọi điện thoại (có thể được ghi âm lại), thư từ, fax, gặp gỡ trực tiếp,
										các nền ứng dụng truyền thông xã hội và email và các hình thức khác.
									</li>
									<li>
										Khi Publisher sử dụng các kênh truyền thông của DIMUADI, DIMUADI sẽ/có thể lưu trữ các thông tin về cookie.
									</li>
									<li>
										Khi Publisher cấp quyền trên thiết bị của Publisher để chia sẻ thông tin với ứng dụng hoặc Nền tảng của DIMUADI.
									</li>
									<li>
										Khi Publisher liên kết tài khoản DIMUADI với tài khoản mạng xã hội của Publisher hoặc các tài khoản bên ngoài khác
										hoặc sử dụng các tính năng mạng xã hội khác.
									</li>
									<li>
										Khi publisher thực hiện các giao dịch thông qua Dịch vụ của DIMUADI chẳng hạn như mỗi khi publisher đặt mua hàng.
									</li>
									<li>
										Khi Publisher cung cấp ý kiến phản hồi hoặc gửi khiếu nại cho DIMUADI.
									</li>
									<li>
										Khi Publisher đăng ký tham gia một cuộc thi.
									</li>
									<li>
										Khi Publisher gửi dữ liệu cá nhân của Publisher cho DIMUADI vì bất kỳ lý do gì.
									</li>
									<li>
										Khi Publisher thực hiện các hoạt động khác trên Nền tảng của DIMUADI.
									</li>
									<li>
										Và nhiều trường hợp khác.
									</li>
									<li>
										DIMUADI có thể thu thập thông tin của Publisher từ Publisher, các công ty liên kết, các bên thứ ba và từ các nguồn khác,
										bao gồm nhưng không giới hạn ở đối tác kinh doanh (ví dụ như đơn vị cung ứng dịch vụ vận chuyển, thanh toán), cơ quan đánh giá tín dụng,
										các đơn vị, đối tác cung cấp dịch vụ marketing, giới thiệu, các chương trình khách hàng thân thiết, những người dùng khác sử dụng
										Các Dịch Vụ của DIMUADI hoặc các nguồn dữ liệu công khai có sẵn hay các nguồn dữ liệu của nhà nước.
									</li>
								</ul>
							</li>
						</ul>

						<p className='title'>
							<strong>2. Mục đích thu thập thông tin cá nhân</strong>
						</p>
						<ul>
							<li style={{ listStyle: 'none' }}>DIMUADI có thể thu thập, sử dụng, tiết lộ và/hoặc xử lý dữ liệu cá nhân của Publisher và khách hàng cho các mục đích sau đây:</li>
							<li>Để xem xét và/hoặc xử lý đơn đăng ký/giao dịch của Publisher với DIMUADI hoặc giao dịch hay thư từ của Publisher và khách hàng với các bên thứ ba qua Các Dịch Vụ.</li>
							<li>Để quản lý, điều hành, cung cấp và/hoặc quản lý việc Publisher và khách hàng sử dụng và/hoặc truy cập Các Dịch Vụ và các Nền tảng của DIMUADI (bao gồm các sở thích của Publisher và khách hàng), cũng như quan hệ và tài khoản người dùng của Publisher và khách hàng với DIMUADI.</li>
							<li>Để đáp ứng, xử lý, giải quyết hoặc hoàn tất một giao dịch và/hoặc đáp ứng các yêu cầu của Publisher, khách hàng và Advertisers đối với các sản phẩm và dịch vụ nhất định và thông báo cho Publisher và khách hàng về các vấn đề dịch vụ và các hoạt động tài khoản bất thường.</li>
							<li>Để thực thi các Điều Khoản Dịch Vụ của DIMUADI hoặc bất kỳ thỏa thuận giấy phép người dùng cuối nào áp dụng.</li>
							<li>Để bảo vệ sự an toàn cá nhân và các quyền, tài sản hoặc sự an toàn của người khác.</li>
							<li>Để phục vụ mục đích nhận dạng, xác minh, đánh giá pháp lý hoặc để nhận biết khách hàng.</li>
							<li>Để liên hệ với Publisher và khách hàng hoặc liên lạc với Publisher và khách hàng qua điện thoại, tin nhắn văn bản và/hoặc tin nhắn fax, email và/hoặc thư hoặc cách khác nhằm mục đích quản trị và/hoặc quản lý quan hệ của Publisher và khách hàng với DIMUADI hoặc việc Publisher và khách hàng sử dụng Các Dịch Vụ của DIMUADI.</li>
							<li>Để cho phép các người dùng khác tương tác hoặc liên lạc với Publisher và khách hàng hoặc thấy một số hoạt động của Publisher và khách hàng trên Nền tảng, bao gồm để thông báo cho Publisher và khách hàng khi một người dùng khác đã gửi cho Publisher và khách hàng một tin nhắn riêng tư hoặc đăng nhận xét về Publisher và khách hàng trên Nền tảng hoặc để liên kết với việc Publisher và khách hàng sử dụng các tính năng xã hội trên Nền tảng.</li>
							<li>Để tiến hành các hoạt động nghiên cứu, phân tích và phát triển (bao gồm nhưng không giới hạn phân tích dữ liệu, khảo sát, phát triển và/hoặc lập đặc tính sản phẩm và dịch vụ), để phân tích cách thức Publisher và khách hàng sử dụng Các Dịch Vụ của DIMUADI, để giới thiệu sản phẩm và/hoặc dịch vụ theo sự quan tâm của Publisher và khách hàng, để cải thiện Các Dịch Vụ hoặc sản phẩm của DIMUADI và/hoặc để cải thiện trải nghiệm khách hàng của Publisher và khách hàng.</li>
							<li>Để lập số liệu thống kê và nghiên cứu đáp ứng yêu cầu báo cáo và/hoặc duy trì sổ sách nội bộ hoặc theo quy định.</li>
							<li>Để thực hiện quy trình tìm hiểu và xác minh hoặc các hoạt động sàng lọc khác (bao gồm nhưng không giới hạn kiểm tra lý lịch) tuân thủ các nghĩa vụ theo quy định pháp luật hoặc cơ quan nhà nước có thẩm quyền hoặc các thủ tục kiểm soát rủi ro của DIMUADI, có thể được pháp luật yêu cầu hoặc có thể đã được DIMUADI áp dụng.</li>
							<li>Để ngăn chặn hoặc điều tra bất kỳ hoạt động gian lận thực tế hoặc bị nghi ngờ nào đối với Điều khoản dịch vụ của DIMUADI, gian lận, các hành vi phi pháp, thiếu sót hay hành vi sai trái nào, cho dù có liên quan đến việc Publisher và khách hàng sử dụng Các Dịch Vụ của DIMUADI hay không hay bất kỳ vấn đề nào phát sinh từ quan hệ của Publisher và khách hàng với DIMUADI.</li>
							<li>Để đáp ứng bất cứ các mối đe dọa hoặc yêu cầu thực tế nào được khẳng định chống lại DIMUADI hoặc các yêu cầu khác liên quan đến các Nội dung vi phạm quy định của các bên thứ ba.</li>
							<li>Để lưu trữ, lập máy chủ, sao lưu (cho dù là vì mục đích khôi phục sau thảm họa hoặc mục đích khác) đối với dữ liệu cá nhân của Publisher và khách hàng.</li>
							<li>Bất kỳ mục đích nào mà DIMUADI thông báo cho Publisher và khách hàng tại thời điểm xin sự cho phép của Publisher và khách hàng.</li>
						</ul>

						<p className='title'>
							<strong>3. Trách nhiệm của Publisher</strong>
						</p>
						<ul>
							<li>Publisher có trách nhiệm cập nhật đầy đủ thông tin lên Publisher Profile và cam kết các thông tin cung cấp là chính xác. Nghiêm cấm việc sử dụng thông tin của người khác để đăng ký.</li>
							<li>Publisher có trách nhiệm cập nhật đầy đủ thông tin khách hàng lên nền tảng DIMUADI và cam kết thông tin cung cấp là chính xác. Và Publisher tuyên bố và đảm bảo rằng Publisher đã nhận được sự đồng ý của khách hàng cho Publisher thu thập và xử lý dữ liệu cá nhân của khách hàng, cũng như việc khách hàng đồng ý việc Publisher chuyển dữ liệu của khách hàng cho DIMUADI tiếp nhận và xử lý.</li>
							<li>DIMUADI sẽ tuân thủ theo “Chính sách riêng tư” được thiết lập riêng biệt cho việc xử lý thông tin cá nhân (được hiểu là các thông tin có thể giúp xác định danh tính của cá nhân như tên, ngày sinh hay các thông tin mô tả khác) mà DIMUADI có được từ việc cung cấp Các dịch vụ.</li>
							<li>DIMUADI sẽ sử dụng và tiết lộ thông tin thống kê hay các thông tin tương tự liên quan đến Publisher nhưng không giúp nhận dạng danh tính của Publisher.</li>
							<li>DIMUADI có quyền hủy/đóng hoặc xử lý các tài khoản của Publisher mà không cần thông báo trước cho Publisher trong trường hợp tài khoản của Publisher không hoạt động hoặc hoạt động không theo quy định.</li>
							<li>Trong suốt thời hạn hợp tác với DIMUADI, Publisher cam kết không xử lý dữ liệu cá nhân của Khách hàng trái với quy định của pháp luật về bảo vệ dữ liệu cá nhân, Publisher tuyên bố và đảm bảo rằng mình đã có được sự đồng ý cho phép xử lý dữ liệu cá nhân của chủ thể dữ liệu cá nhân khác đối với các dữ liệu cá nhân mà Publisher cung cấp cho DIMUADI. </li>
							<li>DIMUADI được miễn trừ hoàn toàn mọi trách nhiệm pháp lý, kinh tế liên quan trong trường hợp: Có bất kỳ sự tranh chấp/khiếu nại về việc Publisher xử lý dữ liệu cá nhân Khách hàng trái quy định pháp luật về bảo vệ dữ liệu cá nhân và/hoặc gây thiệt hại cho Khách hàng. </li>
							<li>Nhằm làm rõ, xử lý dữ liệu cá nhân là một hoặc nhiều hoạt động tác động tới dữ liệu cá nhân, như: thu thập, ghi, phân tích, xác nhận, lưu trữ, chỉnh sửa, công khai, kết hợp, truy cập, truy xuất, thu hồi, mã hóa, giải mã, sao chép, chia sẻ, truyền đưa, cung cấp, chuyển giao, xóa, hủy dữ liệu cá nhân hoặc các hành động khác có liên quan. </li>
							<li>Trường hợp có bất kỳ tranh chấp, khiếu nại hay khiếu kiện liên quan đến dữ liệu cá nhân do Publisher cung cấp cho DIMUADI, Publisher chịu trách nhiệm tự xử lý và phải bảo vệ cho DIMUADI tránh khỏi các tranh chấp, khiếu nại hay khiếu kiện này, và bồi thường đầy đủ cho DIMUADI: mọi thiệt hại, tổn thất, chi phí, phí tổn nào phát sinh từ các tranh chấp, khiếu nại hay khiếu kiện đó.</li>
						</ul>

						<p className='title'>
							<strong>4. Phạm vi sử dụng thông tin</strong>
						</p>
						<ul>
							<li>Nhằm phòng ngừa các hành vi sai trái về các dịch vụ Liên kết quảng cáo, DIMUADI sẽ sử dụng và chia sẻ thông tin về các hành vi sai trái của Publisher (như tên Publisher, thông tin trang mạng đăng kí, địa chỉ, tài khoản ngân hàng).</li>
							<li>
								Nếu xảy ra một trong các trường hợp sau, DIMUADI có thể tiết lộ các thông tin đăng ký và dữ liệu giao dịch của Publisher thu được từ việc vận hành các quảng cáo (hay các chương trình Liên kết quảng cáo):
								<ul>
									<li>
										Tòa án, công an, cơ quan thuế hay các đại diện hành chính khác phát lệnh hay tiến hành điều tra.
									</li>
									<li>
										Tiết lộ thông tin về các quảng cáo (hay các chương trình quảng cáo) cho Nhà quảng cáo trong khuôn khổ vận hành Các dịch vụ; với điều kiện chỉ áp dụng điều khoản này khi DIMUADI đã kí kết thỏa thuận Bảo mật thông tin với Nhà quảng cáo.
									</li>
								</ul>
							</li>
						</ul>

						<p className='title'>
							<strong>5. Bảo mật thông tin</strong>
						</p>
						<ul>
							<li>
								Publisher không được tiết lộ cho bên thứ ba các thông tin về công nghệ, công việc kinh doanh, doanh thu của DIMUADI hay của Nhà quảng cáo có được liên quan đến việc tham gia Các dịch vụ và được công nhận là thông tin mật, ngoại trừ các thông tin đăng trên các tên miền công cộng. Quy định này sẽ tiếp tục có hiệu lực sau khi Đơn đăng ký của Publisher bị hủy bỏ.
							</li>
							<li>
								DIMUADI sẽ/có thể sử dụng, xử lý, tiết lộ và/hoặc chuyển giao dữ liệu cá nhân của Publisher và khách hàng cho các nhà cung cấp dịch vụ bên thứ ba, đại lý và/hoặc các công ty liên kết hoặc công ty liên quan của DIMUADI, có thể ở Việt Nam hoặc bên ngoài Việt Nam, và việc tiết lộ này sẽ được thực hiện theo đúng trình tự và quy định của pháp luật hiện hành. Các nhà cung cấp dịch vụ bên thứ ba,
								đại lý và/hoặc các công ty liên kết hoặc công ty liên quan và/hoặc các bên thứ ba khác như thế sẽ xử lý dữ liệu cá nhân của Publisher và khách hàng thay mặt DIMUADI hoặc ngược lại. DIMUADI cố gắng đảm bảo rằng các bên thứ ba và các công ty liên kết của DIMUADI giữ an toàn cho dữ liệu cá nhân của publisher và khách hàng khỏi bị truy cập, thu thập, sử dụng, tiết lộ, xử lý trái phép.
							</li>
							<li>
								Bằng việc tạo, sử dụng tài khoản, sản phẩm và dịch vụ DIMUADI, các Publisher chấp nhận mọi quy định tại chính sách bảo mật này. Bao gồm nhưng không giới hạn trong việc Publisher đồng ý với việc DIMUADI có quyền thu thập và xử lý dữ liệu cá nhân của mình.
							</li>
						</ul>

						<p className='title'>
							<strong>6. Thời gian lưu trữ thông tin</strong>
						</p>
						<ul>
							<li>
								Thời gian lưu trữ thông tin Publisher Profile trên hệ thống DIMUADI được mặc định là cho tới khi nào Publisher yêu cầu hủy/xóa tài khoản vĩnh viễn.
							</li>
							<li>
								Các thông tin được lưu trữ bao gồm: Họ tên, Số điện thoại, Email, Số tài khoản Ngân hàng, Website, Cách đẩy traffic và một số các thông tin khác.
							</li>
						</ul>

						<p className='title'>
							<strong>7. Địa chỉ của đơn vị thu thập và quản lý thông tin</strong>
						</p>
						<ul>
							<li style={{ listStyle: 'none' }}>Trong trường hợp cần hỗ trợ/khiếu nại về vấn đề thông tin cá nhân, khách hàng có thể liên hệ trực tiếp đơn vị chủ sở hữu website:</li>
							<div className="info">
								<p>CÔNG TY CỔ PHẦN THƯƠNG MẠI VÀ DỊCH VỤ D2C VIỆT NAM</p>

								<p>Địa chỉ: Số 1, ngách 1, ngõ 63, Phố Ngô Thì Sỹ, Tổ dân phố số 9, Phường Vạn Phúc, Quận Hà Đông, Thành phố Hà Nội.</p>

								<p>Hotline: 024 3217 1304</p>
								<p>
									Email: <a href="mailto:support@dimuadi.vn">support@dimuadi.vn</a>
								</p>
							</div>
						</ul>

						<p className='title'>
							<strong>8. Phương thức và công cụ để người tiêu dùng tiếp cận và chỉnh sửa dữ liệu cá nhân</strong>
						</p>
						<ul>
							<li style={{ listStyle: 'none' }}>Trong trường hợp muốn thay đổi thông tin cá nhân bất kỳ, khách hàng có thể truy cập vào Trang cá nhân của mình trên hệ thống DIMUADI để thêm bớt và chỉnh sửa tùy ý.</li>
						</ul>

						<p className='title'>
							<strong>9. Quy định về việc cài đặt và tích hợp hệ thống</strong>
						</p>
						<ul>
							<li style={{ listStyle: 'none' }}>Để việc tracking đơn hàng được tiến hành theo đúng thỏa thuận, Nhà quảng cáo có trách nhiệm hoàn thành nghĩa vụ tích hợp đúng tiêu chuẩn đề ra khi tích hợp chiến dịch lần đầu, đồng thời đảm bảo kết quả tích hợp được duy trì chất lượng liên tục trong thời gian hợp tác.</li>
						</ul>

						<p className='title'>
							<strong>10. Cơ chế hỗ trợ giải quyết khiếu nại</strong>
						</p>
						<ul>
							<li style={{ listStyle: 'none' }}>Trong trường hợp có khiếu nại liên quan đến việc hợp tác, Publisher và Nhà quảng cáo có thể liên hệ trực tiếp tới cổng hỗ trợ trực tuyến thông qua các kênh như: Website, Fanpage, Email, Hotline. Thời gian phản hồi khiếu nại là không quá 8 tiếng làm việc kể từ lúc khách hàng gửi thông tin.</li>
						</ul>
					</div>
				)}
			</div>
		</div>
	)
}

export default PolicyVietname
