import { CREATE_ORDER_TYPE_REDUCER } from '../../constants/home-contants'

const createOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case CREATE_ORDER_TYPE_REDUCER.BOOKER_FORM:
            return {
                ...state,
                isBooker: action.isBooker,
            }
        case CREATE_ORDER_TYPE_REDUCER.SHOW_NOT_BUY:
        return {
            ...state,
            isShowNotBuy: action.isShowNotBuy,
        }
        default:
            return { ...state }
    }
}

export default createOrderReducer
