import { CHECK_OUT_TYPE_REDUCER } from '../../constants/common-contants'

const checkoutReducer = (state = {}, action) => {
    switch (action.type) {
        case CHECK_OUT_TYPE_REDUCER.OPEN_CHANGE_ADDRESS:
            return {
                ...state,
                isChangeAddress: action.isChangeAddress,
            }
        case CHECK_OUT_TYPE_REDUCER.OPEN_ADD_ADDRESS:
            return {
                ...state,
                openAddress: action.openAddress,
            }
        case CHECK_OUT_TYPE_REDUCER.OPEN_ACTION__ADDRESS:
            return {
                ...state,
                isActionAddress: action.isActionAddress,
            }
        case CHECK_OUT_TYPE_REDUCER.SET_ID_ADDRESS:
            return {
                ...state,
                idAddress: action.idAddress,
            }
        case CHECK_OUT_TYPE_REDUCER.SET_IS_ADDRESS:
            return {
                ...state,
                isAddress: action.isAddress,
            }
        case CHECK_OUT_TYPE_REDUCER.OPEN_CONFIRM_DELETE:
            return {
                ...state,
                isShowDelete: action.isShowDelete,
            }
        default:
            return { ...state }
    }
}

export default checkoutReducer
