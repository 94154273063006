import React from 'react'
import { Button, Modal } from 'antd'
import Slider from 'react-slick/lib'
import Rule1 from '../../assets/images/rule_1.png'
import Rule2 from '../../assets/images/rule_2.png'
import Rule3 from '../../assets/images/rule_3.png'
import Rule4 from '../../assets/images/rule_4.png'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { isMobileScreen } from '../../functions/common-func'

function TutorialWelcomeUser({ onClose, isShowWelcome, dispatch }) {
    const settings = {
        autoplaySpeed: 1000,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: 'rule__content',
        dots: true,
    }
    const handleClose = () => {
        onClose()
        localStorage.setItem('is_show_tutorial', JSON.stringify(true))
    }
    if (!isMobileScreen()) {
        return (
            <Modal
                onCancel={handleClose}
                style={{ top: 30 }}
                footer={null}
                className="rule__content"
                visible={isShowWelcome}
            >
                <Slider {...settings}>
                    <div className="rule__content__item">
                        <div className="images__rule">
                            <img src={Rule1} alt="ảnh rule 1" />
                        </div>
                        <div className="rule__content__one__text">
                            <div className="rule__title">
                                <h3> 4 bước để bán hàng với Dimuadi</h3>
                                <span>Đọc kỹ để kiếm nhiều hoa hồng hơn </span>
                            </div>
                            <ul className="list__content">
                                <li className="item__content__rule">
                                    <div className="step">01</div>
                                    <p>Chọn nội dung mẫu muốn chia sẻ. Hãy viết lại một chút theo ngôn ngữ của bạn</p>
                                </li>
                                <li className="item__content__rule">
                                    <div className="step">02</div>
                                    <p>
                                        Đăng tin cho khách hàng, bạn bè kèm link giới thiệu của bạn. Bạn có thể “đặt hộ
                                        đơn” cho bạn bè
                                    </p>
                                </li>
                                <li className="item__content__rule">
                                    <div className="step">03</div>
                                    <p>
                                        Đơn hàng được ghi nhận sau 1-2 phút. Hãy cập nhập thông báo để theo dõi đơn hàng
                                        mỗi ngày
                                    </p>
                                </li>
                                <li className="item__content__rule">
                                    <div className="step">04</div>
                                    <p>Nhận hoa hồng vào rút tiền về tài khoản khi đủ hạn mức</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="rule__content__item">
                        <div className="images__rule">
                            <img src={Rule2} alt="ảnh rule 1" />
                        </div>
                        <div className="rule__content__one__text">
                            <div className="rule__title">
                                <h3> Tôi sẽ nhận hoa hồng khi nào</h3>
                                <span>Đọc kỹ truớc khi bán </span>
                            </div>
                            <ul className="list__content">
                                <li className="item__content__rule">
                                    <div className="step">01</div>
                                    <p>
                                        Hoa hồng tạm duyệt (Pre-approve) sau khi khách hàng đồng ý mua hàng hoặc hoàn
                                        thành hoạt động mua hàng (tùy chiến dịch)
                                    </p>
                                </li>
                                <li className="item__content__rule">
                                    <div className="step">02</div>
                                    <p>
                                        Hoa hồng được duyệt (Approved) là hoa hồng chắc chắn nhận được và sẽ được thanh
                                        toán qua tài khoản từ 18-25 hàng tháng.
                                    </p>
                                </li>
                                <li className="item__content__rule">
                                    <div className="step">03</div>
                                    <p>
                                        Hạn mức được nhận hoa hồng: trên 200.000đ/tháng hoặc trên 500.000đ/tuần (Đối với
                                        chiến dịch có thanh toán tuần)
                                    </p>
                                </li>
                                <li className="item__content__rule">
                                    <div className="step">04</div>
                                    <p>Thông báo về hoa hồng sẽ được gửi qua thông báo và email.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="rule__content__item">
                        <div className="images__rule">
                            <img src={Rule3} alt="ảnh rule 1" />
                        </div>
                        <div className="rule__content__one__text">
                            <div className="rule__title">
                                <h3> Đọc kỹ kẻo bị “block”</h3>
                                <span>Quy định bắt buộc </span>
                            </div>
                            <ul className="list__content">
                                <h4>Điều kiện tham gia chiến dịch</h4>
                                <p className="mb-20">Tỷ lệ đơn rác của Publisher không vượt quá 30% tổng lead.</p>
                                <p className="mb-20">
                                    <span>Định nghĩa đơn rác</span> = Đơn hàng bị trùng, khách hàng không có nhu cầu
                                    hoặc không quan tâm về sản phẩm, sai họ tên, sai số điện thoại, thuê bao tạm khóa,
                                    gọi liên tục 4 cuộc gọi không thành công.{' '}
                                </p>
                                <p>Publisher có thể cập nhập đơn rác ở phần Báo cáo , Lý do hủy </p>
                                <p>
                                    {' '}
                                    Tỉ lệ duyệt dưới 20% sẽ bị block, với Pub mới có 3 mới phát sinh ko được duyệt sẽ bị
                                    block{' '}
                                </p>
                                <p>
                                    {' '}
                                    Test đơn nhiều hoặc nhập sai thông tin, cố tình đưa đơn rác vào hệ thống sẽ bị block{' '}
                                </p>
                            </ul>
                        </div>
                    </div>
                    <div className="rule__content__item">
                        <div className="images__rule">
                            <img src={Rule4} alt="ảnh rule 1" />
                        </div>
                        <div className="rule__content__one__text">
                            <div className="rule__title">
                                <h3> Hoa hồng ghi nhận khi nào</h3>
                                <span>Đọc kỹ hướng dẫn</span>
                            </div>
                            <ul className="list__content">
                                <h4>Điều kiện chấp nhận</h4>
                                <p className="mb-20">
                                    Hoa hồng được chấp nhận thì khánh nhận hàng và thanh toán thành công
                                </p>
                                <p className="mb-20">
                                    <span>Định nghĩa đơn rác</span> = Đơn hàng bị trùng, khách hàng không có nhu cầu
                                    hoặc không quan tâm về sản phẩm, sai họ tên, sai số điện thoại, thuê bao tạm khóa,
                                    gọi liên tục 4 cuộc gọi không thành công.{' '}
                                </p>
                                <p>Publisher có thể cập nhập đơn rác ở phần Báo cáo , Lý do hủy </p>
                            </ul>
                            <div className="form__button">
                                <Button onClick={handleClose} type="primary" size="large" className="w-100">
                                    {' '}
                                    Đã hiểu! Bán hàng ngay{' '}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Slider>
            </Modal>
        )
    }
    return (
        <div className={`rule__for__partner ${isShowWelcome ? 'open' : ''}`}>
            <div className="close__popup" onClick={handleClose}>
                Bỏ qua
            </div>
            <Slider {...settings}>
                <div className="rule__content__item">
                    <div className="images__rule">
                        <img src={Rule1} alt="ảnh rule 1" />
                    </div>
                    <div className="rule__content__one__text">
                        <div className="rule__title">
                            <h3> 4 bước để bán hàng với Dimuadi</h3>
                            <span>Đọc kỹ để kiếm nhiều hoa hồng hơn </span>
                        </div>
                        <ul className="list__content">
                            <li className="item__content__rule">
                                <div className="step">01</div>
                                <p>Chọn nội dung mẫu muốn chia sẻ. Hãy viết lại một chút theo ngôn ngữ của bạn</p>
                            </li>
                            <li className="item__content__rule">
                                <div className="step">02</div>
                                <p>
                                    Đăng tin cho khách hàng, bạn bè kèm link giới thiệu của bạn. Bạn có thể “đặt hộ đơn”
                                    cho bạn bè
                                </p>
                            </li>
                            <li className="item__content__rule">
                                <div className="step">03</div>
                                <p>
                                    Đơn hàng được ghi nhận sau 1-2 phút. Hãy cập nhập thông báo để theo dõi đơn hàng mỗi
                                    ngày
                                </p>
                            </li>
                            <li className="item__content__rule">
                                <div className="step">04</div>
                                <p>Nhận hoa hồng vào rút tiền về tài khoản khi đủ hạn mức</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="rule__content__item">
                    <div className="images__rule">
                        <img src={Rule2} alt="ảnh rule 1" />
                    </div>
                    <div className="rule__content__one__text">
                        <div className="rule__title">
                            <h3> Tôi sẽ nhận hoa hồng khi nào</h3>
                            <span>Đọc kỹ truớc khi bán </span>
                        </div>
                        <ul className="list__content">
                            <li className="item__content__rule">
                                <div className="step">01</div>
                                <p>
                                    Hoa hồng tạm duyệt (Pre-approve) sau khi khách hàng đồng ý mua hàng hoặc hoàn thành
                                    hoạt động mua hàng (tùy chiến dịch)
                                </p>
                            </li>
                            <li className="item__content__rule">
                                <div className="step">02</div>
                                <p>
                                    Hoa hồng được duyệt (Approved) là hoa hồng chắc chắn nhận được và sẽ được thanh toán
                                    qua tài khoản từ 18-25 hàng tháng.
                                </p>
                            </li>
                            <li className="item__content__rule">
                                <div className="step">03</div>
                                <p>
                                    Hạn mức được nhận hoa hồng: trên 200.000đ/tháng hoặc trên 500.000đ/tuần (Đối với
                                    chiến dịch có thanh toán tuần)
                                </p>
                            </li>
                            <li className="item__content__rule">
                                <div className="step">04</div>
                                <p>Thông báo về hoa hồng sẽ được gửi qua thông báo và email.</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="rule__content__item">
                    <div className="images__rule">
                        <img src={Rule3} alt="ảnh rule 1" />
                    </div>
                    <div className="rule__content__one__text">
                        <div className="rule__title">
                            <h3> Đọc kỹ kẻo bị “block”</h3>
                            <span>Quy định bắt buộc </span>
                        </div>
                        <ul className="list__content">
                            <h4>Điều kiện tham gia chiến dịch</h4>
                            <p className="mb-20">Tỷ lệ đơn rác của Publisher không vượt quá 30% tổng lead.</p>
                            <p className="mb-20">
                                <span>Định nghĩa đơn rác</span> = Đơn hàng bị trùng, khách hàng không có nhu cầu hoặc
                                không quan tâm về sản phẩm, sai họ tên, sai số điện thoại, thuê bao tạm khóa, gọi liên
                                tục 4 cuộc gọi không thành công.{' '}
                            </p>
                            <p>Publisher có thể cập nhập đơn rác ở phần Báo cáo , Lý do hủy </p>
                            <p>
                                {' '}
                                Tỉ lệ duyệt dưới 20% sẽ bị block, với Pub mới có 3 mới phát sinh ko được duyệt sẽ bị
                                block{' '}
                            </p>
                            <p>
                                {' '}
                                Test đơn nhiều hoặc nhập sai thông tin, cố tình đưa đơn rác vào hệ thống sẽ bị block{' '}
                            </p>
                        </ul>
                    </div>
                </div>
                <div className="rule__content__item">
                    <div className="images__rule">
                        <img src={Rule4} alt="ảnh rule 1" />
                    </div>
                    <div className="rule__content__one__text">
                        <div className="rule__title">
                            <h3> Hoa hồng ghi nhận khi nào</h3>
                            <span>Đọc kỹ hướng dẫn</span>
                        </div>
                        <ul className="list__content">
                            <h4>Điều kiện chấp nhận</h4>
                            <p className="mb-20">
                                Hoa hồng được chấp nhận thì khánh nhận hàng và thanh toán thành công
                            </p>
                            <p className="mb-20">
                                <span>Định nghĩa đơn rác</span> = Đơn hàng bị trùng, khách hàng không có nhu cầu hoặc
                                không quan tâm về sản phẩm, sai họ tên, sai số điện thoại, thuê bao tạm khóa, gọi liên
                                tục 4 cuộc gọi không thành công.{' '}
                            </p>
                            <p>Publisher có thể cập nhập đơn rác ở phần Báo cáo , Lý do hủy </p>
                        </ul>
                        <div className="form__button">
                            <Button onClick={handleClose} type="primary" size="large" className="w-100">
                                {' '}
                                Đã hiểu! Bán hàng ngay{' '}
                            </Button>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        isShowWelcome: state.homeReducer.isShowWelcome,
    }
}
export default withRouter(connect(mapStateToProps)(TutorialWelcomeUser))
