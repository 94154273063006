export const SEARCH_TYPE_REDUCER = {
    OPEN_HOT_DiRECTORY: 'OPEN_HOT_DiRECTORY',
    OPEN_FILTER_PRODUCT: 'OPEN_FILTER_PRODUCT',
    OPEN_SHOW_CONFIRM_CART: 'OPEN_SHOW_CONFIRM_CART',
    OPEN_SHOW_CHOOSE_CONTENT: 'OPEN_SHOW_CHOOSE_CONTENT',
    OPEN_SORT_PRODUCT: 'OPEN_SORT_PRODUCT',
    OPEN_CONFIRM_TUTORIAL_SHARE: 'OPEN_CONFIRM_TUTORIAL_SHARE',
    TOTAL_PRODUCT_FROM_CART: 'TOTAL_PRODUCT_FROM_CART',
    TOTAL_NOTIFICATION_NOT_READ: 'TOTAL_NOTIFICATION_NOT_READ',
    REFRESH_CART: 'REFRESH_CART',
    REFRESH_NOTIFICATION: 'REFRESH_NOTIFICATION',
}

export const FILTER_PRICE = [
    {
        label: 'Dưới 100k',
        value: 'below_100',
        checked: false,
    },
    {
        label: '100k-300k',
        value: 'from_100_to_300',
        checked: false,
    },
    {
        label: '300k-800k',
        value: 'from_300_to_800',
        checked: false,
    },
    {
        label: 'Trên 800k',
        value: 'more_than_800',
        checked: false,
    },
]

export const SORT_ITEM = [
    {
        value: 'new',
        label: 'Hàng mới',
        id: 1,
    },
    {
        value: 'ban_chay',
        label: 'Bán chạy',
        id: 2,
    },
    {
        value: 'discount',
        label: 'Giảm giá nhiều',
        id: 3,
    },
    {
        value: 'price_asc',
        label: 'Giá thấp - cao',
        id: 4,
    },
    {
        value: 'price_desc',
        label: 'Giá cao - thấp',
        id: 5,
    },
]
