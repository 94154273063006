import React, { useEffect, useState } from 'react'
import { PATH_PRODUCT,DEFAULT_IMAGE } from '../../constants/common-contants';
import { checkMinMax, checkURLImage, convertCommission, convertSizeImage, isDesktopScreen, isMobileScreen, isWideScreen } from '../../functions/common-func';
import RenderPrice from '../product-detail/RenderPrice';
import BreadcrumbNavigation from '../common/BreadcrumbNavigation'
import CouponItem from './CouponItem';
import ErrorProduct from '../common/ErrorProduct';
import PaginationContainer from '../../container/common/PaginationContainer';
import BoxHeaderTitle from '../common/BoxHeaderTitle';
import * as queryString from 'query-string';
import { IconHomeChietKhau } from '../../constants/icon-const';
import DisplayPrice from '../common/DisplayPrice';
import ShowComm from '../product-price/ShowComm';
import LoadingComponent from '../common/LoadingComponent';

const CouponList = ({
    couponList,
    products,
    coupon,
    isPublisher,
    paging,
    reLoading,
    setReLoading,
    setPaging,
    dispatch,
    history,
    location
}) => {
    const [mobileTitle,setMobileTitle] = useState('');
    const [urlCoupon,setUrlCoupon]=useState(false);

    useEffect(() => {
        const params = queryString.parse(location.search);
        const id = params.coupon_id;
        let title = (id) ? "Danh sách sản phẩm theo Coupon" : "Danh sách Coupon";
        if(id) {
            setUrlCoupon(true);
        } else {
            setUrlCoupon(false);
        }
        setMobileTitle(title);
    },[location]);

    useEffect(() => {
        let endSuggestion = document.getElementById('end__coupon');
        let statusSuggestion = false
        if (endSuggestion) {
            window.addEventListener('scroll', function () {
                if (window.pageYOffset >= endSuggestion.offsetTop - window.innerHeight) {
                    if (statusSuggestion === false) {
                        setReLoading(true)
                        statusSuggestion = true
                    }
                } else {
                    if (statusSuggestion === true) {
                        setReLoading(false)
                        statusSuggestion = false
                    }
                }
            })
        }
    }, [couponList, setReLoading]);
    useEffect(() => {
        let endSuggestion = document.getElementById('end__product')
        let statusSuggestion = false
        if (endSuggestion) {
            window.addEventListener('scroll', function () {
                if (window.pageYOffset >= endSuggestion.offsetTop - window.innerHeight) {
                    if (statusSuggestion === false) {
                        setReLoading(true)
                        statusSuggestion = true
                    }
                } else {
                    if (statusSuggestion === true) {
                        setReLoading(false)
                        statusSuggestion = false
                    }
                }
            })
        }
    }, [products, setReLoading]);

    const options = [
        {
            label: 'Sản phẩm',
        },
        {
            label: '>',
        },
        {
            label: 'Coupon',
        },
        {
            label: '>',
        },
        {
            label: coupon && coupon.name,
            active: true,
        },
    ]
    
    return (
        <>
            {!urlCoupon && 
            <>
                <div className="wrapper__coupons">
                    <BoxHeaderTitle text={mobileTitle} link="/" isBell={true} />
                    <div className="header-title">
                        <h2>Danh sách Coupons</h2>
                    </div>
                    {couponList?.length > 0 && 
                    <>
                        <div className="coupon-list">
                            {couponList.map((coupon, index) => {
                                return (
                                    <CouponItem key={`coupon-${index}`} coupon={coupon} can_buy={false}/>
                                )
                            })}
                        </div>
                        {isMobileScreen()  && <div id="end__coupon"></div> }
                    </> 
                    }
                    {reLoading && couponList?.length < paging.total && <LoadingComponent />}
                    { (couponList?.length > 0 && (!isMobileScreen())) && 
                        <PaginationContainer
                        page={paging.page}
                        pageSize={paging.pageSize}
                        total={paging.total}
                    />
                    }
                </div>
                
            </>
            }
            {urlCoupon && 
                <div className="wrapper__coupons">
                    <BoxHeaderTitle text={mobileTitle} link='/coupons' isBell={true}/>
                    <BreadcrumbNavigation option={options} />
                    {products?.length > 0 && 
                    <>
                        <div className="home__product ">
                            {products.map((item) => {
                                    return (
                                        <div key={item.id} className="item__product">
                                            <a style={{ color: '#262626' }} href={`${PATH_PRODUCT}/${item.id}`}>
                                                <div className="item__image">
                                                    {item.status === 3 && (
                                                        <div className="bg_out_of_stock"><span>Tạm hết hàng</span></div>
                                                    )}
                                                    {/*<img src={item.thumbImage} alt="" />*/}
                                                    <img src={convertSizeImage(checkURLImage(item.thumbImage), 200, 200)} alt="" />
                                                </div>
                                                <p className="title">{item.name}</p>
                                                {!item.rangePrice && <span className="price">
                                                    <DisplayPrice price={item.price} />
                                                </span>} 
                                                {item.rangePrice && <span className="price">{checkMinMax(item.rangePrice.minPrice, item.rangePrice.maxPrice)}</span>}
                                                {isPublisher === 1 &&  (
                                                    <p className="chiet-khau">
                                                        <span>Hoa hồng</span>
                                                        <IconHomeChietKhau />
                                                    </p>
                                                )}
                                                {isPublisher === 1 && !item.rangePrice && (
                                                    <span className="price__discount">
                                                        <DisplayPrice
                                                            price={convertCommission(
                                                                item.price,
                                                                item.commissionValue,
                                                                item.commissionType
                                                            )}
                                                        />
                                                    </span>
                                                )}
                                                {isPublisher === 1 && item.rangePrice && (
                                                    <ShowComm
                                                        min={item.rangePrice.minPrice}
                                                        max={item.rangePrice.maxPrice}
                                                        commValue={item.commissionValue}
                                                        commType={item.commissionType}
                                                    />
                                                )}
                                            </a>
                                        </div>
                                    )
                                })}
                            
                            {products.length === 0 && <ErrorProduct/>}
                        </div>
                        {isMobileScreen()  && <div id="end__product"></div> }
                    </>
                        
                    }
                    {reLoading && paging.page < paging.totalPage && <LoadingComponent />}
                    { (products?.length > 0 && (!isMobileScreen()) ) &&
                            <div className="home__paging">
                                <PaginationContainer
                                    page={paging.page}
                                    pageSize={paging.pageSize}
                                    total={paging.total}
                                />
                            </div>
                    }
                </div>
            }
        </>
    );
}
export default CouponList;