import React, {useEffect, useState} from 'react'
import { IconHomeChietKhau } from '../../constants/icon-const'
import DisplayPrice from '../common/DisplayPrice'
import BoxHeaderTitle from '../common/BoxHeaderTitle'
import {Button, Form, Input, Modal} from 'antd'
import { DeleteOutlined, CheckCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router'
import Select from 'react-select'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {checkMinMax, convertCommission, convertTotalCommission, isMaxTableScreen, isMobileScreen, renderDiscount, renderPriceAfterCP} from '../../functions/common-func'
import PopupConfirmDelete from '../common/PopupConfirmDelete'
import ErrorProduct from '../common/ErrorProduct'
import BreadcrumbNavigation from '../common/BreadcrumbNavigation'
import { optionNavigationCheckout1 } from '../../constants/common-contants'
import TextArea from 'antd/es/input/TextArea'
import ShowNote from './ShowNote'
import CouponsSelector from '../coupon/CouponsSelector';
// const Option = Select
function CreateManyOrder({
    handleCreateOrder,
    listProductFromCart,
    setListProductFromCart,
    setIsRefresh,
    onChangeQuantity,
    totalPrice,
    totalCommission,
    handleDeleteProduct,
    handleConfirmDelete,
    dataConfirmDelete,
    phone,
    address,
    province,
    commune,
    name,
    note,
    district,
    isDisableDistrict,
    isDisableCommune, listProvince,
    setListProvince, listDistrict, listCommune, validateAddress, validateProvince,
    validateDistrict, validateCommune, validateName, validatePhone, handleChangeName,
    handleChangePhone, handleChangeNote, handleChangeAddress, handleChangeProvince, 
    handleChangeDistrict, handleChangeCommune, setCouponLocal, couponLocal,setTotalPrice,
    setTotalCommission
}) {
    const [isMaxQuantity, setIsMaxQuantity] = useState(false)

    return (
        <section className="create__order">
            <BoxHeaderTitle text="Tạo nhiều đơn hàng" link="/choose-product" isBell={true} />
            <div className="create__order__content ">
                <div className="container">
                    <BreadcrumbNavigation option={optionNavigationCheckout1} />

                    <div className="create__order__content__desktop">
                        <div className="create__order__right">
                            {(isMobileScreen() || isMaxTableScreen()) && (
                                <div className="item__order border__bottom">
                                    <div className="container__mobile">
                                        <div className="step__order">
                                            <div className="item__step active">
                                                <div className="icon">
                                                    <span className="success">
                                                        <CheckCircleOutlined style={{ fontSize: 18 }} />
                                                    </span>
                                                    <span className="step">1</span>
                                                </div>
                                                <span className="text">Tạo đơn</span>
                                            </div>
                                            <div className="item__step">
                                                <div className="icon">
                                                    <span className="success">
                                                        <CheckCircleOutlined />
                                                    </span>
                                                    <span className="step">2</span>
                                                </div>
                                                <span className="text">Xem đơn</span>
                                            </div>
                                            <div className="item__step waiting">
                                                <div className="icon">
                                                    <span className="success">
                                                        <CheckCircleOutlined />
                                                    </span>
                                                    <span className="step">3</span>
                                                </div>
                                                <span className="text">Đặt đơn</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="item__order product border__bottom">
                                <div className="container__mobile">
                                    <h2 className="title">Danh sách sản phẩm đang chọn</h2>
                                    <div className="list__product">
                                        {Array.isArray(listProductFromCart) &&
                                            listProductFromCart.length > 0 &&
                                            listProductFromCart.map((res, index) => {
                                                
                                                let cpDiscount = renderDiscount(res);
                                                let priceAfterChoose = cpDiscount ? renderPriceAfterCP(res.productPrice*res.quantity, cpDiscount) : res.productPrice*res.quantity;
                                                return (
                                                    <div
                                                        key={res.id}
                                                        className="product__content product__one__content"
                                                    >
                                                        <div className="product__image">
                                                            <img src={res.product.thumbImage} alt="" />
                                                        </div>
                                                        <div className="product__detail">
                                                            <div className="name__group">
                                                                <p className="name">{res.product.name}</p>
                                                               

                                                                <div
                                                                    className="delete__product"
                                                                    onClick={() =>
                                                                        handleConfirmDelete(index, res.itemCode)
                                                                    }
                                                                >
                                                                    <DeleteOutlined style={{ fontSize: 12 }} />
                                                                    Xóa
                                                                </div>
                                                            </div>
                                                            {(res.note && res.note != "") && <ShowNote note={res.note} /> }
                                                            <p className="price__product">
                                                                <span>Giá thành:</span>
                                                                
                                                                <span className="price">
                                                                <DisplayPrice price={res.productPrice} />
                                                            </span>
                                                            
                                                            </p>
                                                             <p className="price__product commission">
                                                                <span>Hoa hồng:</span>
                                                                <span className="price">
                                                                    <IconHomeChietKhau />
                                                                    <DisplayPrice
                                                                        price={convertCommission(
                                                                            res.productPrice,
                                                                            res.product.commissionValue,
                                                                            res.product.commissionType
                                                                        )}
                                                                    />
                                                                </span>
                                                            </p>
                                                            <div className="product__quantity">
                                                                <div className="quantity">
                                                                    <Button
                                                                        disabled={res.quantity === 1}
                                                                        onClick={() => onChangeQuantity(index, res.variantId, 'minus')}
                                                                        icon={<MinusOutlined style={{ fontSize: 12 }} />}
                                                                    />
                                                                    <Button> {res.quantity} </Button>
                                                                    <Button
                                                                        disabled={res.quantity === 10}
                                                                        onClick={async () => {
                                                                            await onChangeQuantity(index, res.variantId, 'plus')
                                                                            if (res.quantity >= 10) setIsMaxQuantity(true)
                                                                        }}
                                                                        className="btn-orange"
                                                                        ref={(node) => {
                                                                            if (node) {
                                                                                node.style.setProperty('background', 'orange','important');
                                                                            }
                                                                        }}
                                                                        icon={<PlusOutlined style={{ fontSize: 12 }} />}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="product__voucher">
                                                                <div className='title'>
                                                                    <span>Coupon sản phẩm:</span>
                                                                </div>
                                                                <CouponsSelector
                                                                    setIsRefresh = {setIsRefresh}
                                                                    product={res}
                                                                    listProduct={listProductFromCart}
                                                                    setListProductFromCart={setListProductFromCart}
                                                                    cpDiscount={cpDiscount}
                                                                    setCouponLocal={setCouponLocal}
                                                                    couponLocal = {couponLocal}
                                                                    setTotalPrice={setTotalPrice}
                                                                    setTotalCommission={setTotalCommission}
                                                                 />
                                                            </div>
                                                            <p className="price__product" style={{paddingTop: "10px", borderTop: "dashed 1px #eee", marginTop: "10px"}}>
                                                                <span>Tổng giá:</span>
                                                                
                                                                 <span className="price">
                                                                    <DisplayPrice
                                                                        price={res  && (priceAfterChoose)}
                                                                    />
                                                                </span>
                                                            </p>
                                                             <p className="price__product commission">
                                                                <span>Tổng hoa hồng:</span>
                                                                <span className="price">
                                                                    <IconHomeChietKhau />
                                                                    <DisplayPrice
                                                                        price={convertTotalCommission(
                                                                            priceAfterChoose,
                                                                            res.quantity,
                                                                            res.product.commissionValue,
                                                                            res.product.commissionType
                                                                        )}
                                                                    />
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                    {listProductFromCart && listProductFromCart.length === 0 && <ErrorProduct />}
                                    {listProductFromCart && listProductFromCart.length < 10 && (
                                        <Link to="/choose-product">
                                            <Button className="w-100 btn__orange" size="large" type="warning">
                                                + Thêm sản phẩm
                                            </Button>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="create__order__left">
                            {/*<div className="item__order">*/}
                            {/*    <div className="container">*/}
                            {/*        {listAddress.length > 0 && isAddress ? (*/}
                            {/*            <div>*/}
                            {/*                <div className="booker__profile">*/}
                            {/*                    <div className="title">*/}
                            {/*                        <h4>Địa chỉ nhận hàng</h4>*/}
                            {/*                        <span*/}
                            {/*                            className="change__address"*/}
                            {/*                            onClick={() =>*/}
                            {/*                                history.push(`/address/shipping?addressId=${isAddress.id}`)*/}
                            {/*                            }*/}
                            {/*                        >*/}
                            {/*                            Thay đổi*/}
                            {/*                        </span>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="profile__item">*/}
                            {/*                        <span>Họ và tên:</span>*/}
                            {/*                        <p>{isAddress.username}</p>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="profile__item">*/}
                            {/*                        <span>Số điện thoại:</span>*/}
                            {/*                        <p>{isAddress.phoneNumber}</p>*/}
                            {/*                    </div>*/}
                            {/*                    <div className="profile__item">*/}
                            {/*                        <span>Địa chỉ:</span>*/}
                            {/*                        <p>{isAddress.address}</p>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*                <Button*/}
                            {/*                    onClick={() => {*/}
                            {/*                        history.push(`/address/edit/${isAddress.id}`)*/}
                            {/*                    }}*/}
                            {/*                    className="w-100 btn__orange"*/}
                            {/*                    size="large"*/}
                            {/*                >*/}
                            {/*                    Chỉnh sửa thông tin địa chỉ nhận hàng*/}
                            {/*                </Button>*/}
                            {/*            </div>*/}
                            {/*        ) : (*/}
                            {/*            <div>*/}
                            {/*                <h2 className="title">Địa chỉ nhận hàng</h2>*/}
                            {/*                <Button*/}
                            {/*                    onClick={() => {*/}
                            {/*                        history.push('/address/create')*/}
                            {/*                    }}*/}
                            {/*                    className="w-100 btn__orange"*/}
                            {/*                    size="large"*/}
                            {/*                >*/}
                            {/*                    + Thêm địa chỉ nhận hàng*/}
                            {/*                </Button>*/}
                            {/*            </div>*/}
                            {/*        )}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="item__order">
                                <div className="container__mobile">
                                    <h2 className="title">Thông tin người mua</h2>
                                    <div className="profile__booker__form">
                                        <div className="form-group">
                                            <label>Người mua</label>
                                            <Form.Item
                                                className="el-input__inner"
                                                validateStatus={validateName.status}
                                                help={validateName.text}
                                            >
                                                <Input
                                                    value={name}
                                                    onChange={handleChangeName}
                                                    placeholder="Nhập tên người mua"
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <label>Số điện thoại</label>
                                            <Form.Item
                                                className="el-input__inner"
                                                validateStatus={validatePhone.status}
                                                help={validatePhone.text}
                                            >
                                                <Input
                                                    value={phone}
                                                    onChange={handleChangePhone}
                                                    placeholder="Nhập số điện thoại"
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <label>Địa chỉ chi tiết</label>
                                            <Form.Item
                                                validateStatus={validateAddress.status}
                                                help={validateAddress.text}
                                                className="el-input__inner"
                                            >
                                                <Input
                                                    value={address}
                                                    onChange={handleChangeAddress}
                                                    placeholder="Nhập số nhà và đường"
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <label>Tỉnh/Thành phố</label>
                                            <Form.Item
                                                validateStatus={validateProvince.status}
                                                help={validateProvince.text}
                                            >
                                                <Select
                                                    value={province}
                                                    onChange={handleChangeProvince}
                                                    options={listProvince}
                                                    isClearable
                                                    placeholder="Tỉnh/Thành phố"
                                                    className="department_sender"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <label>Quận/huyện</label>
                                            <Form.Item
                                                validateStatus={validateDistrict.status}
                                                help={validateDistrict.text}
                                            >
                                                <Select
                                                    value={district}
                                                    onChange={handleChangeDistrict}
                                                    options={listDistrict}
                                                    isClearable
                                                    isDisabled={isDisableDistrict}
                                                    placeholder="Quận/huyện"
                                                    className="department_sender"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <label>Phường/Xã</label>
                                            <Form.Item
                                                validateStatus={validateCommune.status}
                                                help={validateCommune.text}
                                            >
                                                <Select
                                                    value={commune}
                                                    onChange={handleChangeCommune}
                                                    options={listCommune}
                                                    isClearable
                                                    isDisabled={isDisableCommune}
                                                    placeholder="Phường/Xã"
                                                    className="department_sender"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <label>Ghi chú</label>
                                            <Form.Item className="el-input__inner">
                                                <TextArea
                                                    value={note}
                                                    onChange={handleChangeNote}
                                                    placeholder="Ghi chú ..."
                                                />
                                            </Form.Item>
                                        </div>
                                        {/*<div className="form-group">*/}
                                        {/*    <Checkbox checked={isDefault} onChange={onChangeIsDefault}>*/}
                                        {/*        {' '}*/}
                                        {/*        Địa chỉ mặc định{' '}*/}
                                        {/*    </Checkbox>*/}
                                        {/*</div>*/}
                                        {/*<div className="form-group">*/}
                                        {/*    <Checkbox checked={isCompanyAddress} onChange={onChangeIsCompanyAddress}>*/}
                                        {/*        {' '}*/}
                                        {/*        Địa chỉ công ty{' '}*/}
                                        {/*    </Checkbox>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="btn__footer">
                                <div className="container__mobile product__detail" style={{width: "100%"}}>
                                    <div className="total__price1">
                                        <span style={{ fontSize: '18px'}}>Thành tiền: &nbsp;</span>
                                        <span className="price" style={{ fontSize: '18px', fontWeight: '600', color: '#F5222D'}}>
                                            <DisplayPrice price={totalPrice} />
                                        </span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span>
                                            <span className="price" style={{fontSize: '14px', lineHeight: '16px', display: 'flex', fontWeight: '600', color: '#1890FF'}}>
                                                <IconHomeChietKhau />
                                                <DisplayPrice price={totalCommission} />
                                            </span>
                                        </span>
                                    </div>
                                    <Button
                                        style={{ margin: '10px 0', borderRadius: 4 }}
                                        size="large"
                                        className="w-100"
                                        onClick={handleCreateOrder}
                                        type="primary"
                                    >
                                        Tiếp tục
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PopupConfirmDelete text="Bạn muốn bỏ sản phẩm này khỏi giỏ hàng?" handleOk={handleDeleteProduct} dataConfirmDelete={dataConfirmDelete} />
                <Modal
                    dialogClassName="modal__max__quantity"
                    className="modal__max__quantity"
                    title="Thông báo"
                    footer={null}
                    visible={isMaxQuantity}
                    onCancel={() => setIsMaxQuantity(false)}
                >
                    <div className="container__mobile">
                        Số lượng mua tối đa: <b>10 Sản phẩm</b> <br />
                        Vui lòng liên hệ để mua số lượng lớn hơn với giá tốt nhất.
                    </div>
                </Modal>
            </div>
        </section>
    )
}
const mapStateToProps = function (state) {
    return {}
}
export default withRouter(connect(mapStateToProps)(CreateManyOrder))
