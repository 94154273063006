import React from 'react'
import BookingManagement from '../../../component/buyer/booking-management/BookingManagement'
import { withRouter } from 'react-router'
import { connect, useSelector } from 'react-redux'

function BookingManagementContainer() {

    const { isLoggedIn } = useSelector((state) => {
        return {
            isLoggedIn: state.homeReducer.isLoggedIn,
        }
    })

    // if(!isLoggedIn) {
    //     window.location = `${window.location.origin}/login`;
    // }
    return <BookingManagement />
}

const mapStateToProps = function () {
    return {}
}
export default withRouter(connect(mapStateToProps)(BookingManagementContainer))
