import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/min/locales.min'
import YourCart from '../../../component/buyer/your-cart/YourCart'
import { deleteProductCart, fetchProductFromCart, updateProductCart } from '../../../api-services/cart-api'
import { toggleShowLoading } from '../../../redux/actions/home-actions'
import { message } from 'antd'
import { useDispatch } from 'react-redux'
import { toggleShowConfirmDelete } from '../../../redux/actions/checkout-action'
import { renderDiscount} from '../../../functions/common-func'


function YourCartContainer() {
    const [listProduct, setListProduct] = useState([])
    const [isRefresh, setIsRefresh] = useState(false)
    const [totalPrice, setTotalPrice] = useState(0)
    const [dataConfirmDelete, setDataConfirmDelete] = useState({})

    const dispatch = useDispatch()
    useEffect(() => {
        getListProductFromCart()
    }, [isRefresh])

    const getListProductFromCart = async () => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await fetchProductFromCart()
            dispatch(toggleShowLoading(false))
            if (data && data.status === 200 && data.data) {
                setListProduct(data.data.shoppingCartItems)
                let total_price = 0
                data.data.shoppingCartItems.forEach((res) => {
                    if (res && res.product) {
                        let cpDiscount = renderDiscount(res);
                        let prodDiscount = cpDiscount ? res.productPrice - cpDiscount : res.productPrice
                        total_price += parseInt(Number(prodDiscount * res.quantity).toFixed(0))
                    }
                })
                setTotalPrice(total_price)
            }
        } catch (e) {
            throw e
        }
    }

    const onChangeQuantity = async (index, type) => {
        try {
            let newListProduct = [...listProduct]
            let quantity = newListProduct[index].quantity
            if (quantity < 10 && type === 'plus') {
                quantity += 1
            }
            if (quantity > 1 && type === 'minus') {
                quantity -= 1
            }
            const data = {
                cartItemList: [
                    {
                        itemCode: newListProduct[index].itemCode,
                        quantity: quantity,
                    },
                ],
            }
            dispatch(toggleShowLoading(true))
            await updateProductCart(data)
            setIsRefresh(moment())
            dispatch(toggleShowLoading(false))
            newListProduct[index].quantity = quantity
            setListProduct(newListProduct)
        } catch (e) {
            if (e && e.response && e.response.data) {
                if (e.response.data.code === 'E00018') {
                    message.error('Bạn đã chọn hết số lượng còn lại của sản phẩm')
                }
            }
            dispatch(toggleShowLoading(false))
            throw e
        }
    }

    const handleConfirmDelete = (index, itemCode) => {
        setDataConfirmDelete({ index, itemCode })
        dispatch(toggleShowConfirmDelete(true))
    }

    const handleDeleteProduct = async (index, itemCode) => {
        try {
            const data = {
                cartItemList: [
                    {
                        itemCode: itemCode,
                    },
                ],
            }
            const result = await deleteProductCart(data)
            if (result && result.status === 200) {
                dispatch(toggleShowConfirmDelete(false))
                let newListProduct = [...listProduct]
                newListProduct.splice(index, 1)
                setListProduct(newListProduct)
            } else {
                dispatch(toggleShowConfirmDelete(false))
                message.error(result.message)
            }
        } catch (e) {
            dispatch(toggleShowConfirmDelete(false))
            throw e
        }
    }
    return (
        <YourCart
            listProduct={listProduct}
            dataConfirmDelete={dataConfirmDelete}
            totalPrice={totalPrice}
            setIsRefresh={setIsRefresh}
            onChangeQuantity={onChangeQuantity}
            handleDeleteProduct={handleDeleteProduct}
            handleConfirmDelete={handleConfirmDelete}
        />
    )
}

export default YourCartContainer
