import React from 'react'
import { Button, Form, Input } from 'antd'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import DisplayPrice from '../common/DisplayPrice'
import CloseButton from '../../assets/images/close_button.png'

function WholeSale({
    listProductFromCart,
    handleWholeSale,
    onBack,
    name,
    phone,
    quantity,
    handleChangeName,
    handleChangePhone,
    handleChangeQuantity,
    validateName,
    validatePhone,
    validateQuantity,
    onClose

}) {
    return (

        <div className="check__out">

            <div className="container">

                <div className="check__out__content">

                    <div className="check__out__content__desktop">
                       

                        <div className="check__out__left">


                            <div className="check__out__list check__out__item">

                                <div>
                                   
                                <img onClick={onClose} src={CloseButton} style={closeImg}  />
                                </div>
                                <div className="title">
                                    <h4>Nhập hàng số lượng lớn</h4>

                                </div>

                                {Array.isArray(listProductFromCart) &&
                                    listProductFromCart.length > 0 &&
                                    listProductFromCart.map((res, index) => {
                                        return (
                                            <div key={index} className="item__product">
                                                <div className="item__image">
                                                    <img src={res.product && res.product.thumbImage} alt="" />
                                                </div>
                                                <div className="item__text">
                                                    <p className="name__product">{res.product && res.product.name}</p>
                                                    <div className="price__product">
                                                        <p className="quantity">

                                                            Số lượng: {res.quantity}
                                                        </p>

                                                        <p className="price">
                                                            <DisplayPrice price={res.productPrice} />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}



                            </div>


                            <div className="item__order">
                                <div className="container__mobile">

                                    <label > - Hãy để lại thông tin của bạn, chúng tôi sẽ liên hệ với bạn trong vòng 1 giờ.</label>
                                    <label > - Chúng tôi có chính sách bán buôn đính kèm.</label>

                                    <div className="profile__booker__form">
                                        <div className="form-group">
                                            <label>Người mua</label>
                                            <Form.Item
                                                className="el-input__inner"
                                                validateStatus={validateName.status}
                                                help={validateName.text}
                                            >
                                                <Input
                                                    value={name}
                                                    onChange={handleChangeName}
                                                    placeholder="Nhập tên người mua"
                                                    size="large"
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <label>Số điện thoại</label>
                                            <Form.Item
                                                className="el-input__inner"
                                                validateStatus={validatePhone.status}
                                                help={validatePhone.text}
                                            >
                                                <Input
                                                    type="number"
                                                    value={phone}
                                                    onChange={handleChangePhone}
                                                    placeholder="Nhập số điện thoại"
                                                    size="large"

                                                />
                                            </Form.Item>
                                        </div>
                                        <div className="form-group">
                                            <label>Số lượng cần phân phối</label>
                                            <Form.Item
                                                className="el-input__inner"
                                                validateStatus={validateQuantity.status}
                                                help={validateQuantity.text}

                                            >
                                                <Input
                                                    value={quantity}
                                                    onChange={handleChangeQuantity}
                                                    placeholder="Nhập số lượng"
                                                    size="large"
                                                    type="text"
                                                    maxLength="4"

                                                    //min={10} max={10000}

                                                />
                                            </Form.Item>
                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div className="form__btn">
                                <Button onClick={handleWholeSale} type="primary" className="w-100" size="large">
                                    Gửi yêu cầu
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
let closeImg = { cursor: 'pointer', float: 'right', marginTop: '5px', width: '10px',height:'10px'};

const mapStateToProps = function (state) {
    return {
        toggleCart: state.homeReducer.toggleCart,
    }
}
export default withRouter(connect(mapStateToProps)(WholeSale))
