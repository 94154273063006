export const PRODUCT_DETAIL_TYPE_REDUCER = {
    OPEN_WARNING: 'OPEN_WARNING',
}

export const LIST_PRODUCT_TYPE_REDUCER = {
    OPEN_INFO_ORDER: 'OPEN_INFO_ORDER',
    OPEN_FILTER_ORDER: 'OPEN_FILTER_ORDER',
    OPEN_EXPORT_ORDER: 'OPEN_EXPORT_ORDER',
    OPEN_CONFIRM_TUTORIAL_SHARE: 'OPEN_CONFIRM_TUTORIAL_SHARE',
}
