import ApiMicroService from './request-micro-service'

export const fetchReportCurrent = async (paramSearch) => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/report/current`,{
            params: paramSearch
        })
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchReportChart = async () => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/report/chart`)
        return results.data
    } catch (e) {
        throw e
    }
}

export const fetchReportPublisher = async () => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/report/top-publisher`)
        return results.data
    } catch (e) {
        throw e
    }
}
