import React from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import ShareLink from './ShareLink'
function BoxShare({ onClose, toggleShare, dataDetail, deepLink, contentShare, htmlZalo }) {
    const title = toggleShare ? toggleShare.title : 'Gửi lời mời sử dụng Dimuadi'
    const isShare = toggleShare ? toggleShare.isShare : false
    return (
        <div className={`box__share ${isShare ? 'open' : ''}`}>
            <div className="box__share__content">
                <div className="box__title">
                    <h2>{title}</h2>
                    <div className="box__close" onClick={onClose}>
                        <CloseOutlined />
                    </div>
                </div>
                <ShareLink
                    image={
                        dataDetail && dataDetail.videos && dataDetail.videos.length > 0
                            ? dataDetail.videos[0]
                            : dataDetail.thumbImage
                    }
                    url={deepLink}
                    htmlZalo={htmlZalo}
                    contentShare={contentShare}
                    title={dataDetail && dataDetail.name}
                    isShare={isShare}
                />
            </div>
            <div className="box__backdrop" onClick={onClose}></div>
        </div>
    )
}

const mapStateToProps = function (state) {
    return {
        toggleShare: state.homeReducer.toggleShare,
    }
}
export default withRouter(connect(mapStateToProps)(BoxShare))
