import React, { useEffect, useState } from "react";

function ScrollToTop() {
    const [isVisible, setIsVisible] = useState(false);

    // Show button when page is scorlled upto given distance
    const toggleVisibility = () => {
        if (window.pageYOffset > 600) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Set the top cordinate to 0
    // make scrolling smooth
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <div className="scroll-to-top">
            {isVisible &&
            <div onClick={scrollToTop}>
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M30 60C24.0666 60 18.2664 58.2405 13.3329 54.9441C8.39943 51.6476 4.55426 46.9623 2.28363 41.4805C0.0129985 35.9987 -0.581101 29.9667 0.576455 24.1473C1.73401 18.3279 4.59123 12.9824 8.78681 8.78681C12.9824 4.59123 18.3279 1.73401 24.1473 0.576454C29.9667 -0.5811 35.9987 0.0130005 41.4805 2.28363C46.9623 4.55426 51.6476 8.39943 54.9441 13.3329C58.2405 18.2664 60 24.0666 60 30C59.9908 37.9537 56.8272 45.579 51.2031 51.2031C45.579 56.8272 37.9537 59.9908 30 60ZM41.4213 26.6196L31.6344 16.8328C31.2006 16.4 30.6128 16.157 30 16.157C29.3872 16.157 28.7995 16.4 28.3656 16.8328L18.5787 26.6196C18.1468 27.0525 17.9043 27.6392 17.9046 28.2507C17.905 28.8622 18.148 29.4487 18.5805 29.8811C19.0129 30.3135 19.5993 30.5566 20.2109 30.5569C20.8224 30.5572 21.4091 30.3148 21.842 29.8828L27.6923 24.0325V41.5385C27.6923 42.1505 27.9354 42.7375 28.3682 43.1702C28.801 43.603 29.388 43.8462 30 43.8462C30.612 43.8462 31.199 43.603 31.6318 43.1702C32.0646 42.7375 32.3077 42.1505 32.3077 41.5385V24.0325L38.1581 29.8828C38.5909 30.3148 39.1776 30.5572 39.7892 30.5569C40.4007 30.5566 40.9871 30.3135 41.4195 29.8811C41.852 29.4487 42.0951 28.8622 42.0954 28.2507C42.0957 27.6392 41.8532 27.0525 41.4213 26.6196Z" fill="#A6A6A6"/>
                </svg>
                {/* <span className="fa fa-chevron-circle-up" style={{fontSize: '36px', color: '#fd8c13'}}></span> */}
            </div>}
        </div>
    );
}

export default ScrollToTop;
