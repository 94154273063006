import { CHECK_OUT_TYPE_REDUCER } from '../../constants/common-contants'

export const toggleShowChangeAddress = (boolean) => {
    return {
        type: CHECK_OUT_TYPE_REDUCER.OPEN_CHANGE_ADDRESS,
        isChangeAddress: boolean,
    }
}
export const toggleShowAddEditAddress = (boolean) => {
    return {
        type: CHECK_OUT_TYPE_REDUCER.OPEN_ADD_ADDRESS,
        openAddress: boolean,
    }
}

export const toggleShowActionAddress = (boolean) => {
    return {
        type: CHECK_OUT_TYPE_REDUCER.OPEN_ACTION__ADDRESS,
        isActionAddress: boolean,
    }
}

export const handleSetIdAddress = (boolean) => {
    return {
        type: CHECK_OUT_TYPE_REDUCER.SET_ID_ADDRESS,
        idAddress: boolean,
    }
}

export const handleSetIsAddress = (isAddress) => {
    return {
        type: CHECK_OUT_TYPE_REDUCER.SET_IS_ADDRESS,
        isAddress: isAddress,
    }
}

export const toggleShowConfirmDelete = (boolean) => {
    return {
        type: CHECK_OUT_TYPE_REDUCER.OPEN_CONFIRM_DELETE,
        isShowDelete: boolean,
    }
}
