import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import 'moment/min/locales.min'
// import { ArrowLeftOutlined, SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import DisplayPrice from '../common/DisplayPrice'
import { DEFAULT_IMAGE, PATH_DEAL, PATH_PRODUCT, PATH_DEAL_HOT_LIST, PATH_COUPONS } from '../../constants/common-contants'
import { IconHomeChietKhau, IconPlusCommission } from '../../constants/icon-const'
import HomeSuperDiscount from './HomeSuperDiscount'
import HomeMarketing from './HomeMarketing'
import HomeSuperDiscountAll from './HomeSuperDiscountAll'
import NotiUser from '../common/NotiUser'
import {
    checkMinMax,
    checkURLImage,
    convertCommission,
    convertSizeImage,
    getSession,
    isMaxTableScreen,
    isMobileScreen,
} from '../../functions/common-func'
import { toggleIsTopInput, togglePopupWelcomeUser } from '../../redux/actions/home-actions'
import TutorialWelcomeUser from '../register/TutorialWelcomeUser'
import BoxSearch from '../common/BoxSearch'
import HomeSuggestion from './HomeSuggestion'
import LoadingComponent from '../common/LoadingComponent'
import PaginationContainer from '../../container/common/PaginationContainer'
import { Swiper, SwiperSlide } from 'swiper/react'
import TopProduct from '../common/TopProduct/TopProduct'
import ErrorProduct from '../common/ErrorProduct'
import { SearchOutlined } from '@ant-design/icons'
import SuggestProduct from '../choose-product/SuggestProduct'
import { RightOutlined } from '@ant-design/icons'
import store from './../../redux/store'
import CouponItem from '../coupon/CouponItem';
import HomeBanner from './HomeBanner'
import RenderPrice from '../product-detail/RenderPrice'

function Home({
    deadline,
    isPublisher,
    listCategory,
    listHighDiscount,
    listSuggestion,
    listMostShared,
    listTop,
    // onChangeSearchHome,
    onKeyPressSearchHome,
    handleIconSearchClick,
    dispatch,
    // toggleBoxSearch,
    reLoadingSuggestion,
    pagingSuggestion,
    setReLoadingSuggestion,
    upgradeRole,
    isLoggedIn,
    listBanner,
    dataReport,
    nameProduct,
    hotCoupon,
    setHotCoupon,
    isOpenProduct,
    listProductSearchDesktop,
    handleChangeName,
    listDealHot,
}) {
    let user = getSession('userInfo')?.userInfo
    const indexItem = isMobileScreen() ? 100 : 100
    const _listCategory = listCategory && listCategory.filter((res, index) => index < indexItem)
    const [listMostSharedDesktop, setListMostSharedDesktop] = useState(false)
    const [dealHotIntro, setDealHotIntro] = useState({})
    const [dealHotContent, setDealHotContent] = useState([])

    const getStatusPopup = () => {
        return store.getState().homeReducer.toggleBoxSearch
    }
    useEffect(() => {
        if (listDealHot.length > 0) {
            let _listDealHotIntro = listDealHot.find((item, index) => index === 0)
            _listDealHotIntro.image = _listDealHotIntro.avatarHome[0]
            setDealHotIntro(_listDealHotIntro)
            setDealHotContent(listDealHot.filter((item, index) => index !== 0).slice(0, 6))
        }
    }, [listDealHot])

    function getDealHotRender(screen) {
        if (listDealHot && isPublisher === 1) {
            // eslint-disable-next-line no-undef
            return (
                <div className="home__deal__hot">
                    <div className="title">
                        <p>ưu đãi hot</p>
                        {screen === 'desktop' && <Link to={PATH_DEAL_HOT_LIST}>Xem thêm</Link>}
                        {screen === 'mobile' && (
                            <Link to={PATH_DEAL_HOT_LIST}>
                                <RightOutlined />
                            </Link>
                        )}
                    </div>
                    <div className="list">
                        {dealHotIntro && (
                            <Link to={PATH_DEAL + '/' + dealHotIntro.id} className="deal__item__left">
                                <img src={dealHotIntro.image} alt={dealHotIntro.title} />
                            </Link>
                        )}
                        {dealHotContent && (
                            <div className="deal__item__right">
                                <div className="deal__item__row">
                                    {dealHotContent.length > 0 &&
                                        dealHotContent.map((item, index) => {
                                            if (screen === 'desktop' || (screen === 'mobile' && index < 2)) {
                                                return (
                                                    <Link
                                                        to={PATH_DEAL + '/' + item.id}
                                                        className={'deal__item ' + (index > 2 ? 'mt-2' : '')}
                                                        key={'item_' + item.id}
                                                    >
                                                        <img src={item.avatarHome[0]} alt="" />
                                                    </Link>
                                                )
                                            }
                                        })}
                                </div>
                            </div>
                        )}
                        {dealHotContent && screen === 'mobile' && (
                            <div className="deal__item__right__more">
                                {dealHotContent.length > 0 &&
                                    dealHotContent.map((item, index) => {
                                        if (index > 1) {
                                            return (
                                                <Link
                                                    to={PATH_DEAL + '/' + item.id}
                                                    className="deal__item__left"
                                                    key={'item_' + item.id}
                                                >
                                                    <img src={item.avatarHome[0]} alt="" />
                                                </Link>
                                            )
                                        }
                                    })}
                            </div>
                        )}
                    </div>
                </div>
            )
        }
    }
    function getHotCoupon(screen) {
        if(hotCoupon && hotCoupon.length > 0) {
            return (
                <div className={`home__coupon home__${screen}__item`}>
                    <div className="home__coupon__title">
                        <Link to={PATH_COUPONS}>Mã giảm giá</Link>
                        <Link to={PATH_COUPONS}>
                            <RightOutlined />
                        </Link>
                    </div>
                    <div className={`home__coupon__list ${hotCoupon?.length < 3 ? '':'scroll-coupon'} `}>
                        {hotCoupon && hotCoupon.map(coupon => {
                            return <CouponItem key={coupon?.id} coupon={coupon} can_buy={false}/>
                            })
                        }               
                    </div>
                </div>
            )               
    }        
    }

    useEffect(() => {
        const _listMostShared = listMostShared && listMostShared.filter((res, index) => index < 4)
        setListMostSharedDesktop(_listMostShared)
    }, [listMostShared, listSuggestion])

    const handleShareSeeMore = (type) => {
        if (type === 'share') {
            setListMostSharedDesktop(listMostShared)
        }
    }

    useEffect(() => {
        let searchInput = document.getElementById('home__box')
        let status = false
        if (searchInput) {
            window.addEventListener('scroll', function () {
                if (window.pageYOffset > searchInput.offsetTop) {
                    if (status === false) {
                        dispatch(toggleIsTopInput(true))
                        status = true
                    }
                } else {
                    if (status === true) {
                        dispatch(toggleIsTopInput(false))
                        status = false
                    }
                }
            })
        }
    }, [dispatch])

    useEffect(() => {
        let endSuggestion = document.getElementById('end__suggestion')
        let statusSuggestion = false
        if (endSuggestion) {
            window.addEventListener('scroll', function () {
                if (!getStatusPopup() && window.pageYOffset >= endSuggestion.offsetTop - 400) {
                    if (statusSuggestion === false) {
                        setReLoadingSuggestion(true)
                        statusSuggestion = true
                    }
                } else {
                    if (statusSuggestion === true) {
                        setReLoadingSuggestion(false)
                        statusSuggestion = false
                    }
                }
            })
        }
    }, [listSuggestion, setReLoadingSuggestion])
    return (
        <section className={`home ${isPublisher !== 1 ? 'is__buyer' : ''}`}>
            {(isMobileScreen() || isMaxTableScreen()) && (
                <div className="home__mobile ">
                    <div className={`home__banner  `}>
                        <div className="container">
                            {isPublisher === 1 ? (
                                <div className="home__box" id="home__box">
                                    {!user.isNewUser ? (
                                        <div>
                                            <div className="box__content ">
                                                <div className="box__commission">
                                                    <p className="total__commission">Tổng hoa hồng tạm duyệt</p>
                                                    <Link to="/dashboard">Chi tiết</Link>
                                                </div>
                                                <span className="total__price">
                                                    <DisplayPrice price={dataReport.preApproveCom} />
                                                </span>
                                            </div>
                                            <div className="box__footer">
                                                {dataReport && dataReport.type === 'ASC' ? (
                                                    <div className="icon">
                                                        <IconPlusCommission />
                                                    </div>
                                                ) : (
                                                    <div className="icon">
                                                        <IconPlusCommission color="#F5222D" />
                                                    </div>
                                                )}
                                                {dataReport && dataReport.type === 'ASC' && (
                                                    <p>
                                                        Tăng <span>{dataReport.value}%</span> so với tháng trước
                                                    </p>
                                                )}
                                                {dataReport && dataReport.type === 'DESC' && (
                                                    <p>
                                                        Giảm{' '}
                                                        <span style={{ color: '#F5222D' }}>{dataReport.value}%</span> so
                                                        với tháng trước
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="box__content text-center">
                                                <p className="tip">
                                                    Bạn chưa hiểu được cách bán hàng, các quy định, chính sách của
                                                    Dimuadi?
                                                </p>
                                            </div>
                                            <div className="btn__form">
                                                <Button
                                                    onClick={() => dispatch(togglePopupWelcomeUser(true))}
                                                    size="large"
                                                    type="primary"
                                                >
                                                    Tìm hiểu ngay
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="home__box" id="home__box">
                                    <HomeBanner listBanner={listBanner} isMobile={true} />
                                </div>
                            )}
                        </div>
                    </div>
                    {isPublisher !== 1 && (
                        <div className={`home__list__options border__bottom home__product__item`}>
                            <div className="container">
                                <div className="list__options">
                                    {Array.isArray(_listCategory) &&
                                        _listCategory.length > 0 &&
                                        _listCategory.map((category) => {
                                            return (
                                                <div key={category.id} className="option__item">
                                                    <Link to={`/list-product?category_id=${category.id}`}>
                                                        <div className="option__icon">
                                                            <img
                                                                src={convertSizeImage(category.image)}
                                                                alt={category.code}
                                                            />
                                                        </div>
                                                        <p>{category.name}</p>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    )}
                    <HomeMarketing isPublisher={isPublisher} listBanner={listBanner} />
                    {isPublisher !== 1 && <HomeSuperDiscountAll listHighDiscount={listHighDiscount} />}

                    {getDealHotRender('mobile')}
                    {getHotCoupon('mobile')}
                    
                    {isPublisher === 1 && <HomeSuperDiscount deadline={deadline} listHighDiscount={listHighDiscount} />}
                    <div className="home__product__item hot__week">
                        <div className="container">
                            <h2 className="title">Sản phẩm mới chia sẻ gần đây</h2>
                            <Swiper slidesPerView={3.5}>
                                {Array.isArray(listMostShared) &&
                                    listMostShared.length &&
                                    listMostShared.map((item) => {
                                        return (
                                            <SwiperSlide key={item.id}>
                                                <a href={`${PATH_PRODUCT}/${item.id}`} className="hot__week__item">
                                                    <img
                                                        src={convertSizeImage(checkURLImage(item.thumbImage), 200, 200)}
                                                        alt=""
                                                    />
                                                    {isPublisher === 1 ? (
                                                        <p className="chiet-khau">
                                                            <IconHomeChietKhau />
                                                            <span>
                                                                <DisplayPrice
                                                                    price={convertCommission(
                                                                        item.price,
                                                                        item.commissionValue,
                                                                        item.commissionType
                                                                    )}
                                                                />
                                                            </span>
                                                        </p>
                                                    ) : (
                                                        <p className="chiet-khau">
                                                            <span>
                                                                <DisplayPrice price={item.price} />
                                                            </span>
                                                        </p>
                                                    )}
                                                    {item.discountAmount && (
                                                        <div className="discount">
                                                            <span>{item.discountAmount}%</span>
                                                        </div>
                                                    )}
                                                </a>
                                            </SwiperSlide>
                                        )
                                    })}
                            </Swiper>
                        </div>
                    </div>
                    {/* {isPublisher === 0 && */}

                     <TopProduct listTop={listTop}  className={'home__product__item home-top-5'} />
                     {/* } */}
                    {isPublisher === 1 && (
                        <div className="home__list__options border__bottom home__product__item">
                            <div className="container">
                                <h2 className="title">Top danh mục sản phẩm</h2>
                                <div className="list__options">
                                    {Array.isArray(_listCategory) &&
                                        _listCategory.length > 0 &&
                                        _listCategory.map((category) => {
                                            return (
                                                <div key={category.id} className="option__item">
                                                    <Link to={`/list-product?category_id=${category.id}`}>
                                                        <div className="option__icon">
                                                            <img
                                                                src={convertSizeImage(
                                                                    checkURLImage(category.image),
                                                                    200,
                                                                    200
                                                                )}
                                                                alt={category.code}
                                                            />
                                                        </div>
                                                        <p>{category.name}</p>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="home__product__content">
                        <div className="header__title">
                            <div className="container">
                                <h2>{isPublisher === 1 ? 'Gợi ý dành cho bạn' : 'Tất cả sản phẩm'}</h2>
                            </div>
                        </div>
                        <HomeSuggestion isPublisher={isPublisher} listSuggestion={listSuggestion} />
                    </div>
                    {reLoadingSuggestion && pagingSuggestion.page < pagingSuggestion.totalPage && <LoadingComponent />}
                    {isPublisher !== 1 && (
                        <div className="home__banner__bottom">
                            <div className="container">
                                <div className="home__banner__bottom__content">
                                    {listBanner && listBanner.buyerHomeBottom && (
                                        <a href={listBanner.buyerHomeBottom[0].url}>
                                            <img src={listBanner.buyerHomeBottom[0].image} alt="" />
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {isPublisher !== 1 && (
                        <div className="home__chanel">
                            <div className="container">
                                <div className="home__chanel__content">
                                    <p>Tạo kênh bán hàng dành riêng cho bạn!</p>
                                    <h4>Thao tác ĐƠN GIẢN kiếm tiền GIẢN ĐƠN !!!</h4>
                                    <Button onClick={() => upgradeRole()} type="primary" size="large">
                                        Trở thành Dimuadi-er ngay
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                    <NotiUser />
                </div>
            )}
            {!(isMobileScreen() || isMaxTableScreen()) && (
                <div className="home__desktop">
                    <div className="container">
                        <div className="home__desktop__content">
                            <div className="home__left">
                                <div className="search__product__desktop">
                                    <div className="input__search__desktop">
                                        <Form.Item>
                                            <Input
                                                size="large"
                                                style={{ border: '1px solid #BFBFBF', borderRadius: 4 }}
                                                placeholder="Nhập tên sản phẩm"
                                                suffix={<SearchOutlined onClick={handleIconSearchClick} />}
                                                value={nameProduct}
                                                onChange={handleChangeName}
                                                // onKeyPress={handleKeyPressName}
                                                onKeyPress={onKeyPressSearchHome}
                                            />
                                        </Form.Item>
                                    </div>
                                    <SuggestProduct
                                        isOpenProduct={isOpenProduct}
                                        listProductSearchDesktop={listProductSearchDesktop}
                                    />
                                </div>

                                <HomeBanner attrClass={'home__banner__desktop'}
                                    listBanner={listBanner}
                                    isMobile={false} />

                                <div className='home__category home__desktop__item'>
                                    <div className='title'>
                                        <h2>Các danh mục sản phẩm hot nhất tại Dimuadi</h2>
                                    </div>
                                    <div className="home__list__options border__bottom">
                                        <div className="list__options">
                                            {Array.isArray(_listCategory) &&
                                                _listCategory.length > 0 &&
                                                _listCategory.map((category) => {
                                                    return (
                                                        <div key={category.id} className="option__item">
                                                            <Link to={`/list-product?category_id=${category.id}`}>
                                                                <div className="option__icon">
                                                                    <img
                                                                        src={
                                                                            checkURLImage(category.image) ||
                                                                            DEFAULT_IMAGE
                                                                        }
                                                                        alt={category.code}
                                                                    />
                                                                </div>
                                                                <p>{category.name}</p>
                                                            </Link>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                    </div>
                                </div>

                                {getDealHotRender('desktop')}
                                {getHotCoupon('desktop')}

                                <div className="home__share home__desktop__item">
                                    <div className="title">
                                        <h2>Sản phẩm mới chia sẻ gần đây</h2>
                                    </div>
                                    <div className="home__product">
                                        {Array.isArray(listMostSharedDesktop) &&
                                            listMostSharedDesktop.length > 0 &&
                                            listMostSharedDesktop.map((item) => {
                                                return (
                                                    <div key={item.id} className="item__product">
                                                        <a
                                                            style={{ color: '#262626' }}
                                                            href={`${PATH_PRODUCT}/${item.id}`}
                                                        >
                                                            <div className="item__image">
                                                                {item.status === 3 && (
                                                                    <div className="bg_out_of_stock">
                                                                        <span>Tạm hết hàng</span>
                                                                    </div>
                                                                )}
                                                                <img
                                                                    src={
                                                                        checkURLImage(item.thumbImage) || DEFAULT_IMAGE
                                                                    }
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <p className="title">{item.name}</p>
                                                            <RenderPrice 
                                                            data={item} 
                                                            isPublisher = {isPublisher} />
                                                        </a>
                                                    </div>
                                                )
                                            })}
                                        {listSuggestion.length === 0 && <ErrorProduct />}
                                    </div>
                                    {listMostSharedDesktop.length === 4 && (
                                        <div className="btn__group">
                                            <Button
                                                onClick={() => handleShareSeeMore('share')}
                                                size="large"
                                                className="btn_choose_blue"
                                            >
                                                Xem thêm
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                
                                <div className="home__suggestion home__desktop__item">
                                    <div className="title">
                                        <h2>Tất cả sản phẩm</h2>
                                    </div>
                                    <HomeSuggestion listSuggestion={listSuggestion} isPublisher={isPublisher} />
                                    {listSuggestion && listSuggestion.length > 0 && (
                                        <div className="home__paging">
                                            <PaginationContainer
                                                page={pagingSuggestion.page}
                                                pageSize={pagingSuggestion.pageSize}
                                                total={pagingSuggestion.total}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="home__right">
                                {isLoggedIn && (
                                    <div className="home__box">
                                        {!user.isNewUser ? (
                                            <div>
                                                <div className="box__content ">
                                                    <div className="box__commission">
                                                        <p className="total__commission">Tổng hoa hồng tạm duyệt</p>
                                                        <Link to="/dashboard" style={{ fontSize: 15 }}>
                                                            Chi tiết
                                                        </Link>
                                                    </div>
                                                    <span className="total__price">
                                                        <DisplayPrice price={dataReport.preApproveCom} />
                                                    </span>
                                                </div>
                                                <div className="box__footer">
                                                    {dataReport && dataReport.type === 'ASC' ? (
                                                        <div className="icon">
                                                            <IconPlusCommission />
                                                        </div>
                                                    ) : (
                                                        <div className="icon">
                                                            <IconPlusCommission color="#F5222D" />
                                                        </div>
                                                    )}
                                                    {dataReport && dataReport.type === 'ASC' && (
                                                        <p>
                                                            Tăng <span>{dataReport.value}%</span> so với tháng trước
                                                        </p>
                                                    )}
                                                    {dataReport && dataReport.type === 'DESC' && (
                                                        <p>
                                                            Giảm{' '}
                                                            <span style={{ color: '#F5222D' }}>
                                                                {dataReport.value}%
                                                            </span>{' '}
                                                            so với tháng trước
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <div className="box__content text-center">
                                                    <p className="tip">
                                                        Bạn chưa hiểu được cách bán hàng, các quy định, chính sách của
                                                        Dimuadi?
                                                    </p>
                                                </div>
                                                <div className="btn__form">
                                                    <Button
                                                        onClick={() => dispatch(togglePopupWelcomeUser(true))}
                                                        size="large"
                                                        type="primary"
                                                    >
                                                        Tìm hiểu ngay
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <TopProduct listTop={listTop} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <TutorialWelcomeUser onClose={() => dispatch(togglePopupWelcomeUser(false))} />
            <BoxSearch />
        </section>
    )
}

export default Home
