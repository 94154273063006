import ApiMicroService from './request-micro-service'

export const addProductToCart = async (data) => {
    try {
        const results = await ApiMicroService.post(`/d2c-service/shopping-cart/products-has-coupon`, data)
        return results.data
    } catch (e) {
        throw e
    }
}

export const deleteProductCart = async (data) => {
    try {
        const results = await ApiMicroService.delete(`/d2c-service/shopping-cart/products`, { data })
        return results.data
    } catch (e) {
        throw e
    }
}

export const updateProductCart = async (data) => {
    try {
        const results = await ApiMicroService.patch(`/d2c-service/shopping-cart/products-has-coupon`, data)
        return results.data
    } catch (e) {
        throw e
    }
}



export const fetchProductFromCart = async () => {
    try {
        const results = await ApiMicroService.get(`/d2c-service/shopping-cart`)
        return results.data
    } catch (e) {
        throw e
    }
}
