import React, { useEffect, useState } from 'react'
import CheckOutStep1 from '../../../component/buyer/check-out/CheckOutStep1'
import { useRouteMatch, withRouter } from 'react-router'
import { connect, useDispatch } from 'react-redux'
import { toggleShowLoading } from '../../../redux/actions/home-actions'
import { message } from 'antd'
import { handleSetIsAddress } from '../../../redux/actions/checkout-action'
import { fetchAddressUser, fetchListProvince } from '../../../api-services/address-api'
import { fetchProductFromCart } from '../../../api-services/cart-api'
import { createOrder, createOrderBuyOne } from '../../../api-services/order-id'
import { getDetailProduct } from '../../../api-services/product-api'
import { getSessionAddressReview, getSessionListAddress, saveAddressReview } from '../../../functions/common-func'
import * as queryString from 'query-string'

function CheckOutStep1Container({ history, isAddress, location }) {
    const [couponCode, setCouponCode] = useState('')
    const [listProductFromCart, setListProductFromCart] = useState([])
    const [totalPrice, setTotalPrice] = useState(0)
    const [validateCoupon, setValidateCoupon] = useState({ status: 'success', text: '' })
    const [idProduct, setIdProduct] = useState(null)
    // kích thước, màu sắc
    const [color, setColor] = useState('')
    const [size, setSize] = useState('')
    // address
    const [validateName, setValidateName] = useState({ status: '', text: '' })
    const [validatePhone, setValidatePhone] = useState({ status: '', text: '' })
    const [validateAddress, setValidateAddress] = useState({ status: '', text: '' })
    const [validateProvince, setValidateProvince] = useState({ status: '', text: '' })
    const [validateDistrict, setValidateDistrict] = useState({ status: '', text: '' })
    const [validateCommune, setValidateCommune] = useState({ status: '', text: '' })
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [note, setNote] = useState('')
    const [address, setAddress] = useState('')
    const [province, setProvince] = useState(false)
    const [district, setDistrict] = useState(false)
    const [commune, setCommune] = useState(false)
    const [listProvince, setListProvince] = useState([])
    const [listDistrict, setListDistrict] = useState([])
    const [listCommune, setListCommune] = useState([])
    const [isDisableDistrict, setIsDisableDistrict] = useState(true)
    const [isDisableCommune, setIsDisableCommune] = useState(true)
    const [isRefresh, setIsRefresh] = useState(false);
    const dispatch = useDispatch()
    const match = useRouteMatch()

    const clearValidate = () => {
        setValidateName({ status: '', text: '' })
        setValidatePhone({ status: '', text: '' })
        setValidateAddress({ status: '', text: '' })
        setValidateProvince({ status: '', text: '' })
        setValidateDistrict({ status: '', text: '' })
        setValidateCommune({ status: '', text: '' })
    }
    useEffect(() => {
        getListProvince()
    }, [])

    useEffect(() => {
        const query = queryString.parse(location.search)
        let address = getSessionAddressReview()

        if (query.color) {
            setColor(query.color)
            setSize(query.size)
        }
        if (address) {
            setName(address.username)
            setPhone(address.phoneNumber)
            setNote(address.note)
            setAddress(address.address)
            setProvince({
                value: address.provinceId,
                label: address.provinceName,
            })
            setDistrict({
                value: address.districtId,
                label: address.districtName,
            })
            setCommune({
                value: address.wardId,
                label: address.wardName,
            })
        }
    }, [location])

    useEffect(() => {
        if (province) {
            setIsDisableDistrict(false)
        }
        if (district) {
            setIsDisableCommune(false)
        }
    }, [province, district])

    const getListProvince = async () => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await fetchListProvince()
            dispatch(toggleShowLoading(false))
            if (data && data.status === 200) {
                setListProvince(
                    data.data.map((res) => {
                        return {
                            label: res.name,
                            value: res.id,
                        }
                    })
                )
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    const handleChangeName = (e) => {
        setName(e.target.value)
        clearValidate()
    }

    const handleChangePhone = (e) => {
        setPhone(e.target.value)
        clearValidate()
    }

    const handleChangeNote = (e) => {
        setNote(e.target.value)

    }

    const handleChangeAddress = (e) => {
        setAddress(e.target.value)
        clearValidate()
    }

    const handleChangeProvince = async (value) => {
        setProvince(value)
        setDistrict(null)
        setCommune(null)
        clearValidate()
        if (value) {
            await getListDistrict(value.value)
        }
    }

    const handleChangeDistrict = async (value) => {
        setDistrict(value)
        setCommune(null)
        if (value) {
            await getListCommune(value.value)
        }
        clearValidate()
    }

    const getListDistrict = async (province) => {
        try {
            let filter = '?type=district'
            if (province) {
                filter += `&parent_id=${province}`
            }
            const data = await fetchListProvince(filter)
            if (data && data.status === 200) {
                setListDistrict(
                    data.data.map((res) => {
                        return {
                            label: res.name,
                            value: res.id,
                        }
                    })
                )
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    const getListCommune = async (district_id) => {
        try {
            let filter = '?type=ward'
            if (district_id) {
                filter += `&parent_id=${district_id}`
            }
            const data = await fetchListProvince(filter)
            if (data && data.status === 200) {
                setListCommune(
                    data.data.map((res) => {
                        return {
                            label: res.name,
                            value: res.id,
                        }
                    })
                )
            } else {
                message.error(data.message)
            }
        } catch (e) {
            throw e
        }
    }

    const handleChangeCommune = (value) => {
        setCommune(value)
        clearValidate()
    }

    const onBack = () => {
        history.goBack()
    }

    const handleCouponCode = (e) => {
        setCouponCode(e.target.value)
        setValidateCoupon({ status: 'success', text: '' })
    }

    const handleCouponApply = () => {
        if (!couponCode) {
            setValidateCoupon({ status: 'error', text: 'Vui lòng nhập mã giảm giá' })
            return
        }
        try {
            setValidateCoupon({ status: 'success', text: 'Áp dụng thành công' })
        } catch (e) {
            throw e
        }
    }

    useEffect(() => {
        const id = match.params.id
        if (id) {
            getDataDetail(id)
            setIdProduct(id)
            const _listAddress = getSessionListAddress()
            if (_listAddress.length > 0) {
                _listAddress.forEach(async (res) => {
                    if (res.isDefault === true) {
                        if (isAddress) {
                            dispatch(handleSetIsAddress(isAddress))
                        } else {
                            dispatch(handleSetIsAddress(res))
                        }
                    }
                })
            }
        } else {
            getListAddress()
            getListProductFromCart()
        }
    }, [isRefresh])

    const getDataDetail = async (id) => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await getDetailProduct(id)
            dispatch(toggleShowLoading(false))
            if (data && data.status === 200) {
                setListProductFromCart([
                    {
                        product: data.data,
                        productId: data.data.id,
                        quantity: 1,
                    },
                ])
                let total_price = 0

                total_price += parseInt(Number(data.data.price).toFixed(0))
                setTotalPrice(total_price)
            } else {
                message.error(data.message)
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))

            throw e
        }
    }

    const getListProductFromCart = async () => {
        try {
            const data = await fetchProductFromCart()
            if (data && data.status === 200 && data.data) {
                setListProductFromCart(data.data.shoppingCartItems)
                let total_price = 0
                data.data.shoppingCartItems.forEach((res) => {
                    if (res && res.product) {
                        total_price += parseInt(Number(res.productPrice * res.quantity).toFixed(0))
                    }
                })
                setTotalPrice(total_price)
            }
        } catch (e) {
            throw e
        }
    }

    const getListAddress = async () => {
        try {
            dispatch(toggleShowLoading(true))
            const data = await fetchAddressUser()
            dispatch(toggleShowLoading(false))
            if (data && data.status === 200) {
                data.data.forEach(async (res) => {
                    if (res.isDefault === true) {
                        if (isAddress) {
                            dispatch(handleSetIsAddress(isAddress))
                        } else {
                            dispatch(handleSetIsAddress(res))
                        }
                    }
                })
            } else {
                message.error(data.message)
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))

            throw e
        }
    }

    const onChangeQuantity = async (index, type) => {
        try {
            let newListProduct = [...listProductFromCart]
            let quantity = newListProduct[index].quantity
            if (quantity < 10 && type === 'plus') {
                quantity += 1
            }
            if (quantity > 1 && type === 'minus') {
                quantity -= 1
            }
            newListProduct[index].quantity = quantity
            let total_price = parseInt(Number(newListProduct[0].productPrice * quantity).toFixed(0))
            setTotalPrice(total_price)
            setListProductFromCart(newListProduct)
        } catch (e) {
            dispatch(toggleShowLoading(false))
            throw e
        }
    }

    const handleCreateOrder = async () => {
        try {
            if (listProductFromCart && listProductFromCart.length > 0) {
                if (idProduct) {
                    //let filter = /([0-9]{9})/g
                    let phoneFilter = /((09|03|07|08|05)+([0-9]{8})\b)/g
                    let isValidForm = true
                    if (!name) {
                        setValidateName({ status: 'error', text: 'Vui lòng nhập tên người mua' })
                        isValidForm = false
                    }
                    if (!phone) {
                        setValidatePhone({ status: 'error', text: 'Vui lòng nhập số điện thoại' })
                        isValidForm = false
                    }
                    if (!phoneFilter.test(phone)) {
                        setValidatePhone({ status: 'error', text: 'Vui lòng nhập đúng số điện thoại' })
                        isValidForm = false
                    }
                    if (!address) {
                        setValidateAddress({ status: 'error', text: 'Vui lòng nhập địa chỉ' })
                        isValidForm = false
                    }
                    if (!province) {
                        setValidateProvince({ status: 'error', text: 'Vui lòng chọn tỉnh / thành phố' })
                        isValidForm = false
                    }
                    if (!district) {
                        setValidateDistrict({ status: 'error', text: 'Vui lòng nhập quận / huyện' })
                        isValidForm = false
                    }
                    if (!commune) {
                        setValidateCommune({ status: 'error', text: 'Vui lòng nhập phường / xã' })
                        isValidForm = false
                    }

                    if(!isValidForm) {
                        return
                    }

                    saveAddressReview({
                        username: name,
                        phoneNumber: phone,
                        country: 'Việt Nam',
                        address,
                        provinceId: province.value,
                        districtId: district.value,
                        wardId: commune.value,
                        provinceName: province.label,
                        districtName: district.label,
                        wardName: commune.label,
                        note: note,
                    })

                    const quantity = listProductFromCart[0].quantity;
                    history.push(`/thanh-toan/review/${idProduct}?color=9${color}&size=9${size}&quantity=${quantity}`)
                    // qua review để thanh toán
                } else {
                    let orderData = {
                        shippingAddress: {
                            username: isAddress && isAddress.username,
                            phoneNumber: isAddress && isAddress.phoneNumber,
                            country: 'Việt Nam',
                            address: isAddress && isAddress.address,
                            provinceId: isAddress && isAddress.provinceId,
                            districtId: isAddress && isAddress.districtId,
                            wardId: isAddress && isAddress.wardId,
                            provinceName: isAddress && isAddress.provinceName,
                            districtName: isAddress && isAddress.districtName,
                            wardName: isAddress && isAddress.wardName,
                            note: note,
                        },
                        cartItemList: [],
                    }
                    orderData.cartItemList = listProductFromCart.map((res) => {
                        return {
                            itemCode: res.itemCode,
                        }
                    })
                    dispatch(toggleShowLoading(true))
                    const result = await createOrder(orderData)
                    dispatch(toggleShowLoading(false))
                    if (result && result.status === 200) {
                        if (result.data && result.data.status === 'success') {
                            history.push('/thanh-cong')
                        }
                        if (result.data && result.data.status === 'fail') {
                            history.push('/that-bai')
                        }
                    } else {
                        history.push('/that-bai')
                    }
                }
            } else {
                message.error('Vui lòng chọn sản phẩm')
            }
        } catch (e) {
            dispatch(toggleShowLoading(false))
            if (e && e.response && e.response.data && e.response.data.code === 'E00024') {
                message.error(e.response.data.message.message)
                return
            }
            history.push('/that-bai')
            throw e
        }
    }
    return (
        <CheckOutStep1
            validateCoupon={validateCoupon}
            couponCode={couponCode}
            listProductFromCart={listProductFromCart}
            totalPrice={totalPrice}
            isAddress={isAddress}
            onBack={onBack}
            handleCouponApply={handleCouponApply}
            handleCouponCode={handleCouponCode}
            handleCreateOrder={handleCreateOrder}
            idProduct={idProduct}
            onChangeQuantity={onChangeQuantity}
            // address
            name={name}
            phone={phone}
            address={address}
            district={district}
            province={province}
            note={note}
            commune={commune}
            setListProvince={setListProvince}
            isDisableDistrict={isDisableDistrict}
            isDisableCommune={isDisableCommune}
            listProvince={listProvince}
            listDistrict={listDistrict}
            listCommune={listCommune}
            validateName={validateName}
            validatePhone={validatePhone}
            validateAddress={validateAddress}
            validateProvince={validateProvince}
            validateDistrict={validateDistrict}
            validateCommune={validateCommune}
            handleChangeName={handleChangeName}
            handleChangePhone={handleChangePhone}
            handleChangeNote={handleChangeNote}
            handleChangeAddress={handleChangeAddress}
            handleChangeProvince={handleChangeProvince}
            handleChangeDistrict={handleChangeDistrict}
            handleChangeCommune={handleChangeCommune}
        />
    )
}
const mapStateToProps = function (state) {
    return {
        toggleCart: state.homeReducer.toggleCart,
        isAddress: state.checkoutReducer.isAddress,
    }
}
export default withRouter(connect(mapStateToProps)(CheckOutStep1Container))
